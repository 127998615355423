import { ActionExtended } from '../../_common/interfaces/ActionExtended'
import { ACTIONS } from '../../_config'

export interface UsecaseReducer {
  usecase: any | null
}

const initialState: UsecaseReducer = {
  usecase: null
}

const usecaseReducer = (state = initialState, action: ActionExtended) => {
  switch (action.type) {
    case ACTIONS.USECASE.USECASE:
      return {
        ...state,
        usecase: action.payload.usecase
      }
    default:
      return state
  }
}

export default usecaseReducer
