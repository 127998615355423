import React, { useEffect, useRef, useState } from "react";
import './pagination.scss'
import Icon from "../icon";

interface props {
    total: number
    page: number
    pageLimit: number
    setPage: (n: number) => void
}


export function Pagination({ total, page, pageLimit, setPage }: props) {

    const [pages, setPages] = useState<any>(0);
    const pageNumberRef: any = useRef();

    const fetchPageNumbers = (totalPages: number, currentPage: number) => {
        let pageNumbers = []
        if (totalPages <= 6) {
            for (let i = 1; i <= totalPages; i++) {
                pageNumbers.push(i)
            }
        } else {
            // Always print first page button
            pageNumbers.push(1)

            // Print "..." only if currentPage is > 3
            if (currentPage > 3) {
                pageNumbers.push('...')
            }

            // special case where last page is selected...
            if (currentPage == totalPages) {
                pageNumbers.push(currentPage - 2)
            }

            // Print previous number button if currentPage > 2
            if (currentPage > 2) {
                pageNumbers.push(currentPage - 1)
            }

            //Print current page number button as long as it not the first or last page
            if (currentPage != 1 && currentPage != totalPages) {
                pageNumbers.push(currentPage)
            }

            //print next number button if currentPage < lastPage - 1
            if (currentPage < totalPages - 1) {
                pageNumbers.push(currentPage + 1)
            }

            // special case where first page is selected...
            if (currentPage == 1) {
                pageNumbers.push(currentPage + 2)
            }

            //print "..." if currentPage is < lastPage -2
            if (currentPage < totalPages - 2) {
                pageNumbers.push('...')
            }

            //Always print last page button if there is more than 1 page
            pageNumbers.push(totalPages)
        }
        return pageNumbers.length > 1 ? pageNumbers : []
    }


    useEffect(() => {
        setPages(fetchPageNumbers(Math.ceil(total / pageLimit), page));
        pageNumberRef.current.value = page
    }, [total, page, pageLimit])


    return (
        <React.Fragment>


            <div className="pagination">
                <div className="show-per-page">
                    {/* <input
                  type="number"
                  minLength={1}
                  maxLength={3}
                  defaultValue={data?.pagination.per_page}
                  onChange={changePerPageInput}
                  ref={pageLimit}
                /> */}
                    {/* <label>Entries per page</label> */}
                </div>
                <ul className="pages">
                    {pages &&
                        pages.length > 0 &&
                        pages.map((pageNumber: any, i: any) => {
                            return (
                                <li
                                    key={i}
                                    className={'page ' + (page == pageNumber ? 'active' : '')}
                                    onClick={() => {
                                        if (pageNumber !== '...') { setPage(pageNumber) }
                                    }}
                                >
                                    <span>{pageNumber}</span>
                                </li>
                            )
                        })}
                </ul>
                <div className="current-page  ">
                </div>
                <div className="current-page visually-hidden">
                    {' '}
                    Page &nbsp;
                    <input
                        ref={pageNumberRef}
                        type="number"
                        className=""
                        onChange={(e: any) => {
                            if (e.target.value < 1) {
                                setPage(1);
                                e.target.value = 1
                            } else if (e.target.value > Math.ceil(total / pageLimit)) {
                                setPage(Math.ceil(total / pageLimit));
                                e.target.value = Math.ceil(total / pageLimit)
                            } else {
                                setPage(e.target.value)
                            }

                        }}
                    />
                </div>
            </div>




        </React.Fragment>

    );
}

export default Pagination;