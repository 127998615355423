/* for all table loader with no-data view  */
import { useEffect, useState } from "react"
import Loader from "./loader"

interface props {
    data?: any[] | null
}
export default function TableLoader({ data }: props) {
    return (
        <>
            {data && data.length == 0 && (
                <div className="no-data">
                    <img src="/images/no-data.jpg" alt="" />
                    <p>Details Coming Soon!</p>
                </div>

            )}
            {data == null && (
                <Loader/>
            )}
        </>
    )
}



