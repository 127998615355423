import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { replaceUrlParams } from "../../../_config/helpers";
import { URLS } from "../../../_config";
import { MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight } from "react-icons/md";

interface props {
    page?: string
}

const OverviewNextPervious = ({ page }: props) => {
    const { id } = useParams();
    const sections: any = ['Hanger', 'Taxi to Runway', 'In the Air', 'Landed'];

    const [prevBtnDisabled, setPrevBtnDisabled] = useState<string>('disabled')
    const [nextBtnDisabled, setNextBtnDisabled] = useState<string>('disabled')
    const [prevBtnLink, setPrevBtnLink] = useState<any>('#')
    const [nextBtnLink, setNextBtnLink] = useState<any>('#')

    const getPreviousPage = () => {
        let url = '';
        switch (page) {
            case 'Hanger': url = '#'; break;
            case 'Taxi to Runway': url = replaceUrlParams(URLS.USE_CASE_HANGER_OVERVIEW, { id: id }); break;
            case 'In the Air': url = replaceUrlParams(URLS.USE_CASE_RUNWAY_OVERVIEW, { id: id }); break;
            case 'Landed': url = replaceUrlParams(URLS.USE_CASE_IN_FLIGHT_OVERVIEW, { id: id }); break;
            default:
                break;
        }
        setPrevBtnLink(url);
    }

    const getNextPage = () => {
        let url = '';
        switch (page) {
            case 'Hanger': url = replaceUrlParams(URLS.USE_CASE_RUNWAY_OVERVIEW, { id: id }); break;
            case 'Taxi to Runway': url = replaceUrlParams(URLS.USE_CASE_IN_FLIGHT_OVERVIEW, { id: id }); break;
            case 'In the Air': url = replaceUrlParams(URLS.USE_CASE_LANDED_OVERVIEW, { id: id }); break;
            case 'Landed': url = '#'; break;
            default:
                break;
        }
        setNextBtnLink(url);
    }

    useEffect(() => {
        getPreviousPage()
        getNextPage()
    }, [])

    return <React.Fragment>
        <div className="text-center print-hide">
            <Link to={prevBtnLink} className={`btn btn-primary m-2 ${prevBtnLink == '#' && 'disabled'}`} >
                <MdKeyboardDoubleArrowLeft />
                &nbsp; Previous
            </Link>
            <Link to={nextBtnLink} className={`btn btn-primary m-2 ${nextBtnLink == '#' && 'disabled'}`} >
                Next &nbsp;
                <MdKeyboardDoubleArrowRight />
            </Link>
        </div>
    </React.Fragment>
}

export default OverviewNextPervious;