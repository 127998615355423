import React, { useEffect, useState } from "react";
import './DataFilters.scss'
import { Controller, useForm } from "react-hook-form";
import Select from "react-select"
import { useMasterWorkflows, usePhases } from "../../_common/hooks/masterHook";
import { useMasterApi } from "../../_common/hooks/api/MasterApiHook";
import { useUser } from "../../_common/hooks/authHook";
import { useDispatch } from "react-redux";
import { ACTIONS } from "../../_config";
import { useUserApi } from "../../_common/hooks/api/UserApiHook";
import { usecaseFilterData } from "../../_config/dataTypes";
import { showDate } from "../../_config/functions";


interface props {
    setFilter: (data: usecaseFilterData) => void,
    dates?: boolean
}

export function DataFiltersPeriodDashboard({ setFilter, dates = true }: props) {

    const { register, control, handleSubmit, watch, setValue, formState: { errors, isValid }, } = useForm({ reValidateMode: 'onChange' });


    const phasesList: any = usePhases();
    const workflows: any = useMasterWorkflows();
    const user: any = useUser();
    const dispatch: any = useDispatch();
    const masterApi = useMasterApi();
    const userApi = useUserApi();

    const [sectionList, setSectionList] = useState<any>([]);
    const [phaseList, setPhaseList] = useState<any>([]);
    const [airportList, setAirportList] = useState<any>([]);
    const [runwayList, setRunwayList] = useState<any>([]);
    const [captainList, setCaptainList] = useState<any>([]);


    const today = new Date()
    const period2_date2 = showDate(today, 'YYYY-MM-DD')

    const date2 = today.setMonth(today.getMonth() - 3)
    const period2_date1 = showDate(date2, 'YYYY-MM-DD')

    const period1_date2 = showDate(date2, 'YYYY-MM-DD')

    const date3 = today.setMonth(today.getMonth() - 3)
    const period1_date1 = showDate(date3, 'YYYY-MM-DD')

    const sectionWatch = watch('section');
    const defaultFilter: any = {
        firstPhaseStartDate: period1_date1,
        firstPhaseEndDate: period1_date2,
        secondPhaseStartDate: period2_date1,
        secondPhaseEndDate: period2_date2
    }

    const [filter, _setFilter] = useState<usecaseFilterData>(defaultFilter)




    const getSections = () => {
        masterApi.getSections({ urlParams: { companyID: user?.companyID } }, (msg: any, resp: any) => {
            let workflowSteps: any = [];
            let sections: any = resp.data.workFlowSection
            masterApi.getPhases({ urlParams: { companyID: user?.companyID } }, (msg: any, resp: any) => {
                let phases: any = resp.data.workFlowPhase
                sections.map((section: any) => {
                    let sec: any = {
                        sectionDetails: section,
                        phases: []
                    };
                    phases.map((phase: any) => {
                        if (phase.fkSectionValue == section.sectionID) {
                            sec.phases.push(phase)
                        }
                    })
                    workflowSteps.push(sec)
                })
                dispatch({
                    type: ACTIONS.MASTER.WORKFLOWS, payload: {
                        workflows: workflowSteps
                    }
                })
            }, (msg: any) => { })
        }, (msg: any) => { })
    }

    const updateFilter = (name: any, data: any) => {
        switch (name) {
            case 'sectionIDs':
                let _value: any = (data.map((_data: any) => {
                    return _data.value
                })).join(',')
                _setFilter({ ...filter, ...{ sectionIDs: _value } })
                break;

            case 'phaseIDs':
                let _valuePhaseIDs: any = (data.map((_data: any) => {
                    return _data.value
                })).join(',')
                _setFilter({ ...filter, ...{ phaseIDs: _valuePhaseIDs } })
                break;

            case 'firstPhaseStartDate':
                let _valuefirstPhaseStartDate: any = data?.target?.value
                _setFilter({ ...filter, ...{ firstPhaseStartDate: _valuefirstPhaseStartDate } })
                break;
            case 'firstPhaseEndDate':
                let _valuefirstPhaseEndDate: any = data?.target?.value
                _setFilter({ ...filter, ...{ firstPhaseEndDate: _valuefirstPhaseEndDate } })
                break;
            case 'secondPhaseStartDate':
                let _valuesecondPhaseStartDate: any = data?.target?.value
                _setFilter({ ...filter, ...{ secondPhaseStartDate: _valuesecondPhaseStartDate } })
                break;
            case 'secondPhaseEndDate':
                let _valuesecondPhaseEndDate: any = data?.target?.value
                _setFilter({ ...filter, ...{ secondPhaseEndDate: _valuesecondPhaseEndDate } })
                break;


            default:
                break;
        }
    }

    useEffect(() => {
        setFilter(filter);
    }, [filter])



    useEffect(() => {
        if (sectionWatch && sectionWatch.length > 0) {
            let phases: any = [];
            sectionWatch.map((sec: any) => {
                phases = [...phases, ...sec.phases]
            })
            setPhaseList(phases)
        } else {
            setPhaseList([])
        }
    }, [sectionWatch])

    useEffect(() => {
        if (workflows == null) {
            getSections()  /* if workflows is not present in redux store. */
        } else {
            setSectionList(workflows?.map((section: any) => {
                section.label = section.sectionDetails.title
                section.value = section.sectionDetails.sectionID

                section.phases = section.phases.map((phase: any) => {
                    phase.label = phase.title
                    phase.value = phase.phaseValue

                    return phase;
                })
                return section;
            }))
        }
    }, [workflows])



    return (
        <div className="table-filter-panel mb-3 d-flex gap-3">
            <div className="flex-1">
                <label>Section</label>
                <Controller
                    control={control}
                    name={'section'}
                    render={({ field: { onChange, value, ref, name } }) => (
                        <Select
                            name={name}
                            options={sectionList}
                            onChange={(data) => {
                                updateFilter('sectionIDs', data)
                                onChange(data)
                                setValue('phase', null)
                            }}
                            value={value}
                            isMulti={true}
                            ref={ref}
                            isClearable={true}
                            placeholder="ALL"
                        />
                    )}
                />
            </div>

            <div className="flex-1">
                <label>Phase</label>
                <Controller
                    control={control}
                    name={'phase'}
                    render={({ field: { onChange, value, ref, name } }) => (
                        <Select
                            name={name}
                            options={phaseList}
                            onChange={(data) => {
                                updateFilter('phaseIDs', data)
                                onChange(data)
                            }}
                            value={value}
                            ref={ref}
                            isMulti={true}
                            // placeholder={sectionWatch ? 'Select phase...' : 'Select section first...'}
                            isClearable={true}
                            placeholder="ALL"
                        />
                    )}
                />
            </div>

            <div className="flex-1">
                <label>Date Range 1</label>
                <div className="d-flex date-range-flds">
                    <input type="date" className="form-control "
                        onChange={(data) => {
                            updateFilter('firstPhaseStartDate', data)
                        }} defaultValue={period1_date1}
                    // style={{ width: '130px' }} 
                    />
                    <input type="date" className="form-control"
                        onChange={(data) => {
                            updateFilter('firstPhaseEndDate', data)
                        }} defaultValue={period1_date2} />
                </div>
            </div>

            <div className="flex-1">
                <label>Date Range 2</label>
                <div className="d-flex date-range-flds">
                    <input type="date" className="form-control "
                        onChange={(data) => {
                            updateFilter('secondPhaseStartDate', data)
                        }} defaultValue={period2_date1} />
                    <input type="date" className="form-control"
                        onChange={(data) => {
                            updateFilter('secondPhaseEndDate', data)
                        }} defaultValue={period2_date2} />
                </div>
            </div>



        </div>
    );
}

