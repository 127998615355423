import React, { useEffect, useState } from 'react'
import Layout from '../../../components/workflow/Layout'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { PageMenuRunway } from '../../../components/workflow/PageMenuRunway'
import DynamicForm from '../../../components/dynamicForm/dynamicForm'
import { URLS } from '../../../_config'
import { getNameOnly, replaceUrlParams } from '../../../_config/helpers'
import { useWorkFlowApi } from '../../../_common/hooks/api/WorkFlowApiHook'
import { showDate } from '../../../_config/functions'
import { BsDownload } from 'react-icons/bs'
import { GoToNextPhaseButton } from '../../../components/workflow/elements/GoToNextPhaseButton'
import { Button } from 'react-bootstrap'
import TitleBar from '../../../components/titleBar/TitleBar'
import Timer from '../../../components/timer/Timer'
import { useUser } from '../../../_common/hooks/authHook'
import TableLoader from '../../../components/tableLoader'
import Pagination from '../../../components/pagination/Pagination'
import SearchField from '../../../components/searchSection/SearchField'
import GetStartDateEndDate from '../../../components/getStartDateEndDate'
import { useUsecase } from '../../../_common/hooks/usecaseHook'
import Icon from '../../../components/icon'
import { ConfirmAlert } from '../../../_common/confirmAlert'
import PhaseStatus from '../../../components/phaseStatus'


export const ProjectPlan = () => {
    const [collapse, setCollapse] = useState<boolean>(false)
    const { id } = useParams();
    const workFlowApi = useWorkFlowApi();
    const [projectPlanList, setProjectPlanList] = useState<any>(null)
    const [searchText, setSearchText] = useState<any>('');
    const [addMore, setAddMore] = useState<boolean>(false)
    const navigate = useNavigate()
    const [addStartTime, setAddStartTime] = useState<Date | null>(null)
    const user: any = useUser();
    const usecase: any = useUsecase();

    const [page, setPage] = useState<number>(1)
    const [total, setTotal] = useState<number>(0)
    const [pageLimit, setPageLimit] = useState<number>(10)

    const [dateSubmited, setDateSubmited] = useState<boolean>(false)
    const [triggerSDED, setTriggerSDED] = useState<boolean>(false);
    const [SDEDPromise, setSDEDPromise] = useState<any>(null);
    const [allowWorkflow, setAllowWorkflow] = useState<boolean>(false);
    const [phaseUpdate, setPhaseUpdate] = useState<boolean>(false);

    const getAllProjectPlan = () => {
        workFlowApi.getAllProjectPlan({
            urlParams: { companyID: user.companyID, UseCaseID: id },
            pageNo: page,
            pageSize: pageLimit,
            SearchText: searchText
        }, (message: any, resp: any) => {
            setTotal(resp.totalRecords)
            setProjectPlanList(resp.data.projectPlan?.filter((planItem: any) => {
                return planItem?.projectPlanID > 0
            }))
        }, (message: any) => {
            setProjectPlanList([]);
        })
    }

    const checkSDED = ()=>{
        let p:any = new Promise((resolve,reject)=>{
            if(dateSubmited){
                resolve(true)
            } else {
                setSDEDPromise(()=>{ return resolve})
                setTriggerSDED(true);
            }
        })
        return p;
    }
    const deleteProjectFile = (params:any) => {
        return new Promise((resolve:any,reject:any)=>{
            workFlowApi.deleteProjectFile(params, resolve, reject)
        })
    }

    useEffect(() => {
        getAllProjectPlan();
    }, [id, page, searchText])

    useEffect(() => {
        if(dateSubmited){
            if(SDEDPromise){
                SDEDPromise();
            }
        }
    }, [dateSubmited])

    useEffect(() => {
        if(triggerSDED){
            setTriggerSDED(false)
        }
    }, [triggerSDED])

    useEffect(()=>{
        if(usecase){
            if ( user.userID == usecase.fkCoCaptainID || user.userID == usecase.fkAitportManagerID || user.userID == usecase.fkCaptainID) {
                setAllowWorkflow(true)
            }
    
            if(user.roleKey == 'INNOVATION_MANAGER_ROLE' || user.roleKey == 'ADMIN' ){ //&& usecase?.assignedInnovationManagerID == user.userID
                setAllowWorkflow(true)
            }
            if(user.roleKey == 'INNOVATION_MANAGER_ROLE' && usecase?.assignedInnovationManagerID == 0){
                setAllowWorkflow(true)
            }
        }
        
    },[usecase])

    return (

        <Layout titleBar={
            <TitleBar title={`Project Plan | usecase : ${usecase?.useCase}`} breadcrumbs={{
                'Use case list': URLS.USE_CASE_LIST,
                'Use case Details': replaceUrlParams(URLS.USECASE_DETAILS, { id: id }),
                'Project Plan': '#'
            }} >
                <Timer startTime={addStartTime} />
            </TitleBar>
        }>
            <PageMenuRunway pageTab="ProjectPlan" />

            <div className="white-box p-3">
                <div className="d-flex justify-content-between align-items-center">
                    <h1 className="h5 mb-0 font-weight-600">Project Plan</h1>
                    <SearchField onSearch={(str: any) => {
                        setSearchText(str)
                        setPage(1)
                    }} />
                    <Button variant='info' className="py-2"
                        onClick={() => { setAddMore(true); setAddStartTime(new Date()) }}>Add more files</Button>
                </div>

                <div className={`light-blue-bg files-add-panel py-3 mt-3 ${addMore ? '' : 'd-none'}`}>
                    <div className="row form-area">
                        <div className="col-sm-12">
                            <div className="d-flex justify-content-end">
                                <span onClick={() => { setAddMore(false); setAddStartTime(null) }} className="close-btn cursor-pointer"><i className="close-icon"></i></span>
                            </div>
                        </div>
                        <div className="col-sm-12 pt-3">
                            <DynamicForm _workflow='runway' useCaseID={id} _module='project-plan' _form='project-plan'
                                onSubmitSuccess={() => { setCollapse(false); getAllProjectPlan(); setAddStartTime(null) }} />
                        </div>
                    </div>
                </div>

                <div className="table-responsive list-table py-3">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col" className="w-5">#</th>
                                <th scope="col">Title</th>
                                <th scope="col">Note</th>
                                <th scope="col">Uploaded at</th>
                                <th scope="col">Operations</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                projectPlanList && projectPlanList.length > 0 &&
                                projectPlanList.map((planItem: any, index: any) =>
                                    <tr key={`planItem ${planItem?.projectPlanID}`}>
                                        <th>{index + 1}
                                            {/* <div className="number-avatar">D</div> */}
                                        </th>
                                        <td>
                                            <h6>{planItem.projectPlan}</h6>
                                            <p>{getNameOnly(planItem?.userFullName)}</p>
                                        </td>
                                        <td>{planItem.note}</td>
                                        <td>{showDate(planItem.addedDate)}</td>
                                        <td>
                                            <div className="d-flex gap-3 align-items-center">

                                                <a href={planItem.fileURL} download target='_blank' className="react-icon-grey"><Icon name='download' size={19} /></a>

                                                {allowWorkflow &&
                                                    <Icon name='delete' onClick={() => {
                                                    ConfirmAlert.delete('Project File', deleteProjectFile, {
                                                        "projectPlanID": planItem.projectPlanID,
                                                        "fkUseCaseID": id,
                                                        "fkUserID": user.userID,
                                                        "fkCompanyID": user.companyID,
                                                          }).then(() => {
                                                            getAllProjectPlan();
                                                        })
                                                    }}
                                                    />
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>


                    <TableLoader data={projectPlanList} />
                    <Pagination total={total} page={page} setPage={setPage} pageLimit={pageLimit} />

                </div>

                <hr />

                <div className="align-items-end pb-3 pt-2 rounded-1 row sticky-bottom" style={{backgroundColor:'#222747'}}>
                    <div className="col-md-8">
                        <GetStartDateEndDate submited={setDateSubmited} submitTrigger={triggerSDED} dataFor={{
                            useCaseID: id,
                            fkCompanyID: user.companyID,
                            phaseName: 'Project Plan',
                            startDate: '',
                            completeDate: '',
                            updatedBy: user.userID
                        }}
                        />
                    </div>
                    <div className="col-md-4">
                        <div className='text-end'>
                            <PhaseStatus phase='Project Plan' usecaseID={id} update={phaseUpdate} onUpdate={() => {
                                navigate(replaceUrlParams(URLS.WORKFLOW.RUNWAY.PROCUREMENT_PLAN, { id: id }))
                            }} />
                            {projectPlanList && projectPlanList.length > 0 && (
                                <GoToNextPhaseButton usecaseID={id} nextPhase='Procurement Plan' permission={checkSDED} onSuccess={() => {
                                    setPhaseUpdate(true)
                                }} />
                            )}
                        </div>
                    </div>
                </div>


            </div>


            




        </Layout>


    )
}
