import React, { useState } from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import DynamicForm from "../components/dynamicForm/dynamicForm";

const Test = () =>{


    const [startTime, setStartTime] = useState<Date | null>(new Date())
    return(
        <React.Fragment>
            <Header></Header>
            <div className="main-container flex-grow-1">
                    <div className="container">
                        <div className="page-title py-4">
                            <div className="row align-items-center">
                                <div className="col-sm-12">
                                    <ul className="csmt-breadcrumb d-flex gap-3 mb-2 p-0">
                                        <li><a href="#">Dashboard</a></li>
                                        <li>Executive summary</li>
                                    </ul>
                                    <h1 className="h6 text-uppercase font-weight-700  mb-0">Executive summary dashboard</h1>
                                    
                                </div>
                                
                            </div>
                        </div>

                        <div className="white-box">
                        <DynamicForm _workflow='testformedit' useCaseID={2} _module='edit-form' _form='test-form-edit'
                            onSubmitSuccess={(edit) => {  }}
                            _startTime={startTime} />
                        </div>
                    </div>
            </div>
            <Footer></Footer>
        </React.Fragment>
    );
}

export default Test;