/* 
    this component use for breadcrumps and page title 
*/

import React, { HtmlHTMLAttributes } from 'react'
import { Link } from 'react-router-dom';
import { URLS } from '../../_config';
import { useUserPermissions } from '../../_common/hooks/authHook';

interface props extends HtmlHTMLAttributes<HTMLDivElement> {
    title: any;
    breadcrumbs?: any
    dashboard?: boolean
}
const TitleBar: React.FC<props> = ({
    children,
    title,
    breadcrumbs,
    dashboard = true,
    ...args
}) => {
    const permissions: any = useUserPermissions();

    return (
        <div className="page-title py-3">
            <div className='d-flex justify-content-between align-items-center'>
                <div>
                    <ul className="csmt-breadcrumb d-flex gap-3 mb-2 p-0 print-hide">
                        {permissions?.menus.dashboard.read &&
                            <>{dashboard && <li><Link to={URLS.DASHBOARD}>Dashboard</Link></li>}</>
                        }


                        {
                            breadcrumbs && Object.keys(breadcrumbs).map((key: any, index: any) => (
                                <li key={`bed-${index}`}>
                                    {(breadcrumbs[key] == '#') ? key : <Link to={breadcrumbs[key]} style={{textTransform:'capitalize'}}>{key}</Link>}
                                </li>
                            ))
                        }
                    </ul>
                    <h1 className="h6 text-uppercase font-weight-700  mb-0">{title}</h1>
                </div>
                <div>
                    {children}
                </div>
            </div>
        </div>
    )
}

export default TitleBar;
