/*
    This page used for Secion permission to User Role
*/
import React, { useEffect, useState } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import { useUserApi } from "../../_common/hooks/api/UserApiHook";
import { Link, useParams } from "react-router-dom";
import { useUser } from "../../_common/hooks/authHook";
import TitleBar from "../../components/titleBar/TitleBar";
import { URLS } from "../../_config";
import { Button } from "react-bootstrap";
import './RolePermission.scss'
import toast from "react-hot-toast";


export const RolePermission = () => {
    const { id } = useParams();
    const userApi = useUserApi();
    const userInfo: any = useUser();
    const [menuPermission, setMenuPermission] = useState<any>(null)

    const menuPermissionGetByCompanyID = () => {
        userApi.menuPermissionGetByCompanyID({ urlParams: { CompanyID: userInfo?.companyID, FKRoleID: id } },
            (message: any, resp: any) => {
                setMenuPermission(resp.data.permissionList);
            }, (message: any) => {
                setMenuPermission([])
            })
    }

    useEffect(() => {
        menuPermissionGetByCompanyID()
    }, []);

    const submitData = () => {
        let formParam = {
            "permissionList": menuPermission,
            "fkCompanyID": userInfo?.companyID,
            "userID": userInfo?.userID,
            "fkRoleID": id,
        };

        return new Promise((resolve, reject) => {
            userApi.menuPermissionSave(formParam, (message: any, resp: any) => {
                resolve(message);
            }, (message: any) => {
                reject(message);
            })
        })
    }

    const submitDataHandler = () => {
        toast.promise(
            submitData(), {
            loading: 'Updating...',
            success: (msg: any) => { return <b>{msg}</b> },
            error: (msg: any) => { return <b>{msg}</b> },
        });
    }

    const onChangeHandler = (type: string, codeId: any) => {
        const newSet = menuPermission && menuPermission.filter((row: any) => {
            row[type] = (row.code == codeId) ? !row[type] : row[type]

            if (type == 'read' && row[type] == false) {
                row.write = false;
                row.delete = false;
            }
            if (type == 'write' && row[type] == true) {
                row.read = true;
            }
            if (type == 'delete' && row[type] == true) {
                row.read = true;
                row.write = true;
            }
            return row;
        })
        setMenuPermission(newSet)
    }


    return (
        <React.Fragment>
            <Header></Header>
            <div className="main-container flex-grow-1">
                <div className="container">

                    <TitleBar title={'User Roles'} breadcrumbs={{
                        'Settings ': '#',
                        'Roles ': URLS.ROLE.LIST,
                        'Permission ': '#',
                    }} >
                    </TitleBar>

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="white-box px-4 mb-3">
                            <div className="d-flex gap-4">
                                <p className="m-0" style={{fontSize:'24px'}}><span className="font-weight-700 m-0" style={{color:'#B32F84'}}>R</span> - Read</p>
                                <p className="m-0" style={{fontSize:'24px'}}><span className="font-weight-700 m-0" style={{color:'#B32F84'}}>W</span> - Write</p>
                                <p className="m-0" style={{fontSize:'24px'}}><span className="font-weight-700 m-0" style={{color:'#B32F84'}}>D</span> - Delete</p>
                                </div>
                            </div>

                            <div className="white-box px-4 pt-3">

                                
                            
                                <h2 className="h5 mb-3 ">Role Permission Module</h2>

                                <div className="row role-permission-toggler-wrapper">
                                    {
                                        menuPermission && menuPermission.length > 0 && menuPermission.filter((itemData: any) => itemData?.type == 1).map((item: any) => (
                                            <div className="col-sm-6 mb-3" key={`permission${item.code}`}>
                                                <div className="d-flex gap-3 align-items-center ">
                                                    <div className="form-check d-flex gap-2">
                                                        <input className="form-check-input wh-20 cursor-pointer" type="checkbox"
                                                            id={`flexCheck${item.code}`} checked={item?.read ? true : false}
                                                            onChange={() => onChangeHandler('read', item.code)} />

                                                        <label className="form-check-label" htmlFor={`flexCheck${item.code}`} >{item?.name}</label>
                                                    </div>

                                                    <div className="d-flex gap-5 role-permission-toggler">

                                                        <div className="form-check form-switch mb-0">
                                                            <span>R</span>
                                                            <div className="form-switch-wrapper">
                                                                <input className="form-check-input cursor-pointer" type="checkbox" role="switch"
                                                                    id={`flexSwitchCheckRead${item.code}`} title="Read"
                                                                    onChange={() => onChangeHandler('read', item.code)}
                                                                    checked={item?.read ? true : false} />
                                                            </div>
                                                        </div>

                                                        <div className="form-check form-switch mb-0">
                                                            <span>W</span>
                                                            <div className="form-switch-wrapper">
                                                                <input className="form-check-input cursor-pointer" type="checkbox" role="switch"
                                                                    id={`flexSwitchCheckWrite${item.code}`} title="Write"
                                                                    onChange={() => onChangeHandler('write', item.code)}
                                                                    checked={item?.write ? true : false} />
                                                            </div>
                                                        </div>


                                                        <div className="form-check form-switch mb-0">
                                                            <span>D</span>
                                                            <div className="form-switch-wrapper">
                                                                <input className="form-check-input cursor-pointer" type="checkbox" role="switch"
                                                                    id={`flexSwitchCheckDelete${item.code}`} title="Delete"
                                                                    onChange={() => onChangeHandler('delete', item.code)}
                                                                    checked={item?.delete ? true : false} />
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        ))
                                    }

                                </div>

                                <hr />

                                <h2 className="h5 mb-3 ">Action Permission Module</h2>
                                <div className="row role-permission-toggler-wrapper">
                                    {
                                        menuPermission && menuPermission.length > 0 && menuPermission.filter((itemData: any) => itemData?.type == 2).map((item: any) => (
                                            <div className="col-sm-6 mb-3" key={`permission${item.code}`}>
                                                <div className="d-flex gap-3 align-items-center ">
                                                    <div className="form-check d-flex gap-2">
                                                        <input className="form-check-input wh-20 cursor-pointer" type="checkbox"
                                                            id={`flexCheck${item.code}`}
                                                            checked={item?.read ? true : false}
                                                            onChange={() => onChangeHandler('read', item.code)} />

                                                        <label className="form-check-label" htmlFor={`flexCheck${item.code}`} >{item?.name}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }

                                </div>


                                {
                                    menuPermission == null && <p className="text-center">Loading...</p>
                                }

                                {
                                    menuPermission && menuPermission.length > 0 && (
                                        <div className="col-12 d-flex justify-content-end gap-3">
                                            <Link to={URLS.ROLE.LIST} className="btn btn-dark mr-2">Back</Link>
                                            <Button onClick={submitDataHandler}>Submit</Button>
                                        </div>
                                    )
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </React.Fragment>
    )
}
