import { useSelector } from 'react-redux'
import { StateExtended } from '../interfaces/StateExtended'

export function useWorkflows() {
	const form = useSelector((state: StateExtended) => state.form)
	return form.formWorkflows
}

export function useControlTypes() {
	const form = useSelector((state: StateExtended) => state.form)
	return form.controlTypes
}

export function useControlsProps() {
	const form = useSelector((state: StateExtended) => state.form)
	return form.controlsProps
}