import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import toast from 'react-hot-toast';
import { URLS } from "../../_config";
import { useUserApi } from "../../_common/hooks/api/UserApiHook";
import { useUser, useUserPermissions } from "../../_common/hooks/authHook";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";

import { TbLockCog } from "react-icons/tb";
import { MdOutlineModeEdit } from "react-icons/md";
import { replaceUrlParams } from "../../_config/helpers";
import Pagination from "../../components/pagination/Pagination";
import TableLoader from "../../components/tableLoader";
import TitleBar from "../../components/titleBar/TitleBar";


const SolutionProviderList = () => {
    const userApi = useUserApi();
    const dispatch = useDispatch();
    const [employees, setEmployees] = useState<any>(null);
    const navigate = useNavigate();
    const userInfo: any = useUser();
    console.log('userInfo', userInfo);
    const permissions:any = useUserPermissions();

    const [page, setPage] = useState<number>(1)
    const [total, setTotal] = useState<number>(0)
    const pageLimit = 999

    const solutionProviderList = () => {
        userApi.solutionProviderList({ urlParams: { PageSize: pageLimit, PageIndex: page } }, (message: any, resp: any) => {
            setTotal(resp.totalRecords)
            setEmployees(resp.data.solutionProviderResp);
        }, (message: any, resp: any) => {
            setTotal(0)

            setEmployees([]);
        })
    }

    useEffect(() => {
        solutionProviderList()
    }, [page]);

    const submitData = (data: any) => {
        let formParam = {
            "solutionProviderID": data.solutionProviderID,
            "companyID": data.companyID,
            "isActive": data.isActive,
            "updatedBy": userInfo.userID,
            'isDeleted': false
        };

        return new Promise((resolve, reject) => {
            userApi.solutionProviderChangeStatus(data, (message: any, resp: any) => {
                resolve(message);
            }, (message: any) => {
                reject(message);
            })
        })
    }

    const statusChange = (status: boolean, solutionProviderID: number, companyID: number) => {
        let msg = status === false ? 'In-Active' : 'Active';
        let data = {
            "solutionProviderID": solutionProviderID,
            // "companyID": companyID,
            "isActive": status,
            "updatedBy": userInfo.userID,
            'isDeleted': false
        };

        Swal.fire({
            title: 'Confirm Status',
            text: `Are you sure you want to ${msg} this user?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#B32F84',
            cancelButtonColor: '#44546A',
            confirmButtonText: `Yes, ${msg} it!`
        }).then((result) => {
            if (result.isConfirmed) {
                toast.promise(
                    submitData(data), {
                    loading: 'Loading...',
                    success: (msg: any) => { solutionProviderList(); return <b>{msg}</b> },
                    error: (msg: any) => { return <b>{msg}</b> },
                }
                );

            }
        });


    }

    const deleteData = (data: any) => {
        let formParam = {
            "solutionProviderID": data.solutionProviderID,
            "isDeleted": true,
            "updatedBy": userInfo?.userID,
        };
        return new Promise((resolve, reject) => {
            userApi.solutionProviderDelete(formParam, (message: any, resp: any) => {
                resolve(message);
            }, (message: any, resp: any) => {
                reject(message);
            })
        })
    }

    const deleteItem = (solutionProviderID: number) => {
        let data = {
            "solutionProviderID": solutionProviderID,
        };

        Swal.fire({
            title: 'Confirm Delete',
            text: `Are you sure you want to delete this role?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#B32F84',
            cancelButtonColor: '#44546A',
            confirmButtonText: `Yes, delete it!`
        }).then((result) => {
            if (result.isConfirmed) {
                toast.promise(
                    deleteData(data), {
                    loading: 'Loading...',
                    success: (msg: any) => { solutionProviderList(); return <b>{msg}</b> },
                    error: (msg: any) => { return <b>{msg}</b> },
                });
            }
        });
    }


    return (
        <React.Fragment>
            <Header></Header>
            <div className="main-container flex-grow-1">
                <div className="container">

                    <TitleBar title={'Solution Providers'} breadcrumbs={{
                        'Solution Providers ': '#'
                    }} >
                        {permissions?.menus.solutionProviders.write &&
                            <Link to={URLS.SOLUTION_PROVIDER.CREATE} className="btn btn-info py-2 add-more-files-btn"> Add Solution Provider </Link>
                        }
                    </TitleBar>

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="white-box px-4 pt-3">
                                <div className="table-responsive list-table full-width">
                                    <table className="table table-striped">
                                        <thead>
                                            <tr className="align-middle">

                                                <th scope="col" className="text-left">First Name</th>
                                                <th scope="col">Last Name</th>
                                                <th scope="col">Email</th>
                                                <th scope="col">Phone</th>
                                                <th scope="col">Role Type</th>
                                                <th scope="col">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {employees && employees.length > 0 && (
                                                employees.map((member: any) => (
                                                    <tr key={member.solutionProviderID} className="align-middle h-60">
                                                        <td>{member.firstName}</td>
                                                        <td>{member.lastName}</td>
                                                        <td>{member.email}</td>
                                                        <td>{member.phone}</td>
                                                        <td>
                                                            <span className="badge bg-secondary">{member.roleName}</span>
                                                            {/* <span className="badge bg-info">Admin</span> */}
                                                        </td>
                                                        <td className="w-200px">
                                                            <div className="d-flex gap-3">
                                                                {
                                                                    (member?.roleID != 1) &&
                                                                    <>
                                                                        {permissions?.menus.solutionProviders.write &&
                                                                            <>
                                                                                <Link to={replaceUrlParams(URLS.SOLUTION_PROVIDER.EDIT, { id: member.solutionProviderID })} className="react-icon-grey"><MdOutlineModeEdit /></Link>
                                                                                {/* <Link to={replaceUrlParams(URLS.EMPLOYEE_ROLE_PERMISSION, { id: member.solutionProviderID })} className="react-icon-grey" ><TbLockCog /> </Link> */}
                                                                                {/* <Link to="#" className="icon text-danger" onClick={() => { deleteItem(member.solutionProviderID) }}><TbTrashXFilled /></Link> */}

                                                                                {member.isActive === true ? (
                                                                                    <Link to="#" onClick={() => { statusChange(false, member.solutionProviderID, member.companyID) }}><i className="active-icon"></i></Link>
                                                                                ) : (
                                                                                    <Link to="#" onClick={() => { statusChange(true, member.solutionProviderID, member.companyID) }}><i className="in-active"></i></Link>
                                                                                )}
                                                                            </>
                                                                        }
                                                                    </>
                                                                }

                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                            )}

                                        </tbody>
                                    </table>

                                </div>

                                <TableLoader data={employees} />
                                {employees ? <Pagination total={total} page={page} setPage={setPage} pageLimit={pageLimit} /> : null}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </React.Fragment>
    );
}

export default SolutionProviderList;