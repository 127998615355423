/* eslint-disable @typescript-eslint/ban-types */
import { useApiCall } from '../common/appApiCallHook'
//import { BlankReq, LoginReq, LogoutReq } from '../../interfaces/ApiReqRes'
import { SAGA_ACTIONS } from '../../../_config/index'



export function useWorkFlowApi() {
  const callApi = useApiCall()
  

  const getScoringPrioritizationByUsecase = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.WORKFLOW.GET_SCORING_PRIOTITIZATION_LIST, data, onSuccess, onError)
  }
  const scoringPrioritizationSave = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.WORKFLOW.ADD_SCORING_PRIOTITIZATION, data, onSuccess, onError)
  }
  const scoringPrioritizationDelete = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.WORKFLOW.DELETE_SCORING_PRIOTITIZATION, data, onSuccess, onError)
  }
  const getScoreQuestions = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.WORKFLOW.GET_SCORE_QUESTIONS, data, onSuccess, onError)
  }
  const submitScoreQuestions = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.WORKFLOW.SUBMIT_SCORE_QUESTIONS, data, onSuccess, onError)
  }
 

  const getAllProjectPlan = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.WORKFLOW.GET_ALL_PROJECT_PLAN, data, onSuccess, onError)
  }
  const deleteProjectFile = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.WORKFLOW.DELETE_PROJECT_PLAN, data, onSuccess, onError)
  }
  const getAllProcurementPlan = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.WORKFLOW.GET_ALL_PROCUREREMENT_PLAN, data, onSuccess, onError)
  }
  const deleteProcurementFile = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.WORKFLOW.DELETE_PROCUREREMENT_PLAN, data, onSuccess, onError)
  }
  const submitHangerDecision = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.WORKFLOW.SUBMIT_HANGER_DECISION, data, onSuccess, onError)
  }
 

  // ===================
  const getTestingRolesResponsibilitiesByUsecase = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.WORKFLOW.TESTING_ROLES_RESPONSIBILE.LIST_BY_USECASE, data, onSuccess, onError)
  }

  const TestingRolesResponsibilitiesSave = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.WORKFLOW.TESTING_ROLES_RESPONSIBILE.SAVE, data, onSuccess, onError)
  }
  const TestingRolesResponsibilitiesDelete = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.WORKFLOW.TESTING_ROLES_RESPONSIBILE.DELETE, data, onSuccess, onError)
  }
  const getTestCasesAcceptanceCriteriaByUsecase = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.WORKFLOW.TESTING_ROLES_RESPONSIBILE.CRITERIA_BY_USECASE, data, onSuccess, onError)
  }
  const DeleteTestCasesAcceptanceCriteria = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.WORKFLOW.TESTING_ROLES_RESPONSIBILE.CRITERIA_DELETE, data, onSuccess, onError)
  }
  const AssignTestCasesAcceptanceCriteriaUser = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.WORKFLOW.TESTING_ROLES_RESPONSIBILE.CRITERIA_ASSIGN_USER, data, onSuccess, onError)
  }
  const approvePreScoring = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.WORKFLOW.APPROVE_PRE_SCORING, data, onSuccess, onError)
  }

  const addMember = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.WORKFLOW.DAY_IN_LIFE.ADD_MEMBER, data, onSuccess, onError)
  }
  const getMembers = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.WORKFLOW.DAY_IN_LIFE.GET_MEMBERS, data, onSuccess, onError)
  }
  const deleteMember = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.WORKFLOW.DAY_IN_LIFE.DELETE_MEMBER, data, onSuccess, onError)
  }

  const getStartDateEndDate = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.WORKFLOW.GET_START_DATE_END_DATE, data, onSuccess, onError)
  }

  const updateStartDateEndDate = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.WORKFLOW.UPDATE_START_DATE_END_DATE, data, onSuccess, onError)
  }


  
  return { 
    getScoringPrioritizationByUsecase, 
    scoringPrioritizationSave,
    scoringPrioritizationDelete,getScoreQuestions,
    submitScoreQuestions,

    getAllProjectPlan,
    deleteProjectFile,
    getAllProcurementPlan,
    deleteProcurementFile,
    submitHangerDecision,


    getTestingRolesResponsibilitiesByUsecase,
    TestingRolesResponsibilitiesSave,
    TestingRolesResponsibilitiesDelete,
    getTestCasesAcceptanceCriteriaByUsecase,
    DeleteTestCasesAcceptanceCriteria,
    AssignTestCasesAcceptanceCriteriaUser,
    approvePreScoring,
    addMember,getMembers,deleteMember,
    getStartDateEndDate,
    updateStartDateEndDate


  }
}
