import { call } from 'redux-saga/effects'
import { API_URL, UpdateWithVariables } from '../../_config'
import { CallApi, responseHandler } from '../api/callApi'
import { ApiActionType } from '../../_common/interfaces/ActionExtended'


export function* getScoringPrioritizationByUsecase(action: ApiActionType): any {
	try {
		const data = action.payload
		let API = UpdateWithVariables(API_URL.WORKFLOW.GET_SCORING_PRIOTITIZATION_LIST, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
		const resp: any = yield call(CallApi.GET, API, data, true)
		let respChecker = responseHandler(resp)
		if (respChecker.status) {
			action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
		} else {
			action && action.callbackError && action.callbackError(respChecker.data)
		}
	} catch (e: any) {
		action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
	}

 
}
export function* scoringPrioritizationSave(action: ApiActionType): any {
	try {
		const data = action.payload
		let API = UpdateWithVariables(API_URL.WORKFLOW.ADD_SCORING_PRIOTITIZATION, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
		const resp: any = yield call(CallApi.POST, API, data, true)
		let respChecker = responseHandler(resp)
		if (respChecker.status) {
			action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
		} else {
			action && action.callbackError && action.callbackError(respChecker.data)
		}
	} catch (e: any) {
		action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
	}

 
}
export function* scoringPrioritizationDelete(action: ApiActionType): any {
	try {
		const data = action.payload
		let API = UpdateWithVariables(API_URL.WORKFLOW.DELETE_SCORING_PRIOTITIZATION, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
		const resp: any = yield call(CallApi.DELETE, API, data, true)
		let respChecker = responseHandler(resp)
		if (respChecker.status) {
			action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
		} else {
			action && action.callbackError && action.callbackError(respChecker.data)
		}
	} catch (e: any) {
		action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
	}

 
}
 
export function* getScoreQuestions(action: ApiActionType): any {
	try {
		const data = action.payload
		let API = UpdateWithVariables(API_URL.WORKFLOW.GET_SCORE_QUESTIONS, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
		const resp: any = yield call(CallApi.GET, API, data, true)
		let respChecker = responseHandler(resp)
		if (respChecker.status) {
			action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
		} else {
			action && action.callbackError && action.callbackError(respChecker.data)
		}
	} catch (e: any) {
		action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
	}
}

export function* submitScoreQuestions(action: ApiActionType): any {
	try {
		const data = action.payload
		let API = UpdateWithVariables(API_URL.WORKFLOW.SUBMIT_SCORE_QUESTIONS, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
		const resp: any = yield call(CallApi.POST, API, data, true)
		let respChecker = responseHandler(resp)
		if (respChecker.status) {
			action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
		} else {
			action && action.callbackError && action.callbackError(respChecker.data)
		}
	} catch (e: any) {
		action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
	}
}

export function* deleteProjectFile(action: ApiActionType): any {
	try {
		const data = action.payload
		let API = UpdateWithVariables(API_URL.WORKFLOW.DELETE_PROJECT_PLAN, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
		const resp: any = yield call(CallApi.DELETE, API, data, true)
		let respChecker = responseHandler(resp)
		if (respChecker.status) {
			action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
		} else {
			action && action.callbackError && action.callbackError(respChecker.data)
		}
	} catch (e: any) {
		action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
	}
}
export function* getAllProjectPlan(action: ApiActionType): any {
	try {
		const data = action.payload
		let API = UpdateWithVariables(API_URL.WORKFLOW.GET_ALL_PROJECT_PLAN, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
		const resp: any = yield call(CallApi.GET, API, data, true)
		let respChecker = responseHandler(resp)
		if (respChecker.status) {
			action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
		} else {
			action && action.callbackError && action.callbackError(respChecker.data)
		}
	} catch (e: any) {
		action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
	}
}


export function* getAllProcurementPlan(action: ApiActionType): any {
	try {
		const data = action.payload
		let API = UpdateWithVariables(API_URL.WORKFLOW.GET_ALL_PROCUREREMENT_PLAN, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
		const resp: any = yield call(CallApi.GET, API, data, true)
		let respChecker = responseHandler(resp)
		if (respChecker.status) {
			action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
		} else {
			action && action.callbackError && action.callbackError(respChecker.data)
		}
	} catch (e: any) {
		action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
	}
}
export function* deleteProcurementFile(action: ApiActionType): any {
	try {
		const data = action.payload
		let API = UpdateWithVariables(API_URL.WORKFLOW.DELETE_PROCUREREMENT_PLAN, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
		const resp: any = yield call(CallApi.DELETE, API, data, true)
		let respChecker = responseHandler(resp)
		if (respChecker.status) {
			action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
		} else {
			action && action.callbackError && action.callbackError(respChecker.data)
		}
	} catch (e: any) {
		action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
	}
}




export function* submitHangerDecision(action: ApiActionType): any {
	try {
		const data = action.payload
		let API = UpdateWithVariables(API_URL.WORKFLOW.SUBMIT_HANGER_DECISION, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
		const resp: any = yield call(CallApi.POST, API, data, true)
		let respChecker = responseHandler(resp)
		if (respChecker.status) {
			action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
		} else {
			action && action.callbackError && action.callbackError(respChecker.data)
		}
	} catch (e: any) {
		action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
	}
}




export function* getTestingRolesResponsibilitiesByUsecase(action: ApiActionType): any {
	try {
		const data = action.payload
		let API = UpdateWithVariables(API_URL.WORKFLOW.TESTING_ROLES_RESPONSIBILE.LIST_BY_USECASE, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
		const resp: any = yield call(CallApi.GET, API, data, true)
		let respChecker = responseHandler(resp)
		if (respChecker.status) {
			action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
		} else {
			action && action.callbackError && action.callbackError(respChecker.data)
		}
	} catch (e: any) {
		action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
	}
}
export function* TestingRolesResponsibilitiesSave(action: ApiActionType): any {
	try {
		const data = action.payload
		let API = UpdateWithVariables(API_URL.WORKFLOW.TESTING_ROLES_RESPONSIBILE.SAVE, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
		const resp: any = yield call(CallApi.POST, API, data, true)
		let respChecker = responseHandler(resp)
		if (respChecker.status) {
			action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
		} else {
			action && action.callbackError && action.callbackError(respChecker.data)
		}
	} catch (e: any) {
		action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
	}
}
export function* TestingRolesResponsibilitiesDelete(action: ApiActionType): any {
	try {
		const data = action.payload
		let API = UpdateWithVariables(API_URL.WORKFLOW.TESTING_ROLES_RESPONSIBILE.DELETE, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
		const resp: any = yield call(CallApi.DELETE, API, data, true)
		let respChecker = responseHandler(resp)
		if (respChecker.status) {
			action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
		} else {
			action && action.callbackError && action.callbackError(respChecker.data)
		}
	} catch (e: any) {
		action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
	}
}
export function* getTestCasesAcceptanceCriteriaByUsecase(action: ApiActionType): any {
	try {
		const data = action.payload
		let API = UpdateWithVariables(API_URL.WORKFLOW.TESTING_ROLES_RESPONSIBILE.CRITERIA_BY_USECASE, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
		const resp: any = yield call(CallApi.GET, API, data, true)
		let respChecker = responseHandler(resp)
		if (respChecker.status) {
			action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
		} else {
			action && action.callbackError && action.callbackError(respChecker.data)
		}
	} catch (e: any) {
		action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
	}
}
export function* DeleteTestCasesAcceptanceCriteria(action: ApiActionType): any {
	try {
		const data = action.payload
		let API = UpdateWithVariables(API_URL.WORKFLOW.TESTING_ROLES_RESPONSIBILE.CRITERIA_DELETE, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
		const resp: any = yield call(CallApi.DELETE, API, data, true)
		let respChecker = responseHandler(resp)
		if (respChecker.status) {
			action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
		} else {
			action && action.callbackError && action.callbackError(respChecker.data)
		}
	} catch (e: any) {
		action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
	}
}
export function* AssignTestCasesAcceptanceCriteriaUser(action: ApiActionType): any {
	try {
		const data = action.payload
		let API = UpdateWithVariables(API_URL.WORKFLOW.TESTING_ROLES_RESPONSIBILE.CRITERIA_ASSIGN_USER, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
		const resp: any = yield call(CallApi.POST, API, data, true)
		let respChecker = responseHandler(resp)
		if (respChecker.status) {
			action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
		} else {
			action && action.callbackError && action.callbackError(respChecker.data)
		}
	} catch (e: any) {
		action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
	}
}

export function* approvePreScoring(action: ApiActionType): any {
	try {
		const data = action.payload
		let API = UpdateWithVariables(API_URL.WORKFLOW.APPROVE_PRE_SCORING, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
		const resp: any = yield call(CallApi.POST, API, data, true)
		let respChecker = responseHandler(resp)
		if (respChecker.status) {
			action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
		} else {
			action && action.callbackError && action.callbackError(respChecker.data)
		}
	} catch (e: any) {
		action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
	}
}

export function* addMember(action: ApiActionType): any {
	try {
		const data = action.payload
		let API = UpdateWithVariables(API_URL.WORKFLOW.DAY_IN_LIFE.ADD_MEMBER, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
		const resp: any = yield call(CallApi.POST, API, data, true)
		let respChecker = responseHandler(resp)
		if (respChecker.status) {
			action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
		} else {
			action && action.callbackError && action.callbackError(respChecker.data)
		}
	} catch (e: any) {
		action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
	}
}

export function* getMembers(action: ApiActionType): any {
	try {
		const data = action.payload
		let API = UpdateWithVariables(API_URL.WORKFLOW.DAY_IN_LIFE.GET_MEMBERS, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
		const resp: any = yield call(CallApi.GET, API, data, true)
		let respChecker = responseHandler(resp)
		if (respChecker.status) {
			action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
		} else {
			action && action.callbackError && action.callbackError(respChecker.data)
		}
	} catch (e: any) {
		action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
	}
}

export function* deleteMember(action: ApiActionType): any {
	try {
		const data = action.payload
		let API = UpdateWithVariables(API_URL.WORKFLOW.DAY_IN_LIFE.DELETE_MEMBER, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
		const resp: any = yield call(CallApi.DELETE, API, data, true)
		let respChecker = responseHandler(resp)
		if (respChecker.status) {
			action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
		} else {
			action && action.callbackError && action.callbackError(respChecker.data)
		}
	} catch (e: any) {
		action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
	}
}


export function* getStartDateEndDate(action: ApiActionType): any {
	try {
		const data = action.payload
		let API = UpdateWithVariables(API_URL.WORKFLOW.GET_START_DATE_END_DATE, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
		const resp: any = yield call(CallApi.GET, API, data, true)
		let respChecker = responseHandler(resp)
		if (respChecker.status) {
			action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
		} else {
			action && action.callbackError && action.callbackError(respChecker.data)
		}
	} catch (e: any) {
		action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
	}
}

export function* updateStartDateEndDate(action: ApiActionType): any {
	try {
		const data = action.payload
		let API = UpdateWithVariables(API_URL.WORKFLOW.UPDATE_START_DATE_END_DATE, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
		const resp: any = yield call(CallApi.POST, API, data, true)
		let respChecker = responseHandler(resp)
		if (respChecker.status) {
			action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
		} else {
			action && action.callbackError && action.callbackError(respChecker.data)
		}
	} catch (e: any) {
		action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
	}
}
