import React, { useState } from 'react'
import Layout from '../../../components/workflow/Layout'
import { Link, useNavigate, useParams } from 'react-router-dom'
import DynamicForm from '../../../components/dynamicForm/dynamicForm'
import { URLS } from '../../../_config'
import { replaceUrlParams } from '../../../_config/helpers'
import { useUserApi } from '../../../_common/hooks/api/UserApiHook'
import { useUser } from '../../../_common/hooks/authHook'
import TitleBar from '../../../components/titleBar/TitleBar'
import Timer from '../../../components/timer/Timer'
import { useUsecase } from '../../../_common/hooks/usecaseHook'


export const Nogo = () => {
    const [collapse, setCollapse] = useState<boolean>(false)
    const { id } = useParams();
    const user: any = useUser();
    const usecase: any = useUsecase();
    const navigate = useNavigate();
    const userApi = useUserApi();

    const getUsecase = () => {
        return new Promise((resolve, reject) => {
            userApi.useCaseDetails({ urlParams: {companyID:user.companyID, id: id, userID: user.userID } }, (message: any, resp: any) => {
                resolve(true)
            }, (message: any) => { resolve(message) })
        })
    }


    return (
        <Layout titleBar={
            <TitleBar  title={`Go / No go | usecase : ${usecase?.useCase}`} breadcrumbs={{
                'Use case list': URLS.USE_CASE_LIST,
                'Use case Details': replaceUrlParams(URLS.USECASE_DETAILS, { id: id }),
                'Go / No go': '#'
            }} >
                {user.roleKey == 'INNOVATION_MANAGER_ROLE' || user.roleKey == 'ADMIN' ?
                <Timer startTime={new Date()} />: null}
            </TitleBar>
        }>


            <div className="white-box">
                {user.roleKey == 'INNOVATION_MANAGER_ROLE' || user.roleKey == 'ADMIN' ?
                    <DynamicForm sded={true} _workflow='landed' _module='go-no-go' _form='go-no-go' useCaseID={id} editMode={true} section='Landed' phase='Go/No Go' onSubmitSuccess={() => {
                        setCollapse(false);
                        getUsecase().then((m) => { navigate(replaceUrlParams(URLS.USECASE_DETAILS, { id: id })) })
                    }} />
                    :
                    <div className='col-12 d-flex justify-content-center gap-3'>
                        <h5>Waiting For Final Decision</h5>
                    </div>
                }

            </div>

        </Layout>
        

    )
}
