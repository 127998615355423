import React from 'react'
import { URLS } from '../../_config'
import { Link, useParams } from 'react-router-dom'
import { replaceUrlParams } from '../../_config/helpers'

export const PageMenu = ({ pageTab }: any) => {
    const { id } = useParams();
 

    return (

        <div className="progress-steps-panel d-flex mb-4">
            <div className={`progress-steps d-flex align-items-center gap-3 ${pageTab == 'Discovery' ? 'active' : ''}`}>
                <div className="progress-number">1</div>
                <p><Link to={replaceUrlParams(URLS.WORKFLOW.HANGER.DISCOVERY, { id: id })} >Discovery</Link></p>
            </div>
            <div className={`progress-steps d-flex align-items-center gap-3 ${pageTab == 'ChallengeStatement' ? 'active' : ''}`}>
                <div className="progress-number">2</div>
                <p><Link to={replaceUrlParams(URLS.WORKFLOW.HANGER.CHALLENGE, { id: id })} >Challenge Statement</Link></p>
            </div>
            <div className={`progress-steps d-flex align-items-center gap-3 ${pageTab == 'SolutionFraming' ? 'active' : ''}`}>
                <div className="progress-number">3</div>
                <p><Link to={replaceUrlParams(URLS.WORKFLOW.HANGER.SOLUTIONS, { id: id })} >Solutions Framing</Link></p>
            </div>
            <div className={`progress-steps d-flex align-items-center gap-3 ${pageTab == 'RankingPrioritization' ? 'active' : ''}`}>
                <div className="progress-number">4</div>
                <p><Link to={replaceUrlParams(URLS.WORKFLOW.HANGER.SCORE, { id: id })} >Scoring / Prioritization</Link></p>
            </div>
            <div className={`progress-steps d-flex align-items-center gap-3 ${pageTab == 'Decision' ? 'active' : ''}`}>
                <div className="progress-number">5</div>
                <p><Link to={replaceUrlParams(URLS.WORKFLOW.HANGER.DECISION, { id: id })} >Decision</Link></p>
            </div>
        </div>
    )
}
