/*
    This page used for List All user Role
*/
import React, { useEffect, useState } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import { useUserApi } from "../../_common/hooks/api/UserApiHook";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import toast from 'react-hot-toast';
import { useUser, useUserPermissions } from "../../_common/hooks/authHook";

import { MdOutlineModeEdit } from "react-icons/md";
import { MdDeleteOutline } from "react-icons/md";
import TableLoader from "../../components/tableLoader"; 
import TitleBar from "../../components/titleBar/TitleBar";
import { URLS } from "../../_config"; 
import Icon from "../../components/icon";
import { replaceUrlParams } from "../../_config/helpers";


export const RoleList = () => {

    const [roles, setRoles] = useState<any>(null);
    const userApi = useUserApi();
    const userInfo: any = useUser();
    const permissions:any = useUserPermissions();


    const getRoleList = () => {
        //userInfo?.companyID // this is for fontend user
        // 0 // for supper user
        userApi.listRole({ FKCompanyID: userInfo?.companyID }, (message: any, resp: any) => {
            setRoles(resp.data.roles);
        }, (message: any) => {
            setRoles([])
        })

    }

    useEffect(() => {
        getRoleList()
    }, []);

    const submitData = (data: any) => {
        let formParam = {
            "roleID": data.roleID,
            "fkCompanyID": data.fkCompanyID,
        };

        return new Promise((resolve, reject) => {
            userApi.deleteRole(formParam, (message: any, resp: any) => {
                resolve(message);
            }, (message: any, resp: any) => {
                reject(message);
            })
        })
    }

    const deleteRole = (roleID: number) => {
        let data = {
            "roleID": roleID,
            "fkCompanyID": userInfo?.companyID
        };

        Swal.fire({
            title: 'Confirm Delete',
            text: `Are you sure you want to delete this role?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#B32F84',
            cancelButtonColor: '#44546A',
            confirmButtonText: `Yes, delete it!`
        }).then((result) => {
            if (result.isConfirmed) {
                toast.promise(
                    submitData(data), {
                    loading: 'Loading...',
                    success: (msg: any) => { getRoleList(); return <b>{msg}</b> },
                    error: (msg: any) => { return <b>{msg}</b> },
                }
                );

            }
        });
    }


    return (
        <React.Fragment>
            <Header></Header>
            <div className="main-container flex-grow-1">
                <div className="container">

                    <TitleBar title={'User Roles'} breadcrumbs={{
                        'Settings ': '#',
                        'User Roles ': '#'
                    }} >
                        {permissions?.menus.settings.write &&
                            <Link to={URLS.ROLE.CREATE} className="btn btn-info py-2 add-more-files-btn"> Add Role</Link>
                        }
                    </TitleBar>

                    <div className="row">
                        <div className="col-sm-12">

                            <div className="white-box px-4 pt-3">
                                <div className="table-responsive list-table full-width">
                                    <table className="table table-striped">
                                        <thead>
                                            <tr className="align-middle">
                                                <th scope="col" className="text-center">#</th>
                                                <th scope="col">Name</th>
                                                <th scope="col">Dispaly Name</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {roles && roles.length > 0 && (
                                                roles.map((role: any, index: number) => (
                                                    <tr key={role.roleID} className="align-middle h-60">
                                                        <td className="text-center">{index + 1}</td>
                                                        <td >{role.title}</td>
                                                        <td>{role.displayTitle}</td>
                                                        <td className="w-150px">
                                                            {role.fkCompanyID == 0 ? (
                                                                <div className="d-flex gap-3">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M256 8C119.034 8 8 119.033 8 256s111.034 248 248 248 248-111.034 248-248S392.967 8 256 8zm130.108 117.892c65.448 65.448 70 165.481 20.677 235.637L150.47 105.216c70.204-49.356 170.226-44.735 235.638 20.676zM125.892 386.108c-65.448-65.448-70-165.481-20.677-235.637L361.53 406.784c-70.203 49.356-170.226 44.736-235.638-20.676z" /></svg>
                                                                </div>
                                                            ) : (
                                                                <div className="d-flex gap-3">
                                                                  
                                                                    {role.title != 'Admin' && role.title != 'Innovation Manager' && role.title != 'Champion' && role.title != 'Innovator' && role.title != 'Solution Provider' && (
                                                                        <>
                                                                            {permissions?.menus.settings.write &&
                                                                                <Link to={`/role/${role.roleID}`} className="react-icon-grey">
                                                                                    <MdOutlineModeEdit />
                                                                                </Link>
                                                                            }
                                                                            {permissions?.menus.settings.delete &&
                                                                                <Link to="#" onClick={() => { deleteRole(role.roleID) }} className="react-icon-grey"><MdDeleteOutline /></Link>
                                                                            }
                                                                        </>
                                                                        ) }
                                                                    
                                                                    {permissions?.menus.settings.write &&
                                                                        <Link to={replaceUrlParams(URLS.ROLE.PERMISSION, { id: role.roleID })} className="react-icon-grey"><Icon name="shield-lock" /></Link>
                                                                    }

                                                                </div>
                                                            )}
                                                        </td>
                                                    </tr>
                                                ))
                                            )}

                                        </tbody>
                                    </table>
                                    <TableLoader data={roles} />
                                </div>

                                {/*  <Pagination /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </React.Fragment>
    )
}
