import { call } from 'redux-saga/effects'
import { API_URL, UpdateWithVariables } from '../../_config'
import { CallApi, responseHandler } from '../api/callApi'
import { ApiActionType } from '../../_common/interfaces/ActionExtended'


export function* getSections(action: ApiActionType): any {
	try {
		const data = action.payload
		let API = UpdateWithVariables(API_URL.MASTER.GET_SECTIONS, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
		const resp: any = yield call(CallApi.GET, API, data, true)
		let respChecker = responseHandler(resp)
		if (respChecker.status) {
			action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
		} else {
			action && action.callbackError && action.callbackError(respChecker.data)
		}
	} catch (e: any) {
		action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
	}
}

export function* getPhases(action: ApiActionType): any {
	try {
		const data = action.payload
		let API = UpdateWithVariables(API_URL.MASTER.GET_PHASES, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
		const resp: any = yield call(CallApi.GET, API, data, true)
		let respChecker = responseHandler(resp)
		if (respChecker.status) {
			/* yield put({
				type: ACTIONS.MASTER.WORKFLOWS,
				payload: {
				  workflows: {'abcd':'qwerty'}
				},
			  }) */
			action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
		} else {
			action && action.callbackError && action.callbackError(respChecker.data)
		}
	} catch (e: any) {
		action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
	}
}

