import React, { HtmlHTMLAttributes } from 'react'
import { Link } from 'react-router-dom';
import Timer from './timer/Timer';


interface props extends HtmlHTMLAttributes<HTMLDivElement> {
    visible: boolean;
    changeVisibility?: (status: boolean) => void;
    heading: string;
    timer?: boolean
}
const RightSlideModal: React.FC<props> = ({
    children,
    visible,
    changeVisibility,
    heading,
    timer = false,
    ...args
}) => {
    return (
        <div className={`right-float-bar right-float-bar-2 w-500 slow ${visible ? 'collapse-panel-right' : ''}`}>
            <div className='d-flex align-items-center justify-content-between '>
                <h6 className='mb-0 project-sub-hed'>{heading}</h6>
                <span>
                    {timer && <Timer startTime={new Date()} />}
                </span>
                <Link to="#" className="time-cancel hide-btn right-panel-cancel-btn" onClick={() => { changeVisibility ? changeVisibility(false) : console.log() }} >
                    <img src="/images/time-cancel.svg" alt="#" />
                </Link>
            </div>
            <hr />
            <div className='right-float-bar-body' style={{ height: 'calc(100vh - 181px)' }}>
                {children}
            </div>
        </div>
    )
}

export default RightSlideModal;
