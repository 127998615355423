/*
    This page used for List All Notification
*/
import React, { useEffect, useState } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import { useUserApi } from "../../_common/hooks/api/UserApiHook";
import { Link } from "react-router-dom";
import { useUser, useUserPermissions } from "../../_common/hooks/authHook";
import TableLoader from "../../components/tableLoader";
import TitleBar from "../../components/titleBar/TitleBar";
import { URLS } from "../../_config";
import Icon from "../../components/icon";
import { replaceUrlParams } from "../../_config/helpers";
import Pagination from "../../components/pagination/Pagination";


export const NotificationList = () => {

    const [notificationList, setNotificationList] = useState<any>(null);
    const userApi = useUserApi();
    const userInfo: any = useUser();
    const permissions: any = useUserPermissions();

    const [page, setPage] = useState<number>(1)
    const [total, setTotal] = useState<number>(0)
    const pageLimit = 10

    const getNotificationList = () => {
        userApi.notificationList({ urlParams: { PageNo: page, PageSize: pageLimit } }, (message: any, resp: any) => {
            setTotal(resp.totalRecords)

            setNotificationList(resp.data.notificationTemplates);
        }, (message: any) => {
            setTotal(0)
            setNotificationList([])
        })

    }

    useEffect(() => {
        getNotificationList()
    }, [page]);



    return (
        <React.Fragment>
            <Header></Header>
            <div className="main-container flex-grow-1">
                <div className="container">

                    <TitleBar title={'Email & Notifications'} breadcrumbs={{
                        'Settings': '#',
                        'Email & Notifications': '#'
                    }} >

                    </TitleBar>

                    <div className="row">
                        <div className="col-sm-12">

                            <div className="white-box px-4 pt-3">
                                <div className="table-responsive list-table full-width">
                                    <table className="table table-striped">
                                        <thead>
                                            <tr className="align-middle">
                                                <th scope="col" className="text-center">#</th>
                                                <th scope="col">Title</th>
                                                <th scope="col">Subject</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {notificationList && notificationList.length > 0 && (
                                                notificationList.map((item: any, index: number) => (
                                                    <tr key={item.roleID} className="align-middle h-60">
                                                        <td className="text-center">{index + 1}</td>
                                                        <td >{item.title}</td>
                                                        <td>{item.subject}</td>
                                                        <td className="w-150px">
                                                            {permissions?.menus.settings.write &&
                                                                <Link to={replaceUrlParams(URLS.NOTIFICATION.EDIT, { id: item.notificationTemplateID })} className="react-icon-grey"><Icon name="edit" /></Link>
                                                            }
                                                        </td>
                                                    </tr>
                                                ))
                                            )}

                                        </tbody>
                                    </table>
                                </div>


                                <TableLoader data={notificationList} />
                                {notificationList ? <Pagination total={total} page={page} setPage={setPage} pageLimit={pageLimit} /> : null}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </React.Fragment>
    )
}
