import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom'
import Error from '../components/elements/formFieldError';
import toast from 'react-hot-toast';
import { useUser } from '../_common/hooks/authHook';
import { useWorkFlowApi } from '../_common/hooks/api/WorkFlowApiHook';
import { useUserApi } from '../_common/hooks/api/UserApiHook';
import { useUsecase } from '../_common/hooks/usecaseHook';
import Select from 'react-select';
import { getSelectedValue } from '../_config/helpers';

interface props {
    show: boolean,
    onClose: (status: boolean) => void
}

export default function ApproveUsecaseModal({ show, onClose }: props) {
    const [visible, setVisible] = useState<boolean>(false);
    const handleClose = () => { setVisible(false); onClose(false) };
    const user: any = useUser();
    const { control, register, handleSubmit, reset, formState: { errors }, } = useForm();
    const userApi = useUserApi();
    const { id } = useParams();
    const [reviewDetails, setReviewDetails] = useState<any>(null);
    const [allCaptain, setAllCaptain] = useState<any>([]);
    const usecase: any = useUsecase();


    const getUsecase = () => {
        return new Promise((resolve, reject) => {
            userApi.useCaseDetails({ urlParams: { companyID:user.companyID,id: id, userID: user.userID } }, (message: any, resp: any) => {
                resolve(true)
            }, (message: any) => { })
        })
    }

    const submitApproveData = (data: any) => {
        let formParam = {
            "fkUseCaseID": id,
            "userID": user.userID,
            "fkCompanyID": user?.companyID,
            "captainID": data.fkCaptainID.value,
            "coCaptainID": data.fkCoCaptainID?.value
        };

        let formParam2 = {
            "fkRoleID": data.roleID, // Arirport manager it will be dynamic from useCasePermissionDetail
            "fkCompanyID": user?.companyID,
            "fkUseCaseID": id,
            "fkUserID": user.userID,
            "isApproved": true,
            "reason": '',
        };

        return new Promise((resolve, reject) => {
            userApi.setCaptainCoCaptain(formParam, (message: any, resp: any) => {
                userApi.postUsecaseApproved(formParam2, (message: any, resp: any) => {
                    resolve(message);
                }, (message: any, resp: any) => {
                    reject(message);
                })
            }, (message: any, resp: any) => {
                reject(message);
            })
        })
    }

    const onSubmitApproveHandler = (data: any) => {
        toast.promise(
            submitApproveData(data), {
            loading: 'Approving...',
            success: (msg: any) => {
                getUsecase();
                handleClose();
                return <b>{msg}</b>
            },
            error: (msg: any) => { return <b>{msg}</b> },
        });
    }


    const getAllCaptain = () => {
        userApi.employeeList({}, (message: any, resp: any) => {
            /* setManagers(resp.data.members.filter((captain: any) => {
                return captain.roleName == 'Innovation Manager';
            }).map((captain: any) => {
                captain.value = captain.userID;
                captain.label = `${captain.firstName} ${captain.lastName}`;
                return captain;
            })) */
            setAllCaptain(resp.data.members.filter((captain: any) => {
                return captain.roleName == 'Innovator';
            }).map((captain: any) => {
                captain.value = captain.userID;
                captain.label = `${captain.firstName} ${captain.lastName}`;
                return captain;
            }));
        }, (message: any, resp: any) => {
            // Error...
        })
    }


    const getUsecaseReviewListDetails = () => {
        console.log('Get all review')
        userApi.getUsecaseReviewList({ urlParams: { companyID: user?.companyID, usecaseID: id } }, (message: any, resp: any) => {
            setReviewDetails(resp.data);
            
            
        }, (message: any) => { })
    }

    useEffect(() => {
        setVisible(show);
        if(show){
            getAllCaptain();
            getUsecaseReviewListDetails();
        }
    }, [show])
    useEffect(() => {
      
        if(allCaptain?.length && reviewDetails){
            reset({
                fkCaptainID:getSelectedValue(reviewDetails.fkCaptainID,'value',allCaptain),
                fkCoCaptainID:getSelectedValue(reviewDetails.coCaptainID,'value',allCaptain)
            })
        }
    }, [allCaptain, reviewDetails])


    return (
        <Modal show={visible} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Approve</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(onSubmitApproveHandler)} id="disapproveUsecase">
                    <div className="feature-date">
                        <h5>Created by - <span>{usecase?.addedByName}</span></h5>
                    </div>
                    <div className="feature-date">
                        <h5>Innovation Manager - <span>{usecase?.assignedInnovationManagerName}</span></h5>
                    </div>
                    <div className="feature-date">
                        <h5>Airport - <span>{reviewDetails?.airPort}</span></h5>
                    </div>
                    <br />

                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="form-input mb-3">
                            <label>Choose Captain</label>
                            <Controller
                                name="fkCaptainID"
                                control={control}
                                rules={{ required: 'Captain is required' }}
                                render={({ field }) => (
                                    <div>
                                        <Select
                                            {...field}
                                            options={allCaptain}
                                            placeholder={reviewDetails?.fkStatusID == 1 ? reviewDetails?.captain : 'Select...'}
                                        />
                                        <Error error={errors.fkCaptainID} />
                                    </div>
                                )}
                            />
                        </div>
                    </div>

                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="form-input mb-3">
                            <label>Choose Co-Captain</label>

                            <Controller
                                name="fkCoCaptainID"
                                control={control}
                                rules={{ required: false }}
                                render={({ field }) => (
                                    <div>
                                        <Select
                                            {...field}
                                            options={allCaptain}
                                            placeholder={reviewDetails?.fkStatusID == 1 ? reviewDetails?.coCaptain : 'Select...'}
                                        />
                                        <Error error={errors.fkCoCaptainID} />
                                    </div>
                                )}
                            />
                        </div>
                    </div>


                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>Cancel</Button>
                <Button variant="primary" type='submit' form='disapproveUsecase'>Approve</Button>
            </Modal.Footer>
        </Modal>
    )
}