import React, { HtmlHTMLAttributes, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import Timer from './timer/Timer';
import { useUser, useUserPermissions } from '../_common/hooks/authHook';
import { useUsecase } from '../_common/hooks/usecaseHook';
import { useUserApi } from '../_common/hooks/api/UserApiHook';


interface props extends HtmlHTMLAttributes<HTMLDivElement> {
    permission?: string;
    rolls?: string[];
}
const UsecasePermission: React.FC<props> = ({
    children,
    permission,
    rolls,
    ...args
}) => {
    const permissions: any = useUserPermissions();
    const usecase: any = useUsecase();
    const user: any = useUser();
    const userApi = useUserApi();
    const { id } = useParams();

    const [allow,setAllow] = useState<boolean>(false)

    const getUsecase = () => {  /* get usecase details and store in redux */
        return new Promise((resolve, reject) => {
            userApi.useCaseDetails({ urlParams: {companyID:user.companyID, id: id, userID: user.userID } }, (message: any, resp: any) => {
                resolve(true)
            }, (message: any) => { })
        })
    }

    useEffect(()=>{
        
        if(usecase && permissions){
            let _allow:boolean = false

            if(rolls){
                let p:boolean = false
                rolls.map((roll:any)=>{
                    if(user.roleKey == roll){
                        p = true;
                    }
                })

                _allow = p;
            }
            

            if(permissions?.menus.usecase.read){
                _allow = true;
            }

            if (permission) {
                if (permissions?.menus.usecase[permission]) {
                    _allow = true;
                } else {
                    _allow = false;
                }
            }
            

            if(usecase?.fkStatusID == 42 || usecase?.fkStatusID == 47){
                _allow = false;
            } else {
                _allow = true;
            }

            setAllow(_allow)
        } else if(!usecase){
            getUsecase()
        }

    },[usecase,permissions])
    return (
        <>{allow?children:null}</>
    )
}

export default UsecasePermission;
