import ReactDOM from 'react-dom/client';
/* The following line can be included in a src/App.scss */
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';
import { Toaster } from 'react-hot-toast';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux'
import {  store } from './store'
import AppLoader from './_common/pageLoader/appLoader'
import { ClerkProvider } from '@clerk/clerk-react'
import { VITE_CLERK_PUBLISHABLE_KEY} from './_config/site_urls';
//console.log = function() {};
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


root.render(
  <Provider store={store}>
    <ClerkProvider publishableKey={VITE_CLERK_PUBLISHABLE_KEY as string}>
      <AppLoader />
      <App />
    </ClerkProvider>
    {/* <ToastContainer /> */}
    <Toaster
      position="top-center"
      reverseOrder={false}
    />

  </Provider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
/* 

<Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <React.Fragment>
        <AppLoader />
        <ToastContainer />
        <App />
      </React.Fragment>
    </PersistGate>
  </Provider>
*/
reportWebVitals();
