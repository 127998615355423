import React, { useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import './Auth.scss'
import toast from "react-hot-toast"
import { useUserApi } from "../../_common/hooks/api/UserApiHook";
import { URLS } from "../../_config";
import { useUser, useAuth } from "@clerk/clerk-react"
import Loader from "../../components/loader";
import Icon from "../../components/icon";
import { RedirectToSignIn } from "@clerk/clerk-react"


const LoginSSO = () => {
    const navigate = useNavigate();
    const userApi = useUserApi()
    const queryParams = new URLSearchParams((window as any).location.search);

    const reUrl: any = queryParams.get('redirect');

    const ClerkUser = useUser();
    const ClerkAuth = useAuth();

    const location: any = useLocation();


    const submitDataSSO = (data: any) => {
        return new Promise((resolve, reject) => {
            userApi.loginSSO(data, (message: any, resp: any) => {
                resolve(message);
            }, (message: any) => {
                reject(message);
            })
        })
    }
    const loginWithSSo = (data: any) => {
        toast.promise(
            submitDataSSO(data), {
            loading: 'Logging SSO...',
            success: (msg: any) => {
                /* console.log(location, location.hash)
                ClerkAuth.getToken().then((t)=>{
                    if(t){
                        localStorage.setItem('jwt-token',t as string)
                    }
                    
                }) */

                navigate(URLS.USE_CASE_LIST);
                return <b>{msg}</b>
            },
            error: (msg: any) => { return <b>{msg}</b> },
        });
    }

    useEffect(() => {
        if (ClerkUser?.isSignedIn) {
            console.log('ClerkUser==', ClerkUser)
            if (ClerkUser?.user) {
                const organizationMemberships = ClerkUser?.user?.organizationMemberships;
                if (organizationMemberships.length > 0) {
                    const envOrgId = process.env.REACT_APP_CLERK_ORGANIZATION_ID
                    let userOrgId = '';
                    organizationMemberships.map((orgData: any) => {
                        if (envOrgId === orgData?.organization?.id) {
                            userOrgId = orgData?.organization?.id
                        }
                    })
                    if (userOrgId === '') {
                        toast('You are not authorize user for this organization!', {
                            icon: <Icon name="shield-error" className="text-danger" />
                        })

                        ClerkAuth.signOut()
                        navigate(URLS.LOGIN)
                    } else {

                        let ClerkUserParams = {
                            "title": "",
                            "firstName": ClerkUser?.user?.firstName,
                            "lastName": ClerkUser?.user?.lastName,
                            "displayName": ClerkUser?.user?.fullName,
                            "email": ClerkUser?.user?.emailAddresses[0]?.emailAddress,
                            "avatar": ClerkUser?.user?.imageUrl,
                            "username": ClerkUser?.user?.emailAddresses[0]?.emailAddress,
                            "password": "Host@123456",
                            "fkCompanyID": 1,
                            "fkTenantID": 1,
                            "fkJobTitleID": 5,
                            "fkWorkForID": 1,
                            "fkRoleID": 5,
                            "addedBy": 0
                        };

                        loginWithSSo(ClerkUserParams);

                    }
                } else {
                    toast('You are not authorize user for this organization!', {
                        icon: <Icon name="shield-error" className="text-danger" />
                    })

                    ClerkAuth.signOut()
                    navigate(URLS.LOGIN)
                }
            }


        }

    }, [ClerkUser?.isSignedIn])

    return (
        <div className="login_page">

            <Loader />
        </div>
    );
}

export default LoginSSO;