import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom'
import Error from '../components/elements/formFieldError';
import toast from 'react-hot-toast';
import { useUser } from '../_common/hooks/authHook';
import { useWorkFlowApi } from '../_common/hooks/api/WorkFlowApiHook';
import { useUserApi } from '../_common/hooks/api/UserApiHook';
import { useUsecase } from '../_common/hooks/usecaseHook';

interface props {
    show: boolean,
    onClose: (status: boolean) => void
}

export default function RejectScoringPhaseModal({ show, onClose }: props) {
    const [visible, setVisible] = useState<boolean>(false);
    const handleClose = () => { setVisible(false); onClose(false) };

    const user: any = useUser();
    const usecase: any = useUsecase();

    const { control, register, handleSubmit, reset, getValues, formState: { errors, isValid }, } = useForm()
    const workFlowApi = useWorkFlowApi();
    const userApi = useUserApi();
    const workflowApi = useWorkFlowApi();
    const [employees, setEmployees] = useState<any>(null);
    const { id } = useParams();


    const getUsecase = () => {
        return new Promise((resolve, reject) => {
            userApi.useCaseDetails({ urlParams: { companyID:user.companyID,id: id, userID: user.userID } }, (message: any, resp: any) => {
                resolve(true)
            }, (message: any) => { })
        })
    }


    const declining = (data:any) => {
        return new Promise((resolve, reject) => {
        workflowApi.approvePreScoring({
            "fkUseCaseID": usecase.useCaseID,
            "approvalStatus": 0,
            "fkCompanyID": user.companyID,
            "reason": data.reason
        }, (message: any, resp: any) => {
            getUsecase().then(()=>{resolve(message)}).catch(()=>{resolve('please Re-login ')})
        }, (message: any, resp: any) => { reject(message)})
    })
    }
   

    const handleReject = (data: any) => {
        if (isValid) {
            toast.promise(
                declining(data), {
                loading: 'Declining...',
                success: (msg: any) => {
                    onClose(false)
                    return <b>{msg}</b>
                },
                error: (msg: any) => { return <b>{msg}</b> },
            });
        } else {
            //form.current.submit()
            console.log('Not valid')
        }
    }

    useEffect(() => {
        setVisible(show)
    }, [show])



    return (
        <Modal show={visible} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Reject</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form className="form-area" id="addScoreForm" onSubmit={handleSubmit(handleReject)}>
                    <label>Reason</label>
                    <textarea className="form-control" rows={6} {...register("reason", { required: "Required " })} id="fkUserID">
                    </textarea>
                    <Error error={errors.reason} />
                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}> Cancel </Button>
                <Button variant="primary" type='submit' form='addScoreForm'>Reject </Button>
            </Modal.Footer>
        </Modal>
    )
}