import React, { useEffect, useState } from "react";
import './DataFilters.scss'
import { Controller, useForm } from "react-hook-form";
import Select from "react-select"
import { useMasterWorkflows, usePhases } from "../../_common/hooks/masterHook";
import { useMasterApi } from "../../_common/hooks/api/MasterApiHook";
import { useUser } from "../../_common/hooks/authHook";
import { useDispatch } from "react-redux";
import { ACTIONS } from "../../_config";
import { useUserApi } from "../../_common/hooks/api/UserApiHook";
import { usecaseFilterData } from "../../_config/dataTypes";
import { getRelativeListWithMultiSelected, getSelectedValue } from "../../_config/helpers";


interface props {
    setFilter: (data: usecaseFilterData) => void,
    dates?:boolean
}

export function DataFilters({ setFilter, dates = true }: props) {

    const { register, control, handleSubmit, watch, setValue, formState: { errors, isValid }, } = useForm({ reValidateMode: 'onChange' });


    const phasesList: any = usePhases();
    const workflows: any = useMasterWorkflows();
    const user: any = useUser();
    const dispatch: any = useDispatch();
    const masterApi = useMasterApi();
    const userApi = useUserApi();

    const [sectionList, setSectionList] = useState<any>([]);
    const [phaseList, setPhaseList] = useState<any>([]);
    const [airportList, setAirportList] = useState<any>([]);
    const [runwayList, setRunwayList] = useState<any>([]);
    const [captainList, setCaptainList] = useState<any>([]);

    const [sectionListLoader, setSectionListLoader] = useState<boolean>(false);
    const [airportLoader, setAirportLoader] = useState<boolean>(false);
    const [runwayLoader, setRunwayLoader] = useState<boolean>(false);
    const [captainLoader, setCaptainLoader] = useState<boolean>(false);

    const sectionWatch = watch('section');

    const [filter, _setFilter] = useState<usecaseFilterData>({})

    const getAirportList = () => {
        setAirportLoader(true)
        userApi.airportList({}, (message: any, resp: any) => {
            var tempArr = resp.data.roles;
            (tempArr && tempArr.length > 0) &&
                setAirportList(tempArr.map((item: any) => {
                    item.label = item.name
                    item.value = item.airportID
                    return item;
                }))

            setAirportLoader(false)
        }, (message: any, resp: any) => {
            setAirportLoader(false)
        })
    }

    const getRunwayList = () => {
        setRunwayLoader(true)
        userApi.runwayList({ urlParams: { PageIndex: 1, PageSize: 10 } }, (message: any, resp: any) => {
            var tempArr = resp.data.runway;
            (tempArr && tempArr.length > 0) &&
                setRunwayList(tempArr.map((item: any) => {
                    item.label = item.title
                    item.value = item.runwayID
                    return item;
                }))

                setRunwayLoader(false)
        }, (message: any, resp: any) => {
            setRunwayLoader(false)
        })
    }

    const getuseCaseCaptains = () => {
        setCaptainLoader(true)
        userApi.getUsecaseCaptains({}, (message: any, resp: any) => {
            setCaptainList(resp.data.map((item: any) => {
                item.label = item.captain
                item.value = item.fkCaptainID
                return item
            }))
            setCaptainLoader(false)
        }, (message: any, resp: any) => {
            setCaptainLoader(false)
        })

    }


    const getSections = () => {
        setSectionListLoader(true)
        masterApi.getSections({ urlParams: { companyID: user?.companyID } }, (msg: any, resp: any) => {
            let workflowSteps: any = [];
            let sections: any = resp.data.workFlowSection
            masterApi.getPhases({ urlParams: { companyID: user?.companyID } }, (msg: any, resp: any) => {
                let phases: any = resp.data.workFlowPhase
                sections.map((section: any) => {
                    let sec: any = {
                        sectionDetails: section,
                        phases: []
                    };
                    phases.map((phase: any) => {
                        if (phase.fkSectionValue == section.sectionID) {
                            sec.phases.push(phase)
                        }
                    })
                    workflowSteps.push(sec)
                })
                dispatch({
                    type: ACTIONS.MASTER.WORKFLOWS, payload: {
                        workflows: workflowSteps
                    }
                })
                setSectionListLoader(false)
            }, (msg: any) => { setSectionListLoader(false)})
        }, (msg: any) => { setSectionListLoader(false)})
    }

    const updateFilter = (name: any, data: any) => {
        switch (name) {
            case 'sectionIDs':
                let _value: any = (data.map((_data: any) => {
                    return _data.value
                })).join(',')
                _setFilter({ ...filter, ...{ sectionIDs: _value } })
                break;

            case 'phaseIDs':
                let _valuePhaseIDs: any = (data.map((_data: any) => {
                    return _data.value
                })).join(',')
                _setFilter({ ...filter, ...{ phaseIDs: _valuePhaseIDs } })
                break;

            case 'airportIDs':
                let _valueAirportIDs: any = (data.map((_data: any) => {
                    return _data.value
                })).join(',')
                _setFilter({ ...filter, ...{ airportIDs: _valueAirportIDs } })
                break;

            case 'runwayIDs':
                let _valueRunwayIDs: any = (data.map((_data: any) => {
                    return _data.value
                })).join(',')
                _setFilter({ ...filter, ...{ runwayIDs: _valueRunwayIDs } })
                break;

            case 'captainIDs':
                let _valueCaptainIDs: any = (data.map((_data: any) => {
                    return _data.value
                })).join(',')
                _setFilter({ ...filter, ...{ captainIDs: _valueCaptainIDs } })
                break;
            case 'startDate':
                let _valueStartDate: any = data?.target?.value
                _setFilter({ ...filter, ...{ startDate: _valueStartDate } })
                break;
            case 'endDate':
                let _valueEndDate: any = data?.target?.value
                _setFilter({ ...filter, ...{ endDate: _valueEndDate } })
                break;
            case 'rankFrom':
                let _valueRankFrom: any = data?.target?.value
                _setFilter({ ...filter, ...{ rankFrom: _valueRankFrom } })
                break;
            case 'rankTo':
                let _valueRankTo: any = data?.target?.value
                _setFilter({ ...filter, ...{ rankTo: _valueRankTo } })
                break;

            default:
                break;
        }
    }

    useEffect(() => {
        if (workflows) {
            setSectionList(workflows?.map((section: any) => {
                section.label = section.sectionDetails.title
                section.value = section.sectionDetails.sectionID
                section.phases = section.phases.map((phase: any) => {
                    phase.label = phase.title
                    phase.value = phase.workFlowPhaseID
                    return phase;
                })
                return section;
            }))
        }
    }, [workflows])

    useEffect(() => {
        if (sectionList.length != 0 && airportList.length != 0 && runwayList.length != 0 && captainList.length != 0) {
            let prevFilter: any = JSON.parse(sessionStorage.getItem('usecase-filter') || '{}');
            if (Object.keys(prevFilter).length > 0) {
                Object.keys(prevFilter).map((fieldName: any) => {
                    switch (fieldName) {
                        case 'sectionIDs':
                            setValue('section', getSelectedValue(prevFilter[fieldName].split(','), 'value', sectionList))
                            break;
                        case 'phaseIDs':
                            setValue('phase', getSelectedValue(prevFilter[fieldName].split(','), 'value', getPhaseOptions(getSelectedValue(prevFilter['sectionIDs'].split(','), 'value', sectionList))))
                            break;
                        case 'airportIDs':
                            setValue('airport', getSelectedValue(prevFilter[fieldName].split(','), 'value', airportList))
                            break;
                        case 'runwayIDs':
                            setValue('runway', getSelectedValue(prevFilter[fieldName].split(','), 'value', runwayList))
                            break;
                        case 'captainIDs':
                            setValue('captain', getSelectedValue(prevFilter[fieldName].split(','), 'value', captainList))
                            break;
                        case 'startDate':
                            setValue('startDate', prevFilter[fieldName])
                            break;
                        case 'endDate':
                            setValue('endDate', prevFilter[fieldName])
                            break;
                        default:
                            break;
                    }
                })
                _setFilter(prevFilter)
            } else {
                _setFilter({})
                setFilter({});
                sessionStorage.setItem('usecase-filter', '{}');
            }
        }
    }, [sectionList,airportList,runwayList,captainList])

    useEffect(() => {
        console.log('set filter')
        if(sectionList.length != 0) {
            setFilter(filter);
            sessionStorage.setItem('usecase-filter',JSON.stringify(filter));
        }
    }, [filter]);


    



    useEffect(() => {
        getAirportList()
        getRunwayList()
        getuseCaseCaptains();
        /* 
        load all required data for filter..
        */

    }, [])

    const getPhaseOptions = (sections: any) => {
        if(sections == null){
            return null
        } else {
            let phases: any = [];
            sections.map((sec: any) => {
                if(sec != null){
                    phases = [...phases, ...sec.phases]
                }
                
            })
            return phases
        }
        
    }

    useEffect(() => {
        if (sectionWatch && sectionWatch.length > 0) {
            setPhaseList(getPhaseOptions(sectionWatch))
        } else {
            setPhaseList([])
        }
    }, [sectionWatch])

    



    return (
        <div className="table-filter-panel mb-3 d-flex gap-3">
            <div className="flex-1">
                <label>Section</label>
                <Controller
                    control={control}
                    name={'section'}
                    render={({ field: { onChange, value, ref, name } }) => (
                        <Select
                            name={name}
                            options={sectionList}
                            onChange={(data) => {
                                updateFilter('sectionIDs', data)
                                onChange(data)
                                setValue('phase', null)
                            }}
                            onMenuOpen={()=>{
                                if(sectionList.length == 0){
                                    getSections();
                                }
                            }}
                            isLoading={sectionListLoader}
                            value={value}
                            isMulti={true}
                            ref={ref}
                            isClearable={true}
                            placeholder="ALL"
                        />
                    )}
                />
            </div>

            <div className="flex-1">
                <label>Phase</label>
                <Controller
                    control={control}
                    name={'phase'}
                    render={({ field: { onChange, value, ref, name } }) => (
                        <Select
                            name={name}
                            options={phaseList}
                            onChange={(data) => {
                                updateFilter('phaseIDs', data)
                                onChange(data)
                            }}
                            value={value}
                            ref={ref}
                            isMulti={true}
                            isClearable={true}
                            placeholder="ALL"
                        />
                    )}
                />
            </div>

            <div className="flex-1">
                <label>Airport</label>
                <Controller
                    control={control}
                    name={'airport'}
                    render={({ field: { onChange, value, ref, name } }) => (
                        <Select
                            name={name}
                            options={airportList}
                            onChange={(data) => {
                                updateFilter('airportIDs', data)
                                onChange(data)
                            }}
                            onMenuOpen={()=>{
                                if(airportList.length == 0){
                                    getAirportList();
                                }
                            }}
                            isLoading={airportLoader}
                            value={value}
                            isMulti={true}
                            ref={ref}
                            isClearable={true}
                            placeholder="ALL"
                        />
                    )}
                />
            </div>
            <div className="flex-1">
                <label>Runway</label>
                <Controller
                    control={control}
                    name={'runway'}
                    render={({ field: { onChange, value, ref, name } }) => (
                        <Select
                            name={name}
                            options={runwayList}
                            onChange={(data) => {
                                updateFilter('runwayIDs', data)
                                onChange(data)
                            }}
                            isLoading={runwayLoader}
                            onMenuOpen={()=>{
                                if(runwayList.length == 0){
                                    getRunwayList()
                                }
                            }}
                            value={value}
                            isMulti={true}
                            ref={ref}
                            isClearable={true}
                            placeholder="ALL"
                        />
                    )}
                />

            </div>
            <div className="flex-1">
                <label>Captain</label>
                <Controller
                    control={control}
                    name={'captain'}
                    render={({ field: { onChange, value, ref, name } }) => (
                        <Select
                            name={name}
                            options={captainList}
                            onChange={(data) => {
                                updateFilter('captainIDs', data)
                                onChange(data)
                            }}
                            onMenuOpen={() => {
                                if (captainList.length == 0) {
                                    getuseCaseCaptains()
                                }
                            }}
                            isLoading={captainLoader}
                            value={value}
                            isMulti={true}
                            ref={ref}
                            isClearable={true}
                            placeholder="ALL"
                        />
                    )}
                />

            </div>
            {/* <div className="flex-1">
                <label>Rank</label>
                <div className="d-flex  ">
                    <input type="number" className="form-control" min={0}
                        onChange={(data) => { updateFilter('rankFrom', data) }}
                        placeholder="From" style={{minWidth: '80px'}}/>
                    <input type="number" className="form-control" min={0}
                        onChange={(data) => { updateFilter('rankTo', data) }}
                        placeholder="To" style={{minWidth: '80px'}}/>
                </div>
            </div> */}
            {dates && (
                <div className="flex-1">
                    <label>Date Submitted</label>
                    <div className="d-flex date-range-flds">
                        <input type="date" className="form-control "
                             placeholder="MM/DD/YYYY" style={{ width: '130px' }} {...register('startDate')} onChange={(data) => {
                                updateFilter('startDate', data)
                            }} />
                        <input type="date" className="form-control" max={new Date().toISOString().split("T")[0]}
                        {...register('endDate')}
                            onChange={(data) => {
                                updateFilter('endDate', data)
                            }} placeholder="MM/DD/YYYY" style={{ width: '130px' }} />
                    </div>
                </div>
            )}

        </div>
    );
}

