import React, { useEffect, useRef, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { useUserApi } from "../../_common/hooks/api/UserApiHook"
import { useUser } from "../../_common/hooks/authHook"
import ProductList from "../product/productList"
import { ACTIONS, SAGA_ACTIONS } from '../../_config/index'
import { useFormApi } from "../../_common/hooks/api/FormApiHook"
import { useMasterWorkflows } from "../../_common/hooks/masterHook"
import { useMasterApi } from "../../_common/hooks/api/MasterApiHook"
import { useDispatch } from "react-redux"
import { useControlTypes, useWorkflows } from "../../_common/hooks/formHook"

import './dynamicForm.scss'
import { FaRegFile, FaRegFilePdf } from "react-icons/fa6"


import { BsFileEarmarkPpt, BsFileEarmarkZip } from "react-icons/bs"
import { IoDocumentTextOutline } from "react-icons/io5"
import Icon from "../icon"
import Loader from "../loader"
import FilePreview from "../filePreview"
import { getSelectedValue } from "../../_config/helpers"

interface props {
    _workflow: string,
    _module: string,
    _form: string,
    useCaseID: any,
    data?: any,
    onSubmitSuccess?: (edit: boolean) => void,
    editMode?: boolean,
    section?: string,
    phase?: string,
    answerID?: any,
    shift?: number,
    pop?: number,
}


export default function DynamicFormView({ _workflow, _module, _form, useCaseID, data, onSubmitSuccess,
    editMode, section, phase, answerID, shift, pop }: props) {
    const { control, register, handleSubmit, reset, getValues, formState: { errors, isValid }, } = useForm()
    const form: any = useRef();
    const userApi = useUserApi();
    const formApi = useFormApi();

    const [startTime, setStartTime] = useState<any>(new Date())

    const [config, setConfig] = useState<any>(null)
    const [modullies, setModullies] = useState<any[]>([])
    const [forms, setForms] = useState<any[]>([])

    const [solutionID, setSolutionID] = useState<any>(0);
    const user: any = useUser();
    const workflowSteps = useMasterWorkflows();
    const masterApi = useMasterApi();
    const dispatch: any = useDispatch()
    const [selectedImageUrls, setSelectedImageUrls] = useState<any>({})
    const [edit, setEdit] = useState<boolean | null>(null)
    const [multiFileList, setMultiFileList] = useState<any>({})
    const controlTypes: any = useControlTypes();

    const workflows = useWorkflows()

    const getSections = () => {
        masterApi.getSections({ urlParams: { companyID: user.companyID } }, (msg: any, resp: any) => {
            let workflowSteps: any = [];
            let sections: any = resp.data.workFlowSection
            masterApi.getPhases({ urlParams: { companyID: user.companyID } }, (msg: any, resp: any) => {
                let phases: any = resp.data.workFlowPhase
                sections.map((section: any) => {
                    let sec: any = {
                        sectionDetails: section,
                        phases: []
                    };
                    phases.map((phase: any) => {
                        if (phase.fkSectionValue == section.sectionID) {
                            sec.phases.push(phase)
                        }
                    })
                    workflowSteps.push(sec)
                })
                dispatch({
                    type: ACTIONS.MASTER.WORKFLOWS, payload: {
                        workflows: workflowSteps
                    }
                })
            }, (msg: any) => { })
        }, (msg: any) => { })
    }

    useEffect(() => {
        if (workflowSteps == null && user) {
            getSections();
        }
    }, [workflowSteps, user])



    const getWorkFlows = () => {
        formApi.call(SAGA_ACTIONS.FORM.GET_WORKFLOWS, { urlParams: { companyID: user.companyID } }, (message: any, resp: any) => { }, (message: any) => { })
    }

    const getModules = (workFlowID: any) => {
        formApi.call(SAGA_ACTIONS.FORM.GET_MODULES, { urlParams: { WorkFowID: workFlowID, companyID: user.companyID } }, (message: any, resp: any) => {
            setModullies(resp.data.moduleIs.map((mod: any) => {
                mod.value = mod.moduleID;
                mod.label = mod.pageTitle;
                if (mod.pageTitle == _module) {
                    getForms(mod.moduleID)
                }
                return mod
            }))
        }, (message: any, resp: any) => {

        })
    }

    const getForms = (moduleID: any) => {
        formApi.call(SAGA_ACTIONS.FORM.GET_FORMS, { urlParams: { ModuleID: moduleID, companyID: user.companyID } }, (message: any, resp: any) => {
            getFormConfig(resp.data.formID)
            setForms([{ ...resp.data, value: resp.data.formID, label: resp.data.title }])
        }, (message: any, resp: any) => {

        })
    }

    const getFormConfig = (formID: any) => {
        userApi.getFormConfig({ urlParams: { id: formID } }, (message: any, resp: any) => {
            setConfig(resp.data)
        }, (message: any) => {

        })
    }

    const getColClass = (field: any) => {
        let s: number = Math.round(12 / parseInt(config?.layout?.replace('Col:', '')))
        if (field.colSpan) {
            s = s * field.colSpan
        }
        if (s > 12) {
            s = 12
        }
        return 'col-md-' + s
    }


    const getControlTypes = () => {
        formApi.call(SAGA_ACTIONS.FORM.GET_CONTROL_TYPES, { urlParams: { PageIndex: 0, PageSize: 999 } }, (message: any, resp: any) => {

        }, (message: any, resp: any) => {

        })
    }

    /* const getFileElement = (field: any, i: any, bottomBorder: boolean) => {
        return (
            <div className={getColClass(field) + (bottomBorder ? ' border-bottom pb-2' : '')} key={i}>
                <div className="custom-file-uploader mb-4">


                    {selectedImageUrls[field.name] ? (
                        <>
                            <a href={selectedImageUrls[field.name].src} download target='_blank' className="react-icon-grey">
                                {selectedImageUrls[field.name].type == 'image' &&
                                    <img src={selectedImageUrls[field.name].src} style={{ position: 'absolute', width: '100%', height: '100%', objectFit: 'contain', left: 0, top: 0 }} />
                                }
                                {selectedImageUrls[field.name].type == 'pdf' &&
                                    <div className="text-center">
                                        <FaRegFilePdf style={{ fontSize: '64px' }} />
                                        <p>{selectedImageUrls[field.name].name}</p>
                                    </div>
                                }

                                {selectedImageUrls[field.name].type == 'zip' &&
                                    <div className="text-center">
                                        <BsFileEarmarkZip style={{ fontSize: '64px' }} />
                                        <p>{selectedImageUrls[field.name].name}</p>
                                    </div>
                                }
                                {selectedImageUrls[field.name].type == 'doc' &&
                                    <div className="text-center">
                                        <IoDocumentTextOutline style={{ fontSize: '64px' }} />
                                        <p>{selectedImageUrls[field.name].name}</p>
                                    </div>
                                }

                                {selectedImageUrls[field.name].type == 'ppt' &&
                                    <div className="text-center">
                                        <BsFileEarmarkPpt style={{ fontSize: '64px' }} />
                                        <p>{selectedImageUrls[field.name].name}</p>
                                    </div>

                                }

                                {selectedImageUrls[field.name].type == 'file' &&
                                    <div className="text-center">
                                        <FaRegFile style={{ fontSize: '64px' }} />
                                        <p>{selectedImageUrls[field.name].name}</p>
                                    </div>

                                }
                            </a>
                        </>
                    )

                        :
                        null
                    }
                </div>
            </div>
        )
    } */

    const getFileElement = (field: any, i: any, bottomBorder: boolean) => {
        if (field.multiSelect) {
            return (
                <div className={getColClass(field) + (bottomBorder ? ' border-bottom pb-2' : '')} key={i}>

                    <label htmlFor="" className="form-label">{field.caption}</label>

                    

                    {multiFileList[field.name] && multiFileList[field.name].length > 0 && (
                        <React.Fragment>
                            <hr />

                            <div className="table-responsive list-table">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col" className="w-5">#</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">file</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {multiFileList[field.name] && multiFileList[field.name].length > 0 && (
                                            multiFileList[field.name].map((item: any, index: number) => (
                                                <tr key={field.name + '_' + index}>
                                                    <td><h6>{index + 1}</h6></td>
                                                    <td><h6>{item.text}</h6></td>
                                                    <td>
                                                        <div className="d-flex gap-3 justify-content-start" >
                                                            <FilePreview url={item.values} size={42} />
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        )}
                                    </tbody>
                                </table>



                            </div>
                        </React.Fragment>
                    )}




                </div>
            )
        } else {
            return (
                <div className={getColClass(field)} key={i}>
                    <label htmlFor="" className="form-label">{field.caption}</label>
                    <div className="custom-file-uploader mb-4">

                        
                        {selectedImageUrls[field.name] ? (
                            <a href={selectedImageUrls[field.name].src} download target='_blank' className="react-icon-grey d-block" >
                                {selectedImageUrls[field.name].type == 'image' &&
                                    <img src={selectedImageUrls[field.name].src} style={{ position: 'absolute', width: '100%', height: '100%', objectFit: 'contain', left:0,top:0 }} />
                                }


                                {selectedImageUrls[field.name].type == 'pdf' &&
                                    <div className="text-center">
                                        <FaRegFilePdf style={{ fontSize: '64px' }} />
                                        <p>{selectedImageUrls[field.name].name}</p>
                                    </div>
                                }


                                {selectedImageUrls[field.name].type == 'zip' &&
                                    <div className="text-center">
                                        <BsFileEarmarkZip style={{ fontSize: '64px' }} />
                                        <p>{selectedImageUrls[field.name].name}</p>
                                    </div>
                                }

                                {selectedImageUrls[field.name].type == 'doc' &&
                                    <div className="text-center">
                                        <IoDocumentTextOutline style={{ fontSize: '64px' }} />
                                        <p>{selectedImageUrls[field.name].name}</p>
                                    </div>
                                }

                                {selectedImageUrls[field.name].type == 'ppt' &&
                                    <div className="text-center">
                                        <BsFileEarmarkPpt style={{ fontSize: '64px' }} />
                                        <p>{selectedImageUrls[field.name].name}</p>
                                    </div>

                                }

                                {selectedImageUrls[field.name].type == 'file' &&
                                    <div className="text-center">
                                        <FaRegFile style={{ fontSize: '64px' }} />
                                        <p>{selectedImageUrls[field.name].name}</p>
                                    </div>

                                }

                            </a>
                        )
                            :
                            <>
                                <i className="file-upload-icon"></i>
                                <p className="mb-0">Click to upload.</p>
                            </>
                        }

                    </div>

                </div>
            )
        }
    }
    
    const getTextElement = (field: any, i: any, bottomBorder: boolean) => {
        return (
            <div className={getColClass(field) + (bottomBorder ? ' border-bottom pb-2' : '')} key={i}>
                <label className="form-label">{field.caption}</label>
                <Controller
                    defaultValue="--"
                    control={control}
                    name={field.name}
                    render={({ field: { value } }) => (
                        <p>{value}</p>
                    )}
                />

            </div>
        )
    }

    const getTextareaElement = (field: any, i: any, bottomBorder: boolean) => {
        return (
            <div className={getColClass(field) + ' form-area' + (bottomBorder ? ' border-bottom pb-2' : '')} key={i}>
                <label className="form-label">{field.caption}</label>
                <Controller
                    defaultValue="--"
                    control={control}
                    name={field.name}
                    render={({ field: { onChange, value, ref } }) => (
                        <p>{value}</p>
                    )}
                />

            </div>
        )
    }

    const getCheckboxElement = (field: any, i: any, bottomBorder: boolean) => {
        return (
            <div className={getColClass(field) + (bottomBorder ? ' border-bottom pb-2' : '')} key={i}>
                <label className="form-label">{field.caption}</label>
                <div className="d-flex">
                    {Object.keys(field.options).map((valueKey: any, k: any) => (
                        <div key={k}>
                            <Controller
                                defaultValue=""
                                control={control}
                                name={field.name + valueKey}
                                rules={{
                                    required: false, //field.required ? field.required : false,
                                }}
                                render={({ field: { onChange, value, ref, name } }) => (
                                    <>
                                        {value ?
                                            <div className="d-flex gap-2 align-items-center" style={{marginRight:'16px'}}>
                                                <Icon name='checked' size={20} />
                                                <h6 style={{ fontWeight: '700' }} className="m-0">{field.options[valueKey]} </h6>

                                            </div>
                                            : null}
                                    </>

                                )}
                            />
                            
                        </div>
                    ))}
                </div>

            </div>
        )
    }

    const getSelectElement = (field: any, i: any, bottomBorder: boolean) => {
        return (
            <div className={getColClass(field) + (bottomBorder ? ' border-bottom pb-2' : '')} key={i}>
                <label className="form-label">{field.caption}</label>
                <div className="d-flex gap-3">
                    {Object.keys(field.options).map((valueKey: any, k: any) => (
                        <div className="form-check" key={k}>
                            <Controller
                                defaultValue=""
                                control={control}
                                name={field.name}
                                render={({ field: { value, name } }) => {
                                    if (valueKey == value) {
                                        return <label className="form-check-label"> {field.options[valueKey]} </label>
                                    } else {
                                        return <></>
                                    }
                                }}
                            />

                            {/* (
                                    <input className="form-check-input" type="radio" name={name} value={valueKey} checked={valueKey == value} disabled={valueKey != value} />
                                ) */}

                            {/* <label className="form-check-label"> {field.options[valueKey]} </label> */}
                        </div>
                    ))}
                </div>

            </div>
        )
    }

    const getRadioElement = (field: any, i: any, bottomBorder: boolean) => {
        return (
            <div className={getColClass(field) + (bottomBorder ? ' border-bottom pb-2' : '')} key={i}>
                <label className="form-label">{field.caption}</label>
                <div className="d-flex">
                    {Object.keys(field.options).map((valueKey: any, k: any) => (
                        <div key={k}>
                            <Controller
                                defaultValue=""
                                control={control}
                                name={field.name}
                                render={({ field: { value, name } }) => (
                                    <div>
                                        {valueKey == value? <h6 style={{fontWeight:'700'}}> {field.options[valueKey]} </h6>:null}
                                    </div>
                                )}
                            />
                            
                        </div>
                    ))}
                </div>

            </div>
        )
    }

    const getLabelElement = (field: any, i: any, bottomBorder: boolean) => {
        return (
            <div className={getColClass(field) + (bottomBorder ? ' border-bottom pb-2' : '')} key={i}>
                <h3 className="h6 text-purple font-weight-600 mb-4">{field.caption}</h3>

            </div>
        )
    }

    const getHeadLineElement = (field: any, i: any) => {
        return (
            <div className={getColClass(field)} key={i}>
                <h2 className="h5">{field.caption}</h2>
                <hr />
            </div>
        )
    }

    const getDatePickerElement = (field: any, i: any) => {

        return (
            <div className={getColClass(field)} key={i}>
                <label htmlFor="" className="form-label">{field.caption}</label>
                <Controller
                    control={control}
                    name={field.name}
                    render={({ field: { value } }) => (
                        <p>{value}</p>
                    )}
                />
            </div>
        )
    }

    const getComponent = (field: any, i: any, bottomBorder: boolean) => {
        switch (field.text) {
            case 'productList':
                return <div className={getColClass(field) + (bottomBorder ? ' border-bottom pb-2' : '')}><ProductList key={`comp-${i}`} useCaseID={useCaseID} heading={field.caption} solutionID={solutionID} isView={true} /></div>
            default:
                return null
        }
    }



    const getSolutionID = () => {
        return new Promise((resolve, reject) => {
            formApi.getSolutionID({ urlParams: { UseCaseID: useCaseID } }, (msg: any, resp: any) => {
                setSolutionID(resp.data.solutionFramingID);
                resolve(true);
            }, (msg: any) => { })
        })
    }

    const getDynamicFormData = () => {
        let sectionID = 0;
        let phaseID = 0;
        workflowSteps.map((_section: any) => {
            if (_section.sectionDetails.title == section) {
                sectionID = _section.sectionDetails.sectionID;
                _section.phases.map((_phase: any) => {
                    if (_phase.title == phase) {
                        phaseID = _phase.phaseValue
                    }
                })
            }
        })

        let params: any = {
            "companyID": user.companyID,
            "formID": forms[0].value,
            "fkUsecaseID": useCaseID,
        }
        if (answerID) {
            params.fkAnswerID = answerID
        } else {
            params.fkSectionID = sectionID
            params.fkPhaseID = phaseID
        }

        formApi.getDynamicFormData(params, (msg: any, resp: any) => {
            //setConfig(resp.data.form)
            let answers: any = resp.data.answers;
            let ansOptions: any = resp.data.ansOptions;
            if (answers.length > 0) {
                setEdit(true)
            } else {
                setEdit(false)
            }
            let values: any = {};
            let localMultiFileList: any = {};
            answers.map((answer: any) => {
                config.controls.map((control: any) => {
                    if (answer.fkFormControlID == control.fkFormControlID) {
                        if (control.inputType == "FileField") {
                            if(answer.url) {
                                let ext =  answer.url.split(/[#?]/)[0].split('.').pop().trim();
                                let type = 'file';
                                switch (ext.toLowerCase()) {
                                    case 'jpg':
                                    case 'jpeg':
                                    case 'png':
                                    case 'svg':
                                    case 'webp':
                                    case 'gif':
                                    case 'avif':
                                        type = 'image'; break;
                                    case 'pdf':
                                        type = 'pdf'; break;
                                    case 'zip':
                                    case 'x-zip-compressed':
                                        type = 'zip'; break;
                                    case 'doc':
                                    case 'docx':
                                        type = 'doc'; break;
                                    case 'ppt':
                                    case 'pptx':
                                        type = 'ppt'; break;
                                        
                                       
                                
                                    default: type = 'file'; break;
                                }
                                
                                if(control.multiSelect){
                                    let fileData:any = localMultiFileList[control.name]?localMultiFileList[control.name]:[]
                                    fileData.push({ text: answer.textAnswer, values: answer.url });
                                    localMultiFileList = {...localMultiFileList,[control.name]:fileData}
                                } else {
                                    let set: any = {}
                                    set[control.name] = { src: answer.url, type: type }
                                    setSelectedImageUrls({ ...selectedImageUrls, ...set })
                                }

                            }
                        } if (control.inputType == "CheckBox") {
                            let options: any = ansOptions.filter((opt: any) => { return opt.fkFormControlID == control.fkFormControlID })
                            Object.keys(control.options).map((key: any) => {
                                let checked = options.filter((opt: any) => { return opt.optionValue == control.options[key] })
                                values[control.name + key] = !!checked.length
                            })

                        }  else if (control.inputType == "Select" || control.inputType == "Dropdown") {
                            let options: any = ansOptions.filter((opt: any) => { return opt.fkFormControlID == control.fkFormControlID })
                            console.log('Options........', options)

                            values[control.name] = { label: options[0].optionValue, value: options[0].optionKey }
                        } else {
                            values[control.name] = answer.textAnswer || ''
                        }
                    }
                })
            });
            setMultiFileList(localMultiFileList)
            reset(values)

        }, (msg: any) => { })
    }

    useEffect(() => {
        if (forms.length) {
            //getDynamicFormData();
        }
    }, [forms])
    useEffect(() => {
        if (workflows) {
            workflows.map((flow: any) => {
                if (flow.title == _workflow) {
                    getModules(flow.workFlowID)
                }
            })
            
        }
    }, [workflows])

    useEffect(() => {
        if (_form == 'solution-provider') {
            getSolutionID().then((status) => {
                setStartTime(new Date())
            })
        } else {
            setStartTime(new Date())
        }
    }, [])

    useEffect(() => {
        if (data) {
            let dataSet: any = {}
            config.controls.map((field: any, i: any) => {
                dataSet[field.name] = data[field.name]
            })
            reset(dataSet)
        }

    }, [data])

    useEffect(() => {
        if (config && editMode && workflowSteps && ((section && phase) || answerID)) {
            
            getDynamicFormData()
        }
    }, [config, editMode, workflowSteps, section, phase, answerID])

    useEffect(() => {
        if (_form == 'solution-provider' && solutionID != 0) {
            if (workflows == null && user) {
                getWorkFlows();
            }
        } else {
            if (workflows == null && user) {
                getWorkFlows();
            }
        }
    }, [user, workflows]);

    useEffect(() => {
        if (!controlTypes) {
            getControlTypes()
        }
    }, [controlTypes])




    return (
        <React.Fragment>
            {
                (edit === true) ?
                    <div className='white-box mb-3'>
                        <div className="form-area">
                            <form className="row g-3 usecase-paragraph"  >
                                {config?.controls.map((field: any, i: any) => {
                                    if (shift && i < shift) {
                                        return null;
                                    }
                                    if (pop && i >= config?.controls.length - pop) {
                                        return null;
                                    }
                                    switch ((getSelectedValue(field.fkControlTypeID, 'controlTypeID', controlTypes) as any)?.controlName) {
                                        case 'FileField': return getFileElement(field, i, true); break;
                                        case 'TextBox': return getTextElement(field, i, true); break;
                                        case 'TextArea': return getTextareaElement(field, i, true); break;
                                        case 'CheckBox': return getCheckboxElement(field, i, true); break;
                                        case 'Radio': return getRadioElement(field, i, true); break;
                                        case 'Lebel': return getLabelElement(field, i, true); break;
                                        case 'HeadLine': return getHeadLineElement(field, i); break;
                                        case 'CustomControl': return getComponent(field, i, true); break;
                                        case 'Select':
                                        case 'Dropdown':

                                            return getSelectElement(field, i, true); break;
                                        case 'DatePicker': return getDatePickerElement(field, i); break;
                                        default:
                                            return null;
                                    }
                                })}
                            </form>
                        </div>
                    </div> : (edit === null?<Loader/> :null)
            }

        </React.Fragment>



    )

}