import { ActionExtended } from '../../_common/interfaces/ActionExtended'
import { ACTIONS, URLS } from '../../_config'

export interface MasterReducer {
  workflows: any,
  phases:string[],
  urls:any,
  systemConfigs:any,
}



const initialState: MasterReducer = {
  workflows: null,
  phases: ['Discovery','Challenge Statement','Solution Framing','Ranking and Prioritization','Hanger Decision','Project Plan','Procurement Plan','Test Plan','In-Field Testing','Day in the Life','Field Acceptance','Go/No Go'],
  urls: {
    'Discovery': URLS.WORKFLOW.HANGER.DISCOVERY,
    'Challenge Statement': URLS.WORKFLOW.HANGER.CHALLENGE,
    'Solution Framing': URLS.WORKFLOW.HANGER.SOLUTIONS,
    'Ranking and Prioritization': URLS.WORKFLOW.HANGER.SCORE,
    'Hanger Decision': URLS.WORKFLOW.HANGER.DECISION,
    'Project Plan': URLS.WORKFLOW.RUNWAY.PROJECT_PLAN,
    'Procurement Plan': URLS.WORKFLOW.RUNWAY.PROCUREMENT_PLAN,
    'Test Plan': URLS.WORKFLOW.RUNWAY.TEST_PLAN,
    'In-Field Testing': URLS.WORKFLOW.INTHEAIR.FIELD_TESTING,
    'Day in the Life': URLS.WORKFLOW.INTHEAIR.DAY_IN_LIFE,
    'Field Acceptance': URLS.WORKFLOW.INTHEAIR.FIELD_ASSISTANCE,
    'Go/No Go': URLS.WORKFLOW.LANDED.GONOGO,
  },
  systemConfigs:null
}

const masterReducer = (state = initialState, action: ActionExtended) => {
  switch (action.type) {
    case ACTIONS.MASTER.WORKFLOWS:
      return {
        ...state,
        workflows: action.payload.workflows
      }
    case ACTIONS.MASTER.SYSTEM_CONFIGS:
      return {
        ...state,
        systemConfigs: action.payload.systemConfigs
      }
    default:
      return state
  }
}

export default masterReducer
