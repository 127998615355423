import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom'
import Error from '../components/elements/formFieldError';
import toast from 'react-hot-toast';
import { useUser } from '../_common/hooks/authHook';
import { useWorkFlowApi } from '../_common/hooks/api/WorkFlowApiHook';
import { useUserApi } from '../_common/hooks/api/UserApiHook';

interface props {
    show: boolean,
    onClose: (status: boolean) => void,
    receiveNewData: (data: any) => void
}

export default function ScoringPrioritizationListModal({ show, onClose, receiveNewData }: props) {
    const [visible, setVisible] = useState<boolean>(false);
    const handleClose = () => { setVisible(false); onClose(false) };

    const user: any = useUser();

    const { control, register, handleSubmit, reset, getValues, formState: { errors, isValid }, } = useForm()
    const workFlowApi = useWorkFlowApi();
    const userApi = useUserApi();
    const [employees, setEmployees] = useState<any>(null);
    const { id } = useParams();


    const getEmployeeList = () => {
        userApi.employeeList({}, (message: any, resp: any) => {
            setEmployees(resp.data.members);
        }, (message: any, resp: any) => {
            setEmployees([])
        })

    }


    const addScore = (data: any) => {
        return new Promise((resolve, reject) => {
            console.log('datadatadatadatadatadatadatadata========', data);
            let fkUserIDStr = data.fkUserID;
            let fkUserIDArr = fkUserIDStr.split('___');

            workFlowApi.scoringPrioritizationSave({
                "fkUseCaseID": id,
                "fkCompanyID": user.companyID,
                "fkUserID": fkUserIDArr[0],
                "userFullName": fkUserIDArr[1],
                "timeInSec": 10,
                "addedBy": user.userID,
            }, (msg: any, resp: any) => {
                receiveNewData(resp.data)
                resolve(msg)
            }, (msg: any) => { })
        })
    }

    const handleAddScore = (data: any) => {
        if (isValid) {
            toast.promise(
                addScore(data), {
                loading: 'Saving...',
                success: (msg: any) => {
                    reset();
                    handleClose();
                    return <b>{msg}</b>
                },
                error: (msg: any) => { return <b>{msg}</b> },
            }
            );
        } else {
            //form.current.submit()
            console.log('Not valid')
        }
    }

    useEffect(() => {
        setVisible(show)
        getEmployeeList()
    }, [show])



    return (
        <Modal show={visible} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Add Scorer</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form className="form-area" id="addScoreForm" onSubmit={handleSubmit(handleAddScore)}>
                    <label>Employee</label>
                    <select className="form-control" {...register("fkUserID", { required: "User is required" })} id="fkUserID">
                        <option value=''>Select one...</option>
                        {
                            (employees && employees.length) &&
                            employees.map((item: any) => (item?.userID != 1) &&
                                <option value={`${item?.userID}___${item?.title} ${item?.firstName} ${item?.lastName}`} key={item?.userID} >
                                    {`${item?.firstName} ${item?.lastName}`}
                                </option>
                            )
                        }
                    </select>
                    <Error error={errors.fkUserID} />
                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}> Close </Button>
                <Button variant="primary" type='submit' form='addScoreForm'>Add </Button>
            </Modal.Footer>
        </Modal>
    )
}