import React from "react";
import { useForm, SubmitHandler } from "react-hook-form"
import { Link } from 'react-router-dom';
import './Auth.scss' 
import toast, { Toaster } from 'react-hot-toast';
import { URLS } from "../../_config";

const Signup = () => {

    type Inputs = {
        name: string
        email: string
        phone: string
        userName: string
        password: string
    }
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm<Inputs>()


    const onSubmit: SubmitHandler<Inputs> = (data) => { 
        toast.success('Success!');
    }

    return (
        <div className="login_page">
            <div className="loin_area">
                <div className="login_logo d-flex justify-content-center mb-2">
                    <Link to="/"><img src="./images/logo.png" /></Link>
                </div>
                <h3>Registration</h3>
                <p>Sign up your account.</p>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="login_content">
                        <div className="loin_input">
                            <input type="text" {...register("name", { required: "Name is required" })} placeholder="Name" className="input_box" />
                            <span className="material-symbols-outlined">person</span>
                        </div>
                        {errors.name && <span className="text-danger">{errors.name.message}</span>}
                        <div className="loin_input">
                            <input type="text" {...register("email", { required: "Email is required" })} placeholder="Email" className="input_box" />
                            <span className="material-symbols-outlined">mail</span>
                        </div>
                        {errors.email && <span className="text-danger">{errors.email.message}</span>}
                        <div className="loin_input">
                            <input type="text" {...register("phone", { required: "Phone is required" })} placeholder="Phone Number" className="input_box" />
                            <span className="material-symbols-outlined">call</span>
                        </div>
                        {errors.phone && <span className="text-danger">{errors.phone.message}</span>}
                        <div className="loin_input">
                            <input type="text" {...register("userName", { required: "User name is required" })} placeholder="User Name" className="input_box" />
                            <span className="material-symbols-outlined">person</span>
                        </div>
                        {errors.userName && <span className="text-danger">{errors.userName.message}</span>}
                        <div className="loin_input">
                            <input type="password" {...register("password", { required: "Password is required" })} placeholder="Password" className="input_box" />
                            <span className="material-symbols-outlined">lock</span>
                        </div>
                        {errors.password && <span className="text-danger">{errors.password.message}</span>}
                    </div>
                    <div className="d-flex align-items-center justify-content-center mt-3">
                        <button type="submit" className="btn btn-primary d-flex justify-content-between align-items-center py-2 px-4 gap-2">Sign Up <i className="arrow-right"></i></button>

                    </div>
                </form>
                <p className="dont_account mt-3">Already have an accoun? <Link to={URLS.LOGIN}>Sign In</Link></p>
            </div>
        </div>

    );
}

export default Signup;