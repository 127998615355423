import React from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import './intectFlow.scss'
import { Link } from "react-router-dom";
import TitleBar from "../../components/titleBar/TitleBar";
import { URLS } from "../../_config";
import Timer from "../../components/timer/Timer";

const IntactForm = () =>{
    return(
        <React.Fragment>
            <Header></Header>
                <div className="main-container flex-grow-1">
                <div className="container">
                    <div className="page-title py-4">
                        <div className="row">
                            <div className="col-sm-12">
                                <ul className="csmt-breadcrumb d-flex justify-content-start gap-3 mb-2 ps-0">
                                    <li><a href="#">Dashboard</a></li>
                                    <li>Idea</li>
                                </ul>
                            </div>
                            <div className="col-sm-12">
                                <h1 className="h6 text-uppercase font-weight-700  mb-0">Submit your Idea</h1>
                            </div>
                            
                        </div>
                    </div>
                   

                    <div className="start-idea-wrapper mt-5">
                        <div className="white-box d-flex flex-column justify-content-center align-items-center">
                            <h1 className="h5 font-weight-700 mb-3">Start your Idea</h1>
                            <p className="text-center font-weight-400">Itaque earum rerum hic tenetur a sapiente delectus ut aut reiciendis
                                perferendis asperiores repellat.</p>
                            <Link to="/idea-submit" className="btn btn-info py-2 px-5">Get Started</Link>
                            <div className="mt-5"><img src="../images/start-idea.png" alt="" /></div>
                        </div>
                    </div>

                </div>
            </div>
            <Footer></Footer>
        </React.Fragment>
    );
}

export default IntactForm;