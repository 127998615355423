import React, { useEffect, useState } from "react";
import Error from "../elements/formFieldError";
import { Controller } from "react-hook-form";
import Select from "react-select";
import { useFormApi } from "../../_common/hooks/api/FormApiHook";
import { ACTIONS, SAGA_ACTIONS } from '../../_config/index'
import ControlAttributeProp from "./ControlAttributeProp";

import './formConfig.scss'
import { useControlsProps } from "../../_common/hooks/formHook";
import { useDispatch } from "react-redux";
import { Button } from "react-bootstrap";
import Icon from "../icon";
import Swal from "sweetalert2";
import toast from "react-hot-toast";
interface props {
    index: number
    control: any
    register: any
    getValues: any
    setValue: any
    controlTypes: any
    errors: any
    layout: any
    watch: any
    field: any
    remove: any
}
const CreateControl = ({ index, control, register, getValues, setValue, controlTypes, errors, layout , watch, field, remove}: props) => {

    const [controlProps,setControlProps] = useState<any>(null);
    const [colSpan,setColSpan] = useState<any>(1);
    const formApi = useFormApi();
    const dispatch = useDispatch()

    const controlsProps:any = useControlsProps();

    const getControlTypes = (ControlTypeID:any)=>{
        formApi.call(SAGA_ACTIONS.FORM.GET_CONTROL_PROPS, {urlParams:{ControlTypeID:ControlTypeID}}, (message: any, resp: any) => {
            //setControlProps(resp.data.controlTypeProperties) 
            let data:any = {}
            data[`id-${ControlTypeID}`] = resp.data.controlTypeProperties
            dispatch({
                type: ACTIONS.FORM.CONTROLS_PROPS, payload: {
                    controlsProps: {...controlsProps,...data}
                }
            })
        }, (message: any, resp: any) => {

        })
    }
    const typeWatch = watch(`field.${index}.controlType`)


    useEffect(()=>{
        if(typeWatch){
            if(!controlsProps[`id-${typeWatch.value}`]){
                getControlTypes(typeWatch.value)
            } else {
                setControlProps(controlsProps[`id-${typeWatch.value}`]) 
            }
        }
    },[typeWatch])

    useEffect(()=>{
        if(typeWatch){
            if(controlsProps[`id-${typeWatch.value}`]){
                setControlProps(controlsProps[`id-${typeWatch.value}`]) 
            }
        }
    },[controlsProps])

    useEffect(()=>{
        console.log('control panel =>',field)
    },[])

    const removeHandler = ()=>{
        Swal.fire({
            title: 'Confirm Delete',
            text: `Are you sure you want to delete this control?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#B32F84',
            cancelButtonColor: '#44546A',
            confirmButtonText: `Yes, delete it!`
        }).then((result) => {
            if (result.isConfirmed) {
                remove(index)
            }
        });
    }

    

    return (
        <div className={`col-md-${(layout ? parseInt(layout.col) : 1) * parseInt(colSpan)} mb-3 `}>
            {!field.isMandetoryControl && (
                <div className="position-relative w-100">
                    <Button variant="danger" size="sm" className="m-1 mx-28 position-absolute right-0" onClick={removeHandler}><Icon name="times" size={16} /></Button>
                </div>
            )}

            
            <div className="form-control-config">
                <label htmlFor="tenant" className="form-label">Type</label>
                <Controller
                    defaultValue=""
                    control={control}
                    name={`field.${index}.controlType`}
                    rules={{required: true}}
                    render={({ field: { onChange, value, ref, name } }) => (
                        <div>
                            <Select
                                name={name}
                                onChange={onChange}
                                value={value}
                                options={controlTypes}
                                isMulti={false}
                                isDisabled={field?.fkFormControlID != 0}
                            />
                            <Error error={errors[`field.${index}.controlType`]} />
                        </div>
                    )}
                />
                <hr />
                {controlProps?.map((prop:any, i:any)=>(
                <ControlAttributeProp key={i}
                    controlType={typeWatch}
                    index={index}
                    control={control}
                    register={register}
                    getValues={getValues}
                    setValue={setValue}
                    errors={errors}
                    prop={prop}
                    layout={layout}
                    setColSpan={setColSpan}
                    watch={watch}
                    field={field}
                    
                />
            ))}

            </div>

            
        </div>
    );
}

export default CreateControl;