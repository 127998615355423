import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import { useUserApi } from '../../_common/hooks/api/UserApiHook';
import './intectFlow.scss'
import { toast } from 'react-hot-toast';
import { useUser, useUserPermissions } from '../../_common/hooks/authHook';

import Swal from 'sweetalert2';
import { URLS } from '../../_config';
import { replaceUrlParams } from '../../_config/helpers';
import { UseCaseDetailsOverviewList } from './UseCaseDetailsOverviewList';
import DynamicFormView from '../../components/dynamicForm/dynamicFormView';
import { showDate } from '../../_config/functions';
import { useUsecase } from '../../_common/hooks/usecaseHook';
import { UseCaseStatusManageFooter } from './UseCaseStatusManageFooter';
import { Button } from 'react-bootstrap';
import { useSystemConfigs } from '../../_common/hooks/masterHook';
import DisapproveUsecaseModal from '../../modals/disaproveUsecase.modal';
import { UsecaseReviewModal } from './usecaseReviewModal';
import Icon from '../../components/icon';
import Timer from '../../components/timer/Timer';
import TitleBar from '../../components/titleBar/TitleBar';
import ApproveUsecaseModal from '../../modals/aproveUsecase.modal';
import ChangeAirportRunwayCaptainsModal from '../../modals/changeAirportRunwayCaptains.modal';

export const UseCaseDetails = () => {
    const { id } = useParams();
    const user: any = useUser();
    const usecase: any = useUsecase();
    const systemConfigs: any = useSystemConfigs();
    const userApi = useUserApi();
    const navigate = useNavigate();

    const [isPanelCollapsed, setPanelCollapsed] = useState(false);
    const [showApprovedDisapprovedButton, setShowApprovedDisapprovedButton] = useState<boolean>(false);
    const [reviewDetails, setReviewDetails] = useState<any>([]);
    const [isModalOpen, setModalOpen] = useState(false);
    const [approveModal, setApproveModal] = useState(false);
    const [allowWorkflow, setAllowWorkflow] = useState<boolean>(false);
    const [changeModal, setChangeModal] = useState<boolean>(false);

    const permissions:any = useUserPermissions();


    const handleButtonClick = () => {
        setPanelCollapsed((prevState) => !prevState);
    }

    const getUsecase = () => {  /* get usecase details and store in redux */
        return new Promise((resolve, reject) => {
            userApi.useCaseDetails({ urlParams: {companyID:user.companyID, id: id, userID: user.userID } }, (message: any, resp: any) => {
                resolve(true)
            }, (message: any) => { })
        })
    }
    const usecaseLike = () => {
        return new Promise((resolve, reject) => {
            userApi.usecaseLike({
                "fkUseCaseID": id,
                "fkUserID": user.userID,
                "fkCompanyID": user.companyID,
                "statusID": usecase.likeStatusID == 1 ? 2 : 1,
                "userFullName": user.userProfileDisplayName
            }, (message: any, resp: any) => {
                resolve(message)
            }, (message: any) => {
                reject(message)
            })
        })
    }
    const usecaseLikeHandler = () => {
        toast.promise(
            usecaseLike(), {
            loading: 'Updating...',
            success: (msg: any) => {
                getUsecase();
                return <b>{msg}</b>
            },
            error: (msg: any) => { return <b>{msg}</b> },
        });
    }

    const submitStatusData = (data: any) => {
        let formParam = {
            "fkRoleID": data.roleID, // Arirport manager it will be dynamic from useCasePermissionDetail
            "fkCompanyID": user?.companyID,
            "fkUseCaseID": id,
            "fkUserID": user.userID,
            "isApproved": data.useCaseStatus,
            "reason": '',
        };

        return new Promise((resolve, reject) => {
            userApi.postUsecaseApproved(formParam, (message: any, resp: any) => {
                resolve(message);
            }, (message: any, resp: any) => {
                reject(message);
            })
        })
    }

    const ApproveRejectUseCase = (status: any) => {
        let data = {
            "roleID": user?.roleID,
            "useCaseStatus": (status == 'approve')
        };

        Swal.fire({
            title: `Confirm ${status}`,
            text: `Are you sure you want to ${status} this usecase?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#B32F84',
            cancelButtonColor: '#44546A',
            confirmButtonText: `Yes, ${status} it!`
        }).then((result) => {
            if (result.isConfirmed) {
                toast.promise(
                    submitStatusData(data), {
                    loading: 'Status Changing...',
                    success: (msg: any) => {
                        getUsecase();
                        return <b>{msg}</b>
                    },
                    error: (msg: any) => { return <b>{msg}</b> },
                });
            }
        });
    }

    const getWorkFlowURL = () => {
        console.log('usecase?.section v vusecase?.sectionusecase?.sectionusecase?',usecase?.section)
        let url = URLS.WORKFLOW.HANGER.DISCOVERY
        switch (usecase?.section) {
            case 'Hanger':
                switch (usecase?.currentPhase) {
                    case 'Discovery': url = URLS.WORKFLOW.HANGER.DISCOVERY; break;
                    case 'Challenge Statement': url = URLS.WORKFLOW.HANGER.CHALLENGE; break;
                    case 'Solution Framing': url = URLS.WORKFLOW.HANGER.SOLUTIONS; break;
                    case 'Ranking and Prioritization': url = URLS.WORKFLOW.HANGER.SCORE; break;
                    case 'Hanger Decision': url = URLS.WORKFLOW.HANGER.DECISION; break;

                    default:
                        break;
                }
                break;

            case 'Taxi to Runway':
            case 'Runway':
                switch (usecase?.currentPhase) {
                    case 'Project Plan': url = URLS.WORKFLOW.RUNWAY.PROJECT_PLAN; break;
                    case 'Procurement Plan': url = URLS.WORKFLOW.RUNWAY.PROCUREMENT_PLAN; break;
                    case 'Test Plan': url = URLS.WORKFLOW.RUNWAY.TEST_PLAN; break;

                    default:
                        break;
                }
                break;

            case 'In the Air':
                switch (usecase?.currentPhase) {
                    case 'In-Field Testing': url = URLS.WORKFLOW.INTHEAIR.FIELD_TESTING; break;
                    case 'Day in the Life': url = URLS.WORKFLOW.INTHEAIR.DAY_IN_LIFE; break;
                    case 'Field Acceptance': url = URLS.WORKFLOW.INTHEAIR.FIELD_ASSISTANCE; break;
                    case 'Field Assistance': url = URLS.WORKFLOW.INTHEAIR.FIELD_ASSISTANCE; break;
                    default:
                        break;
                }
                break;

            case 'Landed':
                switch (usecase?.currentPhase) {
                    case 'Go/No Go': url = URLS.WORKFLOW.LANDED.GONOGO; break;

                    default:
                        break;
                }
                break;


            default:
                break;
        }
        return replaceUrlParams(url, { id: id })
    }


    const isAirportManager = () => {  /* match role key with system config */
        let airKey = systemConfigs?.filter((conf: any) => { return (conf.key == "AIRPORT_MANAGER_ROLE") })[0]?.value
        let users: any = usecase?.useCasePermissionDetail?.filter((user: any) => { return (user.roleName == airKey) })
        return ((users.length > 0) || user.roleKey == 'ADMIN')
    }

    useEffect(() => {
        if (usecase) {
            if (usecase?.useCaseID != id) {
                getUsecase();
            } else {
                if (usecase?.fkStatusID == 6 || usecase?.fkStatusID == 9 || usecase?.fkStatusID == 10 || usecase?.fkStatusID == 11) {
                    setShowApprovedDisapprovedButton(false)
                } else {
                    setShowApprovedDisapprovedButton(true)
                }
                //user.userID == usecase.addedBy ||
                if ( user.userID == usecase.fkCoCaptainID || user.userID == usecase.fkAitportManagerID || user.userID == usecase.fkCaptainID) {
                    setAllowWorkflow(true)
                }

                if(user.roleKey == 'INNOVATION_MANAGER_ROLE' || user.roleKey == 'ADMIN' ){ //&& usecase?.assignedInnovationManagerID == user.userID
                    setAllowWorkflow(true)
                }
                if(user.roleKey == 'INNOVATION_MANAGER_ROLE' && usecase?.assignedInnovationManagerID == 0){
                    setAllowWorkflow(true)
                }

                if(usecase?.fkStatusID == 42 || usecase?.fkStatusID == 47 ||  usecase?.fkStatusID == 9){
                    setAllowWorkflow(false)
                }

                if(usecase.fkStatusID == 18 && usecase.addedBy != user.userID){
                    navigate(URLS.USE_CASE_LIST)
                }
                
            }
        } else {
            if (id && user?.userID && systemConfigs) {
                getUsecase();
            }
        }
    }, [usecase, user?.userID, systemConfigs])




    return (
        <React.Fragment>
            <Header></Header>
            <div className="main-container flex-grow-1">
                <div className="container">

                    <TitleBar title={`Usecase: ${usecase?.useCase}`} breadcrumbs={{
                        'Use case list': URLS.USE_CASE_LIST,
                        'Use Case Details': '#'
                    }} >
                        {/* <Timer startTime={new Date()} /> */}
                    </TitleBar>

                    <div className="white-box">
                        <div className="row ">
                            <div className="col-lg-6 col-md-12 col-12">
                                <div className="feature-image mb-3"><img src={usecase?.imgURL ? usecase?.imgURL : '/images/no-image.svg'} alt="#" /></div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-12">
                                <div className="feature-image-text">
                                    <div className="d-flex justify-content-between align-items-center mb-3">
                                        <div className='d-flex align-items-center gap-2'>
                                            <span className="badge tags p-2 px-3">{usecase?.fkStatusID == 10 ? 'Under Review' : usecase?.status}</span>
                                        </div>
                                        <div className='d-flex align-items-center gap-2'>

                                            {usecase?.fkStatusID != 18 && (
                                                <Button className={`usecase-like ${usecase?.likeStatusID == 1 ? 'liked' : ''}`} onClick={usecaseLikeHandler}>
                                                    <Icon name='like' />
                                                </Button>
                                            )}

                                            {(usecase?.fkStatusID == 6 || usecase?.fkStatusID == 18 ) && usecase?.fkStatusID && (usecase.addedBy == user.userID || permissions?.menus.usecase.write) && (
                                                <Link to={`/idea-submit/${usecase?.useCaseID}`} className="edit_iconbtn">
                                                    <i className="edit-icon"></i>
                                                </Link>
                                            )}

                                            

                                        </div>
                                    </div>

                                    <h2 className="project-sub-hed mb-1">{usecase?.useCase}</h2>
                                    <div className="feature-date">
                                        <h5>Created by - <span>{usecase?.addedByName}</span></h5>
                                        <h5>Created date - <span>{showDate(usecase?.addedDate)}</span></h5>
                                    </div>
                                    {usecase?.assignedInnovationManagerID > 0 && (
                                        <div className="feature-date">
                                            <h5>Innovation Manager - <span>{usecase?.assignedInnovationManagerName}</span></h5>
                                        </div>
                                    )}
                                    
                                    <div className="feature-graphis-icon pt-25 align-items-center gap-3">
                                        <div className=""><img src="/images/runway-use-case.svg" alt="#" /></div>
                                        <div className="feature-icon-text">
                                            <span>Airport</span>
                                            <h5>{usecase?.airPort ? usecase?.airPort : 'NA'}</h5>
                                        </div>
                                        <Button size='sm' onClick={()=>{setChangeModal(true)}}>Change</Button>
                                    </div>
                                    <div className="feature-graphis-line-icon">
                                        <div className="row mx-15">
                                            <div className="col-lg-4 col-md-6 col-6 feature-graphis-border feature-graphis-border-right resp-border">
                                                <div className="feature-graphis-icon ps-0">
                                                    <div className="feature-icon"><img src="/images/air-pilot-hat.svg" alt="#" /></div>
                                                    <div className="feature-icon-text">
                                                        <span>Captain</span>
                                                        <h5>{usecase?.captain ? usecase?.captain : 'NA'}</h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-6 feature-graphis-border feature-graphis-border-right resp-border">
                                                <div className="feature-graphis-icon">
                                                    <div className="feature-icon"><img src="/images/menu.svg" alt="#" /></div>
                                                    <div className="feature-icon-text">
                                                        <span>Section</span>
                                                        <h5>{usecase?.section ? usecase?.section : 'NA'}</h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-6 feature-graphis-border resp-border">
                                                <div className="feature-graphis-icon pe-0">
                                                    <div className="feature-icon"><img src="/images/night-portrait.svg" alt="#" /></div>
                                                    <div className="feature-icon-text">
                                                        <span>Phase</span>
                                                        <h5>{usecase?.currentPhase ? usecase?.currentPhase : 'NA'}</h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-6 feature-graphis-border-right resp-border">
                                                <div className="feature-graphis-icon ps-0">
                                                    <div className="feature-icon"><img src="/images/one-to-one.svg" alt="#" /></div>
                                                    <div className="feature-icon-text">
                                                        <span>Score</span>
                                                        <h5>{usecase?.score}</h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-6 feature-graphis-border-right resp-border">
                                                <div className="feature-graphis-icon">
                                                    <div className="feature-icon"><img src="/images/rating.svg" alt="#" /></div>
                                                    <div className="feature-icon-text">
                                                        <span>Rank</span>
                                                        <h5>#{usecase?.rank ? usecase?.rank : '0'}</h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-6 resp-border">
                                                <div className="feature-graphis-icon pe-0">
                                                    <div className="feature-icon"><img src="/images/singles-choice.svg" alt="#" /></div>
                                                    <div className="feature-icon-text">
                                                        <span>Like</span>
                                                        <h5>{usecase?.likes ? usecase?.likes : '0'}</h5>
                                                    </div>
                                                </div>
                                            </div>
                                            {usecase?.fkStatusID != 18 && (
                                                <div className='d-flex align-items-center gap-2 mt-4'>

                                                    <Button variant='secondary' onClick={handleButtonClick}>Review or Update Status</Button>


                                                    {/* show this button if the use-case proceeds and has no final decision  */}
                                                    {allowWorkflow && usecase?.fkStatusID >= 9 && usecase?.fkStatusID != 11 && (
                                                        <Link to={getWorkFlowURL()} type="button" className="btn btn-primary d-flex align-items-center gap-2 ">Enter Workflow</Link>
                                                    )}
                                                    {/* show this buttons to Airport Manager after just proceeds   */}
                                                    {usecase?.fkStatusID < 10 && (
                                                        <>
                                                            {isAirportManager() && showApprovedDisapprovedButton == true && (
                                                                <>
                                                                    <Button onClick={() => {
                                                                        //ApproveRejectUseCase('approve');
                                                                        setApproveModal(true)
                                                                    }} >Approve</Button>
                                                                    <Button onClick={() => { setModalOpen(true) }} >Disapprove</Button>
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                            )}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        {/* Overviews links... */}

                        <div className="flight-section flight-section-gray mx-0">
                            <UseCaseDetailsOverviewList caseDetails={usecase} />
                        </div>

                         {/* use-case details via dynamic form view component */}
                        <DynamicFormView _workflow='use-case' useCaseID={id}
                            _module='create-use-case' _form='Usecase intake form'
                            editMode={true} answerID={usecase?.fkFormAnsweID} shift={2} pop={0} />

                    </div>
                </div>

                 {/* modal for use-case proceed */}
                <UsecaseReviewModal show={isPanelCollapsed} changeShowStatus={setPanelCollapsed} />

                {/* modal for airport manager reject the usecase */}
                <DisapproveUsecaseModal show={isModalOpen} onClose={() => { setModalOpen(false) }} />
                <ApproveUsecaseModal show={approveModal} onClose={() => { setApproveModal(false) }} />
                <ChangeAirportRunwayCaptainsModal show={changeModal} onClose={setChangeModal} />
                <UseCaseStatusManageFooter />

            </div>
            <Footer></Footer>
        </React.Fragment>
    )
}
