import React, { useEffect, useState } from 'react'
import Layout from '../../../components/workflow/Layout'
import { Link, useNavigate, useParams } from 'react-router-dom'
import RightSlideModal from '../../../components/RightSlideModal'
import { URLS } from '../../../_config'
import { mergeListData, replaceUrlParams } from '../../../_config/helpers'
import { useWorkFlowApi } from '../../../_common/hooks/api/WorkFlowApiHook'
import DynamicFormView from '../../../components/dynamicForm/dynamicFormView'
import { GoToNextPhaseButton } from '../../../components/workflow/elements/GoToNextPhaseButton'
import FilePreview from '../../../components/filePreview'
import { PageMenuInTheAir } from '../../../components/workflow/PageMenuInTheAir'
import TitleBar from '../../../components/titleBar/TitleBar'
import { useUser } from '../../../_common/hooks/authHook'
import TableLoader from '../../../components/tableLoader'
import Pagination from '../../../components/pagination/Pagination'
import SearchField from '../../../components/searchSection/SearchField'
import GetStartDateEndDate from '../../../components/getStartDateEndDate'
import { useUsecase } from '../../../_common/hooks/usecaseHook'
import PhaseStatus from '../../../components/phaseStatus'


export const FieldTesting = () => {
    const { id } = useParams();
    const workFlowApi = useWorkFlowApi()
    const [rightPannel, setRightPannel] = useState<boolean>(false);
    const [testCriteriaList, setTestCriteriaList] = useState<any>(null)
    const [criteria, setCriteria] = useState<any>(null)
    const user: any = useUser();
    const usecase: any = useUsecase();
    const navigate = useNavigate();

    const [page, setPage] = useState<number>(1)
    const [total, setTotal] = useState<number>(0)
    const [pageLimit, setPageLimit] = useState<number>(10)

    const [searchText, setSearchText] = useState<any>('');
    const [dateSubmited, setDateSubmited] = useState<boolean>(false)
    const [triggerSDED, setTriggerSDED] = useState<boolean>(false);
    const [SDEDPromise, setSDEDPromise] = useState<any>(null);
    const [phaseUpdate, setPhaseUpdate] = useState<boolean>(false);

    const checkSDED = ()=>{
        let p:any = new Promise((resolve,reject)=>{
            if(dateSubmited){
                resolve(true)
            } else {
                setSDEDPromise(()=>{ return resolve})
                setTriggerSDED(true);
            }
        })
        return p;
    }

    const getTestCriteriaList = () => {
        workFlowApi.getTestCasesAcceptanceCriteriaByUsecase({
            urlParams: { companyID: user.companyID, UseCaseID: id },
            pageNo: page,
            pageSize: pageLimit,
            SearchText: searchText
        }, (message: any, resp: any) => {
            setTotal(resp.totalRecords)
            setTestCriteriaList(mergeListData('testCasesAcceptanceCriteriaID','fileURL',resp.data?.testCriteriaList))
        }, (message: any) => {
            setTestCriteriaList([]);
        })
    }

    useEffect(() => {
        getTestCriteriaList();
    }, [id, page, searchText])

    useEffect(() => {
        if (criteria) {
            setRightPannel(true)
        } else {
            setRightPannel(false)
        }
    }, [criteria])

    useEffect(() => {
        if(dateSubmited){
            if(SDEDPromise){
                SDEDPromise();
            }
        }
    }, [dateSubmited])

    useEffect(() => {
        if(triggerSDED){
            setTriggerSDED(false)
        }
    }, [triggerSDED])


    return (

        <Layout titleBar={
            <TitleBar  title={`Field Testing | usecase : ${usecase?.useCase}`} breadcrumbs={{
                'Use case list': URLS.USE_CASE_LIST,
                'Use case Details': replaceUrlParams(URLS.USECASE_DETAILS, { id: id }),
                'Field Testing': '#'
            }} >
                {/* <Timer startTime={new Date()} /> */}
            </TitleBar>
        }>
            <PageMenuInTheAir pageTab="In-Field Testing" />
            <div className="white-box">
                <div className="form-area mb-5">
                    <div className="d-flex justify-content-between align-items-center">
                        <h6 className="mb-0">Test Cases</h6>
                        <SearchField onSearch={(str: any) => {
                            setSearchText(str)
                            setPage(1)
                        }} />
                    </div>
                    <hr />

                    <div className="table-responsive list-table">
                        <table className="table table-striped">
                            <thead>
                                <tr className="align-middle">
                                    <th scope="col" className="w-10 text-center"> # </th>
                                    <th scope="col" className="w-50">Test Case </th>
                                    <th scope="col" >File</th>
                                    <th scope="col" >Created By</th>
                                    <th scope="col" >Assign To</th>
                                    <th scope="col">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {testCriteriaList?.map((criteria: any, i: any) => (
                                    <tr className="align-middle h-60" key={`criteria-${i}`}>
                                        <th className="text-center">
                                            {i + 1}
                                        </th>
                                        <td><h6 onClick={() => { setCriteria(criteria) }} className='cursor-pointer'>{criteria?.testCasesAcceptanceCriteria}</h6>
                                            <p>{criteria?.description}</p>
                                        </td>
                                        <td >
                                            <FilePreview url={criteria?.fileURL} size={32} />
                                        </td>
                                        <td  >
                                            {criteria?.addedByName}
                                        </td>
                                        <td  >
                                            {criteria?.assignUserName}
                                        </td>
                                        <td><span className="badge bg-info">{criteria?.status}</span></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <TableLoader data={testCriteriaList} />
                        <Pagination total={total} page={page} setPage={setPage} pageLimit={pageLimit} />

                    </div>



                </div>
                <hr />



                <div className="align-items-end pb-3 pt-2 rounded-1 row sticky-bottom" style={{backgroundColor:'#222747'}}>
                    <div className="col-md-8">
                        <GetStartDateEndDate submited={setDateSubmited} submitTrigger={triggerSDED} dataFor={{
                            useCaseID: id,
                            fkCompanyID: user.companyID,
                            phaseName: 'In-Field Testing',
                            startDate: '',
                            completeDate: '',
                            updatedBy: user.userID
                        }}
                        />
                    </div>
                    <div className="col-md-4">
                        <div className='text-end'>
                        <PhaseStatus phase='In-Field Testing' usecaseID={id} update={phaseUpdate} onUpdate={()=>{
                                navigate(replaceUrlParams(URLS.WORKFLOW.INTHEAIR.DAY_IN_LIFE, { id: id }))
                            }} />
                            <GoToNextPhaseButton usecaseID={id} label="Next" nextPhase='Day in the Life' permission={checkSDED} onSuccess={() => {
                                setPhaseUpdate(true)
                            }} />
                        </div>
                    </div>
                </div>




            </div>
            <RightSlideModal visible={rightPannel} changeVisibility={(e) => { setRightPannel(e); setCriteria(null) }} heading='Submit test case'>
                <DynamicFormView editMode={true} answerID={criteria?.testCasesCriteriaAnswerID} _workflow='taxi-to-runway' useCaseID={id} _module='test-plan--tester-case' _form='test-plan--tester-case' onSubmitSuccess={() => { setRightPannel(false); getTestCriteriaList() }} />
                <DynamicFormView editMode={true} answerID={criteria?.testCasesAnswerID != 0 ? criteria?.testCasesAnswerID : null} _workflow='in-the-air' useCaseID={id} _module='in-field-testing' _form='in-field-testing' onSubmitSuccess={() => { setCriteria(null); getTestCriteriaList() }} />
                <br />
            </RightSlideModal>
        </Layout>


    )
}
