import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { URLS } from '../../_config'
import { replaceUrlParams } from '../../_config/helpers';

export const PageMenuInTheAir = ({ pageTab }: any) => {
    const { id } = useParams();
    console.log('pageTab', pageTab)
    return (

        <div className="progress-steps-panel d-flex mb-4">
            <div className={`progress-steps d-flex align-items-center gap-3 ${pageTab == 'In-Field Testing' ? 'active' : ''}`}>
                <div className="progress-number">1</div>
                <Link to={replaceUrlParams(URLS.WORKFLOW.INTHEAIR.FIELD_TESTING, { id: id })}> <p>In-Field Testing</p></Link>
            </div>
            <div className={`progress-steps d-flex align-items-center gap-3 ${pageTab == 'Day in the Life' ? 'active' : ''}`}>
                <div className="progress-number">2</div>

                <Link to={replaceUrlParams(URLS.WORKFLOW.INTHEAIR.DAY_IN_LIFE, { id: id })}> <p>Day in the Life</p></Link>
            </div>

            <div className={`progress-steps d-flex align-items-center gap-3 ${pageTab == 'Field Acceptance' ? 'active' : ''}`}>
                <div className="progress-number">3</div>
                <Link to={replaceUrlParams(URLS.WORKFLOW.INTHEAIR.FIELD_ASSISTANCE, { id: id })}> <p>Field Acceptance</p></Link>
            </div>
        </div>
    )
}
