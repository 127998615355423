import React, { useEffect, useState } from 'react'
import Layout from '../../../components/workflow/Layout'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { MdDeleteOutline } from "react-icons/md";
import { URLS } from '../../../_config'
import { getNameOnly, mergeListData, replaceUrlParams } from '../../../_config/helpers'
import { useWorkFlowApi } from '../../../_common/hooks/api/WorkFlowApiHook'
import { useUser } from '../../../_common/hooks/authHook'
import AddDayInLifeMemberModal from '../../../modals/addDayInLifeMember.modal'
import { Button } from 'react-bootstrap'
import TableLoader from '../../../components/tableLoader'
import { PageMenuInTheAir } from '../../../components/workflow/PageMenuInTheAir'
import DynamicForm from '../../../components/dynamicForm/dynamicForm'
import Icon from '../../../components/icon';
import Swal from 'sweetalert2';
import toast from 'react-hot-toast';
import RightSlideModal from '../../../components/RightSlideModal';
import FilePreview from '../../../components/filePreview';
import { GoToNextPhaseButton } from '../../../components/workflow/elements/GoToNextPhaseButton';
import TitleBar from '../../../components/titleBar/TitleBar';
import Timer from '../../../components/timer/Timer';
import Pagination from '../../../components/pagination/Pagination';
import SearchField from '../../../components/searchSection/SearchField';
import GetStartDateEndDate from '../../../components/getStartDateEndDate';
import { useUsecase } from '../../../_common/hooks/usecaseHook';
import PhaseStatus from '../../../components/phaseStatus';


export const DayInTheLife = () => {
    const { id } = useParams();

    const user: any = useUser();
    const workFlowApi = useWorkFlowApi();
    const navigate = useNavigate();
    const usecase: any = useUsecase();


    const [addMemberModal, setAddMemberModal] = useState<boolean>(false)
    const [feedbackModal, setFeedbackModal] = useState<boolean>(false)
    const [isAllFeedbackComplete, setIsAllFeedbackComplete] = useState<boolean>(false)
    const [members, setMembers] = useState<any[] | null>(null)
    const [feedbacker, setFeedbacker] = useState<any>(null)

    const [page, setPage] = useState<number>(1)
    const [total, setTotal] = useState<number>(0)
    const [pageLimit, setPageLimit] = useState<number>(10)
    const [searchText, setSearchText] = useState<any>('');
    const [dateSubmited, setDateSubmited] = useState<boolean>(false)
    const [triggerSDED, setTriggerSDED] = useState<boolean>(false);
    const [SDEDPromise, setSDEDPromise] = useState<any>(null);
    const [phaseUpdate, setPhaseUpdate] = useState<boolean>(false);

    const checkSDED = ()=>{
        let p:any = new Promise((resolve,reject)=>{
            if(dateSubmited){
                resolve(true)
            } else {
                setSDEDPromise(()=>{ return resolve})
                setTriggerSDED(true);
            }
        })
        return p;
    }

    const getMembers = () => {
        workFlowApi.getMembers({
            urlParams: { CompanyID: user.companyID, UseCaseID: id },
            pageNo: page,
            pageSize: pageLimit,
            SearchText: searchText
        }, (message: any, resp: any) => {
            setTotal(resp.totalRecords)
            setMembers(mergeListData('dayInTheLifeCandidateID','canidateReportFileURL',resp.data.candidateList));
            
        }, (message: any, resp: any) => {
            setMembers([])
        })
    }

    const deleteMember = (data: any) => {
        return new Promise((resolve, reject) => {
            workFlowApi.deleteMember(data, (message: any, resp: any) => {
                resolve(message);
                setPage(1)
                setMembers(mergeListData('dayInTheLifeCandidateID','canidateReportFileURL',resp.data.candidateList))
            }, (message: any) => {
                setMembers([])
                toast.dismiss();
            })
        })
    }

    const deleteItemHandler = (item: any) => {
        let data = {
            dayInTheLifeCandidateID: item.dayInTheLifeCandidateID,
            fkCompanyID: user.companyID,
            updatedBy: user?.userID
        };

        Swal.fire({
            title: 'Confirm Delete',
            text: `Are you sure you want to delete this ?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#B32F84',
            cancelButtonColor: '#44546A',
            confirmButtonText: `Yes, delete it!`
        }).then((result) => {
            if (result.isConfirmed) {
                toast.promise(
                    deleteMember(data), {
                    loading: 'Deleting...',
                    success: (msg: any) => { return <b>{msg}</b> },
                    error: (msg: any) => { return <b>{msg}</b> },
                }
                );

            }
        });
    }

    useEffect(() => {
        getMembers()
    }, [id, page, searchText])
    
    useEffect(() => {
        if (feedbacker) {
            setFeedbackModal(true)
        } else {
            setFeedbackModal(false)
        }
    }, [feedbacker])

    useEffect(() => {
        if (!feedbackModal) {
            setFeedbacker(null)
        }
    }, [feedbackModal])

    useEffect(() => {
        if (members?.length) {
            let status: any = true;
            members.map((member: any) => {
                if (member.submitStatus == 'No') {
                    status = false;
                }
            })
            setIsAllFeedbackComplete(status)
        } else {
            setIsAllFeedbackComplete(false)
        }
    }, [members])

    useEffect(() => {
        if(dateSubmited){
            if(SDEDPromise){
                SDEDPromise();
            }
        }
    }, [dateSubmited])

    useEffect(() => {
        if(triggerSDED){
            setTriggerSDED(false)
        }
    }, [triggerSDED])


    return (
        <Layout titleBar={
            <TitleBar title={`Day In The Life | usecase : ${usecase?.useCase}`} breadcrumbs={{
                'Use case list': URLS.USE_CASE_LIST,
                'Use case Details': replaceUrlParams(URLS.USECASE_DETAILS, { id: id }),
                'Day In The Life': '#'
            }} >
                <Timer startTime={new Date()} />
            </TitleBar>
        }>
            {/* <PageMenu /> */}
            <PageMenuInTheAir pageTab="Day in the Life" />
            <div className="white-box">
                <div className="form-area">
                    <div className="d-flex justify-content-between align-items-center">
                        <h6 className="mb-0">Day In Life</h6>
                        <SearchField onSearch={(str: any) => {
                            setSearchText(str)
                            setPage(1)
                        }} />
                        <Button onClick={() => { setAddMemberModal(true) }}>Add Candidate</Button>
                    </div>
                    <hr />

                    <div className="table-responsive list-table">
                        <table className="table table-striped">
                            <thead>
                                <tr className="align-middle">
                                    <th scope="col" className="text-left">Name</th>
                                    <th scope="col">Role </th>
                                    <th scope="col">Title</th>
                                    <th scope="col" className="text-center">Upload Feedback</th>
                                    <th scope="col" className="text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    members && members.length > 0 &&
                                    members.map((member: any) => (
                                        <tr className="align-middle h-60" key={`member${member.dayInTheLifeCandidateID}`}>
                                            <td>{getNameOnly(member.userFullName)}</td>
                                            <td> <h6>{member.roleName}</h6> </td>
                                            <td>{member.title}</td>
                                            <td className="text-center">
                                                <div className="d-flex align-items-center justify-content-center gap-2">
                                                    <span className="badge badge-secondary">{member.submitStatus}</span>
                                                    {member.canidateReportFileURL ? <FilePreview size={24} url={member.canidateReportFileURL} /> : null}
                                                </div>
                                            </td>
                                            <td >
                                                <div className="d-flex align-items-center justify-content-center gap-2">
                                                    <Button size='sm' variant='secondary' onClick={() => { setFeedbacker(member) }}>Add Feedback</Button>
                                                    <Icon name='delete' onClick={() => { deleteItemHandler(member) }} />
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                        <TableLoader data={members} />
                        <Pagination total={total} page={page} setPage={setPage} pageLimit={pageLimit} />
                    </div>

                    <div className="pt-3">
                        <DynamicForm _workflow='in-the-air' section='In the Air' phase='Day in the Life' useCaseID={id} _module='feedback-management' _form='feedback-management' editMode={true} onSubmitSuccess={(edit) => { }} />
                    </div>



                </div>
                <hr />
         

                <div className="align-items-end pb-3 pt-2 rounded-1 row sticky-bottom" style={{backgroundColor:'#222747'}}>
                    <div className="col-md-8">
                        <GetStartDateEndDate submited={setDateSubmited} submitTrigger={triggerSDED} dataFor={{
                            useCaseID: id,
                            fkCompanyID: user.companyID,
                            phaseName: 'In-Field Testing',
                            startDate: '',
                            completeDate: '',
                            updatedBy: user.userID
                        }}
                        />
                    </div>
                    <div className="col-md-4">
                        <div className='text-end'>
                        <PhaseStatus phase='Day in the Life' usecaseID={id} update={phaseUpdate} onUpdate={()=>{
                                navigate(replaceUrlParams(URLS.WORKFLOW.INTHEAIR.FIELD_ASSISTANCE, { id: id }))
                            }} />
                            {isAllFeedbackComplete ?
                                <GoToNextPhaseButton usecaseID={id} label="Submit" nextPhase='Field Acceptance' permission={checkSDED} onSuccess={() => {
                                    setPhaseUpdate(true)
                                }} />
                                :
                                <Button disabled>Submit</Button>
                            }
                        </div>
                    </div>
                </div>





            </div>
            <AddDayInLifeMemberModal
                show={addMemberModal}
                onClose={setAddMemberModal}
                receiveNewData={(list:any)=>{setMembers(mergeListData('dayInTheLifeCandidateID','canidateReportFileURL',list))}}
            />

            <RightSlideModal visible={feedbackModal} changeVisibility={setFeedbackModal}
                heading='Add Test Cases & Acceptance Criteria' timer={true}>
                <DynamicForm metadata={{ DayInTheLifeCandidateID: feedbacker?.dayInTheLifeCandidateID }} _workflow='in-the-air' useCaseID={id} _module='feedback-user' _form='feedback-user' editMode={true} answerID={feedbacker?.candidateReportFormAnswerID} onSubmitSuccess={(edit) => { getMembers(); setFeedbacker(null) }} />
            </RightSlideModal>
        </Layout>


    )
}
