import React from 'react'
import toast from 'react-hot-toast';
import { useUserApi } from '../../../_common/hooks/api/UserApiHook';
import { useUser } from '../../../_common/hooks/authHook';
import { Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

interface props  {
    label?:string
    usecaseID:any
    nextPhase:string
    onSuccess:()=>void
    permission?:()=>Promise<any>
}

export const GoToNextPhaseButton = ({usecaseID, nextPhase, onSuccess, label = 'Go To Next Step', permission}:props) => {

    const userApi = useUserApi();
    const userInfo: any = useUser();
    const { id } = useParams(); 

    const getUsecase = () => {
        return new Promise((resolve, reject) => {
            userApi.useCaseDetails({ urlParams: {companyID:userInfo.companyID, id: id, userID: userInfo.userID } }, (message: any, resp: any) => {
                resolve(true)
            }, (message: any) => { reject(message) })
        })
    }

    

    const savePhaseChangeData = (data: any) => {
        return new Promise((resolve,reject)=>{  
            userApi.usecasePhaseChange(data,(message:any, resp:any)=>{
                getUsecase().then(()=>{resolve(message)}).catch((msg)=>{reject(msg)})
            },(message:any, resp:any)=>{
                //reject(message);
                toast.dismiss();
                onSuccess();
            })           
        })
    }

    const changeThisPhase = ()=>{
        let formParam = {
            "useCaseID": usecaseID,
            "fkCompanyID": userInfo.companyID,
            "nextPhase": nextPhase,
            "updatedBy": userInfo.userID,
        };
        toast.promise(
            savePhaseChangeData(formParam), {
            loading: 'Loading...',
            success: (msg: any) => { onSuccess(); return <b>{msg}</b> },
            error: (msg: any) => { return <b>{msg}</b> },
        });
    }

    const phaseChange = () => {
        if(permission){
            permission().then((s)=>{
                changeThisPhase();
            }).catch((msg:any)=>{toast.error(msg)})
        } else {
            changeThisPhase();
        }
        
    }

    return (
        <Button onClick={phaseChange} type="button" variant='primary' className="align-items-center">{label}</Button>
    )
}
