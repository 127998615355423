import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { URLS } from '../../_config'
import { replaceUrlParams } from '../../_config/helpers';

export const PageMenuRunway = ({ pageTab }: any) => {
    const { id } = useParams();
    console.log('pageTab', pageTab)
    return (

        <div className="progress-steps-panel d-flex mb-4">
            <div className={`progress-steps d-flex align-items-center gap-3 ${pageTab == 'ProjectPlan' ? 'active' : ''}`}>
                <div className="progress-number">1</div>

                <Link to={replaceUrlParams(URLS.WORKFLOW.RUNWAY.PROJECT_PLAN, { id: id })}> <p>Project Plan</p></Link>
            </div>
            <div className={`progress-steps d-flex align-items-center gap-3 ${pageTab == 'ProcurementPlan' ? 'active' : ''}`}>
                <div className="progress-number">2</div>

                <Link to={replaceUrlParams(URLS.WORKFLOW.RUNWAY.PROCUREMENT_PLAN, { id: id })}> <p>Procurement Plan</p></Link>
            </div>
            <div className={`progress-steps d-flex align-items-center gap-3 ${pageTab == 'TestPlan' ? 'active' : ''}`}>
                <div className="progress-number">3</div>

                <Link to={replaceUrlParams(URLS.WORKFLOW.RUNWAY.TEST_PLAN, { id: id })}>  <p>Test Plan</p></Link>
            </div>
        </div>
    )
}
