import React from "react";
import { useForm, SubmitHandler } from "react-hook-form"
import { Link } from 'react-router-dom';
import './Auth.scss'
import toast, { Toaster } from 'react-hot-toast';
import { useUserApi } from "../../_common/hooks/api/UserApiHook";
import { URLS } from "../../_config";


const ForgotPassword = () => {

    const hostnameInfo = window.location;
    const reset_password_link = hostnameInfo.origin + '/reset-password';
    const userApi = useUserApi();
    console.log(hostnameInfo);

    type Inputs = {
        email: string
    }

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm<Inputs>()

    const submitData = (data: any) => {
        let formParam = {
            ...data,
            "resetPasswordURL": reset_password_link
        };   
        return new Promise((resolve,reject)=>{           
            userApi.forgotPassword(formParam,(message:any, resp:any)=>{           
                resolve(resp.message); 
            },(message:any)=>{
                reject(message)
            })           
        })
    }

    const onSubmit: SubmitHandler<Inputs> = (data) => {

        toast.promise(
            submitData(data), {
            loading: 'Loading...',
            success: (msg: any) => { return <b>{msg}</b> },
            error: (msg: any) => { return <b>{msg}</b> },
        }
        );
    }
    return (
        <div className="login_page">
            <div className="loin_area">
                <div className="login_logo d-flex justify-content-center mb-2">
                    <img src="./images/logo.png" />
                </div>
                <h3 className="h3">Welcome!</h3>
                <p>Forgot your password</p>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="login_content">
                        <div className="loin_input">
                            <input type="text" {...register("email", { required: "Email is required" })} placeholder="Email ID" className="input_box" />
                            <span className="material-symbols-outlined">mail</span>
                        </div>
                        {errors.email && <span className="text-danger">{errors.email.message}</span>}
                    </div>
                    <div className="d-flex justify-content-between align-items-baseline mt-4 pt-2">
                        <p><Link to={URLS.LOGIN} className="text-purple text-sm"><i>Back to login</i></Link></p>
                        <button type="submit" className="btn btn-primary d-flex justify-content-between align-items-center py-2 px-4 gap-2">Send <i className="arrow-right"></i></button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default ForgotPassword;