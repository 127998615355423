import { call, put } from 'redux-saga/effects'
import { ACTIONS, API_URL,  UpdateWithVariables } from '../../_config'
import { CallApi, responseHandler } from '../api/callApi'
import { ApiActionType } from '../../_common/interfaces/ActionExtended'


export function* getWorkflows(action: ApiActionType): any {
	try {
		const data = action.payload
		let API = UpdateWithVariables(API_URL.FORM.GET_WORKFLOWS, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
		const resp: any = yield call(CallApi.GET, API, data, true)
		let respChecker = responseHandler(resp)
		if (respChecker.status) {
			
			let workflows = respChecker.data.data.workFlows.map((flow: any) => {
				flow.value = flow.workFlowID
				flow.label = flow.title
				return flow
			})

			console.log('respChecker', workflows)
			yield put({
				type: ACTIONS.FORM.WORKFLOW,
				payload: {
					workflows: workflows
				},
			})
			action && action.callbackSuccess && action.callbackSuccess(workflows)
		} else {
			action && action.callbackError && action.callbackError(respChecker.data)
		}
	} catch (e: any) {
		action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
	}
}

export function* getModules(action: ApiActionType): any {
	try {
		const data = action.payload
		let API = UpdateWithVariables(API_URL.FORM.GET_MODULES, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
		const resp: any = yield call(CallApi.GET, API, data, true)
		let respChecker = responseHandler(resp)
		if (respChecker.status) {
			action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
		} else {
			action && action.callbackError && action.callbackError(respChecker.data)
		}
	} catch (e: any) {
		action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
	}
}

export function* getForms(action: ApiActionType): any {
	try {
		const data = action.payload
		let API = UpdateWithVariables(API_URL.FORM.GET_FORMS, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
		const resp: any = yield call(CallApi.GET, API, data, true)
		let respChecker = responseHandler(resp)
		if (respChecker.status) {
			action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
		} else {
			action && action.callbackError && action.callbackError(respChecker.data)
		}
	} catch (e: any) {
		action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
	}
}

export function* getControlTypes(action: ApiActionType): any {
	try {
		const data = action.payload
		let API = UpdateWithVariables(API_URL.FORM.GET_CONTROL_TYPES, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
		const resp: any = yield call(CallApi.GET, API, data, true)
		let respChecker = responseHandler(resp)
		if (respChecker.status) {
			let types = respChecker.data.data.controlTypes.map((control:any)=>{
                control.value = control.controlTypeID
                control.label = control.controlName
                return control
            })
			yield put({
				type: ACTIONS.FORM.CONTROL_TYPES,
				payload: {
					controlTypes: types
				},
			})
			action && action.callbackSuccess && action.callbackSuccess(types)
		} else {
			action && action.callbackError && action.callbackError(respChecker.data)
		}
	} catch (e: any) {
		action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
	}
}

export function* getControlProps(action: ApiActionType): any {
	try {
		const data = action.payload
		let API = UpdateWithVariables(API_URL.FORM.GET_CONTROL_PROPS, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
		const resp: any = yield call(CallApi.GET, API, data, true)
		let respChecker = responseHandler(resp)
		if (respChecker.status) {
			action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
		} else {
			action && action.callbackError && action.callbackError(respChecker.data)
		}
	} catch (e: any) {
		action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
	}
}

export function* save(action: ApiActionType): any {
	try {
		const data = action.payload
		let API = UpdateWithVariables(API_URL.FORM.SAVE, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
		const resp: any = yield call(CallApi.POST, API, data, true)
		let respChecker = responseHandler(resp)
		if (respChecker.status) {
			action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
		} else {
			action && action.callbackError && action.callbackError(respChecker.data)
		}
	} catch (e: any) {
		action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
	}
}
export function* getSolutionID(action: ApiActionType): any {
	try {
		const data = action.payload
		let API = UpdateWithVariables(API_URL.FORM.GET_SOLUTION_ID, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
		const resp: any = yield call(CallApi.GET, API, data, true)
		let respChecker = responseHandler(resp)
		if (respChecker.status) {
			action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
		} else {
			action && action.callbackError && action.callbackError(respChecker.data)
		}
	} catch (e: any) {
		action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
	}
}
export function* addProduct(action: ApiActionType): any {
	try {
		const data = action.payload
		let API = UpdateWithVariables(API_URL.FORM.ADD_PRODUCT, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
		const resp: any = yield call(CallApi.POST, API, data, true)
		let respChecker = responseHandler(resp)
		if (respChecker.status) {
			action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
		} else {
			action && action.callbackError && action.callbackError(respChecker.data)
		}
	} catch (e: any) {
		action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
	}
}
export function* getProductsBySolution(action: ApiActionType): any {
	try {
		const data = action.payload
		let API = UpdateWithVariables(API_URL.FORM.GET_PRODUCTS_BY_SOLUTION, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
		const resp: any = yield call(CallApi.GET, API, data, true)
		let respChecker = responseHandler(resp)
		if (respChecker.status) {
			action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
		} else {
			action && action.callbackError && action.callbackError(respChecker.data)
		}
	} catch (e: any) {
		action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
	}
}

export function* productDelete(action: ApiActionType): any {
	try {
		const data = action.payload

		const resp: any = yield call(CallApi.DELETE, API_URL.FORM.PRODUCT.DELETE, data, true)
		if (resp.status >= 200 && resp.status < 300 && resp.data) {

			action && action.callbackSuccess && action.callbackSuccess(resp.data)
		} else {
			action && action.callbackError && action.callbackError(resp.data)
		}
	} catch (e: any) {
		action &&
			action.callbackError &&
			action.callbackError(e && e.data ? e.data : e)
	}
}

export function* getDynamicFormData(action: ApiActionType): any {
	try {
		const data = action.payload
		let API = UpdateWithVariables(API_URL.FORM.GET_DYNAMIC_FORM_DATA, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
		const resp: any = yield call(CallApi.POST, API, data, true)
		let respChecker = responseHandler(resp)
		if (respChecker.status) {
			action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
		} else {
			action && action.callbackError && action.callbackError(respChecker.data)
		}
	} catch (e: any) {
		action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
	}
}

export function* uploadFile(action: ApiActionType): any {
	try {
		const data = action.payload
		let API = UpdateWithVariables(API_URL.FORM.UPLOAD_FILE, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
		let progress: any = data.progress
		if (data.progress) {
			delete data.progress
		}
		const resp: any = yield call(CallApi.POST, API, data, true, progress ? progress : false)
		let respChecker = responseHandler(resp)
		if (respChecker.status) {
			action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
		} else {
			action && action.callbackError && action.callbackError(respChecker.data)
		}
	} catch (e: any) {
		action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
	}
}
export function* upload3rdPartyUrl(action: ApiActionType): any {
	try {
		const data = action.payload
		let API = UpdateWithVariables(API_URL.FORM.UPLOAD_3RD_PARTY_URL, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
		const resp: any = yield call(CallApi.POST, API, data, true)
		let respChecker = responseHandler(resp)
		if (respChecker.status) {
			action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
		} else {
			action && action.callbackError && action.callbackError(respChecker.data)
		}
	} catch (e: any) {
		action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
	}
}




export function* getAllScoreQuestions(action: ApiActionType): any {
	try {
		const data = action.payload
		let API = UpdateWithVariables(API_URL.SCORE_QUESTION.LIST, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
		const resp: any = yield call(CallApi.GET, API, data, true)
		let respChecker = responseHandler(resp)
		if (respChecker.status) {
			action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
		} else {
			action && action.callbackError && action.callbackError(respChecker.data)
		}
	} catch (e: any) {
		action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
	}
}
export function* scoreQuestionSave(action: ApiActionType): any {
	try {
		const data = action.payload
		let API = UpdateWithVariables(API_URL.SCORE_QUESTION.SAVE, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
		const resp: any = yield call(CallApi.POST, API, data, true)
		let respChecker = responseHandler(resp)
		if (respChecker.status) {
			action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
		} else {
			action && action.callbackError && action.callbackError(respChecker.data)
		}
	} catch (e: any) {
		action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
	}
}
export function* getScoreQuestions(action: ApiActionType): any {
	try {
		const data = action.payload
		let API = UpdateWithVariables(API_URL.SCORE_QUESTION.DETAILS, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
		const resp: any = yield call(CallApi.GET, API, data, true)
		let respChecker = responseHandler(resp)
		if (respChecker.status) {
			action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
		} else {
			action && action.callbackError && action.callbackError(respChecker.data)
		}
	} catch (e: any) {
		action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
	}
} 
export function* scoreQuestionUpdate(action: ApiActionType): any {
	try {
		const data = action.payload
		let API = UpdateWithVariables(API_URL.SCORE_QUESTION.UPDATE, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
		const resp: any = yield call(CallApi.PUT, API, data, true)
		let respChecker = responseHandler(resp)
		if (respChecker.status) {
			action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
		} else {
			action && action.callbackError && action.callbackError(respChecker.data)
		}
	} catch (e: any) {
		action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
	}
}
export function* scoreQuestionStatusChange(action: ApiActionType): any {
	try {
		const data = action.payload
		let API = UpdateWithVariables(API_URL.SCORE_QUESTION.CHANGE_STATUS, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
		const resp: any = yield call(CallApi.PUT, API, data, true)
		let respChecker = responseHandler(resp)
		if (respChecker.status) {
			action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
		} else {
			action && action.callbackError && action.callbackError(respChecker.data)
		}
	} catch (e: any) {
		action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
	}
}
export function* scoreQuestionDelete(action: ApiActionType): any {
	try {
		const data = action.payload
		let API = UpdateWithVariables(API_URL.SCORE_QUESTION.DELETE, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
		const resp: any = yield call(CallApi.DELETE, API, data, true)
		let respChecker = responseHandler(resp)
		if (respChecker.status) {
			action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
		} else {
			action && action.callbackError && action.callbackError(respChecker.data)
		}
	} catch (e: any) {
		action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
	}
}
export function* getPhaseStatus(action: ApiActionType): any {
	try {
		const data = action.payload
		let API = UpdateWithVariables(API_URL.FORM.GET_PHASE_STATUS, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
		const resp: any = yield call(CallApi.GET, API, data, true)
		let respChecker = responseHandler(resp)
		if (respChecker.status) {
			action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
		} else {
			action && action.callbackError && action.callbackError(respChecker.data)
		}
	} catch (e: any) {
		action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
	}
}
export function* setPhaseStatus(action: ApiActionType): any {
	try {
		const data = action.payload
		let API = UpdateWithVariables(API_URL.FORM.SET_PHASE_STATUS, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
		const resp: any = yield call(CallApi.POST, API, data, true)
		let respChecker = responseHandler(resp)
		if (respChecker.status) {
			action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
		} else {
			action && action.callbackError && action.callbackError(respChecker.data)
		}
	} catch (e: any) {
		action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
	}
}