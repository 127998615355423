import React from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import TitleBar from "../../components/titleBar/TitleBar";
import PrgressCircle from "./components/PrgressCircle";

const Dashboard = () => {
    return (
        <React.Fragment>
            <Header></Header>
            <div className="main-container flex-grow-1">
                <div className="container">

                    <TitleBar title={'Executive summary dashboard'} breadcrumbs={{
                        'Executive summary dashboard': '#'
                    }} >

                    </TitleBar>



                    <div className=" executive-dashboard">
                        <div className="dashboard-top-part">
                            <div className="row">
                                <div className="col-lg-2 col-md-6 col-12">
                                    <div className="dashboard-top-part-body">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <h2 className="h5 mb-0 font-weight-600">Hanger</h2>
                                            <img src="../images/hang-bulb.svg" alt="#" />
                                        </div>
                                        <div className="hang-num mx-auto mx-28">
                                            <span>#34</span>
                                        </div>
                                        <div className="dashboard-bottom-part-body">
                                            <span># of ideas</span>
                                        </div>
                                    </div>
                                    <div className="dashboard-top-part-body desk-none">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <h2 className="h5 mb-0 font-weight-600">landed</h2>
                                            <img src="../images/hang-flight-land.svg" alt="#" />
                                        </div>
                                        <div className="graph-text ">
                                            <div>
                                                <span>value proposition</span>
                                                <img src="../images/run-6.png" alt="#" />
                                            </div>
                                            <div>
                                                <span>go/no-go</span>
                                                <img src="../images/run-6.png" alt="#" />
                                            </div>

                                        </div>
                                        <div className="dashboard-bottom-part-body">
                                            <span># of arrivals </span>
                                            <div className="number-avatar number-avatar-red">2</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-7 col-md-6 col-12">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-12 col-12">
                                            <div className="dashboard-top-part-body">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <h2 className="h5 mb-0 font-weight-600">Runway</h2>
                                                    <img src="../images/hang-flight-run.svg" alt="#" />

                                                </div>
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <span className="progress-heading">Pre-Flight list</span>
                                                        {/* <img src="../images/run-1.png" alt="#" /> */}
                                                        <PrgressCircle deg={20} color1={'color1'} />
                                                    </div>
                                                    <div className="col-md-4">
                                                        <span className="progress-heading">Project Plan</span>
                                                        {/* <img src="../images/run-1.png" alt="#" /> */}
                                                        <PrgressCircle deg={50} />
                                                    </div>
                                                    <div className="col-md-4">
                                                        <span className="progress-heading">Flight Plan</span>
                                                        {/* <img src="../images/run-1.png" alt="#" /> */}
                                                        <PrgressCircle deg={80} />
                                                    </div>
                                                </div>
                                                <div className="dashboard-bottom-part-body">
                                                    <span># of Planes on the runway </span>
                                                    <div className="number-avatar">7</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12 col-12">
                                            <div className="dashboard-top-part-body">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <h2 className="h5 mb-0 font-weight-600">In-flight</h2>
                                                    <img src="../images/hang-flight-running.svg" alt="#" />
                                                </div>
                                                <div className="row ">
                                                    <div className="col-md-4">
                                                        <span className="progress-heading">In-field testing</span>
                                                        <PrgressCircle deg={10} />
                                                    </div>
                                                    <div className="col-md-4">
                                                        <span className="progress-heading">dil feedback</span>
                                                        <PrgressCircle deg={120} />
                                                    </div>
                                                    <div className="col-md-4">
                                                        <span className="progress-heading">field acceptance</span>
                                                        <PrgressCircle deg={20} />
                                                    </div>
                                                </div>
                                                <div className="dashboard-bottom-part-body">
                                                    <span># of Planes in the air </span>
                                                    <div className="number-avatar number-avatar-purple">4</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-12">
                                    <div className="dashboard-top-part-body resp-none">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <h2 className="h5 mb-0 font-weight-600">landed</h2>
                                            <img src="../images/hang-flight-land.svg" alt="#" />
                                        </div>
                                        <div className="row ">
                                            <div className="col-md-6">
                                                <span className="progress-heading">value proposition</span>
                                                <PrgressCircle deg={60} />
                                            </div>
                                            <div className="col-md-6">
                                                <span className="progress-heading">go/no-go</span>
                                                <PrgressCircle deg={120} />
                                            </div>

                                        </div>
                                        <div className="dashboard-bottom-part-body">
                                            <span># of arrivals </span>
                                            <div className="number-avatar number-avatar-red">2</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flight-section">
                            <ul>
                                <li>
                                    <img src="../images/hanger.svg" alt="#" />
                                    <span>Hanger</span>
                                </li>
                                <li>
                                    <img src="../images/runway.svg" alt="#" />
                                    <span>Runway</span>
                                </li>
                                <li>
                                    <img src="../images/in-flight.svg" alt="#" />
                                    <span>In-flight</span>
                                </li>
                                <li>
                                    <img src="../images/landed.svg" alt="#" />
                                    <span>landed</span>
                                </li>
                            </ul>
                        </div>

                        <div className="flight-take-information">
                            <div className="row">
                                <div className="col-lg-3 col-md-6 col-12 p-0 flight-take-black-col">
                                    <div className="flight-take-information-body">
                                        <h2>Intake</h2>
                                        <ul>
                                            <li># of approved Ideas <div className="number-avatar">16</div></li>
                                            <li># of ideas in discovery <div className="number-avatar">18</div></li>
                                            <li># of passed ideas <div className="number-avatar">18</div></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-12 p-0 flight-take-black-col-two">
                                    <div className="flight-take-information-body">
                                        <h2>throughput</h2>
                                        <ul>
                                            <li>avg #of days in the hanger <div className="number-avatar">113</div></li>
                                            <li>avg #of days in pre-flight checklist <div className="number-avatar">40</div></li>
                                            <li>avg #of days in security <div className="number-avatar">58</div></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-12 p-0 flight-take-black-col-two">
                                    <div className="flight-take-information-body">
                                        <h2> </h2>
                                        <ul>
                                            <li>avg #of days to create flight plan <div className="number-avatar">99</div></li>
                                            <li>avg #of days on the runway <div className="number-avatar">48</div></li>
                                            <li>avg #of days in the air <div className="number-avatar">33</div></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-12 p-0 flight-take-black-col flight-take-white-col-avatar">
                                    <div className="flight-take-information-body">
                                        <h2>quality of output</h2>
                                        <ul>
                                            <li>go/total decisions ratio <div className="number-avatar">2/2</div></li>
                                            <li>re-allocated capex <div className="number-avatar">$345M</div></li>
                                            <li>opex saved <div className="number-avatar">$50M</div></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer></Footer>
        </React.Fragment>
    );
}

export default Dashboard;