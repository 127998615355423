import React, { useEffect, useState } from "react";
import Error from "../elements/formFieldError";
import { Controller, useFieldArray } from "react-hook-form";
import Select from "react-select";
import Form from 'react-bootstrap/Form';
import { useFormApi } from "../../_common/hooks/api/FormApiHook";
import { SAGA_ACTIONS } from '../../_config/index'
import { Button } from "react-bootstrap";
interface props {
    index: number
    control: any
    register: any
    getValues: any
    setValue: any
    errors: any
    prop:any
    options:any[]
    field:any
}
const OptionGenerators = ({ index, control, register, getValues, setValue,  errors, prop , options, field}: props) => {

    /* const [controlProps,setControlProps] = useState<any>(null);

    const formApi = useFormApi(); */
    const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
        control,
        name: "option", 
    });

    const addNewOption = ()=>{
        append({value:'',label:''})
    }


    useEffect(()=>{
        if(options){
            remove();
            Object.keys(options).map((valueKey:any, i:any)=>{
                append({value:valueKey.split('_').pop(),label:options[valueKey]})

                setValue(`field.${index}.prop.${prop.propertyName}.options.${i}.value`,valueKey.split('_').pop())
                setValue(`field.${index}.prop.${prop.propertyName}.options.${i}.label`,options[valueKey])
            })
        }
    },[])

 

    return (
        <>
            <div className="row">
                {fields.map((field:any, i) => (
                    <div className="row mb-3" key={i}>
                        <div className="col-md-6">
                            <label htmlFor="tenant" className="form-label">Value</label>
                            <Controller
                                control={control}
                                name={`field.${index}.prop.${prop.propertyName}.options.${i}.value`}
                                rules={{ required: true }}
                                render={({ field: { onChange, value,  name } }) => (
                                    <>
                                        <input className="form-control" onChange={onChange} value={value} type="text" disabled={field.isMandetoryControl} />
                                        <Error error={errors[name]} />
                                    </>
                                )}
                            />
                            
                            
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="tenant" className="form-label">Label</label>
                            <Controller
                                control={control}
                                name={`field.${index}.prop.${prop.propertyName}.options.${i}.label`}
                                rules={{ required: true }}
                                render={({ field: { onChange, value, name } }) => (
                                    <>
                                        <input className="form-control" onChange={onChange} value={value} type="text" disabled={field.isMandetoryControl}/>
                                        <Error error={errors[name]} />
                                    </>
                                )}
                            />
                            
                        </div>
                    </div>
                ))}
            </div>
            {!field.isMandetoryControl && <Button onClick={addNewOption}>Add New Option</Button>}
            
        </>
    );
}

export default OptionGenerators;