import React, { useEffect, useState } from "react";
import './Search.scss';
import { CiSearch } from "react-icons/ci";
import { Controller, useForm } from "react-hook-form";
import Icon from "../icon";
import { useNavigate, useSearchParams } from "react-router-dom";
import { URLS } from "../../_config";
interface props {

}
const Search = ({ }: props) => {
    const navigate = useNavigate();
    const { control, formState: { errors }, handleSubmit, reset, getValues } = useForm();
    const [searchParams, setSearchParams] = useSearchParams();
    const [search, setSearch] = useState<string>(searchParams.get("search") || '');

    const submitHandler = (data: any) => {
        navigate(URLS.USE_CASE_LIST + '?search=' + data.filter)
    }

    useEffect(() => {
        reset({ filter: search })
    }, [search])

    
    return (
        <form className="search" onSubmit={handleSubmit(submitHandler)}>
            <div className="position-relative">
                <Icon name="search" />
                <Controller
                    defaultValue={""}
                    control={control}
                    name="filter"
                    render={({ field: { onChange, onBlur, value, name, ref } }) => (
                        <input
                            type="search"
                            name={name}
                            value={value}
                            onChange={(e: any) => {
                                if (e.target.value == '' && e.target.value != (getValues()).filter) {
                                    navigate(URLS.USE_CASE_LIST)
                                }
                                onChange(e.target.value)
                            }}
                            placeholder="Search..."
                            autoComplete="new-password"
                            spellCheck={false}
                        />
                    )}
                />
                
            </div>
        </form>
    )
}

export default Search;