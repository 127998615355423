import React, { useEffect, useState } from 'react'
import { Accordion, AccordionItem } from 'react-bootstrap'
import { Link, useLocation, useParams } from 'react-router-dom'
import { replaceUrlParams } from '../../_config/helpers';
import { ACTIONS, URLS } from '../../_config';
import { useMasterApi } from '../../_common/hooks/api/MasterApiHook';
import { useUser } from '../../_common/hooks/authHook';
import { useDispatch } from 'react-redux';
import { useMasterWorkflows } from '../../_common/hooks/masterHook';


export const LeftMenu = () => {

    const [collapse, setCollapse] = useState<boolean>(false);
    const workflow = ['hanger', 'taxi-to-runway', 'intheair', 'landed'];
    const location = useLocation();
    let pathnameSegments = location.pathname.split('/');
    let secondPositionValue = pathnameSegments[3];
    let index: any = workflow.indexOf(secondPositionValue);
    const defaultActiveKey = String(index);
    const { id } = useParams();
    const user: any = useUser();
    const workflowSteps = useMasterWorkflows();

    const dispatch: any = useDispatch()
    const masterApi = useMasterApi();
    //const [workflowSteps,setWorkflowSteps] = useState<any>(null)
    const currentURL = useLocation();
    const [activeKey, setActiveKey] = useState<any>("0");

    const getSections = () => {
        masterApi.getSections({ urlParams: { companyID: user.companyID } }, (msg: any, resp: any) => {
            let workflowSteps: any = [];
            let sections: any = resp.data.workFlowSection
            masterApi.getPhases({ urlParams: { companyID: user.companyID } }, (msg: any, resp: any) => {
                let phases: any = resp.data.workFlowPhase
                sections.map((section: any) => {
                    let sec: any = {
                        sectionDetails: section,
                        phases: []
                    };
                    phases.map((phase: any) => {
                        if (phase.fkSectionValue == section.sectionID) {
                            sec.phases.push(phase)
                        }
                    })
                    workflowSteps.push(sec)
                })
                dispatch({
                    type: ACTIONS.MASTER.WORKFLOWS, payload: {
                        workflows: workflowSteps
                    }
                })
            }, (msg: any) => { })
        }, (msg: any) => { })
    }

    useEffect(() => {
        if (workflowSteps == null && user) {
            getSections();
        }
    }, [workflowSteps, user])

    const matchUrl = (url:string) =>{
        if(url == currentURL.pathname){
            return true;
        } else {
            return false;
        }
    }

    const getActiveClass = (name: string) => {
        switch (name) {
            case 'Discovery': return matchUrl(replaceUrlParams(URLS.WORKFLOW.HANGER.DISCOVERY, { id: id }))?'active':'';
            case 'Challenge Statement': return matchUrl(replaceUrlParams(URLS.WORKFLOW.HANGER.CHALLENGE, { id: id }))?'active':'';
            case 'Solution Framing': return matchUrl(replaceUrlParams(URLS.WORKFLOW.HANGER.SOLUTIONS, { id: id }))?'active':'';
            case 'Ranking and Prioritization': return matchUrl(replaceUrlParams(URLS.WORKFLOW.HANGER.SCORE, { id: id }))?'active':'';
            case 'Hanger Decision': return matchUrl(replaceUrlParams(URLS.WORKFLOW.HANGER.DECISION, { id: id }))?'active':'';

            case 'Project Plan': return matchUrl(replaceUrlParams(URLS.WORKFLOW.RUNWAY.PROJECT_PLAN, { id: id }))?'active':'';
            case 'Procurement Plan': return matchUrl(replaceUrlParams(URLS.WORKFLOW.RUNWAY.PROCUREMENT_PLAN, { id: id }))?'active':'';
            case 'Test Plan': return matchUrl(replaceUrlParams(URLS.WORKFLOW.RUNWAY.TEST_PLAN, { id: id }))?'active':'';

            case 'In-Field Testing': return matchUrl(replaceUrlParams(URLS.WORKFLOW.INTHEAIR.FIELD_TESTING, { id: id }))?'active':'';
            case 'Day in the Life':  return matchUrl(replaceUrlParams(URLS.WORKFLOW.INTHEAIR.DAY_IN_LIFE, { id: id }))?'active':'';
            case 'Field Acceptance': return matchUrl(replaceUrlParams(URLS.WORKFLOW.INTHEAIR.FIELD_ASSISTANCE, { id: id }))?'active':'';
            case 'Go/No Go': return matchUrl(replaceUrlParams(URLS.WORKFLOW.LANDED.GONOGO, { id: id }))?'active':'';
            default: return ''
        }
    }

    const getActiveSection = (section: any) => {
        let cls: any = ''
        section.phases.map((phase: any, i: any) => {
            if (getActiveClass(phase.title) == 'active') {
                cls = 'active'
            }
        })
        return cls;
    }

    const getUrlByPhaseName = (name: string) => {
        switch (name) {
            case 'Discovery': return replaceUrlParams(URLS.WORKFLOW.HANGER.DISCOVERY, { id: id });
            case 'Challenge Statement': return replaceUrlParams(URLS.WORKFLOW.HANGER.CHALLENGE, { id: id });
            case 'Solution Framing': return replaceUrlParams(URLS.WORKFLOW.HANGER.SOLUTIONS, { id: id });
            case 'Ranking and Prioritization': return replaceUrlParams(URLS.WORKFLOW.HANGER.SCORE, { id: id });
            case 'Hanger Decision': return replaceUrlParams(URLS.WORKFLOW.HANGER.DECISION, { id: id });
            case 'Project Plan': return replaceUrlParams(URLS.WORKFLOW.RUNWAY.PROJECT_PLAN, { id: id });
            case 'Procurement Plan': return replaceUrlParams(URLS.WORKFLOW.RUNWAY.PROCUREMENT_PLAN, { id: id });
            case 'Test Plan': return replaceUrlParams(URLS.WORKFLOW.RUNWAY.TEST_PLAN, { id: id });
            case 'In-Field Testing': return replaceUrlParams(URLS.WORKFLOW.INTHEAIR.FIELD_TESTING, { id: id });
            case 'Day in the Life':  return replaceUrlParams(URLS.WORKFLOW.INTHEAIR.DAY_IN_LIFE, { id: id });
            case 'Field Acceptance': return replaceUrlParams(URLS.WORKFLOW.INTHEAIR.FIELD_ASSISTANCE, { id: id });
            case 'Go/No Go': return replaceUrlParams(URLS.WORKFLOW.LANDED.GONOGO, { id: id });
            default: return '#'
        }
    }

    const getSectionIcon = (name: string) => {
        switch (name) {
            case 'Hanger': return (
                <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="email-outline 1">
                        <path id="Vector" d="M12.1809 3.25008C12.1809 2.65425 11.6934 2.16675 11.0975 2.16675H2.43087C1.83503 2.16675 1.34753 2.65425 1.34753 3.25008V9.75008C1.34753 10.3459 1.83503 10.8334 2.43087 10.8334H11.0975C11.6934 10.8334 12.1809 10.3459 12.1809 9.75008V3.25008ZM11.0975 3.25008L6.7642 5.95841L2.43087 3.25008H11.0975ZM11.0975 9.75008H2.43087V4.33341L6.7642 7.04175L11.0975 4.33341V9.75008Z" fill="currentColor" />
                    </g>
                </svg>
            );
            case 'Taxi to Runway': return (
                <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="star-outline 1">
                        <path id="Vector" d="M6.7642 8.33617L4.72753 9.56575L5.26378 7.24742L3.46545 5.68742L5.83795 5.487L6.7642 3.29867L7.69045 5.487L10.063 5.68742L8.26462 7.24742L8.80087 9.56575L6.7642 8.33617ZM12.1809 5.00492L8.28628 4.6745L6.7642 1.08325L5.24212 4.6745L1.34753 5.00492L4.29962 7.567L3.4167 11.3749L6.7642 9.3545L10.1117 11.3749L9.22337 7.567L12.1809 5.00492Z" fill="currentColor" />
                    </g>
                </svg>
            );
            case 'In the Air': return (
                <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="diamond-stone 1">
                        <path id="Vector" d="M8.93087 4.87492H10.5559L7.84753 8.66659L8.93087 4.87492ZM5.68087 4.87492H7.84753L6.7642 9.20825L5.68087 4.87492ZM2.97253 4.87492H4.59753L5.68087 8.66659L2.97253 4.87492ZM8.3892 2.16659H9.47253L10.5559 3.79159H8.93087L8.3892 2.16659ZM6.22253 2.16659H7.30587L7.84753 3.79159H5.68087L6.22253 2.16659ZM4.05587 2.16659H5.1392L4.59753 3.79159H2.97253L4.05587 2.16659ZM3.5142 1.08325L1.34753 4.33325L6.7642 11.9166L12.1809 4.33325L10.0142 1.08325H3.5142Z" fill="currentColor" />
                    </g>
                </svg>
            );
            case 'Landed': return (
                <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="file-outline 1">
                        <path id="Vector" d="M7.84745 1.08325H3.51412C3.2268 1.08325 2.95125 1.19739 2.74809 1.40055C2.54492 1.60372 2.43079 1.87927 2.43079 2.16659V10.8333C2.43079 11.1206 2.54492 11.3961 2.74809 11.5993C2.95125 11.8024 3.2268 11.9166 3.51412 11.9166H10.0141C10.3014 11.9166 10.577 11.8024 10.7802 11.5993C10.9833 11.3961 11.0975 11.1206 11.0975 10.8333V4.33325L7.84745 1.08325ZM10.0141 10.8333H3.51412V2.16659H7.30579V4.87492H10.0141V10.8333Z" fill="currentColor" />
                    </g>
                </svg>
            );
            default: return (<svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="email-outline 1">
                    <path id="Vector" d="M12.1809 3.25008C12.1809 2.65425 11.6934 2.16675 11.0975 2.16675H2.43087C1.83503 2.16675 1.34753 2.65425 1.34753 3.25008V9.75008C1.34753 10.3459 1.83503 10.8334 2.43087 10.8334H11.0975C11.6934 10.8334 12.1809 10.3459 12.1809 9.75008V3.25008ZM11.0975 3.25008L6.7642 5.95841L2.43087 3.25008H11.0975ZM11.0975 9.75008H2.43087V4.33341L6.7642 7.04175L11.0975 4.33341V9.75008Z" fill="currentColor" />
                </g>
            </svg>)
        }
    }

    useEffect(() => {
        if(matchUrl(replaceUrlParams(URLS.WORKFLOW.RUNWAY.PROJECT_PLAN, { id: id }))){setActiveKey('1')};
        if(matchUrl(replaceUrlParams(URLS.WORKFLOW.RUNWAY.PROCUREMENT_PLAN, { id: id }))){setActiveKey('1')};
        if(matchUrl(replaceUrlParams(URLS.WORKFLOW.RUNWAY.TEST_PLAN, { id: id }))){setActiveKey('1')};
        if(matchUrl(replaceUrlParams(URLS.WORKFLOW.INTHEAIR.FIELD_TESTING, { id: id }))){setActiveKey('2')};
        if(matchUrl(replaceUrlParams(URLS.WORKFLOW.INTHEAIR.DAY_IN_LIFE, { id: id }))){setActiveKey('2')};
        if(matchUrl(replaceUrlParams(URLS.WORKFLOW.INTHEAIR.FIELD_ASSISTANCE, { id: id }))){setActiveKey('2')};
        if(matchUrl(replaceUrlParams(URLS.WORKFLOW.LANDED.GONOGO, { id: id }))){setActiveKey('3')};
    }, [])


    return (
        <div className={"left-panel " + (collapse ? 'collapse-panel' : '')}>
            <Accordion activeKey={activeKey} onSelect={(key) => setActiveKey(key)}>
                {workflowSteps?.map((section: any, i: any) => (
                    <Accordion.Item eventKey={`${i}`} key={`section-${i}`} >
                        <Accordion.Header className={getActiveSection(section)}>
                            <span className="menu-icon">
                                {getSectionIcon(section.sectionDetails.title)}
                            </span> {section.sectionDetails.title}
                        </Accordion.Header>
                        <Accordion.Body className='show' >
                            <ul className="submenu">
                                {section.phases.map((phase: any, i: any) => (
                                    <li key={`phase-${i}`}><Link to={getUrlByPhaseName(phase.title)} className={getActiveClass(phase.title)}>{phase.title}</Link></li>
                                ))}
                            </ul>
                        </Accordion.Body>
                    </Accordion.Item>
                ))}
                
            </Accordion>
            <button onClick={() => { setCollapse(!collapse) }} className={"collapse-arrow p-0 "}><i className="collapse-arrow-icon"></i></button>
        </div>
    )
}
