import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom'
import Error from '../components/elements/formFieldError';
import toast from 'react-hot-toast';
import { useUser } from '../_common/hooks/authHook';
import { useWorkFlowApi } from '../_common/hooks/api/WorkFlowApiHook';
import { useUserApi } from '../_common/hooks/api/UserApiHook';
import Select from 'react-select';

interface props {
    show: boolean,
    onClose: (status: boolean) => void,
    receiveNewData: (data: any) => void
}

export default function AddDayInLifeMemberModal({ show, onClose, receiveNewData }: props) {
    const [visible, setVisible] = useState<boolean>(false);
    const handleClose = () => { setVisible(false); onClose(false) };

    const user: any = useUser();

    const { control, register, handleSubmit, reset, getValues, formState: { errors, isValid }, } = useForm()
    const workFlowApi = useWorkFlowApi();
    const userApi = useUserApi();
    const [employees, setEmployees] = useState<any>(null);
    const { id } = useParams();


    const getEmployeeList = () => {
        userApi.employeeList({}, (message: any, resp: any) => {
            setEmployees(resp.data.members.map((member:any)=>{
                member.label = member.userProfileDisplayName
                member.value = member.userID
                return member;

            }));
        }, (message: any, resp: any) => {
            setEmployees([])
        })

    }


    const addMember = (data: any) => {
        return new Promise((resolve, reject) => {
            workFlowApi.addMember({
                "fkUseCaseID": id,
                "fkCompanyID": user.companyID,
                "fkUserID": data.fkUserID.value,
                "timeInSec": 10,
                "userFullName": data.fkUserID.label,
                "title": data.title,
                "addedBy": user.userID
             }, (msg: any, resp: any) => {
                receiveNewData(resp.data.candidateList)
                resolve(msg)
            }, (msg: any) => { })
        })
    }

    /* 
    {
                "fkUseCaseID": id,
                "fkCompanyID": user.companyID,
                "fkUserID": 0,
                "timeInSec": 0,
                "userFullName": "string",
                "title": "string",
                "addedBy": user.userID
              }
    */


    const handleAddmember = (data: any) => {
        if (isValid) {
            toast.promise(
                addMember(data), {
                loading: 'Saving...',
                success: (msg: any) => {
                    reset();
                    handleClose();
                    return <b>{msg}</b>
                },
                error: (msg: any) => { return <b>{msg}</b> },
            } );
        } else {
            //form.current.submit()
            console.log('Not valid')
        }
    }

    useEffect(() => {
        setVisible(show)
        if(show){
            getEmployeeList()
        }
    }, [show])



    return (
        <Modal show={visible} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Add Day in the life member</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form className="form-area" id="add-day-in-life-member" onSubmit={handleSubmit(handleAddmember)}>
                    <div className="form-input mb-3 ">
                        <label>Title</label>
                        <Controller
                            name="title"
                            control={control}
                            rules={{ required: 'Title is required' }}
                            render={({ field }) => (
                                <>
                                    <input className='form-control' type='text' {...field} />
                                    <Error error={errors[field.name]} />
                                </>
                            )}
                        />
                    </div>
                    <div className="form-input mb-3">
                        <label>Employee</label>
                        <Controller
                            name="fkUserID"
                            control={control}
                            rules={{ required: 'Employee is required' }}
                            render={({ field }) => (
                                <>
                                    <Select
                                        {...field}
                                        options={employees?employees:{}}
                                        isClearable={true}
                                    />
                                    <Error error={errors[field.name]} />
                                </>
                            )}
                        />
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>Close</Button>
                <Button variant="primary" type='submit' form='add-day-in-life-member'>Add</Button>
            </Modal.Footer>
        </Modal>
    )
}