import React, { useEffect, useState } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import Pagination from "../../components/pagination/Pagination";
import { useUserApi } from "../../_common/hooks/api/UserApiHook";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import toast from 'react-hot-toast';
import { useForm, SubmitHandler } from "react-hook-form"
import { useUser } from "../../_common/hooks/authHook";
import { URLS } from "../../_config";
import TitleBar from "../../components/titleBar/TitleBar";
import Icon from "../../components/icon";
import { replaceUrlParams } from "../../_config/helpers";

export const Profile = () => {

    const navigate = useNavigate();
    const userApi = useUserApi();
    const userInfo: any = useUser();
    const { id } = useParams();
    const paramRole: any = id;
    console.log('userInfo', userInfo);



    return (
        <React.Fragment>
            <Header></Header>

            <div className="main-container flex-grow-1">
                <div className="container">

                    <TitleBar title={'Profile'} breadcrumbs={{
                        'Profile ': '#',
                    }} >
                        <Link className="btn btn-primary" to={replaceUrlParams(URLS.EMPLOYEE_EDIT, { id: userInfo.userProfID }) + '?return=profile'}>
                            <Icon name="edit" size={16} /> Edit
                        </Link>
                    </TitleBar>

                    <div className="row">
                        <div className="col-sm-8">
                            <div className="white-box h-100">
                                <div className="row">
                                    <div className="col-md-4 text-center mb-3">
                                        <img src={userInfo?.avatar} className="rounded-2 w-100" />
                                    </div>
                                    <div className="col-md-8">
                                        <h3>{userInfo?.userProfileTitle} {userInfo?.firstName} {userInfo?.lastName}</h3>
                                        <h6><span className="opacity-50 text-grey">Email - </span>{userInfo?.email}</h6>
                                        <h6><span className="opacity-50 text-grey">Company - </span>{userInfo?.companyName}</h6>
                                        <h6><span className="opacity-50 text-grey">Role - </span>{userInfo?.roleName}</h6>
                                        <p>{userInfo?.bio}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="pt-5">
                                <img src="../images/idea-pic.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer></Footer>
        </React.Fragment>
    )
}
