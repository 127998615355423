import { ActionExtended } from '../../_common/interfaces/ActionExtended'
import { User } from '../../_common/interfaces/models/user'
import { ACTIONS } from '../../_config'

export interface UserReducer {
  user: User | null
  token: string | null
  refreshtoken: string | null
  isAuthenticated: boolean
  isExpired: boolean
  permissions: any
}

const initialState: UserReducer = {
  user: null,
  token: null,
  refreshtoken: null,
  isAuthenticated: false,
  isExpired: false,
  permissions: null
}

const userReducer = (state = initialState, action: ActionExtended) => {
  switch (action.type) {
    case ACTIONS.USER.LOGIN:
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token,
        refreshtoken: action.payload.refreshToken,
        isAuthenticated: true,
        isExpired: true,
        permissions: action.payload.permissions
      }
    case ACTIONS.USER.LOGOUT:
      return {
        ...state,
        user: null,
        token: null,
        refreshtoken: null,
        isAuthenticated: false,
        isExpired: false,
        permissions:null
      }
    case ACTIONS.USER.ME:
      return {
        ...state,
        user: action.payload.user,
      }
    default:
      return state
  }
}

export default userReducer
