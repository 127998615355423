import { API_BASE_URL, API_BUS_BASE_URL, API_BUS_BASE_URL_ORIGIN, CLERK_API } from './site_urls'

export const STORAGE = 'innsass'
export const APP_URL = window.location.origin
export const CURRENCYSYMBOL = '$'
export const CURRENCY = 'usd'
export const PERCENTSYMBOL = '%'
export const SYNCFUNCTION_LICENCE_KEY = 'ORg4AjUWIQA/Gnt2VlhhQlJCfV5DQmJLYVF2R2BJdlR0cF9FYUwgOX1dQl9gSH5SdURiXH5fdnJWRGk='
export const BASIS_THEORY_API_KEY = 'key_GBZCq4BZAdj8QqkJqL4gP7'
// export const HOST_NAME = '.fluxpay.com'
export const PER_PAGE = '10'

export const URLS = {
  LOGIN: '/login',
  LOGIN_SSO: '/login-sso',
  SIGNUP: '/signup/',
  SIGNUP_NEXT: '/signup-next/:form_id',
  TWO_FACTOR: '/otp/:type',
  VERIFY_ACCOUNT: '/verify-account',
  THANKYOU: '/thank-you',
  PLANS: '/plans',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',

  DASHBOARD: '/dashboard', // will delete
  DASHBOARD2: '/dashboard2', // will delete 
  DASHBOARD_EXECUTIVE: '/executive-dashboard',
  DASHBOARD_PERIOD_COMPARISON: '/date-range-comparison-dashboard',

  PROFILE_VIEW: '/profile',



  VIRTUAL_TERMINAL: '/virtual-terminal',
  ACCOUNTS: '/accounts',
  ADD_NEW_ACCOUNT: '/add-new-account',
  ACCOUNT_DETAILS: '/accounts/:id',
  ACCOUNT_AUTHORIZATIONS: '/accounts/:id/authorizations',
  ACCOUNT_TRANSACTIONS: '/accounts/:id/transactions',
  ACCOUNT_SUBSCRIPTIONS: '/accounts/:id/subscriptions',
  ACCOUNT_INVOICES: '/accounts/:id/invoices',
  ACCOUNT_BILLS: '/accounts/:id/bills',
  USECASE_DETAILS: '/use-case/:id',
  ONBOARDING: '/onboarding',
  MANAGE_FORMS: '/forms',
  TEST: '/test',

  AIRPORT: {
    LIST: '/airport/list',
    CREATE: '/airport/create',
    EDIT: '/airport/:id',
  },


  COMPANY: {
    LIST: '/company/list',
  },

  TENANT: {
    LIST: '/tenant/list',
  },

  RUNWAY: {
    LIST: '/runway/list',
    DETAILS: '/runway/details/:id',
    CREATE: '/runway/create',
    EDIT: '/runway/edit/:id',
  },

  WORKFLOW: {
    HANGER: {
      DISCOVERY: '/use-case/:id/workflow/hanger/discovery',
      CHALLENGE: '/use-case/:id/workflow/hanger/challenge-statement',
      SOLUTIONS: '/use-case/:id/workflow/hanger/solution-framing',
      SCORE: '/use-case/:id/workflow/hanger/ranking-prioritization',
      DECISION: '/use-case/:id/workflow/hanger/decision'
    },
    RUNWAY: {
      PROJECT_PLAN: '/use-case/:id/workflow/taxi-to-runway/project-plan',
      PROCUREMENT_PLAN: '/use-case/:id/workflow/taxi-to-runway/procurement-plan',
      TEST_PLAN: '/use-case/:id/workflow/taxi-to-runway/test-plan',
    },
    INTHEAIR: {
      FIELD_TESTING: '/use-case/:id/workflow/intheair/field-testing',
      DAY_IN_LIFE: '/use-case/:id/workflow/intheair/day-in-life',
      FIELD_ASSISTANCE: '/use-case/:id/workflow/intheair/field-acceptance',

    },
    LANDED: {
      GONOGO: '/use-case/:id/workflow/landed/nogo',

    },
  },
  PAYEE: {
    VENDOR: '/payee',
  },
  HPP: '/hpp',
  HPP_PAYMENT_DONE: '/hpp/payment-done',
  INVOICE: '/invoice/',
  INVOICE_LIST: '/invoice-list/',
  INVOICE_DETAILS: '/invoice-details/',
  INVOICE_PREVIEW: '/invoice-preview/',
  INVOICE_ALL_TRXN: '/invoice-transaction/',
  PRODUCT_SERVICES: '/product-services',
  PRODUCT_LIST: '/product',
  PRODUCTS_DETAILS: '/product/:id',
  PRODUCTS_TRANSACTIONS: '/product/:id/transactions',
  PRODUCTS_INVOICES: '/product/:id/invoices',
  ACCOUNT_DETAILS_EDIT: '/accounts-edit',
  USER_PROFILE: '/user-profile',
  PRINT_INVOICE: '/print-invoice/:invoiceId',



  EMPLOYEE_LIST: '/employee',
  EMPLOYEE_CREATE: '/employee/create',
  EMPLOYEE_EDIT: '/employee/edit/:id',
  EMPLOYEE_ROLE_PERMISSION: '/employee/role/:id',

  SOLUTION_PROVIDER: {
    LIST: '/solution-provider/list',
    DETAILS: '/solution-provider/details/:id',
    CREATE: '/solution-provider/create',
    EDIT: '/solution-provider/edit/:id',
  },

  USE_CASE_CREATE: '/idea-submit',
  USE_CASE_LIST: '/use-case-library',
  USE_CASE_LIST_GRID: '/use-case-library-grid',
  USE_CASE_DETAILS: '/use-case/',
  USE_CASE_HANGER_OVERVIEW: '/use-case/:id/hanger-overview',
  USE_CASE_RUNWAY_OVERVIEW: '/use-case/:id/runway-overview',
  USE_CASE_IN_FLIGHT_OVERVIEW: '/use-case/:id/in-flight-overview',
  USE_CASE_LANDED_OVERVIEW: '/use-case/:id/landed-overview',

  ROLE: {
    LIST: '/role/list',
    CREATE: '/role/create',
    EDIT: '/role/:id',
    PERMISSION: '/role/permission/:id',
  },

  NOTIFICATION: {
    LIST: '/email-notification/list', 
    EDIT: '/email-notification/:id', 
  },


  SCORE_QUESTION: {
    LIST: '/score-questions/list',
    DETAILS: '/score-questions/details/:id',
    CREATE: '/score-questions/create',
    EDIT: '/score-questions/edit/:id',
  },

}

export const UpdateWithVariables = (url: string, data: any) => {
  if (data) {
    let dynamicParams = url.match(/:[A-Z_]*/gi)
    dynamicParams?.map(key => {
      if (key.length > 1) url = url.replace(key, data[key.replace(':', '')])
    })
  }
  return url
}

export const API_URL = {
  COMMON: {
    SITE_MASTER: `${API_BASE_URL}/site-master/`,
    PHONE_CODES: `${API_BASE_URL}/geocoding/number-lookup`,
  },
  USER: {
    ME: `${API_BASE_URL}/auth/me`,
    LOGIN: `${API_BASE_URL}/login/token`,
    REFRESH_TOKEN: `${API_BASE_URL}/Login/RefreshToken`,
    LOGIN_SSO: `${API_BASE_URL}/login/SSORegister`,
    LOGIN_WITH_CLERK: `${API_BASE_URL}/Login/ClerkLogin`,
    SIGNUP: `${API_BASE_URL}/auth/register`,
    VERIFY_EMAIL: `${API_BASE_URL}/verify/email`,
    FORGOT_PASSWORD: `${API_BASE_URL}/login/ForgotPassword`,
    RESET_PASSWORD: `${API_BASE_URL}/login/ForgotPasswordReset`,
    EMPLOYEE_LIST: `${API_BASE_URL}/member/GetMemberList`,
    DELETE: `${API_BASE_URL}/Users/Delete`,
    EMPLOYEE_ACTIVE_INACTIVE: `${API_BASE_URL}/member/MemberActiveInactive`,
    ROLE_ADD: `${API_BASE_URL}/Role/InsertRole`,
    ROLE_EDIT: `${API_BASE_URL}/Role/UpdateRole`,
    ROLE_DETAILS: `${API_BASE_URL}/Role/GetRoleDetails`,
    ROLE_LIST: `${API_BASE_URL}/Role/GetRoleList`,
    ROLE_DELETE: `${API_BASE_URL}/Role/DeleteRole`,


    MENU_PERMISSION: {
      GET_LIST: `${API_BUS_BASE_URL}/MenuPermission/GetByCompanyID/:CompanyID/:FKRoleID`,
      SAVE: `${API_BUS_BASE_URL}/MenuPermission/Save`,
    },


    LOGOUT: `${API_BASE_URL}/auth/logout`,
    GET_FORM_CONFIG: `${API_BUS_BASE_URL}/DynamicForm/getform/:id`,
    SUBMIT_DYNAMIC_FORM: `${API_BUS_BASE_URL_ORIGIN}/`,
    USE_CASE_LIST: `${API_BUS_BASE_URL}/UseCase/getAllUseCase`,
    USE_CASE_DETAILS: `${API_BUS_BASE_URL}/UseCase/getUseCase/:companyID/:id/:userID`,
    USE_CASE_DELETE: `${API_BUS_BASE_URL}/UseCase/Delete`,
    CHANGE_AIRPORT_RUNWAY_CAPTAINS: `${API_BUS_BASE_URL}/UseCase/ChangeAirportRunwayCaptains`,
    REVERIFY_ACCOUNT: `${API_BASE_URL}/auth/reverify-account`,
    UPDATE_USER: `${API_BASE_URL}/users/:user_id`,
    SESSIONS_LIST: `${API_BASE_URL}/auth/sessions`,
    GET_MEMBER_DETAILS: `${API_BASE_URL}/Member/GetMemberDetails`,
    ADD_EMPLOYEE: `${API_BASE_URL}/Login/Register`,
    SEND_INVITATION: `${API_BASE_URL}/Member/SendInvitation`,
    AIRPORT_LIST: `${API_BUS_BASE_URL}/Airport/GetAirportList`,
    AIRPORT_ADD: `${API_BUS_BASE_URL}/Airport/InsertAirport`,
    AIRPORT_EDIT: `${API_BUS_BASE_URL}/Airport/UpdateAirport`,
    AIRPORT_DETAILS: `${API_BUS_BASE_URL}/Airport/GetAirportDetails`,
    AIRPORT_DELETE: `${API_BUS_BASE_URL}/Airport/DeleteAirport`,
    BY_ROLE_NAME: `${API_BASE_URL}/Member/GetUsersByRoleName`,
    CONFIG_BY_ROLE_NAME: `${API_BASE_URL}/SystemConfiguration/getConfigByKey/:key/:companyID`,
    CONFIG_BY_COMPANY_ID: `${API_BASE_URL}/SystemConfiguration/getConfigByCompany/:companyID`,
    //WORKFLOW_STATUS_LOOKUPS_ALL: `${API_BUS_BASE_URL}/WorkFlowStatusLookUps/getWorkFlowStatusLookUpsAll/:PageIndex/:PageSize`,  
    WORKFLOW_STATUS_LOOKUPS_ALL: `${API_BUS_BASE_URL}/WorkFlowStatusLookUps/getWorkFlowStatusLookUpsByType/:companyID/:type`,
    GET_RUNWAY_BY_AIRPORT: `${API_BUS_BASE_URL}/Runway/getRunwayAll/:PageIndex/:PageSize`,
    POST_USECASE_REVIEW_DATA: `${API_BUS_BASE_URL}/UseCaseReview/SaveAll`,

    POST_USECASE_APPROVED: `${API_BUS_BASE_URL}/UseCaseReview/ApproveReject`,
    POST_USECASE_DISAPPROVED: `${API_BUS_BASE_URL}/UseCaseReview/SaveAll`,
    SET_CAPTAIN_CO_CAPTAIN: `${API_BUS_BASE_URL}/UseCase/AssignCaptainCoCaptain`,
    GET_USECASE_REVIEW_LIST: `${API_BUS_BASE_URL}/UseCaseReview/GetUseCaseReviewByUsecase/:companyID/:usecaseID`,



    USER_LIST_ALL: `${API_BASE_URL}/UserProfiles/getUserProfilesAll/:PageIndex/:PageSize`,
    USER_DETAILS: `${API_BASE_URL}/UserProfiles/getUserProfiles/:UserProfID`,
    USER_UPDATE: `${API_BASE_URL}/UserProfiles/Update`,
    USER_CHANGE_STATUS: `${API_BASE_URL}/UserProfiles/StatusUpdate`,
    USER_ROLE_MAP: `${API_BASE_URL}/Role/UserRoleMap`,


    DISCOVERY_GET_SINGLE: `${API_BUS_BASE_URL}/Discovery/getDiscovery/:ID`,
    DISCOVERY_GET_ALL: `${API_BUS_BASE_URL}/Discovery/getAllDiscovery/:CompanyID/:UseCaseID`,
    DISCOVERY_DELETE: `${API_BUS_BASE_URL}/Discovery/Delete`,
    USECASE_CHALLENGE_STATEMENT: `${API_BUS_BASE_URL}/ChallengeStatement/getChallengeStatementByUsecase/:UseCaseID`,
    USECASE_PHASE_CHANGE: `${API_BUS_BASE_URL}/UseCase/PhaseChange`,

    DECISION_GET: `${API_BUS_BASE_URL}/Decision/getDecisionByUsecase/:UseCaseID`,
    DECISION_SAVE: `${API_BUS_BASE_URL}/Decision/Save`,
    USECASE_LIKE: `${API_BUS_BASE_URL}/UseCase/Likes`,
    USECASE_CAPTAINS: `${API_BUS_BASE_URL}/UseCase/GetAllCaptains`,
    ASSIGN_INNOVATION_MANAGER_TO_USECASE: `${API_BUS_BASE_URL}/UseCase/AssignInnovationManager`,
    GET_NITIFICATIONS: `${API_BUS_BASE_URL}/NotificationTemplates/GetUserNotificationByUserId/:userID/:pageNo/:pageSize`,
    GET_NITIFICATION_DETAILS: `${API_BUS_BASE_URL}/NotificationTemplates/GetUserNotificationById/:userNotificationID`,


    DASHBOARD: {
      SUMMERY_COUNT: `${API_BUS_BASE_URL}/ExecutiveDashboard/SummaryCount`,
      AVG_DURATION_LIST: `${API_BUS_BASE_URL}/ExecutiveDashboard/AvgDuration`,
      PHASE_PLAN: `${API_BUS_BASE_URL}/ExecutiveDashboard/PhasePlan`,
      PHASE_AVG: `${API_BUS_BASE_URL}/ExecutiveDashboard/PhaseAvg`,

      AVG_TOTAL_DURATION: `${API_BUS_BASE_URL}/PeriodComparisonDashboard/AvgTotalDuration`,
      AVG_DURATION_BY_SECTION: `${API_BUS_BASE_URL}/PeriodComparisonDashboard/AvgDurationBySection`,
      SECTION_PHASE_TABLE: `${API_BUS_BASE_URL}/PeriodComparisonDashboard/SectionPhaseTable`,
      AVG_DURATION_PHASE: `${API_BUS_BASE_URL}/PeriodComparisonDashboard/AvgDurationPhase`,


 
    },

  },
  FORM: {
    GET_WORKFLOWS: `${API_BUS_BASE_URL}/WorkFlows/getWorkFlowsByCompany/:companyID`,
    GET_MODULES: `${API_BUS_BASE_URL}/WorkFlows/getModulesByWorkFlowID/:WorkFowID/:companyID`,
    GET_FORMS: `${API_BUS_BASE_URL}/ModuleIs/getModuleForms/:ModuleID/:companyID`,
    GET_CONTROL_TYPES: `${API_BUS_BASE_URL}/ControlTypes/getControlTypesAll/:PageIndex/:PageSize`,
    GET_CONTROL_PROPS: `${API_BUS_BASE_URL}/ControlTypeProperties/getPropertiesByControlID/:ControlTypeID`,
    SAVE: `${API_BUS_BASE_URL}/Forms/SaveFull`,
    GET_SOLUTION_ID: `${API_BUS_BASE_URL}/SolutionFraming/getSolutionFramingByUsecase/:UseCaseID`,
    ADD_PRODUCT: `${API_BUS_BASE_URL}/Products/Save`,
    GET_PRODUCTS_BY_SOLUTION: `${API_BUS_BASE_URL}/Products/getProductsBySolutionID/:solutionID`,
    GET_DYNAMIC_FORM_DATA: `${API_BUS_BASE_URL}/DynamicForm/getEditForm`,
    UPLOAD_FILE: `${API_BUS_BASE_URL}/DynamicForm/UploadFile`,
    UPLOAD_3RD_PARTY_URL: `${API_BUS_BASE_URL}/DynamicForm/Upload3rdpartyImage`,
    GET_PHASE_STATUS: `${API_BUS_BASE_URL}/UseCase/SectionPhaseCompleteByPhase/:companyID/:usecaseID/:phaseName`,
    SET_PHASE_STATUS: `${API_BUS_BASE_URL}/UseCase/SectionPhaseComplete`,
    PRODUCT: {
      DELETE: `${API_BUS_BASE_URL}/Products/Delete`,
    },
  },
  MASTER: {
    GET_SECTIONS: `${API_BUS_BASE_URL}/WorkFlowSection/getWorkFlowSectionByCompany/:companyID`,
    GET_PHASES: `${API_BUS_BASE_URL}/WorkFlowPhase/getWorkFlowPhaseByCompany/:companyID`,
  },
  WORKFLOW: {
    GET_SCORING_PRIOTITIZATION_LIST: `${API_BUS_BASE_URL}/ScoringPrioritization/getScoringPrioritizationByUsecase/:CompanyID/:UseCaseID`,
    ADD_SCORING_PRIOTITIZATION: `${API_BUS_BASE_URL}/ScoringPrioritization/Save`,
    DELETE_SCORING_PRIOTITIZATION: `${API_BUS_BASE_URL}/ScoringPrioritization/Delete`,
    GET_SCORE_QUESTIONS: `${API_BUS_BASE_URL}/ScoreQuestions/getScoreQuestionsByCompany/:companyID`,
    SUBMIT_SCORE_QUESTIONS: `${API_BUS_BASE_URL}/ScoringPrioritization/UseCaseScoreCalculation`,
    GET_ALL_PROJECT_PLAN: `${API_BUS_BASE_URL}/ProjectPlan/getAllProjectPlan/:companyID/:UseCaseID`,
    DELETE_PROJECT_PLAN: `${API_BUS_BASE_URL}/ProjectPlan/Delete`,
    GET_ALL_PROCUREREMENT_PLAN: `${API_BUS_BASE_URL}/ProcurementPlan/getAllProcurementPlan/:companyID/:UseCaseID`,
    DELETE_PROCUREREMENT_PLAN: `${API_BUS_BASE_URL}/ProcurementPlan/Delete`,
    SUBMIT_HANGER_DECISION: `${API_BUS_BASE_URL}/Decision/Save`,
    APPROVE_PRE_SCORING: `${API_BUS_BASE_URL}/SolutionFraming/SolutionFramingApproval`,
    UPDATE_START_DATE_END_DATE: `${API_BUS_BASE_URL}/UseCase/PhaseCompleteDays`,
    GET_START_DATE_END_DATE: `${API_BUS_BASE_URL}/UseCase/PhaseCompleteDaysByPhase/:companyID/:usecaseID/:phaseName`,
    
    DAY_IN_LIFE: {
      ADD_MEMBER: `${API_BUS_BASE_URL}/DayInTheLife/CandidateSave`,
      GET_MEMBERS: `${API_BUS_BASE_URL}/DayInTheLife/CandidateByUsecase/:CompanyID/:UseCaseID`,
      DELETE_MEMBER: `${API_BUS_BASE_URL}/DayInTheLife/CandidateDelete`,

    },

    TESTING_ROLES_RESPONSIBILE: {
      LIST_BY_USECASE: `${API_BUS_BASE_URL}/TestingRolesResponsibilities/getTestingRolesResponsibilitiesByUsecase/:CompanyID/:UseCaseID`,
      SAVE: `${API_BUS_BASE_URL}/TestingRolesResponsibilities/Save`,
      DELETE: `${API_BUS_BASE_URL}/TestingRolesResponsibilities/Delete`,
      CRITERIA_BY_USECASE: `${API_BUS_BASE_URL}/TestingRolesResponsibilities/getTestCasesAcceptanceCriteriaByUsecase/:companyID/:UseCaseID`,
      CRITERIA_DELETE: `${API_BUS_BASE_URL}/TestingRolesResponsibilities/DeleteTestCasesAcceptanceCriteria`,
      CRITERIA_ASSIGN_USER: `${API_BUS_BASE_URL}/TestingRolesResponsibilities/AssignTestCasesAcceptanceCriteriaUser`,
    }


  },
  COMPANY: {
    LIST: `${API_BASE_URL}/Companies/getCompaniesAll/:index/:size`,
  },
  TENANT: {
    LIST: `${API_BASE_URL}/Tenants/getTenantsAll/:PageIndex/:PageSize`,
  },
  RUNWAY: {
    LIST: `${API_BUS_BASE_URL}/Runway/getRunwayAll/:PageIndex/:PageSize`,
    DETAILS: `${API_BUS_BASE_URL}/Runway/getRunway/:RunwayID`,
    SAVE: `${API_BUS_BASE_URL}/Runway/Save`,
    UPDATE: `${API_BUS_BASE_URL}/Runway/Update`,
    CHANGE_STATUS: `${API_BUS_BASE_URL}/Runway/StatusUpdate`,
    DELETE: `${API_BUS_BASE_URL}/Runway/Delete`,
  },
  NOTIFICATION: {
    LIST: `${API_BUS_BASE_URL}/NotificationTemplates/getNotificationTemplatesAll/:PageNo/:PageSize`,
    DETAILS: `${API_BUS_BASE_URL}/NotificationTemplates/getNotificationTemplates/:NotificationTemplateID`, 
    UPDATE: `${API_BUS_BASE_URL}/NotificationTemplates/Update`, 
  },
  SCORE_QUESTION: {
    LIST: `${API_BUS_BASE_URL}/ScoreQuestions/getScoreQuestionsAll/:PageIndex/:PageSize`,
    DETAILS: `${API_BUS_BASE_URL}/ScoreQuestions/getScoreQuestions/:ScoreQuestionID`,
    SAVE: `${API_BUS_BASE_URL}/ScoreQuestions/Save`,
    UPDATE: `${API_BUS_BASE_URL}/ScoreQuestions/Update`,
    CHANGE_STATUS: `${API_BUS_BASE_URL}/ScoreQuestions/StatusUpdate`,
    DELETE: `${API_BUS_BASE_URL}/ScoreQuestions/Delete`,
  },
  SOLUTION_PROVIDER: {
    LIST: `${API_BASE_URL}/SolutionProvider/getSolutionProviderAll/:PageIndex/:PageSize`,
    DETAILS: `${API_BASE_URL}/SolutionProvider/getSolutionProvider/:SolutionProviderID`,
    SAVE: `${API_BASE_URL}/SolutionProvider/Save`,
    UPDATE: `${API_BASE_URL}/SolutionProvider/Update`,
    CHANGE_STATUS: `${API_BASE_URL}/SolutionProvider/StatusUpdate`,
    DELETE: `${API_BASE_URL}/SolutionProvider/Delete`,
  },
  CLERK:{
    CREATE_USER:`${CLERK_API}/users`,
    GET_USERS:`${CLERK_API}/users`
  }

}

export const ACTIONS = {
  RESET_REDUCER: 'ACTIONS/RESET_REDUCER',
  COMMON: {
    LOADER: {
      SET_FP_STATUS: 'ACTIONS/LOADER/SET_FP_STATUS',
    },
    SIDEBAR: {
      TOGGLE_COLLAPSED: 'ACTIONS/SIDEBAR/TOGGLE_COLLAPSED',
      TOGGLE_LOCKED: 'ACTIONS/SIDEBAR/TOGGLE_LOCKED',
    },
    SITE_MASTER: 'ACTIONS/COMMON/SITE_MASTER',
  },
  USER: {
    LOGIN: 'ACTIONS/USER/LOGIN',
    LOGIN_SSO: 'ACTIONS/USER/LOGIN_SSO',
    LOGIN_WITH_CLERK: 'ACTIONS/USER/LOGIN_WITH_CLERK', 
    LOGOUT: 'ACTIONS/USER/LOGOUT',
    SIGNUP: 'ACTIONS/USER/SIGNUP',
    ME: 'ACTIONS/USER/ME',
    SIGNUP_SAVE: 'ACTIONS/USER/SIGNUP_SAVE',
    SIGNUP_RESET: 'ACTIONS/USER/SIGNUP_RESET',
    MERCHANT_SIGNUP: 'ACTIONS/USER/MERCHANT_SIGNUP',
  },
  FORM: {
    WORKFLOW: 'ACTIONS/FORM/WORKFLOW',
    CONTROL_TYPES: 'ACTIONS/FORM/CONTROL_TYPES',
    CONTROLS_PROPS: 'ACTIONS/FORM/CONTROL_PROPS',
  },
  MASTER: {
    WORKFLOWS: 'ACTIONS/MASTER/WORKFLOWS',
    SYSTEM_CONFIGS: 'ACTIONS/MASTER/SYSTEM_CONFIGS',
  },
  USECASE: {
    USECASE: 'ACTIONS/MASTER/USECASE',
  },
}

export const SAGA_ACTIONS: any = {
  COMMON: {
    SITE_MASTER: 'SAGA_ACTIONS/COMMON/SITE_MASTER',
    PHONE_CODES: 'SAGA_ACTIONS/COMMON/PHONE_CODES',
  },
  USER: {
    LOGIN: 'SAGA_ACTIONS/USER/LOGIN',
    LOGIN_SSO: 'SAGA_ACTIONS/USER/LOGIN_SSO', 
    LOGIN_WITH_CLERK: 'SAGA_ACTIONS/USER/LOGIN_WITH_CLERK',
    LOGOUT: 'SAGA_ACTIONS/USER/LOGOUT',
    SIGNUP: 'SAGA_ACTIONS/USER/SIGNUP',
    FORGOT_PASSWORD: 'SAGA_ACTIONS/USER/FORGOT_PASSWORD',
    RESET_PASSWORD: 'SAGA_ACTIONS/USER/RESET_PASSWORD',
    EMPLOYEE_LIST: 'SAGA_ACTIONS/USER/EMPLOYEE_LIST',
    DELETE: 'SAGA_ACTIONS/USER/DELETE',
    EMPLOYEE_ACTIVE_INACTIVE: 'SAGA_ACTIONS/USER/EMPLOYEE_ACTIVE_INACTIVE',
    ME: 'SAGA_ACTIONS/USER/ME',
    GET_FORM_CONFIG: 'SAGA_ACTIONS/USER/GET_FORM_CONFIG',
    SUBMIT_DYNAMIC_FORM: 'SAGA_ACTIONS/USER/SUBMIT_DYNAMIC_FORM',
    GET_MEMBER_DETAILS: 'SAGA_ACTIONS/USER/GET_MEMBER_DETAILS',
    ADD_EMPLOYEE: 'SAGA_ACTIONS/USER/ADD_EMPLOYEE',
    SEND_INVITATION: 'SAGA_ACTIONS/USER/SEND_INVITATION',
    ROLE_ADD: 'SAGA_ACTIONS/USER/ROLE_ADD',
    ROLE_EDIT: 'SAGA_ACTIONS/USER/ROLE_EDIT',
    ROLE_DETAILS: 'SAGA_ACTIONS/USER/ROLE_DETAILS',
    ROLE_LIST: 'SAGA_ACTIONS/USER/ROLE_LIST',
    ROLE_DELETE: 'SAGA_ACTIONS/USER/ROLE_DELETE',

    MENU_PERMISSION: {
      GET_LIST: 'SAGA_ACTIONS/USER/MENU_PERMISSION/GET_LIST',
      SAVE: 'SAGA_ACTIONS/USER/MENU_PERMISSION/SAVE',
    },

    USE_CASE_LIST: 'SAGA_ACTIONS/USER/USE_CASE_LIST',
    USE_CASE_DETAILS: 'SAGA_ACTIONS/USER/USE_CASE_DETAILS',
    USE_CASE_DELETE: 'SAGA_ACTIONS/USER/USE_CASE_DELETE',
    CHANGE_AIRPORT_RUNWAY_CAPTAINS: 'SAGA_ACTIONS/USER/CHANGE_AIRPORT_RUNWAY_CAPTAINS',
    AIRPORT_LIST: 'SAGA_ACTIONS/USER/AIRPORT_LIST',
    AIRPORT_ADD: 'SAGA_ACTIONS/USER/AIRPORT_ADD',
    AIRPORT_EDIT: 'SAGA_ACTIONS/USER/AIRPORT_EDIT',
    AIRPORT_DETAILS: 'SAGA_ACTIONS/USER/AIRPORT_DETAILS',
    AIRPORT_DELETE: 'SAGA_ACTIONS/USER/AIRPORT_DELETE',
    BY_ROLE_NAME: 'SAGA_ACTIONS/USER/BY_ROLE_NAME',
    CONFIG_BY_ROLE_NAME: 'SAGA_ACTIONS/USER/CONFIG_BY_ROLE_NAME',
    CONFIG_BY_COMPANY_ID: 'SAGA_ACTIONS/USER/CONFIG_BY_COMPANY_ID',
    WORKFLOW_STATUS_LOOKUPS_ALL: 'SAGA_ACTIONS/USER/WORKFLOW_STATUS_LOOKUPS_ALL',
    GET_RUNWAY_BY_AIRPORT: 'SAGA_ACTIONS/USER/GET_RUNWAY_BY_AIRPORT',
    POST_USECASE_REVIEW_DATA: 'SAGA_ACTIONS/USER/POST_USECASE_REVIEW_DATA',

    POST_USECASE_APPROVED: 'SAGA_ACTIONS/USER/POST_USECASE_APPROVED',
    POST_USECASE_DISAPPROVED: 'SAGA_ACTIONS/USER/POST_USECASE_DISAPPROVED',
    SET_CAPTAIN_CO_CAPTAIN: 'SAGA_ACTIONS/USER/SET_CAPTAIN_CO_CAPTAIN',
    GET_USECASE_REVIEW_LIST: 'SAGA_ACTIONS/USER/GET_USECASE_REVIEW_LIST',


    USER_LIST_ALL: 'SAGA_ACTIONS/USER/USER_LIST_ALL',
    USER_DETAILS: 'SAGA_ACTIONS/USER/USER_DETAILS',
    USER_UPDATE: 'SAGA_ACTIONS/USER/USER_UPDATE',
    USER_CHANGE_STATUS: 'SAGA_ACTIONS/USER/USER_CHANGE_STATUS',
    USER_ROLE_MAP: 'SAGA_ACTIONS/USER/USER_ROLE_MAP',


    DISCOVERY_GET_SINGLE: 'SAGA_ACTIONS/USER/DISCOVERY_GET_SINGLE',
    DISCOVERY_GET_ALL: 'SAGA_ACTIONS/USER/DISCOVERY_GET_ALL',
    DISCOVERY_DELETE: 'SAGA_ACTIONS/USER/DISCOVERY_DELETE',
    USECASE_CHALLENGE_STATEMENT: 'SAGA_ACTIONS/USER/USECASE_CHALLENGE_STATEMENT',
    USECASE_PHASE_CHANGE: 'SAGA_ACTIONS/USER/USECASE_PHASE_CHANGE',

    DECISION_GET: 'SAGA_ACTIONS/USER/DECISION_GET',
    DECISION_SAVE: 'SAGA_ACTIONS/USER/DECISION_SAVE',
    USECASE_LIKE: 'SAGA_ACTIONS/USER/USECASE_LIKE',
    GET_USECASE_CAPTAINS: 'SAGA_ACTIONS/USER/GET_USECASE_CAPTAINS',
    ASSIGN_INNOVATION_MANAGER_TO_USECASE: 'SAGA_ACTIONS/USER/ASSIGN_INNOVATION_MANAGER_TO_USECASE',
    GET_NITIFICATIONS: 'SAGA_ACTIONS/USER/GET_NITIFICATIONS',
    GET_NITIFICATION_DETAILS: 'SAGA_ACTIONS/USER/GET_NITIFICATION_DETAILS',

    DASHBOARD: {
      SUMMERY_COUNT: 'SAGA_ACTIONS/USER/DASHBOARD/SUMMERY_COUNT',
      AVG_DURATION_LIST: 'SAGA_ACTIONS/USER/DASHBOARD/AVG_DURATION_LIST',
      PHASE_PLAN: 'SAGA_ACTIONS/USER/DASHBOARD/PHASE_PLAN',  
      PHASE_AVG: 'SAGA_ACTIONS/USER/DASHBOARD/PHASE_AVG',

      AVG_TOTAL_DURATION: 'SAGA_ACTIONS/USER/DASHBOARD/AVG_TOTAL_DURATION',
      AVG_DURATION_BY_SECTION: 'SAGA_ACTIONS/USER/DASHBOARD/AVG_DURATION_BY_SECTION',
      SECTION_PHASE_TABLE: 'SAGA_ACTIONS/USER/DASHBOARD/SECTION_PHASE_TABLE',
      AVG_DURATION_PHASE: 'SAGA_ACTIONS/USER/DASHBOARD/AVG_DURATION_PHASE',
    },

  },
  FORM: {
    GET_WORKFLOWS: 'SAGA_ACTIONS/FORM/GET_WORKFLOWS',
    GET_MODULES: 'SAGA_ACTIONS/FORM/GET_MODULES',
    GET_FORMS: 'SAGA_ACTIONS/FORM/GET_FORMS',
    GET_CONTROL_TYPES: 'SAGA_ACTIONS/FORM/GET_CONTROL_TYPES',
    GET_CONTROL_PROPS: 'SAGA_ACTIONS/FORM/GET_CONTROL_PROPS',
    SAVE: 'SAGA_ACTIONS/FORM/SAVE',
    GET_SOLUTION_ID: 'SAGA_ACTIONS/FORM/GET_SOLUTION_ID',
    ADD_PRODUCT: 'SAGA_ACTIONS/FORM/ADD_PRODUCT',
    GET_PRODUCTS_BY_SOLUTION: 'SAGA_ACTIONS/FORM/GET_PRODUCTS_BY_SOLUTION',
    GET_DYNAMIC_FORM_DATA: 'SAGA_ACTIONS/FORM/GET_DYNAMIC_FORM_DATA',
    UPLOAD_FILE: 'SAGA_ACTIONS/FORM/UPLOAD_FILE',
    UPLOAD_3RD_PARTY_URL: 'SAGA_ACTIONS/FORM/UPLOAD_3RD_PARTY_URL',
    GET_PHASE_STATUS: 'SAGA_ACTIONS/FORM/GET_PHASE_STATUS',
    SET_PHASE_STATUS: 'SAGA_ACTIONS/FORM/SET_PHASE_STATUS',
    PRODUCT: {
      DELETE: 'SAGA_ACTIONS/FORM/PRODUCT/DELETE',
    },
  },

  MASTER: {
    GET_SECTIONS: 'SAGA_ACTIONS/MASTER/GET_SECTIONS',
    GET_PHASES: 'SAGA_ACTIONS/MASTER/GET_PHASES'
  },

  WORKFLOW: {
    GET_SCORING_PRIOTITIZATION_LIST: 'SAGA_ACTIONS/WORKFLOW/GET_SCORING_PRIOTITIZATION_LIST',
    ADD_SCORING_PRIOTITIZATION: 'SAGA_ACTIONS/WORKFLOW/ADD_SCORING_PRIOTITIZATION',
    DELETE_SCORING_PRIOTITIZATION: 'SAGA_ACTIONS/WORKFLOW/DELETE_SCORING_PRIOTITIZATION',
    GET_SCORE_QUESTIONS: 'SAGA_ACTIONS/WORKFLOW/GET_SCORE_QUESTIONS',
    SUBMIT_SCORE_QUESTIONS: 'SAGA_ACTIONS/WORKFLOW/SUBMIT_SCORE_QUESTIONS',
    GET_ALL_PROJECT_PLAN: 'SAGA_ACTIONS/WORKFLOW/GET_ALL_PROJECT_PLAN',
    DELETE_PROJECT_PLAN: 'SAGA_ACTIONS/WORKFLOW/DELETE_PROJECT_PLAN',
    GET_ALL_PROCUREREMENT_PLAN: 'SAGA_ACTIONS/WORKFLOW/GET_ALL_PROCUREREMENT_PLAN',
    DELETE_PROCUREREMENT_PLAN: 'SAGA_ACTIONS/WORKFLOW/DELETE_PROCUREREMENT_PLAN',
    SUBMIT_HANGER_DECISION: 'SAGA_ACTIONS/WORKFLOW/SUBMIT_HANGER_DECISION',
    APPROVE_PRE_SCORING: 'SAGA_ACTIONS/WORKFLOW/APPROVE_PRE_SCORING',
    DAY_IN_LIFE: {
      ADD_MEMBER: 'SAGA_ACTIONS/WORKFLOW/DAY_IN_LIFE/ADD_MEMBER',
      GET_MEMBERS: 'SAGA_ACTIONS/WORKFLOW/DAY_IN_LIFE/GET_MEMBERS',
      DELETE_MEMBER: 'SAGA_ACTIONS/WORKFLOW/DAY_IN_LIFE/DELETE_MEMBER'
    },
    TESTING_ROLES_RESPONSIBILE: {
      LIST_BY_USECASE: 'SAGA_ACTIONS/WORKFLOW/TESTING_ROLES_RESPONSIBILE/LIST_BY_USECASE',
      SAVE: 'SAGA_ACTIONS/WORKFLOW/TESTING_ROLES_RESPONSIBILE/SAVE',
      DELETE: 'SAGA_ACTIONS/WORKFLOW/TESTING_ROLES_RESPONSIBILE/DELETE',
      CRITERIA_BY_USECASE: 'SAGA_ACTIONS/WORKFLOW/TESTING_ROLES_RESPONSIBILE/CRITERIA_BY_USECASE',
      CRITERIA_DELETE: 'SAGA_ACTIONS/WORKFLOW/TESTING_ROLES_RESPONSIBILE/CRITERIA_DELETE',
      CRITERIA_ASSIGN_USER: 'SAGA_ACTIONS/WORKFLOW/TESTING_ROLES_RESPONSIBILE/CRITERIA_ASSIGN_USER',
    },
    UPDATE_START_DATE_END_DATE: 'SAGA_ACTIONS/WORKFLOW/UPDATE_START_DATE_END_DATE',
    GET_START_DATE_END_DATE: 'SAGA_ACTIONS/WORKFLOW/GET_START_DATE_END_DATE',


  },

  COMPANY: {
    LIST: 'SAGA_ACTIONS/COMPANY/LIST',
  },

  TENANT: {
    LIST: 'SAGA_ACTIONS/TENANT/LIST',
  },

  RUNWAY: {
    LIST: 'SAGA_ACTIONS/RUNWAY/LIST',
    DETAILS: 'SAGA_ACTIONS/RUNWAY/DETAILS',
    SAVE: 'SAGA_ACTIONS/RUNWAY/SAVE',
    UPDATE: 'SAGA_ACTIONS/RUNWAY/UPDATE',
    CHANGE_STATUS: 'SAGA_ACTIONS/RUNWAY/CHANGE_STATUS',
    DELETE: 'SAGA_ACTIONS/RUNWAY/DELETE',
  },

  SOLUTION_PROVIDER: {
    LIST: 'SAGA_ACTIONS/SOLUTION_PROVIDER/LIST',
    DETAILS: 'SAGA_ACTIONS/SOLUTION_PROVIDER/DETAILS',
    SAVE: 'SAGA_ACTIONS/SOLUTION_PROVIDER/SAVE',
    UPDATE: 'SAGA_ACTIONS/SOLUTION_PROVIDER/UPDATE',
    CHANGE_STATUS: 'SAGA_ACTIONS/SOLUTION_PROVIDER/CHANGE_STATUS',
    DELETE: 'SAGA_ACTIONS/SOLUTION_PROVIDER/DELETE',
  },

  NOTIFICATION: {
    LIST: 'SAGA_ACTIONS/NOTIFICATION/LIST',
    DETAILS: 'SAGA_ACTIONS/NOTIFICATION/DETAILS', 
    UPDATE: 'SAGA_ACTIONS/NOTIFICATION/UPDATE', 
  },


  SCORE_QUESTION: {
    LIST: 'SAGA_ACTIONS/SCORE_QUESTION/GET_WORKFLOWS',
    DETAILS: 'SAGA_ACTIONS/SCORE_QUESTION/DETAILS',
    SAVE: 'SAGA_ACTIONS/SCORE_QUESTION/SAVE',
    UPDATE: 'SAGA_ACTIONS/SCORE_QUESTION/UPDATE', 
    CHANGE_STATUS: 'SAGA_ACTIONS/SCORE_QUESTION/CHANGE_STATUS', 
    DELETE: 'SAGA_ACTIONS/SCORE_QUESTION/DELETE', 
  },
  CLERK:{
    GET_USERS: 'SAGA_ACTIONS/CLERK/GET_USERS',
  }
}
