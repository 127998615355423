import { call } from 'redux-saga/effects'
import { API_URL, UpdateWithVariables } from '../../_config'
import { CallApi, responseHandler } from '../api/callApi'
import { ApiActionType } from '../../_common/interfaces/ActionExtended'



export function* getUsers(action: ApiActionType): any {
	try {
		const data = action.payload
		let API = UpdateWithVariables(API_URL.CLERK.GET_USERS, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
		const resp: any = yield call(CallApi.GET, API, data)
		let respChecker = responseHandler(resp)
		if (respChecker.status) {
			action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
		} else {
			action && action.callbackError && action.callbackError(respChecker.data)
		}
	} catch (e: any) {
		action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
	}
}