import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useUserApi } from '../../../../_common/hooks/api/UserApiHook';
interface props {
    getData?:(data:any)=>void
}


export const HangerOverviewChallengeStatement = ({getData}:props) => {
    const { id } = useParams();
    const [challengeStatementData, setChallengeStatementData] = useState<any>(null)
    const userApi = useUserApi();

    const getChallengeStatementByUsecase = () => {
        userApi.getChallengeStatementByUsecase({ urlParams: { UseCaseID: id } }, (message: any, resp: any) => {
            console.log('resp', resp)
            setChallengeStatementData(resp.data.challengeStatementList);
            if(getData){
                getData(resp.data.challengeStatementList)
            }
        }, (message: any, resp: any) => {
            setChallengeStatementData([])
        })
    }

    useEffect(() => {
        getChallengeStatementByUsecase()
    }, []);

    return (
        <React.Fragment>
            {
                challengeStatementData && challengeStatementData.length > 0 && (
                    <div className="white-box mb-3">
                        <h6>Challenge Statement</h6> <hr />
                        {
                            challengeStatementData.map((item: any, i: any) => {
                                return (
                                    <div className="usecase-paragraph mb-50" key={'ov_ch_st_' + i}>
                                        <div className="form-area">
                                            <label htmlFor="">{item.question}</label>
                                            <p>{item.answer}</p>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                )
            }
        </React.Fragment>
    )
}
