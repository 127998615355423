import React, { HtmlHTMLAttributes } from 'react'
import { LeftMenu } from './LeftMenu'
import Header from '../header/Header'
import Footer from '../footer/Footer'
import { UseCaseStatusManageFooter } from '../../pages/intectFlow/UseCaseStatusManageFooter';

interface props extends HtmlHTMLAttributes<HTMLDivElement> {
    titleBar?: any;
}
 const Layout:React.FC<props> = ({
    children,
    titleBar,
    ...args
 }) => {
    return (
        <div className='d-flex flex-column min-vh-100'>
        <Header></Header>
        <div className="main-container flex-grow-1">
            <div className="container">
                {/* <div className="page-title py-2"> */}
                    {titleBar}
                {/* </div> */}
                <div className="row">
                    <div className="col-sm-12">
                        <div className="two-panel-wrapper d-flex">
                            <LeftMenu />
                            <div className="right-panel">
                            {children}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <UseCaseStatusManageFooter />
        <Footer></Footer>
        </div>
    )
}

export default Layout;
