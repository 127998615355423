import React, { useEffect, useState } from "react"; 
import { UsecaseList } from '../../components/usecaseList/UsecaseList'; 
import { DataFilters } from '../../components/dataFilters/DataFilters';
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import './intectFlow.scss'
import { URLS } from "../../_config";
import { usecaseFilterData } from "../../_config/dataTypes";
import { isMobile } from 'react-device-detect';
import { UsecaseGrid } from "../../components/usecaseList/UsecaseGrid";
import TitleBar from "../../components/titleBar/TitleBar";
import { useUserPermissions } from "../../_common/hooks/authHook";
import { Button } from "react-bootstrap";
import BulkUsecaseUploadModal from "../../modals/bulkUsecaseUpload";

const IdeaList = () => {
  const [filter, setFilter] = useState<usecaseFilterData | null>(null)
  const [viewType, setViewType] = useState<string>((isMobile) ? 'grid' : 'list')
  const [bulkUploadModal, setBulkUploadModal] = useState<boolean>(false)
  const permissions:any = useUserPermissions();
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState<string>(searchParams.get("search") || '');
  const location = useLocation();

  useEffect(()=>{
    setSearch(searchParams.get("search") || '')
  },[location])

  return (
    <React.Fragment>
      <Header></Header>

      <div className="main-container flex-grow-1">
        <div className="container">


          {/* Bellow the submenu title bar contains breadcrumbs and page title */}

          <TitleBar title={'Use case library'} breadcrumbs={{
            'Use case library': '#'
          }} >
            <div className="d-flex justify-content-between align-items-center gap-2">
              <div className="btn-group ms-auto">
                <Button size="sm" onClick={() => setViewType('list')}>List</Button>
                <Button size="sm" onClick={() => setViewType('grid')}>Grid</Button>
              </div>
              {permissions?.actions.usecase &&
                <>
                  <Link to={URLS.USE_CASE_CREATE} className="btn btn-info add-more-files-btn btn-sm">Create Usecase</Link>
                  <Button size="sm" onClick={()=>{setBulkUploadModal(true)}} >Bulk Upload</Button>
                </>
              }
            </div>
          </TitleBar>

          <div className="row">
            <div className="col-sm-12">
              <DataFilters setFilter={setFilter} />  {/* filter for use-case list, collect filter values and set it to this component via setFilter */}

              {filter != null && (
                <div className="white-box px-4 pt-3">
                  {
                    (viewType == 'list') ? <UsecaseList filter={filter} search={search} /> : <UsecaseGrid filter={filter} search={search}/>  /* control view type */
                  }
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <BulkUsecaseUploadModal show={bulkUploadModal} onClose={()=>{setBulkUploadModal(false)}}/>
      <Footer></Footer>
    </React.Fragment>
  );
}

export default IdeaList;