import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useUserApi } from '../../../../_common/hooks/api/UserApiHook';
import { showDate } from '../../../../_config/functions';
import { useUser } from '../../../../_common/hooks/authHook';
import Icon from '../../../../components/icon';
import { getNameOnly } from '../../../../_config/helpers';

interface props {
    getData?:(data:any)=>void
}


export const HangerOverviewDiscovery = ({getData}:props) => {
    const { id } = useParams();
    const userApi = useUserApi();
    const user: any = useUser();

    const [discoveryData, setDiscoveryData] = useState<any>(null)

    const getAllDiscovery = () => {
        userApi.getAllDiscovery({ urlParams: { CompanyID: user.companyID, UseCaseID: id }, PageNo: 1, PageSize: 999 }, (message: any, resp: any) => {
            setDiscoveryData(resp.data.discoveryList);
            if(getData){
                getData(resp.data.discoveryList)
            }
            
        }, (message: any, resp: any) => {
            setDiscoveryData([])
        })
    }

    useEffect(() => {
        getAllDiscovery()
    }, []);


    return (
        <React.Fragment>
            {
                discoveryData && discoveryData.length > 0 && (
                    <div className="white-box mb-3">
                        <h6>Discovery</h6>
                        <hr />
                        <div className="row">
                            {

                                discoveryData.map((item: any, index: number) =>
                                    <div className="col-md-4 mb-3" key={Math.random()}>
                                        <div className="overview_box">
                                            <div className="d-flex ">
                                                <div className="circel">{index + 1}</div>
                                                <div className="p-0 m-0">
                                                    <p className="mb-2">
                                                        <strong>{item.discovery}</strong>
                                                    </p>
                                                    <p className="mb-2">Uploaded by {getNameOnly(item.userFullName)} on {showDate(item.addedDate)}</p>
                                                    <p className="d-flex align-items-center mb-2">
                                                        <strong className="me-2">Download:</strong>
                                                        <a href={item.fileURL} download target='_blank'
                                                        >
                                                            <i className="upload-icon"></i>
                                                        
                                                        </a>
                                                    </p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                )
            }

        </React.Fragment>

    )
}
