import React, { useEffect, useState } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import { useUserApi } from "../../_common/hooks/api/UserApiHook";
import { Link, useNavigate, useParams } from "react-router-dom";
import toast from 'react-hot-toast';
import { useForm, SubmitHandler, Controller } from "react-hook-form"
import { useUser } from "../../_common/hooks/authHook";
import { URLS } from "../../_config";
import TitleBar from "../../components/titleBar/TitleBar";


import Editor from 'react-simple-wysiwyg';

export const NotificationEdit = () => {

    const navigate = useNavigate();
    const userApi = useUserApi();
    const userInfo: any = useUser();
    const { id } = useParams();


    type Inputs = {
        notificationTemplateID: any
        title: string
        subject: string
        templateHTML: string
    }

    const {
        control,
        register,
        handleSubmit,
        watch,
        reset,
        formState: { errors },
    } = useForm<Inputs>()

    const getNotificationDetails = (id: any) => {

        userApi.notificationDetails({ urlParams: { NotificationTemplateID: id } }, (message: any, resp: any) => {
            reset({
                notificationTemplateID: resp.data.notificationTemplateID,
                title: resp.data.title,
                subject: resp.data.subject,
                templateHTML: resp.data.templateHTML,
            })
        }, (message: any) => {
            // Error...
        })
    }

    useEffect(() => {
        if (id) {
            getNotificationDetails(id)
        }
    }, [id]);



    const submitData = (data: any) => {
        let formParam = {
            ...data,  
        };
        return new Promise((resolve, reject) => {
            if (id) {
                formParam.roleID = id;
                userApi.notificationUpdate(formParam, (message: any, resp: any) => {
                    resolve(message);
                }, (message: any, resp: any) => {
                    reject(message);
                })

            } else {

                userApi.addRole(formParam, (message: any, resp: any) => {
                    resolve(message);
                }, (message: any, resp: any) => {
                    reject(message);
                })
            }
        })
    }

    const onSubmit: SubmitHandler<Inputs> = (data) => {

        toast.promise(
            submitData(data), {
            loading: 'Loading...',
            success: (msg: any) => { navigate(URLS.NOTIFICATION.LIST); return <b>{msg}</b> },
            error: (msg: any) => { return <b>{msg}</b> },
        }
        );
    }

    return (
        <React.Fragment>
            <Header></Header>

            <div className="main-container flex-grow-1">
                <div className="container">

                    <TitleBar title={'Email & Notifications'} breadcrumbs={{
                        'Settings ': '#',
                        'Email & Notifications ': URLS.NOTIFICATION.LIST,
                        'Edit Notification ': '#',
                    }} >
                    </TitleBar>

                    <div className="row">
                        <div className="col-sm-8">
                            <div className="white-box h-100">
                                <div className="form-area">
                                    <form className="row g-3" onSubmit={handleSubmit(onSubmit)}>

                                        <div className="col-md-6">
                                            <label htmlFor="rolename" className="form-label">Title</label>
                                            <input {...register("title", { required: "Role name is required" })} type="text" className="form-control" disabled />
                                            {errors.title && <span className="text-danger">{errors.title.message}</span>}
                                        </div>

                                        <div className="col-md-6">
                                            <label htmlFor="roledisplayname" className="form-label">Subject</label>
                                            <input type="text" {...register("subject", { required: "Subject is required" })} className="form-control" placeholder="Put subject" id="roledisplayname" />
                                            {errors.subject && <span className="text-danger">{errors.subject.message}</span>}
                                        </div>

                                        <div className="col-md-12">
                                            <label htmlFor="roledisplayname" className="form-label">Template HTML</label>
                                            <Controller
                                                defaultValue=""
                                                control={control}
                                                name="templateHTML"
                                                rules={{ required: true }}
                                                render={({ field: { onChange, value, ref } }) => (
                                                    <Editor value={value} onChange={onChange} />
                                                )}
                                            />
                                            
                                            {/* {...register("templateHTML", { required: "Template HTML is required" })} */}
                                            {errors.templateHTML && <span className="text-danger">{errors.templateHTML.message}</span>}
                                        </div>


                                        <div className="col-12 d-flex justify-content-end gap-3">
                                            <Link to={URLS.NOTIFICATION.LIST} className="btn btn-dark mr-2">Back</Link>
                                            <button type="submit" className="btn btn-info d-flex align-items-center gap-2">Submit <i className="arrow-right"></i></button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="pt-5">
                                <img src="../images/idea-pic.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer></Footer>
        </React.Fragment>
    )
}
