import React, { useEffect, useState } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import Pagination from "../../components/pagination/Pagination";
import { useUserApi } from "../../_common/hooks/api/UserApiHook";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import toast from 'react-hot-toast';
import { useForm, SubmitHandler } from "react-hook-form"
import { useUser } from "../../_common/hooks/authHook";
import { URLS } from "../../_config";
import TitleBar from "../../components/titleBar/TitleBar";

export const RoleAddEdit = () => {

    const navigate = useNavigate();
    const userApi = useUserApi();
    const userInfo: any = useUser();
    const { id } = useParams();
    const paramRole: any = id;
    console.log('userInfo', userInfo);

    type Inputs = {
        title: string
        displayTitle: string
    }

    const {
        register,
        handleSubmit,
        watch,
        reset,
        formState: { errors },
    } = useForm<Inputs>()

    const getRoleByID = (id: any) => {
        let param = {
            RoleID: id,
            FKCompanyID: userInfo?.companyID
        }

        userApi.detailsRole(param, (message: any, resp: any) => {
            reset({
                title: resp.data.title,
                displayTitle: resp.data.displayTitle,
            })

        }, (message: any, resp: any) => {
            // Error...
        })
    }

    useEffect(() => {
        if (id) {
            getRoleByID(id)
        }
    }, [id]);



    const submitData = (data: any) => {
        let formParam = {
            ...data,
            "isPlatform": true,
            "fkCompanyID": userInfo?.companyID,
        };
        return new Promise((resolve, reject) => {
            if (id) {
                formParam.roleID = id;
                userApi.editRole(formParam, (message: any, resp: any) => {
                    resolve(message);
                }, (message: any, resp: any) => {
                    reject(message);
                })

            } else {

                userApi.addRole(formParam, (message: any, resp: any) => {
                    resolve(message);
                }, (message: any, resp: any) => {
                    reject(message);
                })
            }
        })
    }

    const onSubmit: SubmitHandler<Inputs> = (data) => {

        toast.promise(
            submitData(data), {
            loading: 'Loading...',
            success: (msg: any) => { navigate('/role/list'); return <b>{msg}</b> },
            error: (msg: any) => { return <b>{msg}</b> },
        }
        );
    }

    return (
        <React.Fragment>
            <Header></Header>

            <div className="main-container flex-grow-1">
                <div className="container">

                    <TitleBar title={'User Roles'} breadcrumbs={{
                        'Settings ': '#',
                        'Roles ': URLS.ROLE.LIST,
                        'Add Reole ': '#',
                    }} >
                    </TitleBar>

                    <div className="row">
                        <div className="col-sm-6">
                            <div className="white-box h-100">
                                <div className="form-area">
                                    <form className="row g-3" onSubmit={handleSubmit(onSubmit)}>

                                        <div className="col-md-6">
                                            <label htmlFor="rolename" className="form-label">Role Name</label>
                                            <input {...register("title", { required: "Role name is required" })} type="text" className="form-control" placeholder="Put role name" id="rolename" />
                                            {errors.title && <span className="text-danger">{errors.title.message}</span>}
                                        </div>

                                        <div className="col-md-6">
                                            <label htmlFor="roledisplayname" className="form-label">Role Display Name</label>
                                            <input type="text" {...register("displayTitle", { required: "Display name is required" })} className="form-control" placeholder="Put display name" id="roledisplayname" />
                                            {errors.displayTitle && <span className="text-danger">{errors.displayTitle.message}</span>}
                                        </div>


                                        <div className="col-12 d-flex justify-content-end gap-3">
                                            <Link to={URLS.ROLE.LIST} className="btn btn-dark mr-2">Back</Link>
                                            <button type="submit" className="btn btn-info d-flex align-items-center gap-2">Submit <i className="arrow-right"></i></button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="pt-5">
                                <img src="../images/idea-pic.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer></Footer>
        </React.Fragment>
    )
}
