import React, { useEffect, useRef, useState } from 'react'
import Header from '../../components/header/Header'
import Footer from '../../components/footer/Footer'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useUserApi } from '../../_common/hooks/api/UserApiHook'
import Error from '../../components/elements/formFieldError'
import { URLS } from '../../_config'
import { useUser, useUserPermissions } from '../../_common/hooks/authHook'
import Select from "react-select"
import { getSelectedValue } from '../../_config/helpers'
import TitleBar from '../../components/titleBar/TitleBar'

export const AddEmployee = () => {

    const { control, register, handleSubmit, reset, setValue,  formState: { errors } } = useForm()
    const navigate = useNavigate()

    const userInfo: any = useUser();

    const userApi = useUserApi()
    const params: any = useParams();

    const location = useLocation();
    const permissions: any = useUserPermissions();
    const searchParams = new URLSearchParams(location.search);
    const returnTo = searchParams.get('return');

    const [empId, setEmpId] = useState<any>(params?.id);

    const [roles, setRoles] = useState<Array<any>>([]);
    const [empDetails, setEmpDetails] = useState<any>({});
    const [previewImg, setPreviewImg] = useState<any>(null);

    const getUserDetails = () => {
        userApi.userDetails({ urlParams: { UserProfID: empId } }, (message: any, resp: any) => {
            reset({
                Title: resp.data.title,
                FirstName: resp.data.firstName,
                LastName: resp.data.lastName,
                DisplayName: resp.data.displayName,
                Email: resp.data.email,
                Phone: resp.data.phone,
                Bio: resp.data.bio,
                fkRoleID: getSelectedValue(resp.data.fkRoleID,'value',roles),
            })
            setEmpDetails(resp.data);
            setPreviewImg(resp.data?.avatar)
        }, (message: any, resp: any) => {
            // Error...
        })
    }


    const getRoleList = () => {
        userApi.listRole({ FKCompanyID: userInfo.companyID }, (message: any, resp: any) => {
            setRoles(resp.data.roles.map((role:any)=>{
                role.label = role.displayTitle
                role.value = role.roleID
                return role;
            }));
        }, (message: any, resp: any) => {
            setRoles([])
        })
    }

    useEffect(() => {
        if (empId && roles) {
            setValue('sendNotification',false)
            getUserDetails()
        } else {
            setValue('sendNotification',true)
        }
    }, [empId, roles]);

    useEffect(()=>{
        getRoleList();
    },[])


    const convertBase64 = (file: any) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                resolve(fileReader.result);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };


    const submitData = (data: any) => {
        return new Promise((resolve, reject) => {
            let params:any = {};
            params.Title = data.Title
            params.FirstName = data.FirstName
            params.LastName = data.LastName
            params.DisplayName = [data.Title, data.FirstName, data.LastName].join(' ')
            params.Phone = data.Phone
            params.Bio = data.Bio
            params.fkRoleID = data.fkRoleID?.value
            params.FKCompanyID = data.FKCompanyID
            params.FKTenantID = data.FKTenantID
            params.FKWorkForID = data.FKWorkForID
            params.FKJobTitleID = data.FKJobTitleID
            params.Password = 'Host@123456'
            params.Email = data.Email

            if (!empId) {
                params.isSendInvitation = data.sendNotification
                params.Username = data.Email
                params.addedBy = userInfo.userID
            } else {
                params.userProfID = empId
                params.fkUserID = empDetails && empDetails.fkUserID;
                params.isActive = true
                params.updatedBy = userInfo.userID
            }


       

            if (data.Image) {
                convertBase64((document.getElementById('employeeImage') as any).files[0]).then((base64File) => {
                    params.Avatar = base64File;
                    if (!empId) {
                        userApi.addEmployee(params, resolve, reject)
                    } else {
                        userApi.userUpdate(params, resolve, reject)
                    }
                }).catch(()=>{
                    toast.error('Something is wrong. please check image format')
                })
            } else {
                if (!empId) {
                    userApi.addEmployee(params, resolve, reject)
                } else {
                    userApi.userUpdate(params, resolve, reject)
                }
            }
        })
    }

    const onSubmit = (data: any) => {
        toast.promise(
            submitData(data), {
            loading: 'Saving...',
            success: (msg: any) => {
                reset();
                if (returnTo && returnTo == 'profile') {
                    userApi.getMemberDetails({}, (message: any, resp: any) => {
                        navigate(URLS.PROFILE_VIEW);
                    }, (message: any) => {
                        navigate(URLS.PROFILE_VIEW);
                    })
                } else {
                    navigate(URLS.EMPLOYEE_LIST);
                }

                return <b>{msg}</b>
            },
            error: (msg: any) => { return <b>{msg}</b> },
        });
    }



    return (

        <React.Fragment>
            <Header></Header>
            <div className="main-container flex-grow-1">
                <div className="container">
                    {(returnTo && returnTo == 'profile')?
                    <TitleBar title={'Edit Profile'} breadcrumbs={{
                        'Profile': URLS.PROFILE_VIEW,
                        'Edit Profile': '#'
                    }} />
                    :
                    <TitleBar title={`${(empId > 0) ? 'Edit' : 'Add'} Employee Data`} breadcrumbs={{
                        'Employee List': URLS.EMPLOYEE_LIST,
                        'Employee Data': '#'
                    }} />
                    }
      

                    <div className="row">
                        <div className="col-sm-8">
                            <div className="white-box">
                                <div className="form-area">
                                    <form className="row g-3" onSubmit={handleSubmit(onSubmit)} >
                                        <div className={'col-md-6'} >
                                            <label htmlFor="" className="form-label">Title<span className='text-danger'>*</span></label>
                                            <Controller
                                                control={control}
                                                name="Title"
                                                rules={{ required: true }}
                                                render={({ field: { onChange, value, ref } }) => (
                                                    <input type="text" className="form-control" placeholder={''} ref={ref} onChange={onChange} value={value} />
                                                )}
                                            />
                                            <Error error={errors['Title']} />
                                        </div>

                                        <div className={'col-md-6'} >
                                            <label htmlFor="" className="form-label">First Name<span className='text-danger'>*</span></label>
                                            <Controller
                                                
                                                control={control}
                                                name="FirstName"
                                                rules={{ required: true }}
                                                render={({ field: { onChange, value, ref } }) => (
                                                    <input type="text" className="form-control" placeholder={''} ref={ref} onChange={onChange} value={value} />
                                                )}
                                            />
                                            <Error error={errors['FirstName']} />
                                        </div>

                                        <div className={'col-md-6'} >
                                            <label htmlFor="" className="form-label">Last Name<span className='text-danger'>*</span></label>
                                            <Controller
                                                
                                                control={control}
                                                name="LastName"
                                                rules={{ required: true }}
                                                render={({ field: { onChange, value, ref } }) => (
                                                    <input type="text" className="form-control" placeholder={''} ref={ref} onChange={onChange} value={value} />
                                                )}
                                            />
                                            <Error error={errors['LastName']} />
                                        </div>


                                        <div className={'col-md-6'} >
                                            <label htmlFor="" className="form-label">Email<span className='text-danger'>*</span></label>
                                            <Controller
                                                control={control}
                                                name="Email"
                                                rules={{ required: true }}
                                                render={({ field: { onChange, value, ref } }) => (
                                                    <input type="text" className="form-control" placeholder={''} ref={ref}
                                                        onChange={onChange} value={value} disabled={empId > 0} />
                                                )}
                                            />
                                            <Error error={errors['Email']} />
                                        </div>

                                        <div className={'col-md-6'} >
                                            <label htmlFor="" className="form-label">Phone</label>
                                            <Controller
                                                
                                                control={control}
                                                name="Phone"
                                                rules={{ required: false }}
                                                render={({ field: { onChange, value, ref } }) => (
                                                    <input type="text" className="form-control" placeholder={''} ref={ref} onChange={onChange} value={value} />
                                                )}
                                            />
                                            <Error error={errors['Phone']} />
                                        </div>

                                        <div className="col-md-6">
                                            <label htmlFor="fkRoleID" className="form-label">Primary Role<span className='text-danger'>*</span></label>
                                            <Controller
                                                
                                                control={control}
                                                name="fkRoleID"
                                                rules={{ required: "Role is required"  }}
                                                render={({ field: { onChange, value, ref, name } }) => (
                                                    <Select
                                                        name={name}
                                                        options={roles}
                                                        onChange={onChange}
                                                        value={value}
                                                        isDisabled={!permissions?.menus.employee.write}
                                                    />
                                                    
                                                )}
                                            />

                                            {/* 
                                            
                                            <select className="form-control"  disabled={!permissions?.menus.employee.write}>
                                                        <option value=''>Select one...</option>
                                                        {
                                                            (roles && roles.length > 0) &&
                                                            roles.map((item: any) => item?.roleID != 1 &&
                                                                <option value={item?.roleID} key={item?.roleID}
                                                                    selected={(empId) ? ((empDetails.fkRoleID == item?.roleID) ? true : false) : (item?.displayTitle == 'Innovator')}>
                                                                    {item?.displayTitle}
                                                                </option>
                                                            )
                                                        }
                                                    </select>
                                            */}
                                            
                                            <Error error={errors['fkRoleID']} />
                                        </div>


                                        <div className={'col-md-12'} >
                                            <label htmlFor="" className="form-label">Bio</label>
                                            <Controller
                                                
                                                control={control}
                                                name="Bio"
                                                rules={{ required: false }}
                                                render={({ field: { onChange, value, ref } }) => (
                                                    <textarea className="form-control" ref={ref} onChange={onChange} value={value} placeholder={'Enter bio...'} rows={3}></textarea>
                                                )}
                                            />
                                            <Error error={errors['Bio']} />
                                        </div>

                                        <div className="col-md-12">
                                            <div className="custom-file-uploader mb-4">
                                                <Controller
                                                    
                                                    control={control}
                                                    name={'Image'}
                                                    rules={{ required: false, }}
                                                    render={({ field: { onChange, value, ref, name } }) => (
                                                        <input id="employeeImage" type="file" name={name} ref={ref}
                                                            accept="image/*"
                                                            onChange={(e: any) => {setPreviewImg(URL.createObjectURL(e.target.files[0])); onChange(e)}}
                                                            value={value} />
                                                    )}
                                                />
                                                {
                                                    (previewImg == null) ?
                                                        <>
                                                            <i className="file-upload-icon"></i>
                                                            <p className="mb-0">Click to upload.</p>
                                                        </>
                                                        : <img src={previewImg} alt="" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                                                }

                                            </div>
                                            <Error error={errors['Image']} />
                                        </div>
                                        {!empId &&
                                            <div className="col-md-12">
                                                <div className="d-flex gap-3 align-items-center">
                                                    <div className="align-items-center d-flex gap-2">
                                                        <Controller

                                                            control={control}
                                                            name={`sendNotification`}

                                                            rules={{
                                                                required: false //field.required ? field.required : false,
                                                            }}
                                                            render={({ field: { onChange, value, ref, name } }) => (
                                                                <input className="form-check-input m-0" type="checkbox" name={name} value={1} checked={value} ref={ref} onChange={(e) => {
                                                                    onChange(e)
                                                                }} />
                                                            )}
                                                        />


                                                        <label className="form-check-label" >
                                                            Send invitation
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        }



                                        <input type="hidden" value={1} {...register('FKCompanyID')} />
                                        <input type="hidden" value={0} {...register('FKTenantID')} />
                                        <input type="hidden" value={1} {...register('FKJobTitleID')} />
                                        <input type="hidden" value={1} {...register('FKWorkForID')} />


                                        <div className="col-12 d-flex justify-content-end gap-3">
                                            <Link to={(returnTo && returnTo == 'profile') ?
                                                (URLS.PROFILE_VIEW) : (URLS.EMPLOYEE_LIST)} className={`btn btn-info d-flex align-items-center gap-2`} >Back</Link>

                                            <button type="submit" className={`btn btn-primary d-flex align-items-center gap-2`} >{(empId > 0) ? 'Update' : 'Add'} { returnTo == 'profile'?'Profile':'Employee'} <i className="arrow-right"></i></button>
                                        </div>
                                    </form>
                                </div>
                                {/* <DynamicForm config={config} onSubmitSuccess={()=>{navigate('/employee-list')}}/> */}
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="pt-5">
                                <img src="/../images/idea-pic.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </React.Fragment>

    )
}
