import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useUserApi } from '../../../../_common/hooks/api/UserApiHook';
import { useWorkFlowApi } from '../../../../_common/hooks/api/WorkFlowApiHook';
import { useUser } from '../../../../_common/hooks/authHook';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import Error from '../../../../components/elements/formFieldError';
import { Button } from 'react-bootstrap';

interface props {
    onSubmitSuccess: () => void
    scorer: any
}

export default function AddScore({ onSubmitSuccess, scorer }: props) {
    const { id } = useParams();
    const user: any = useUser();
    const [scoreQuestions, setScoreQuestions] = useState<any>(null)
    const workflowApi = useWorkFlowApi();

    const { control, register, handleSubmit, reset, getValues, formState: { errors, isValid }, } = useForm()

    // /api/v{version}/ChallengeStatement/getChallengeStatementByUsecase/{UseCaseID}
    const getScoreQuestions = () => {
        workflowApi.getScoreQuestions({ urlParams: { companyID: user.companyID } }, (message: any, resp: any) => {
            console.log('resp-----------------------------', resp.data.scoreQuestions)
            setScoreQuestions(resp.data.scoreQuestions);
        }, (message: any, resp: any) => {
            // Error...
        })
    }

    const addScore = (data: any) => {
        return new Promise((resolve, reject) => {
            let answerIndexes = ['', 'low', 'medium', 'high']
            let params: any = {
                fkUseCaseID: id,
                fkUserID: scorer.fkUserID,
                fkScoringPrioritizationUserID: scorer.scoringPrioritizationUserID,
                fkCompanyID: user.companyID,
                fkTenantID: user.tenantID,
                addedBy: user.userID,
                useCaseScoreAnswer: Object.keys(data).map((questionKey: any) => {
                    let qset: any = JSON.parse(data[questionKey]);
                    return {
                        scoreQuestionID: qset.scoreQuestionID,
                        number: qset[`${qset.answer}Number`],
                        choice: answerIndexes.indexOf(qset.answer) //1/2/3

                    }
                })
            };



            console.log('params=>', params)
            workflowApi.submitScoreQuestions(params, (msg: any, resp: any) => {
                resolve(msg)
            }, (msg: any) => {
                reject(msg)
            })
        })
    }


    const handleAddScore = (data: any) => {
        if (isValid) {
            toast.promise(
                addScore(data), {
                loading: 'Saving...',
                success: (msg: any) => {
                    reset();
                    onSubmitSuccess();
                    return <b>{msg}</b>
                },
                error: (msg: any) => { return <b>{msg}</b> },
            });
        } else {
            console.log('Not valid')
        }
    }

    useEffect(() => {
        if (user) { getScoreQuestions() }
    }, [user]);

    return (
        <React.Fragment>
            <form className="form-area" id="addScoreForm" onSubmit={handleSubmit(handleAddScore)}>
                {scoreQuestions?.map((question: any, i: any) => {
                    let name = `store-q-${i}`
                    return (
                        <div key={`score-q-${i}`} className='mb-4'>
                            <h6 style={{ color: '#b32f84' }}>{question.criteria}</h6>
                            <label><b>{question.question}</b></label>
                            {question.low != '' &&
                                <div className="align-items-start d-flex form-check gap-4">
                                    <input type="radio" value={JSON.stringify({ ...question, ...{ answer: 'low' } })} className="mt-1" {...register(name, { required: true })} />
                                    <label>{question.low}</label>
                                </div>
                            }
                            {question.medium != '' &&
                                <div className="align-items-start d-flex form-check gap-4">
                                    <input type="radio" value={JSON.stringify({ ...question, ...{ answer: 'medium' } })} className="mt-1" {...register(name, { required: true })} />
                                    <label>{question.medium}</label>
                                </div>
                            }
                            {question.high != '' &&
                                <div className="align-items-start d-flex form-check gap-4">
                                    <input type="radio" value={JSON.stringify({ ...question, ...{ answer: 'high' } })} className="mt-1" {...register(name, { required: true })} />
                                    <label>{question.high}</label>
                                </div>
                            }

                            {question.answer4 != '' &&
                                <div className="align-items-start d-flex form-check gap-4">
                                    <input type="radio" value={JSON.stringify({ ...question, ...{ answer: 'answer4' } })} className="mt-1" {...register(name, { required: true })} />
                                    <label>{question.answer4}</label>
                                </div>
                            }
                            {question.answer5 != '' &&
                                <div className="align-items-start d-flex form-check gap-4">
                                    <input type="radio" value={JSON.stringify({ ...question, ...{ answer: 'answer5' } })} className="mt-1" {...register(name, { required: true })} />
                                    <label>{question.answer5}</label>
                                </div>
                            }

                            <Error error={errors[name]} />
                            <hr />
                        </div>
                    )
                })}

                {/*  <div className="row">
                    <div className={'col-md-6'} >
                        <label htmlFor="" className="form-label">Start Date</label>
                        <Controller
                            defaultValue=""
                            control={control}
                            name={'startDate'}
                            rules={{ required: 'Start date is required' }}
                            render={({ field: { onChange, value, ref } }) => (
                                <input type="date" className="form-control" placeholder='yyyy-mm-dd' ref={ref} onChange={onChange} value={value} />
                            )}
                        />
                        <Error error={errors['startDate']} />
                    </div>
                    <div className={'col-md-6'} >
                        <label htmlFor="" className="form-label">End Date</label>
                        <Controller
                            defaultValue=""
                            control={control}
                            name={'endDate'}
                            rules={{ required: 'End date is required' }}
                            render={({ field: { onChange, value, ref } }) => (
                                <input type="date" className="form-control" placeholder='yyyy-mm-dd' ref={ref} onChange={onChange} value={value} />
                            )}
                        />
                        <Error error={errors['startDate']} />
                    </div>
                </div> */}


                <div className="d-flex justify-content-end px-10">
                    <Button type='submit'>Submit</Button>
                </div>
            </form>
        </React.Fragment>

    )
}
