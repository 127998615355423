/* 
    this component use for chart circle in dashboard
*/

import React, { HtmlHTMLAttributes, useEffect, useRef } from 'react';
import './PrgressCircle.scss'

interface props extends HtmlHTMLAttributes<HTMLDivElement> {
    deg: number,
    phaseName?: string,
    count?: number,
    totalCount?: number,
    color1?: string,
    color2?: string,
    onClick?:any
}
const PrgressCircle: React.FC<props> = ({
    children,
    deg,
    phaseName,
    count,
    totalCount,
    color1,
    color2,
    onClick,
    ...args
}) => {



    return (
        <div className="progress-block flex-1 d-flex flex-column justify-content-between" onClick={()=>{
            if(onClick){
                onClick()
            }
        }}>
            <span className="progress-heading">{phaseName}</span>

            <div className='position-relative graph-content'  >
                <div className="multigraph">
                    <span className="multigraph-tooltip shadow-none">{count}</span>
                    <span className={`graph ${color1}`}>
                        <div className={`graph-pogress ${color1}`} style={{ transform: `rotate(${deg}deg)` }} ></div>
                    </span>
                    <div className="min-max-no">
                        <span>0</span>
                        <span>{totalCount}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PrgressCircle;
