import React, { useEffect } from "react";
import { Link, useLocation, useNavigate, useNavigation } from 'react-router-dom';
import { useForm, SubmitHandler } from "react-hook-form"
import './Auth.scss'
import toast from 'react-hot-toast';
import { useUserApi } from "../../_common/hooks/api/UserApiHook";
import { URLS } from "../../_config";

const ResetPassword = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const resetId = searchParams.get('ResetId');   
    
    const userApi= useUserApi();
    type Inputs = {
        newPassword: string
        confirmNewPassword: string
    }

    const {
        register,
        handleSubmit,
        watch,
        getValues,
        formState: { errors },
    } = useForm<Inputs>()


    const submitData = (data:any) =>{     
        let formParam = {                     
            ...data,
            "token": resetId? resetId: ''
        };   
        return new Promise((resolve,reject)=>{           
            userApi.resetPassword(formParam,(message:any, resp:any)=>{           
                resolve(resp.message);  
            },(message:any, resp:any)=>{
                reject(resp.message);  
            })           
        })
    }

    const onSubmit: SubmitHandler<Inputs> = (data) => {
        toast.promise(
            submitData(data), {
            loading: 'Resetting...',
            success: (msg: any) => { navigate('/'); return <b>{msg}</b> },
            error: (msg: any) => { return <b>{msg}</b> },
        });
    }

    const checkPasswordMatch = (value: string, password: string): string | undefined => {
        if (!value) {
            return "Confirm password is required";
        }
        return password === value ? undefined : "Password Mismatch";
    }


    const checkStrength = (password:string) => {
        let strength = 0;
        if (password.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/)) {
            strength += 1;
        }
        if (password.match(/([0-9])/)) {
            strength += 1;
        }
        if (password.match(/([!,%,&,@,#,$,^,*,?,_,~])/)) {
            strength += 1;
        }
        if (password.length > 7) {
            strength += 1;
        }
    
        return strength == 4
    }

    useEffect(()=>{
        if(resetId == null || resetId == undefined){
            navigate(URLS.LOGIN)
        }
    },[resetId])


    return (
        <div className="login_page">
            <div className="loin_area">
                <div className="login_logo d-flex justify-content-center mb-2">
                    <Link to="/"><img src="./images/logo.png" /></Link>
                </div>
                <h3 className="h3">Welcome!</h3>
                <p>Reset your password</p>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="login_content">
                        <div className="loin_input">
                            <input type="password" {...register("newPassword", { required: "New password is required", validate:(str:string)=>{return checkStrength(str) || 'password Minimum 8 and maximum 100 characters, at least one uppercase letter, one lowercase letter, one number and one special character'} })} placeholder="New Password" className="input_box" />
                            <span className="password-icon position-absolute right-0 top-0"></span>
                        </div>
                        {errors.newPassword && <span className="text-danger">{errors.newPassword.message}</span>}
                        <div className="loin_input">
                            <input type="password" {...register("confirmNewPassword", { required: "Confirm password is required", validate: (value) => checkPasswordMatch(value, getValues("newPassword")) })} placeholder="Confirm Password" className="input_box" />
                            <span className="password-icon position-absolute right-0 top-0"></span>
                        </div>
                        {errors.confirmNewPassword && <span className="text-danger">{errors.confirmNewPassword.message}</span>}
                    </div>
                    <div className="d-flex justify-content-between align-items-baseline mt-4 pt-2">
                        <p><Link to="/" className="text-purple text-sm"><i>Back to login</i></Link></p>
                        <button type="submit" className="btn btn-primary d-flex justify-content-between align-items-center py-2 px-4 gap-2">Send <i className="arrow-right"></i></button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default ResetPassword;