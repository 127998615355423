import Loader from '../../components/loader'
import { useAppLoaderSelector } from '../hooks/loaderSelector'
import './appLoader.scss'
function AppLoader() {
  const show = useAppLoaderSelector()
  return show ? 
  <div className="app-loader-container">
   
     <Loader />
    
  </div>
  
  
  
  : null
}

export default AppLoader
