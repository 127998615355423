import { useApiCall } from '../common/appApiCallHook'
import { SAGA_ACTIONS } from '../../../_config/index'
import { useAuth } from '@clerk/clerk-react';

export function useClerkApi() {
	const callApi = useApiCall();
	const { getToken } = useAuth();

	return {
		getUsers: (data: any, onSuccess: Function, onError: Function) => {
			callApi(SAGA_ACTIONS.CLERK.GET_USERS, data, onSuccess, onError)
		},
		clerk : async (args:any) => {
			return fetch(args , {
				headers: { Authorization: `Bearer ${await getToken()}` }
			}).then(res => res.json());
		}
	}
}



