import Layout from '../../../components/workflow/Layout'
import { PageMenu } from '../../../components/workflow/PageMenu'
import { Link, useNavigate, useParams } from 'react-router-dom'
import DynamicForm from '../../../components/dynamicForm/dynamicForm'
import { replaceUrlParams } from '../../../_config/helpers'
import { URLS } from '../../../_config'
import { useUser } from '../../../_common/hooks/authHook'
import { useUserApi } from '../../../_common/hooks/api/UserApiHook'
import TitleBar from '../../../components/titleBar/TitleBar'
import Timer from '../../../components/timer/Timer'
import { useUsecase } from '../../../_common/hooks/usecaseHook'


export const SolutionFraming = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const userInfo: any = useUser();
    const userApi = useUserApi();
    const usecase: any = useUsecase();

    const getUsecase = () => {
        return new Promise((resolve, reject) => {
            userApi.useCaseDetails({ urlParams: {companyID:userInfo.companyID, id: id, userID: userInfo.userID } }, (message: any, resp: any) => {
                resolve(true)
            }, (message: any) => { reject(message) })
        })

    }

    return (
        <Layout titleBar={
            <TitleBar title={`Solution Framing | usecase : ${usecase?.useCase}`} breadcrumbs={{
                'Use case list': URLS.USE_CASE_LIST,
                'Use case Details': replaceUrlParams(URLS.USECASE_DETAILS, { id: id }),
                'Solution Framings': '#'
            }} >
                <Timer startTime={new Date()} />
            </TitleBar>
        }>

            <PageMenu pageTab="SolutionFraming" />
            <div className="white-box">
                <DynamicForm sded={true} phaseStatus='Solution Framing'  _workflow='hangar' useCaseID={id} _module='create-solution-provider' section='Hanger' editMode={true} phase='Solution Framing' _form='solution-provider' onSubmitSuccess={(edit) => {
                    if (edit) {
                        getUsecase().then(() => {
                            //navigate(replaceUrlParams(URLS.WORKFLOW.HANGER.SCORE, { id: id }))
                        })
                    } else {
                        getUsecase().then(() => {
                            //navigate(replaceUrlParams(URLS.WORKFLOW.HANGER.SCORE, { id: id }))
                        })
                    }
                }} />

                {/* <hr />
                <div className="row">
                    <div className='col-12 d-flex justify-content-end gap-3'>
                        <GoToNextPhaseButton usecaseID={id} nextPhase='Ranking and Prioritization' onSuccess={() => {
                            navigate(replaceUrlParams(URLS.WORKFLOW.HANGER.SCORE, { id: id }))
                        }} />
                    </div>
                </div> */}
            </div>
        </Layout>
    )
}
