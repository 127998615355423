import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import ProductListModal from '../../modals/productList.modal';
import { useFormApi } from '../../_common/hooks/api/FormApiHook';
import TableLoader from '../tableLoader';
import { MdDeleteOutline } from "react-icons/md";
import Swal from 'sweetalert2';
import toast from 'react-hot-toast';
import { useUser } from '../../_common/hooks/authHook';
import UsecasePermission from '../usecasePermission';

interface props {
    heading?: string,
    solutionID: any,
    useCaseID: any,
    isView?: boolean,
}

export default function ProductList({ heading = 'Product Details', solutionID, useCaseID, isView = false }: props) {

    const [showAddProduct, setShowAddProduct] = useState<boolean>(false);
    const workflow = ['hanger', 'taxi-to-runway', 'intheair', 'landed'];
    const location = useLocation();
    let pathnameSegments = location.pathname.split('/');
    let secondPositionValue = pathnameSegments[2];
    let index: any = workflow.indexOf(secondPositionValue);
    const defaultActiveKey = String(index);
    const userInfo: any = useUser();

    const [products, setProducts] = useState<any>(null);
    const formApi = useFormApi();
    const getProducts = () => {
        formApi.getProductsBySolution({ urlParams: { solutionID: solutionID },PageNo:1, PageSize:999 }, (msg: any, resp: any) => {
            setProducts(resp.data.products)
        }, () => {
            setProducts([])
        })

    }
    useEffect(() => {
        getProducts()
    }, [])

    const deleteItemSubmit = (data: any) => {
        return new Promise((resolve, reject) => {
            formApi.productDelete(data, (message: any, resp: any) => {
                if (resp.success === true) {
                    resolve(resp.message);
                } else {
                    reject(resp.message);
                }
            }, (message: any, resp: any) => {
                // Error...
            })
        })
    }

    const deleteItem = (itemID: number) => {
        let data = {
            "productID": itemID,
            updatedBy: userInfo?.userID
        };

        Swal.fire({
            title: 'Confirm Delete',
            text: `Are you sure you want to delete this product?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#B32F84',
            cancelButtonColor: '#44546A',
            confirmButtonText: `Yes, delete it!`
        }).then((result) => {
            if (result.isConfirmed) {
                toast.promise(
                    deleteItemSubmit(data), {
                    loading: 'Loading...',
                    success: (msg: any) => { getProducts(); return <b>{msg}</b> },
                    error: (msg: any) => { return <b>{msg}</b> },
                });
            }
        });
    }


    return (
        <React.Fragment>
            <div className="d-flex justify-content-between align-items-center mb-3">
                <h2 className="h5">{heading}</h2>
                {
                    (!isView) && <Button variant='info' onClick={() => { setShowAddProduct(true) }} className='py-2'>Add Product</Button>
                }

            </div>

            <div className="table-responsive list-table">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col">Product Name</th>
                            <th scope="col">Description</th>
                            <th scope="col">Product Type</th>
                            <th scope="col">Unit Cost</th>
                            <th scope="col">Unit Definition</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {products && products.length > 0 && (
                            products.map((product: any, i: number) => (
                                <tr key={i}>
                                    <td>{product.productName}</td>
                                    <td>{product.productDescription}</td>
                                    <td>{product.productType}</td>
                                    <td>${product.unitCost}</td>
                                    <td>{product.unit}</td>
                                    <td>
                                        <UsecasePermission rolls={['INNOVATION_MANAGER_ROLE']} permission='delete'>
                                            <Link to="#" onClick={() => { deleteItem(product.productID) }} className="react-icon-grey"><MdDeleteOutline /></Link>
                                        </UsecasePermission>
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
                <TableLoader data={products} />
            </div>

            <ProductListModal useCaseID={useCaseID} solutionID={solutionID} show={showAddProduct} onClose={setShowAddProduct} receiveNewData={(data) => {
                getProducts()
            }} />
        </React.Fragment>
    )
}