import React, { useEffect, useState } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import { useUserApi } from "../../_common/hooks/api/UserApiHook";
import { Link, useNavigate, useParams } from "react-router-dom";
import toast from 'react-hot-toast';
import { useForm, SubmitHandler, useFieldArray, Controller } from "react-hook-form"
import { URLS } from "../../_config";
import Error from "../../components/elements/formFieldError";
import { useFormApi } from "../../_common/hooks/api/FormApiHook";
import TitleBar from "../../components/titleBar/TitleBar";
import { useUser, useUserPermissions } from "../../_common/hooks/authHook";

export const ScoreQuestionsForm = () => {
    const navigate = useNavigate();
    const userApi = useUserApi();
    const formApi = useFormApi();
    const params: any = useParams();
    const permissions: any = useUserPermissions();
    const userInfo: any = useUser();


    const { control, register, handleSubmit, reset, formState: { errors } } = useForm();
    const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
        control,
        name: "answer"
    });

    const [scoreQuestionId, setScoreQuestionId] = useState<any>(params?.id);
    const [companyListData, setCompanyListData] = useState<any>({});
    const [tenantListData, setTenantListData] = useState<any>({});

    const getScoreQuestions = () => {
        formApi.getScoreQuestions({ urlParams: { ScoreQuestionID: scoreQuestionId } }, (message: any, resp: any) => {
            reset({
                sectionName: resp.data.sectionName, 
                fkCompanyID: userInfo?.companyID,
                fkTenantID: userInfo?.tenantID,
                fkScoreSectionID: resp.data.fkScoreSectionID,
                weight: resp.data.weight,
                criteria: resp.data.criteria,
                question: resp.data.question,
                low: resp.data.low,
                lowNumber: resp.data.lowNumber,
                medium: resp.data.medium,
                mediumNumber: resp.data.mediumNumber,
                high: resp.data.high,
                highNumber: resp.data.highNumber,
                answer4: resp.data.answer4,
                answer4Number: resp.data.answer4Number,
                answer5: resp.data.answer5,
                answer5Number: resp.data.answer5Number,
            })
        }, (message: any) => {
            // Error...
        })
    }
     
    useEffect(() => { 
        if (scoreQuestionId) {
            getScoreQuestions()
        }
    }, [scoreQuestionId]);


    const submitData = (data: any) => {
        let formParam = {
            ...data,
            fkCompanyID: userInfo?.companyID,
            fkTenantID: userInfo?.tenantID,
            "isActive": true,
            fkScoreSectionID:1,
            weight:0
        };
        if (!scoreQuestionId) {
            return new Promise((resolve, reject) => {
                formApi.scoreQuestionSave(formParam, (message: any, resp: any) => {
                    resolve(message);
                }, (message: any, resp: any) => {
                    reject(message)
                })
            })
        } else {
            formParam.scoreQuestionID = scoreQuestionId;
            return new Promise((resolve, reject) => {
                formApi.scoreQuestionUpdate(formParam, (message: any, resp: any) => {
                    resolve(message);
                }, (message: any, resp: any) => {
                    reject(message)
                })
            })
        }
    }

    const onSubmit = (data:any) => {
        toast.promise(
            submitData(data), {
            loading: (!scoreQuestionId) ? 'Creating...' : 'Updating...',
            success: (msg: any) => { navigate(URLS.SCORE_QUESTION.LIST); return <b>{msg}</b> },
            error: (msg: any) => { return <b>{msg}</b> },
        });
    }

    return (
        <React.Fragment>
            <Header></Header>

            <div className="main-container flex-grow-1">
                <div className="container">
                    <TitleBar title={`${(scoreQuestionId > 0) ? 'Edit' : 'Add'}  Score Questions`} breadcrumbs={{
                        'Settings ': '#',
                        'Score Questions ': URLS.SCORE_QUESTION.LIST,
                        "Form": '#'
                        // '"'+ (scoreQuestionId > 0) ? 'Edit' : 'Add'  +'  Score Questions"': '#'
                    }} >
                        {permissions?.menus.settings.read && <Link to={URLS.SCORE_QUESTION.LIST} className="btn btn-info py-2 add-more-files-btn"> Score Questions</Link>}
                    </TitleBar>


                    <div className="row">
                        <div className="col-sm-8">
                            <div className="white-box h-100">
                                <div className="form-area">
                                    <form className="row g-3" onSubmit={handleSubmit(onSubmit)}>

                                        {/* <div className="col-md-12">
                                            <label htmlFor="fkScoreSectionID" className="form-label">Section Name </label>
                                            <select className="form-control" {...register("fkScoreSectionID", { required: "Section is required" })} id="fkScoreSectionID">
                                                <option value=''>Select one...</option>
                                                <option value={1}>Benefits</option>
                                                <option value={2}>Risk</option>
                                            </select>
                                            <Error error={errors.fkScoreSectionID} />
                                        </div> */}
                                        
                                        <div className="col-md-10">
                                            <label htmlFor="criteria" className="form-label">Criteria</label>
                                            <input   {...register("criteria", { required: "Required" })} type="text" className="form-control" id="criteria" />
                                            <Error error={errors.criteria} />
                                        </div>
                                        {/* <div className="col-md-2">
                                            <label htmlFor="weight" className="form-label">Weight</label>
                                            <input   {...register("weight", { required: "Required" })} type="text" className="form-control" id="weight" />
                                            <Error error={errors.weight} />
                                        </div> */}
                                        <div className="col-md-12">
                                            <label htmlFor="question" className="form-label">Question</label>
                                            <input   {...register("question", { required: "Required" })} type="text" className="form-control" id="question" />
                                            <Error error={errors.question} />
                                        </div>

                                        {/* {fields.map((field, i) => (
                                            <div className="col-md-12" key={field.id}>
                                                <div className="row">
                                                    <div className="col-md-10">
                                                        <label htmlFor="low" className="form-label">Answer {i+1}</label>
                                                        <Controller
                                                            rules={{ required: "Answer required" }}
                                                            control={control}
                                                            name={`answer.${i}.answer`}
                                                            render={({field: { onChange, onBlur, value, name, ref }}) => (
                                                                <input type="text" className="form-control" name={name} value={value} onChange={onChange} />
                                                            )}
                                                        />
                                                        <Error error={errors[`answer.${i}.answer`]} />
                                                    </div>
                                                    <div className="col-md-2">
                                                        <label htmlFor="lowNumber" className="form-label">Weight {i+1}</label>
                                                        <Controller
                                                            rules={{ required: "Weight required" }}
                                                            control={control}
                                                            name={`answer.${i}.weight`}
                                                            render={({field: { onChange, onBlur, value, name, ref }}) => (
                                                                <input type="text" className="form-control" name={name} value={value} onChange={(e:any) => {
                                                                    console.log('............',e.target.value.replace(/^0+/, "").replace(/[^\d.]/g, ""))
                                                                    if(e.target.value != ''){
                                                                        e.target.value = e.target.value.replace(/[^\d.]/g, "");

                                                                        e.target.value = parseInt(e.target.value);
                                                                    } else {
                                                                        e.target.value = 0
                                                                    }
                                                                    if(e.target.value > 5){
                                                                        e.target.value = 5
                                                                    }


                                                                    onChange(e)
                                                                }} />
                                                            )}
                                                        />
                                                        <Error error={errors[`answer.${i}.weight`]} />
                                                    </div>
                                                </div>
                                            </div>
                                        ))} */}







                                        <div className="col-md-10">
                                            <label htmlFor="low" className="form-label">Answer 1</label>
                                            <input   {...register("low", { required: "Required" })} type="text" className="form-control" id="low" />
                                            <Error error={errors.low} />
                                        </div>
                                        <div className="col-md-2">
                                            <label htmlFor="lowNumber" className="form-label">Answer 1 Number</label>
                                            <input   {...register("lowNumber", { required: "Required" })} type="text" className="form-control" id="lowNumber" onChange={(e: any) => {
                                                if (e.target.value != '') {
                                                    e.target.value = e.target.value.replace(/[^\d.]/g, "");
                                                    e.target.value = parseInt(e.target.value);
                                                } else {
                                                    e.target.value = 0
                                                }
                                                if (e.target.value > 5) {
                                                    e.target.value = 5
                                                }
                                            }} />
                                            <Error error={errors.lowNumber} />
                                        </div>

                                        <div className="col-md-10">
                                            <label htmlFor="medium" className="form-label">Answer 2</label>
                                            <input   {...register("medium", { required: "Required" })} type="text" className="form-control" id="medium" />
                                            <Error error={errors.medium} />
                                        </div>
                                        <div className="col-md-2">
                                            <label htmlFor="mediumNumber" className="form-label">Answer 2 Number</label>
                                            <input   {...register("mediumNumber", { required: "Required" })} type="text" className="form-control" id="mediumNumber" onChange={(e: any) => {
                                                if (e.target.value != '') {
                                                    e.target.value = e.target.value.replace(/[^\d.]/g, "");
                                                    e.target.value = parseInt(e.target.value);
                                                } else {
                                                    e.target.value = 0
                                                }
                                                if (e.target.value > 5) {
                                                    e.target.value = 5
                                                }
                                            }} />
                                            <Error error={errors.mediumNumber} />
                                        </div>

                                        <div className="col-md-10">
                                            <label htmlFor="high" className="form-label">Answer 3</label>
                                            <input   {...register("high", { required: "Required" })} type="text" className="form-control" id="high" />
                                            <Error error={errors.high} />
                                        </div>
                                        <div className="col-md-2">
                                            <label htmlFor="highNumber" className="form-label">Answer 3 Number</label>
                                            <input   {...register("highNumber", { required: "Required" })} type="text" className="form-control" id="highNumber" onChange={(e: any) => {
                                                if (e.target.value != '') {
                                                    e.target.value = e.target.value.replace(/[^\d.]/g, "");
                                                    e.target.value = parseInt(e.target.value);
                                                } else {
                                                    e.target.value = 0
                                                }
                                                if (e.target.value > 5) {
                                                    e.target.value = 5
                                                }
                                            }} />
                                            <Error error={errors.highNumber} />
                                        </div>

                                        <div className="col-md-10">
                                            <label htmlFor="answer4" className="form-label">Answer 4</label>
                                            <input   {...register("answer4", { required: "Required" })} type="text" className="form-control" id="answer4" />
                                            <Error error={errors.answer4} />
                                        </div>
                                        <div className="col-md-2">
                                            <label htmlFor="answer4Number" className="form-label">Answer 4 NUmber</label>
                                            <input   {...register("answer4Number", { required: "Required" })} type="text" className="form-control" id="answer4Number" onChange={(e: any) => {
                                                if (e.target.value != '') {
                                                    e.target.value = e.target.value.replace(/[^\d.]/g, "");
                                                    e.target.value = parseInt(e.target.value);
                                                } else {
                                                    e.target.value = 0
                                                }
                                                if (e.target.value > 5) {
                                                    e.target.value = 5
                                                }
                                            }}/>
                                            <Error error={errors.answer4Number} />
                                        </div>

                                        <div className="col-md-10">
                                            <label htmlFor="answer5" className="form-label">Answer 5</label>
                                            <input   {...register("answer5", { required: "Required" })} type="text" className="form-control" id="answer5" />
                                            <Error error={errors.answer5} />
                                        </div>
                                        <div className="col-md-2">
                                            <label htmlFor="answer5Number" className="form-label">Answer 5 NUmber</label>
                                            <input   {...register("answer5Number", { required: "Required" })} type="text" className="form-control" id="answer5Number" onChange={(e: any) => {
                                                if (e.target.value != '') {
                                                    e.target.value = e.target.value.replace(/[^\d.]/g, "");
                                                    e.target.value = parseInt(e.target.value);
                                                } else {
                                                    e.target.value = 0
                                                }
                                                if (e.target.value > 5) {
                                                    e.target.value = 5
                                                }
                                            }} />
                                            <Error error={errors.answer5Number} />
                                        </div>




                                        <div className="col-12 d-flex justify-content-end gap-3">
                                            <Link to={URLS.SCORE_QUESTION.LIST} className="btn btn-primary  d-flex gap-2">Back</Link>
                                            <button type="submit" className="btn btn-info d-flex align-items-center gap-2">Submit <i className="arrow-right"></i></button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="pt-5">
                                <img src="/../images/idea-pic.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer></Footer>
        </React.Fragment>
    )
}
