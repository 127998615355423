import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';
import './Auth.scss'
import { useUserApi } from "../../_common/hooks/api/UserApiHook";
import { URLS } from "../../_config";
import { SignedOut, useUser, useAuth, SignIn, SignedIn, SignOutButton, } from "@clerk/clerk-react"
import Icon from "../../components/icon";
import { toastHandeler } from "../../_config/helpers";
import { Button } from "react-bootstrap";


const Login = () => {
    const navigate = useNavigate();
    const userApi = useUserApi()
    
    const [clarkLogin, setClarkLogin] = useState<any>('loading');
    const [startWaiting, setStartWaiting] = useState<any>(null);

    const queryParams = new URLSearchParams((window as any).location.search);
    const reUrl: any = queryParams.get('redirect');

    const ClerkUser = useUser();
    const ClerkAuth = useAuth();

    const animation:any = useRef();


    const submitDataClerk = (data: any) => {
        return new Promise((resolve, reject) => {
            userApi.loginWithClerk(data, (message: any, resp: any) => {
                resolve(message);
            }, (message: any) => {
                reject(message);
            })
            // resolve('We are waiting for api...')
            ClerkAuth.signOut()
        })
    }

    const loginWithClerk = () => {
        toastHandeler(submitDataClerk, {
            ReferenceUserID: ClerkUser?.user?.id,
            OrganizationID: process.env.REACT_APP_CLERK_ORGANIZATION_ID
        }, 'Logging Clerk...').then(() => {
            if (reUrl) {
                let re = new URL(reUrl)
                navigate(re.pathname + re.search);
            } else {
                navigate(URLS.USE_CASE_LIST);
            }

        })
    }


    const waitting = () =>{
        if (((new Date()).getTime() - startWaiting) > 3000) {
           cancelAnimationFrame(animation.current);
           ClerkAuth.signOut()
        } else {
            animation.current = requestAnimationFrame(waitting)
        }
    }

    useEffect(() => {
        if (ClerkUser?.isSignedIn && ClerkUser?.user && ClerkAuth.isSignedIn) {
            let organizationMemberships = ClerkUser?.user?.organizationMemberships;
            if (organizationMemberships.length > 0) {
                const envOrgId = process.env.REACT_APP_CLERK_ORGANIZATION_ID
                let userOrgId = '';
                organizationMemberships.map((orgData: any) => {
                    if (envOrgId === orgData?.organization?.id) {
                        userOrgId = orgData?.organization?.id
                    }
                })
                if (userOrgId === '') {
                    setClarkLogin('org_not_match')
                    setStartWaiting((new Date()).getTime())
                    // ClerkAuth.signOut()
                } else {
                    loginWithClerk();
                }
            } else {
                setClarkLogin('org_not_match')
                setStartWaiting((new Date()).getTime())
            }
        }
    }, [ClerkUser, ClerkAuth])


    useEffect(() => {
        if (startWaiting) {
            console.log('start waitting timer......')
            animation.current  = requestAnimationFrame(waitting)
        }
    }, [startWaiting])


    return (
        <div className="login_page">
            <div className="justify-content-center mb-5 mt-5">
                {
                    ClerkUser?.isLoaded ? (
                        <SignedOut >
                            <SignIn />
                        </SignedOut>
                    ) : <p>Loading...</p>

                }


                <SignedIn>
                    {
                        (clarkLogin === 'loading') && <p>Checking Authentication...</p>
                    }

                    {
                        (clarkLogin === 'org_not_match') && <p className="text-danger" >
                            <Icon name="shield-error" />
                            &nbsp;  &nbsp;  You are not authorize user for this organization!
                        </p>
                    }
                </SignedIn>

            </div>



            {/* <SignedIn>
                <SignOutButton children={
                    <Button variant='secondary' size="lg" className="d-flex" >
                        <Icon name="shield-lock-outline" size={18} />
                        &nbsp; Already SSO Loggedin | Logout
                    </Button>} />  
            </SignedIn> */}
        </div>
    );
}

export default Login;