import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

import Signup from './pages/auth/Singnup';
import Login from './pages/auth/Login';
import ForgotPassword from './pages/auth/ForgotPassword';
import ResetPassword from './pages/auth/ResetPassword';

import IdeaList from './pages/intectFlow/IdeaList';
import Dashboard from './pages/dashboard/Dashboard';
import IntactForm from './pages/intectFlow/IntactForm';

import { IdeaSubmit } from './pages/intectFlow/IdeaSubmit';
import { AddEmployee } from './pages/employee/addEmployee';
import PrivateRoutes from './_config/privateRoutes';
import { RoleList } from './pages/role/RoleList';
import { RoleAddEdit } from './pages/role/RoleAddEdit';
import { UseCaseDetails } from './pages/intectFlow/UseCaseDetails';

import { AirportList } from './pages/airport/AirportList';
import { AirportAddEdit } from './pages/airport/AirportAddEdit';
import { Discovery } from './pages/workflow/hanger/Discovery';
import { ChallengeStatements } from './pages/workflow/hanger/ChallengeStatements';
import { ProjectPlan } from './pages/workflow/runway/ProjectPlan';
import { ProcurementPlan } from './pages/workflow/runway/ProcurementPlan';
import { TestPlan } from './pages/workflow/runway/TestPlan';
import { FieldTesting } from './pages/workflow/intheair/FieldTesting';
import { Nogo } from './pages/workflow/landed/Nogo';
import { Decision } from './pages/workflow/hanger/Decision';
import { SolutionFraming } from './pages/workflow/hanger/SolutionFraming';
import { RankingPrioritization } from './pages/workflow/hanger/RankingPrioritization';
import EmployeeList from './pages/employee/EmployeeList';
import { URLS } from './_config';
import { EmployeeRolePermission } from './pages/employee/EmployeeRolePermission';
import { RunwayOverview } from './pages/intectFlow/overviews/RunwayOverview';
import { HangerOverview } from './pages/intectFlow/overviews/HangerOverview';
import { DayInTheLife } from './pages/workflow/intheair/DayInTheLife';
import { InFlightOverview } from './pages/intectFlow/overviews/InFlightOverview';
import { LandedOverview } from './pages/intectFlow/overviews/LandedOverview';
import UsecaseRoutes from './_config/usecaseRoutes';
import { RunwayList } from './pages/runway/RunwayList';
import { RunwayForm } from './pages/runway/RunwayForm';
import SolutionProviderList from './pages/solution_provider/SolutionProviderList';
import { SolutionProviderForm } from './pages/solution_provider/SolutionProviderForm';
import { FieldAssistance } from './pages/workflow/intheair/FieldAssistance';
import { RolePermission } from './pages/role/RolePermission';
import Dashboard2 from './pages/dashboard/Dashboard2';
import { ManageForms } from './pages/manageForms/manageForms';
import Test from './pages/test';
import LoginSSO from './pages/auth/LoginSSO';
import DashboardExecutive from './pages/dashboard/DashboardExecutive';
import PeriodComparisonDashboard from './pages/dashboard/PeriodComparisonDashboard';
import { NotificationList } from './pages/notification/NotificationList';
import { NotificationEdit } from './pages/notification/NotificationEdit';
import { Profile } from './pages/profile/Profile';
import { ScoreQuestionsList } from './pages/scoreQuestions/ScoreQuestionsList';
import { ScoreQuestionsForm } from './pages/scoreQuestions/ScoreQuestionsForm';

function App() {
  return (
    <Router>
      <div className="App">
        <div className="d-flex flex-column min-vh-100">

          <Routes>
            <Route path='/signup' element={<Signup></Signup>}></Route>
            <Route path='/forgot-password' element={<ForgotPassword></ForgotPassword>}></Route>
            <Route path='/reset-password' element={<ResetPassword></ResetPassword>}></Route>
            <Route path='/login' element={<Login></Login>}></Route>
            <Route path='/' element={<Login></Login>}></Route>
            <Route path={URLS.LOGIN_SSO} element={<LoginSSO />}></Route>

            <Route element={<PrivateRoutes />}> {/* this route guard allows only logged-in users */}

              <Route path={URLS.PROFILE_VIEW} element={<Profile />} ></Route>


              <Route path='/intact-form' element={<IntactForm></IntactForm>} ></Route>
              <Route path='/idea-submit' element={<IdeaSubmit></IdeaSubmit>}></Route>
              <Route path='/idea-submit/:id' element={<IdeaSubmit></IdeaSubmit>}></Route>


              <Route path={URLS.EMPLOYEE_LIST} element={<EmployeeList />}></Route>
              <Route path={URLS.EMPLOYEE_CREATE} element={<AddEmployee />}></Route>
              <Route path={URLS.EMPLOYEE_EDIT} element={<AddEmployee />}></Route>
              <Route path={URLS.EMPLOYEE_ROLE_PERMISSION} element={<EmployeeRolePermission />}></Route>


              <Route path={URLS.SOLUTION_PROVIDER.LIST} element={<SolutionProviderList />}></Route>
              <Route path={URLS.SOLUTION_PROVIDER.CREATE} element={<SolutionProviderForm />}></Route>
              <Route path={URLS.SOLUTION_PROVIDER.EDIT} element={<SolutionProviderForm />}></Route>


              <Route path={URLS.ROLE.LIST} element={<RoleList />}></Route>
              <Route path={URLS.ROLE.CREATE} element={<RoleAddEdit />}></Route>
              <Route path={URLS.ROLE.EDIT} element={<RoleAddEdit />}></Route>
              <Route path={URLS.ROLE.PERMISSION} element={<RolePermission />}></Route>

              <Route path={URLS.USECASE_DETAILS} element={<UseCaseDetails />}></Route>
              <Route path={URLS.USE_CASE_HANGER_OVERVIEW} element={<HangerOverview />}></Route>
              <Route path={URLS.USE_CASE_RUNWAY_OVERVIEW} element={<RunwayOverview />}></Route>
              <Route path={URLS.USE_CASE_IN_FLIGHT_OVERVIEW} element={<InFlightOverview />}></Route>
              <Route path={URLS.USE_CASE_LANDED_OVERVIEW} element={<LandedOverview />}></Route>

              <Route path={URLS.AIRPORT.LIST} element={<AirportList />}></Route>
              <Route path={URLS.AIRPORT.CREATE} element={<AirportAddEdit />}></Route>
              <Route path={URLS.AIRPORT.EDIT} element={<AirportAddEdit />}></Route>


              <Route path={URLS.RUNWAY.LIST} element={<RunwayList />}></Route>
              <Route path={URLS.RUNWAY.CREATE} element={<RunwayForm />}></Route>
              <Route path={URLS.RUNWAY.EDIT} element={<RunwayForm />}></Route>
              <Route path={URLS.TEST} element={<Test />}></Route>


              <Route element={<UsecaseRoutes />}> {/* this route guard protects the user from accessing future phases */}
                <Route path={URLS.WORKFLOW.HANGER.DISCOVERY} element={<Discovery />}></Route>
                <Route path={URLS.WORKFLOW.HANGER.CHALLENGE} element={<ChallengeStatements />}></Route>
                <Route path={URLS.WORKFLOW.HANGER.SOLUTIONS} element={<SolutionFraming />}></Route>
                <Route path={URLS.WORKFLOW.HANGER.SCORE} element={<RankingPrioritization />}></Route>
                <Route path={URLS.WORKFLOW.HANGER.DECISION} element={<Decision />}></Route>

                <Route path={URLS.WORKFLOW.RUNWAY.PROJECT_PLAN} element={<ProjectPlan />}></Route>
                <Route path={URLS.WORKFLOW.RUNWAY.PROCUREMENT_PLAN} element={<ProcurementPlan />}></Route>
                <Route path={URLS.WORKFLOW.RUNWAY.TEST_PLAN} element={<TestPlan />}></Route>

                <Route path={URLS.WORKFLOW.INTHEAIR.FIELD_TESTING} element={<FieldTesting />}></Route>
                <Route path={URLS.WORKFLOW.INTHEAIR.DAY_IN_LIFE} element={<DayInTheLife />}></Route>
                <Route path={URLS.WORKFLOW.INTHEAIR.FIELD_ASSISTANCE} element={<FieldAssistance />}></Route>

                <Route path={URLS.WORKFLOW.LANDED.GONOGO} element={<Nogo />}></Route>

              </Route>



              <Route path={URLS.NOTIFICATION.LIST} element={<NotificationList />}></Route>
              <Route path={URLS.NOTIFICATION.EDIT} element={<NotificationEdit />}></Route>


              <Route path={URLS.MANAGE_FORMS} element={<ManageForms />}></Route>
              <Route path={URLS.USE_CASE_LIST} element={<IdeaList></IdeaList>}></Route>

              <Route path={URLS.SCORE_QUESTION.LIST} element={<ScoreQuestionsList />}></Route>
              <Route path={URLS.SCORE_QUESTION.CREATE} element={<ScoreQuestionsForm />}></Route>
              <Route path={URLS.SCORE_QUESTION.EDIT} element={<ScoreQuestionsForm />}></Route>



              <Route path={URLS.DASHBOARD_EXECUTIVE} element={<DashboardExecutive />}></Route>
              <Route path={URLS.DASHBOARD_PERIOD_COMPARISON} element={<PeriodComparisonDashboard />}></Route>
            </Route>



            <Route path={URLS.DASHBOARD} element={<Dashboard />}></Route>
            <Route path={URLS.DASHBOARD2} element={<Dashboard2 />}></Route>

          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
