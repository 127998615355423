/* eslint-disable @typescript-eslint/ban-types */
import { useApiCall } from '../common/appApiCallHook'
//import { BlankReq, LoginReq, LogoutReq } from '../../interfaces/ApiReqRes'
import { SAGA_ACTIONS } from '../../../_config/index'



export function useMasterApi() {
  const callApi = useApiCall()

  const getSections = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.MASTER.GET_SECTIONS, data, onSuccess, onError)
  }

  const getPhases = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.MASTER.GET_PHASES, data, onSuccess, onError)
  }

  return {
    getSections,
    getPhases
  }
}
