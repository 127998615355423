import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom'
import Error from '../components/elements/formFieldError';
import toast from 'react-hot-toast';
import { useUser } from '../_common/hooks/authHook';
import { useWorkFlowApi } from '../_common/hooks/api/WorkFlowApiHook';
import { useUserApi } from '../_common/hooks/api/UserApiHook';
import { useUsecase } from '../_common/hooks/usecaseHook';
import Select from 'react-select';
import { getSelectedValue, toastHandeler } from '../_config/helpers';
import { airportList, runwayList } from '../store/sagas/userSaga';

interface props {
    show: boolean,
    onClose: (status: boolean) => void
}

export default function ChangeAirportRunwayCaptainsModal({ show, onClose }: props) {

    const handleClose = () => { onClose(false) };
    const user: any = useUser();
    const { control, register, handleSubmit, reset, formState: { errors }, } = useForm();
    const userApi = useUserApi();
    const { id } = useParams();
    const [reviewDetails, setReviewDetails] = useState<any>(null);
    const [allAirport, setAllAirport] = useState<any>([]);
    const [allRunway, setAllRunway] = useState<any>([]);
    const [allCaptain, setAllCaptain] = useState<any>([]);
    const usecase: any = useUsecase();


    const getUsecase = () => {
        return new Promise((resolve, reject) => {
            userApi.useCaseDetails({ urlParams: { companyID:user.companyID,id: id, userID: user.userID } }, (message: any, resp: any) => {
                resolve(true)
            }, (message: any) => { })
        })
    }

    const submitApproveData = (data: any) => {
        return new Promise((resolve, reject) => {
            userApi.changeAirportRunwayCaptains(data, resolve, reject)
        })
    }

    const onSubmitApproveHandler = (data: any) => {
        toastHandeler(submitApproveData, {
            "fkUseCaseID": id,
            "userID": user.userID,
            "fkCompanyID": user?.companyID,
            "airportID": data.airport.value,
            "runwayID": data.runway.value,
            "captainID": data.fkCaptainID.value,
            "coCaptainID": data.fkCoCaptainID?.value
        }, 'Updating...').then(()=>{
            getUsecase();
            handleClose();
        })
    }

    const getAllAirport = () => {
        userApi.airportList({ FKCompanyID: user?.companyID }, (message: any, resp: any) => {
            setAllAirport(resp.data.roles.map((airport: any) => {
                airport.value = airport.airportID;
                airport.label = `${airport.name}`;
                return airport;
            }));
        }, (message: any, resp: any) => {
            // Error...
        })
    }

    const getRunway = () => {
        userApi.getRunwayByAirport({ urlParams: { PageIndex: 1, PageSize: 999 } }, (message: any, resp: any) => {
            //console.log('ranway', resp);
            setAllRunway(resp.data.runway.map((runway: any) => {
                runway.value = runway.runwayID;
                runway.label = `${runway.title}`;
                return runway;
            }));

        }, (message: any, resp: any) => {
            // Handle error...
        });

    }


    const getAllCaptain = () => {
        userApi.employeeList({}, (message: any, resp: any) => {
            /* setManagers(resp.data.members.filter((captain: any) => {
                return captain.roleName == 'Innovation Manager';
            }).map((captain: any) => {
                captain.value = captain.userID;
                captain.label = `${captain.firstName} ${captain.lastName}`;
                return captain;
            })) */
            setAllCaptain(resp.data.members.filter((captain: any) => {
                return captain.roleName == 'Innovator';
            }).map((captain: any) => {
                captain.value = captain.userID;
                captain.label = `${captain.firstName} ${captain.lastName}`;
                return captain;
            }));
        }, (message: any, resp: any) => {
            // Error...
        })
    }


    const getUsecaseReviewListDetails = () => {
        console.log('Get all review')
        userApi.getUsecaseReviewList({ urlParams: { companyID: user?.companyID, usecaseID: id } }, (message: any, resp: any) => {
            setReviewDetails(resp.data);
            
            
        }, (message: any) => { })
    }

    useEffect(() => {
        if(show){
            if(allCaptain?.length == 0){
                getAllCaptain();
            } 
            if(allAirport?.length == 0){
                getAllAirport();
            } 
            if(allRunway?.length == 0){
                getRunway();
            } 
            
            getUsecaseReviewListDetails();
                    
        }
    }, [show])
    useEffect(() => {
      
        if(allCaptain?.length && allAirport?.length && allRunway?.length  && reviewDetails){
            reset({
                airport:getSelectedValue(reviewDetails.fkAirPortID,'value',allAirport),
                runway:getSelectedValue(reviewDetails.fkRunwayID,'value',allRunway),
                fkCaptainID:getSelectedValue(reviewDetails.fkCaptainID,'value',allCaptain),
                fkCoCaptainID:getSelectedValue(reviewDetails.coCaptainID,'value',allCaptain)
            })
        }
    }, [allCaptain, reviewDetails, allAirport, allRunway])


    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Change</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(onSubmitApproveHandler)} id="changeUsecaseManagers">
                    <div className="feature-date">
                        <h5>Created by - <span>{usecase?.addedByName}</span></h5>
                    </div>
                    <div className="feature-date">
                        <h5>Innovation Manager - <span>{usecase?.assignedInnovationManagerName}</span></h5>
                    </div>
                    <br />

                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="form-input mb-3">
                            <label>Airport</label>
                            <Controller
                                name="airport"
                                control={control}
                                rules={{ required: 'Airport is required' }}
                                render={({ field }) => (
                                    <div>
                                        <Select
                                            {...field}
                                            options={allAirport}
                                        />
                                        <Error error={errors?.airport} />
                                    </div>
                                )}
                            />
                        </div>
                    </div>

                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="form-input mb-3">
                            <label>Runway</label>
                            <Controller
                                name="runway"
                                control={control}
                                rules={{ required: 'Runway is required' }}
                                render={({ field }) => (
                                    <div>
                                        <Select
                                            {...field}
                                            options={allRunway}
                                        />
                                        <Error error={errors?.runway} />
                                    </div>
                                )}
                            />
                        </div>
                    </div>

                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="form-input mb-3">
                            <label>Choose Captain</label>
                            <Controller
                                name="fkCaptainID"
                                control={control}
                                rules={{ required: 'Captain is required' }}
                                render={({ field }) => (
                                    <div>
                                        <Select
                                            {...field}
                                            options={allCaptain}
                                            placeholder={reviewDetails?.fkStatusID == 1 ? reviewDetails?.captain : 'Select...'}
                                        />
                                        <Error error={errors.fkCaptainID} />
                                    </div>
                                )}
                            />
                        </div>
                    </div>

                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="form-input mb-3">
                            <label>Choose Co-Captain</label>

                            <Controller
                                name="fkCoCaptainID"
                                control={control}
                                rules={{ required: false }}
                                render={({ field }) => (
                                    <div>
                                        <Select
                                            {...field}
                                            options={allCaptain}
                                            placeholder={reviewDetails?.fkStatusID == 1 ? reviewDetails?.coCaptain : 'Select...'}
                                        />
                                    </div>
                                )}
                            />
                        </div>
                    </div>


                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>Cancel</Button>
                <Button variant="primary" type='submit' form='changeUsecaseManagers'>Update</Button>
            </Modal.Footer>
        </Modal>
    )
}