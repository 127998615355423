import React, { useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useUserApi } from '../../../_common/hooks/api/UserApiHook'
import Header from '../../../components/header/Header'
import Footer from '../../../components/footer/Footer'
import { URLS } from '../../../_config'
import RightSlideModal from '../../../components/RightSlideModal'
import DynamicForm from '../../../components/dynamicForm/dynamicForm'
import DynamicFormView from '../../../components/dynamicForm/dynamicFormView'
import OverviewNextPervious from './OverviewNextPervious'
import PhaseGuard from '../../../components/phaseGuard'
import { useUser } from '../../../_common/hooks/authHook'
import { useUsecase } from '../../../_common/hooks/usecaseHook'
import jsPDF from 'jspdf'
import TitleBar from '../../../components/titleBar/TitleBar'
import { replaceUrlParams } from '../../../_config/helpers'
import { Button } from 'react-bootstrap'


export const LandedOverview = () => {
    const { id } = useParams();

    const [rightPannel, setRightPannel] = useState<boolean>(false)
    const overviewRef:any = useRef();
    const user:any = useUser();
    const usecase:any = useUsecase();
    const userApi:any = useUserApi();
    const getUsecase = () => {  /* get usecase details and store in redux */
        return new Promise((resolve, reject) => {
            userApi.useCaseDetails({ urlParams: {companyID:user.companyID, id: id, userID: user.userID } }, (message: any, resp: any) => {
                resolve(true)
            }, (message: any) => { })
        })
    }

    useEffect(() => {
        if (usecase) {
            if (usecase?.useCaseID != id) {
                getUsecase();
            } 
        } else {
            if (id && user?.userID) {
                getUsecase();
            }
        }
    }, [usecase])


    const downloadPDF = () => {
        overviewRef.current.style.width = '1260px'
        let doc = new jsPDF('p', 'px', 'a1');

        doc.html(overviewRef.current, {
            html2canvas: {
                width: 200
            },
            callback: function (doc) {
                doc.save(`${usecase.useCase}-landed-overview.pdf`);
                overviewRef.current.style.width = ''
            }
        });
    }

    

   


    return (
        <React.Fragment>
            <Header></Header>

            <div className="main-container flex-grow-1">
                <div className="container">
                <TitleBar  title={`Landed Overview | usecase : ${usecase?.useCase}`} breadcrumbs={{
                        'Use case list': URLS.USE_CASE_LIST,
                        'Use case Details': replaceUrlParams(URLS.USECASE_DETAILS, { id: id }),
                        'Landed Overview': '#'
                    }} >
                        <Button className='print-hide' onClick={() => {
                            window.print();
                            //downloadPDF();
                        }}>Download As PDF</Button>
                    </TitleBar>
                    <div ref={overviewRef}>
                    <PhaseGuard phase='Go/No Go'>
                        <div className="white-box">
                            <DynamicFormView _workflow='landed' useCaseID={id}
                                _module='go-no-go' _form='go-no-go'
                                editMode={true} section='Landed' phase='Go/No Go' />
                        </div>
                    </PhaseGuard>
                    </div>
                    <OverviewNextPervious page='Landed' />
                </div>
            </div>

            <RightSlideModal visible={rightPannel} changeVisibility={setRightPannel}
                heading='Grid Reliability Testing' timer={true}>

                <h5>Description</h5>
                <p>To verify the reliability of the electricity grid during peak load conditions.</p>
                <h5>Acceptance Criteria</h5>
                <div className="testcase_listtxt">
                    <ol>
                        <li>The electricity grid can handle a simulated peak load condition widthout voltage fluctuations exceeding + 5% of the nominal voltage.</li>
                        <li>The frequency of the grid remains within +0.1 Hz of the nominal frequency during the peak load test.
                        </li>
                        <li>
                            No unplanned grid outages or power interruptions occur during the test.
                        </li>
                        <li>
                            Grid components, including transformers and circuit breakers, operate within their specified temperature and load limits without tripping or overheating.
                        </li>
                        <li>
                            Protective relay systems trigger and isolate any detected faults within 0.5 seconds of detection.
                        </li>
                        <li>Automatic load shedding mechanisms engage appropriately, if necessary, to maintain grid stability.</li>
                    </ol>
                </div>

                <DynamicForm _workflow='in-the-air' useCaseID={id} _module='in-field-testing' _form='in-field-testing' onSubmitSuccess={() => { setRightPannel(false) }} />

            </RightSlideModal>


            <Footer></Footer>
        </React.Fragment>

    )
}
