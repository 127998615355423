import React, { useEffect, useState } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import { useUserApi } from "../../_common/hooks/api/UserApiHook";
import { Link, useNavigate, useParams } from "react-router-dom";
import toast from 'react-hot-toast';
import { useForm, SubmitHandler } from "react-hook-form"
import { URLS } from "../../_config"; 
import { useUser } from "../../_common/hooks/authHook";

export const RunwayForm = () => {
    const navigate = useNavigate();
    const userApi = useUserApi();
    const params: any = useParams();
    const userInfo: any = useUser();

    type Inputs = {
        title: string
        description: string
        fkCompanyID: number
        fkTenantID: number
    }

    const {
        register,
        handleSubmit,
        watch,
        getValues,
        reset,
        formState: { errors },
    } = useForm<Inputs>()

    const [runwayID, setRunwayId] = useState<any>(params?.id); 

    const getRunwayDetails = () => {
        userApi.runwayDetails({ urlParams: { RunwayID: runwayID } }, (message: any, resp: any) => {
            reset({
                title: resp.data.title,
                description: resp.data.description,
                fkCompanyID: resp.data.fkCompanyID, 
                fkTenantID: resp.data.fkTenantID,
            })
        }, (message: any, resp: any) => {
            // Error...
        })
    }
    
 
    useEffect(() => { 
        if (runwayID) {
            getRunwayDetails()
        }
    }, [runwayID]);


    const submitData = (data: any) => {
        let formParam = {
            ...data,
            "fkCompanyID": userInfo?.companyID,  
            "fkTenantID": userInfo?.tenantID,
            "isActive": true, 
            'isDeleted': false
        };
        if (!runwayID) {
            formParam.runwayID = 0;
            formParam.addedBy = userInfo?.userID;;
            return new Promise((resolve, reject) => {
                userApi.runwaySave(formParam, (message: any, resp: any) => {
                    resolve(message);
                }, (message: any, resp: any) => {
                    reject(message)
                })
            })
        } else {
            formParam.runwayID = runwayID;
            formParam.updatedBy = userInfo?.userID;;
            return new Promise((resolve, reject) => {
                userApi.runwayUpdate(formParam, (message: any, resp: any) => {
                    resolve(message);
                }, (message: any, resp: any) => {
                    reject(message)
                })
            })
        }
    }

    const onSubmit: SubmitHandler<Inputs> = (data) => {
        toast.promise(
            submitData(data), {
            loading: (!runwayID) ? 'Creating...' : 'Updating...',
            success: (msg: any) => { navigate(URLS.RUNWAY.LIST); return <b>{msg}</b> },
            error: (msg: any) => { return <b>{msg}</b> },
        });
    }

    return (
        <React.Fragment>
            <Header></Header>

            <div className="main-container flex-grow-1">
                <div className="container">
                    <div className="page-title py-4">
                        <div className="row">
                            <div className="col-sm-12">
                                <ul className="csmt-breadcrumb d-flex justify-content-start gap-3 mb-2 ps-0">
                                    <li><Link to={URLS.DASHBOARD}>Dashboard</Link></li>
                                    <li><Link to={URLS.RUNWAY.LIST}>Runway</Link></li>
                                    <li>{(runwayID > 0) ? 'Edit' : 'Add'}  Runway</li>
                                </ul>
                            </div>
                            <div className="col-sm-12">
                                <h1 className="h6 text-uppercase font-weight-700  mb-0">{(runwayID > 0) ? 'Edit' : 'Add'} Runway</h1>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-6">
                            <div className="white-box h-100">
                                <div className="form-area">
                                    <form className="row g-3" onSubmit={handleSubmit(onSubmit)}>
 
 
                                        <div className="col-md-12">
                                            <label htmlFor="title" className="form-label">Runway <span className="text-danger">*</span></label>
                                            <input   {...register("title", { required: "Runway is required" })} type="text" className="form-control" placeholder="Put Runway" id="title" />
                                            {errors.title && <span className="text-danger">{errors.title.message}</span>}
                                        </div>

                                        <div className="col-md-12">
                                            <label htmlFor="description" className="form-label">Description  <span className="text-danger">*</span></label>
                                            <textarea  {...register("description", { required: "Description is required" })} className="form-control" placeholder="Put description" id="description" />
                                            {errors.description && <span className="text-danger">{errors.description.message}</span>}
                                        </div>


                                        <div className="col-12 d-flex justify-content-end gap-3">
                                            <Link to={URLS.RUNWAY.LIST} className="btn btn-dark d-flex align-items-center gap-2">Back</Link>
                                          
                                            <button type="submit" className="btn btn-primary d-flex align-items-center gap-2">Submit <i className="arrow-right"></i></button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="pt-5">
                                <img src="/../images/idea-pic.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer></Footer>
        </React.Fragment>
    )
}
