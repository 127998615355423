import { Outlet, Navigate } from 'react-router-dom'
import { useAuthStatus } from '../_common/hooks/authHook'
import PrivateLayout from '../components/PrivateLayout'

interface IncominfProps {
  layoutRequired?: boolean
}

const PrivateRoutes = ({ layoutRequired = true }: IncominfProps) => {
  const isAuth = useAuthStatus()
  return isAuth ? (
    layoutRequired ? (
      <PrivateLayout>
        <Outlet />
      </PrivateLayout>
    ) : (
      <Outlet />
    )
  ) : <Navigate to={"/login?redirect=" +( window as any).location.href} />
}

export default PrivateRoutes
