import { ActionExtended } from '../../_common/interfaces/ActionExtended'
import { ACTIONS } from '../../_config'

export interface FormReducer {
  formWorkflows: any
  controlTypes: any
  controlsProps: any
}

const initialState: FormReducer = {
  formWorkflows: null,
  controlTypes:null,
  controlsProps:{}
  
}

const formReducer = (state = initialState, action: ActionExtended) => {
  
  switch (action.type) {
    case ACTIONS.FORM.WORKFLOW:
      return {
        ...state,
        formWorkflows: action.payload.workflows
      }
    case ACTIONS.FORM.CONTROL_TYPES:
      return {
        ...state,
        controlTypes: action.payload.controlTypes
      }
    case ACTIONS.FORM.CONTROLS_PROPS:
      return {
        ...state,
        controlsProps: action.payload.controlsProps
      }
    default:
      return state
  }
}

export default formReducer
