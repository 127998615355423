import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useUser } from '../../_common/hooks/authHook';
import { useUserApi } from '../../_common/hooks/api/UserApiHook';
import { Button } from 'react-bootstrap';
import toast from 'react-hot-toast';
import RightSlideModal from '../../components/RightSlideModal';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import Error from '../../components/elements/formFieldError';
import { UseCaseTimeLine } from './UseCaseTimeLine';
import { URLS } from '../../_config';
import { getSelectedValue } from '../../_config/helpers';

interface props {
    show: boolean
    changeShowStatus: (status: boolean) => void

}
export const UsecaseReviewModal = ({ show, changeShowStatus = (status: any) => { } }: props) => {
    const { id } = useParams();
    const user: any = useUser();
    const userApi: any = useUserApi();
    const navigate = useNavigate();

    const { register, control, handleSubmit, watch, setValue, formState: { errors, isValid }, } = useForm({ reValidateMode: 'onChange' });
    const { control: innoControl, handleSubmit: innoHandleSubmit, setValue: innoSetValue, formState: { errors: innoErrors, isValid: innoIsValid }, } = useForm({ reValidateMode: 'onChange' });

    const [chooseDecision, setChooseDecision] = useState<any>([]);
    const [isDisabledAll, setIsDisabledAll] = useState<boolean>(false);
    const [reviewDetails, setReviewDetails] = useState<any>([]);
    const [allAirport, setAllAirport] = useState<any>([]);
    const [allRunway, setAllRunway] = useState<any>([]);
    const [allCaptain, setAllCaptain] = useState<any>([]);
    const [managers, setManagers] = useState<any>([]);
    const [reviewDetailsShowBy, setReviewDetailsShowBy] = useState<any>(0); //15
    const [innovationManager, setInnovationManager] = useState<boolean>(false); //15

    const status = watch('reviewStatus');

    const getUsecase = () => {
        return new Promise((resolve, reject) => {
            userApi.useCaseDetails({ urlParams: {companyID:user.companyID, id: id, userID: user.userID } }, (message: any, resp: any) => {
                resolve(true)
            }, (message: any) => { })
        })
    }

    const getChooseDecision = () => {
        userApi.getWorkFlowStatusLookUpsAll({ urlParams: { companyID: user?.companyID, type: 1 } }, (message: any, resp: any) => {
            setChooseDecision(resp.data.workFlowStatusLookUps.map((workFlowStatu: any) => {
                workFlowStatu.value = workFlowStatu.statusIntValue;
                workFlowStatu.label = `${workFlowStatu.statusTitle}`;
                return workFlowStatu;
            }));
        }, (message: any, resp: any) => {
            // Handle error...
        });
    }

    const getAllAirport = () => {
        userApi.airportList({ FKCompanyID: user?.companyID }, (message: any, resp: any) => {
            setAllAirport(resp.data.roles.map((airport: any) => {
                airport.value = airport.airportID;
                airport.label = `${airport.name}`;
                return airport;
            }));
        }, (message: any, resp: any) => {
            // Error...
        })
    }

    const getRunway = () => {
        userApi.getRunwayByAirport({ urlParams: { PageIndex: 1, PageSize: 999 } }, (message: any, resp: any) => {
            //console.log('ranway', resp);
            setAllRunway(resp.data.runway.map((runway: any) => {
                runway.value = runway.runwayID;
                runway.label = `${runway.title}`;
                return runway;
            }));

        }, (message: any, resp: any) => {
            // Handle error...
        });

    }

    const getAllCaptain = () => {
        userApi.employeeList({}, (message: any, resp: any) => {
            setManagers(resp.data.members.filter((captain: any) => {
                return captain.roleName == 'Innovation Manager';
            }).map((captain: any) => {
                captain.value = captain.userID;
                captain.label = `${captain.firstName} ${captain.lastName}`;
                return captain;
            }))
            setAllCaptain(resp.data.members.filter((captain: any) => {
                return captain.roleName == 'Innovator';
            }).map((captain: any) => {
                captain.value = captain.userID;
                captain.label = `${captain.firstName} ${captain.lastName}`;
                return captain;
            }));
        }, (message: any, resp: any) => {
            // Error...
        })
    }

    const getUsecaseReviewListDetails = () => {
        return new Promise((resolve, reject)=>{
            userApi.getUsecaseReviewList({ urlParams: { companyID: user?.companyID, usecaseID: id } }, (message: any, resp: any) => {
                setReviewDetails(resp.data)     
                if (resp.data.fkStatusID == 1) {
                    setValue('reviewStatus', { value: resp.data.fkStatusID, label: 'Proceed' });
                    setIsDisabledAll(true);
                }
                resolve(true)
            }, (message: any) => { })
        })
        
    }

    const submitData = (data: any) => {
        let formParam: any = {
            fkUseCaseID: id,
            fkRoleID: user.roleID,
            fkCompanyID: user.companyID,
            fkStatusID: data.reviewStatus?.value ? data.reviewStatus?.value : 0,
            StatusTitle: data.reviewStatus?.label ? data.reviewStatus?.label : '',
            airportManagerRoleID: 0,
            captainRoleID: 0,
            coCaptainRoleID: 0,
            userID: user.userID,
            fkAirportID: data.fkAirportID?.airportID ? data.fkAirportID?.airportID : 0,
            fkRunwayID: data.fkRunwayID?.runwayID ? data.fkRunwayID?.runwayID : 0,
            fkCaptainID: data.fkCaptainID?.userID ? data.fkCaptainID?.userID : 0,
            fkCoCaptainID: data.fkCoCaptainID?.userID ? data.fkCoCaptainID?.userID : 0,
            assignedInnovationManagerID: reviewDetails.innovationManagerID,
            review: data.description ? data.description : '',

        };

    

        return new Promise((resolve, reject) => {
            userApi.postUsecaseReviewData(formParam, (message: any, resp: any) => {
                resolve(message);
                getUsecase();
            }, (message: any, resp: any) => {
                reject(message);
            })
        })
    }

    const onSubmit = (data: any) => {
        toast.promise(
            submitData(data), {
            loading: 'Status Changing...',
            success: (msg: any) => {
                navigate(URLS.USE_CASE_LIST);
                return <b>{msg}</b>
            },
            error: (msg: any) => { return <b>{msg}</b> },
        });
    }
    
    const changeInnovationManager = (data:any) => {
        return new Promise((resolve:any, reject:any)=>{
            userApi.assignInnovationManagerToUsecase({
                "fkUseCaseID": id,
                "userID": user.userID,
                "fkCompanyID": user.companyID,
                "assignedInnovationManagerID": data.manager.value
            }, (message: any, resp: any) => {
                getUsecase();
                getUsecaseReviewListDetails().then(()=>{
                    setInnovationManager(false);
                    resolve(message);
                })
            }, (message: any, resp: any) => {
                reject(message);
            })
        })
    }

    const changeInnovationManagerHandler = (data:any) => {
        toast.promise(
            changeInnovationManager(data), {
            loading: 'Updating Innovation Manager...',
            success: (msg: any) => {
                return <b>{msg}</b>
            },
            error: (msg: any) => { return <b>{msg}</b> },
        });
    }

    useEffect(() => {
        setValue('description', '')
        setValue('fkAirportID', null)
        setValue('fkRunwayID', null)
        setValue('fkCaptainID', null)
        setValue('fkCoCaptainID', null)
    }, [status])

    useEffect(() => {
        if (user && show) {
            if (reviewDetailsShowBy != user.userID) {
                setReviewDetailsShowBy(user.userID);
                if (user.roleKey == 'INNOVATION_MANAGER_ROLE' || user.roleKey == 'ADMIN') {
                    getAllAirport();
                    getRunway();
                    getAllCaptain();
                    getChooseDecision();
                }
            }
            getUsecaseReviewListDetails();
        }
    }, [user, show])

    useEffect(() => {
        if (reviewDetails && show) {
            if (reviewDetails.innovationManagerID == 0) {
                setInnovationManager(true)
            }
        }
    }, [reviewDetails])

    return (
        <RightSlideModal visible={show} changeVisibility={changeShowStatus} heading='Submit Your Review'>
            {(user.roleKey == 'INNOVATION_MANAGER_ROLE' || user.roleKey == 'ADMIN') ? (
                <>
                    {reviewDetails.innovationManagerID != 0 && innovationManager == false ? (
                        <div>
                            <div className='d-flex align-items-center gap-1'>
                                <h6 className='m-0 text-grey'>Innovation Manager</h6>
                                {reviewDetails.fkStatusID == 0 && user.userID == reviewDetails?.innovationManagerID &&
                                    <Button size='sm' style={{
                                        fontSize: '10px',
                                        fontWeight: '600',
                                        padding: '2px 4px'
                                    }} onClick={() => { setInnovationManager(true) }}>Change</Button>
                                }
                            </div>
                            <h5>{(getSelectedValue(reviewDetails.innovationManagerID, 'value', managers) as any)?.label}</h5>

                        </div>
                    ) : null}
                    {innovationManager && (
                        <form className="mt-3 form-area" onSubmit={innoHandleSubmit(changeInnovationManagerHandler)}>
                            <div className="col-lg-12 col-md-12 col-12">
                                <div className="form-input mb-2">
                                    <label>Assign Innovation Manager</label>
                                    <Controller
                                        name="manager"
                                        control={innoControl}
                                        rules={{ required: 'Manager is required' }}
                                        render={({ field }) => (
                                            <div>
                                                <Select
                                                    {...field}
                                                    options={managers}
                                                    isMulti={false}
                                                    isDisabled={isDisabledAll}
                                                    placeholder={reviewDetails.fkStatusID == 0 ? (getSelectedValue(reviewDetails.innovationManagerID, 'value', managers) as any)?.label : 'Select...'}
                                                />
                                                <Error error={innoErrors.manager} />
                                            </div>
                                        )}
                                    />
                                </div>
                            </div>

                            <div className="col-12 d-flex justify-content-end gap-1 mt-0">
                                {reviewDetails.innovationManagerID != 0 &&
                                    <Button type="button" variant='dark' size='sm' onClick={() => { setInnovationManager(false) }}>
                                        Cancel
                                    </Button>
                                }
                                <Button type="submit" variant='info' size='sm'>
                                    Submit
                                </Button>
                            </div>


                        </form>
                    )}

                    <hr />

                    {!innovationManager && (reviewDetails.innovationManagerID == user.userID || user.roleKey == 'ADMIN') && (
                        <form className="mt-3 form-area" onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-12">
                                    <div className="form-input mb-3">
                                        <label>Status</label>
                                        <Controller
                                            name="reviewStatus"
                                            control={control}
                                            rules={{ required: 'Status is required' }}
                                            render={({ field }) => (
                                                <div>
                                                    <Select
                                                        {...field}
                                                        defaultValue={null}
                                                        options={chooseDecision}
                                                        isMulti={false}
                                                        isDisabled={isDisabledAll}
                                                        isClearable={true}
                                                    />
                                                    <Error error={errors.reviewStatus} />
                                                </div>
                                            )}
                                        />
                                    </div>
                                </div>

                                {status?.value >= 2 ? (
                                    <div className="col-12">
                                        <div className="form-input mb-3">
                                            <label>Review</label>
                                            <textarea className="form-control mb-3"  {...register("description", { required: "Review is required" })} rows={5} ></textarea>
                                            <Error error={errors.description} />
                                        </div>
                                    </div>
                                ) : null}

                                {status?.value == 1 ? (
                                    <>
              
                                        <div className="col-lg-12 col-md-12 col-12">
                                            <div className="form-input mb-3">
                                                <label>Chose Airport</label>
                                                <Controller
                                                    name="fkAirportID"
                                                    control={control}
                                                    rules={{ required: 'Airport is required' }}
                                                    render={({ field }) => (
                                                        <div>
                                                            <Select
                                                                {...field}
                                                                options={allAirport}
                                                                isMulti={false}
                                                                isDisabled={isDisabledAll}
                                                                placeholder={reviewDetails.fkStatusID == 1 ? reviewDetails.airPort : 'Select...'}
                                                            />
                                                            <Error error={errors.fkAirportID} />
                                                        </div>
                                                    )}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12 col-12">
                                            <div className="form-input mb-3">
                                                <label>Chose Runway</label>
                                                <Controller
                                                    name="fkRunwayID"
                                                    control={control}
                                                    rules={{ required: 'Runway is required' }}
                                                    render={({ field }) => (
                                                        <div>
                                                            <Select
                                                                {...field}
                                                                options={allRunway}
                                                                isMulti={false}
                                                                isDisabled={isDisabledAll}
                                                                placeholder={reviewDetails.fkStatusID == 1 ? (getSelectedValue(reviewDetails.fkRunwayID, 'value',allRunway))?.label : 'Select.......'}
                                                            />
                                                            <Error error={errors.fkRunwayID} />
                                                        </div>
                                                    )}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12 col-12">
                                            <div className="form-input mb-3">
                                                <label>Choose Captain</label>
                                                <Controller
                                                    name="fkCaptainID"
                                                    control={control}
                                                    /* rules={{ required: 'Captain is required' }} */
                                                    render={({ field }) => (
                                                        <div>
                                                            <Select
                                                                {...field}
                                                                options={allCaptain}
                                                                isMulti={false}
                                                                isDisabled={isDisabledAll}
                                                                placeholder={reviewDetails.fkStatusID == 1 ? reviewDetails.captain : 'Select...'}
                                                            />
                                                            <Error error={errors.fkCaptainID} />
                                                        </div>
                                                    )}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12 col-12">
                                            <div className="form-input mb-3">
                                                <label>Choose Co-Captain</label>

                                                <Controller
                                                    name="fkCoCaptainID"
                                                    control={control}
                                                    /* rules={{ required: 'Co-Captain is required' }} */
                                                    render={({ field }) => (
                                                        <div>
                                                            <Select
                                                                {...field}
                                                                options={allCaptain}
                                                                isMulti={false}
                                                                isDisabled={isDisabledAll}
                                                                placeholder={reviewDetails.fkStatusID == 1 ? reviewDetails.coCaptain : 'Select...'}
                                                            />
                                                            <Error error={errors.fkCoCaptainID} />
                                                        </div>
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    </>
                                ) : null}

                                {!isDisabledAll && (
                                    <div className="col-12 d-flex justify-content-end gap-3 mt-4">
                                        <Button type="submit" variant='info' className="d-flex align-items-center gap-2">
                                            Submit <i className="arrow-right"></i>
                                        </Button>
                                    </div>
                                )}
                            </div>
                        </form>
                    )}

                    
                </>
            ) : null}
            {reviewDetails && (<UseCaseTimeLine props={reviewDetails} />)}
            
        </RightSlideModal>
    )
}
