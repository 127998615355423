import React, { useEffect, useState } from 'react'
import Header from '../../components/header/Header'
import Footer from '../../components/footer/Footer'
import { Link } from 'react-router-dom'
import { useUserApi } from '../../_common/hooks/api/UserApiHook'
import Swal from 'sweetalert2'
import { toast } from 'react-hot-toast'
import { URLS } from '../../_config'
import { MdOutlineModeEdit } from "react-icons/md";
import { MdDeleteOutline } from "react-icons/md";
import TableLoader from '../../components/tableLoader'
import Pagination from '../../components/pagination/Pagination'
import TitleBar from '../../components/titleBar/TitleBar'
import { useUserPermissions } from '../../_common/hooks/authHook'
import { getNameOnly } from '../../_config/helpers'

export const AirportList = () => {

    const [allAirportList, setAllAirportList] = useState<any>(null);
    const userApi = useUserApi();
    const permissions:any = useUserPermissions();

    const [page, setPage] = useState<number>(1)
    const [total, setTotal] = useState<number>(0)
    const [pageLimit, setPageLimit] = useState<number>(999)

    const getAirportList = () => {
        userApi.airportList({ pageNo: page, pageSize: pageLimit }, (message: any, resp: any) => {
            setTotal(resp.totalRecords)
            setAllAirportList(resp.data.roles);
        }, (message: any) => {
            setAllAirportList([])
        })
    }

    useEffect(() => {
        getAirportList()
    }, [page]);

    const submitData = (data: any) => {
        let formParam = {
            "airportID": data.airportID,
            "fkCompanyID": data.fkCompanyID,
        };
        return new Promise((resolve, reject) => {
            userApi.airportDelete(formParam, (message: any, resp: any) => {
                resolve(message);
            }, (message: any, resp: any) => {
                reject(message);
            })
        })
    }

    const deleteItem = (airportID: number, companyID: number) => {
        let data = {
            "airportID": airportID,
            "fkCompanyID": companyID
        };

        Swal.fire({
            title: 'Confirm Delete',
            text: `Are you sure you want to delete this airport?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#B32F84',
            cancelButtonColor: '#44546A',
            confirmButtonText: `Yes, delete it!`
        }).then((result) => {
            if (result.isConfirmed) {
                toast.promise(
                    submitData(data), {
                    loading: 'Loading...',
                    success: (msg: any) => { getAirportList(); return <b>{msg}</b> },
                    error: (msg: any) => { return <b>{msg}</b> },
                }
                );

            }
        });
    }

    return (
        <React.Fragment>
            <Header></Header>
            <div className="main-container flex-grow-1">
                <div className="container">
                    <div className="page-title py-2">
                        <div className="row align-items-center">
                            <div className="col-sm-12">

                                <TitleBar title={'Airport List'} breadcrumbs={{
                                    'Airport List': '#'
                                }} >
                                    {permissions?.menus.airport.write &&
                                        <Link to={URLS.AIRPORT.CREATE} className="btn btn-info py-2 add-more-files-btn">Create Airport</Link>
                                    }
                                </TitleBar>

                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="two-panel-wrapper ">
                                            <div className="right-panel">
                                                <div className="white-box">
                                                    <div className="table-responsive list-table full-width">
                                                        <table className="table table-striped">
                                                            <thead>
                                                                <tr className="align-middle">
                                                                    <th scope="col" className="text-center">#</th>
                                                                    <th scope="col" className='text-left'>Airport Name</th>
                                                                    <th scope="col">Owner's Name</th>
                                                                    <th scope="col" className='w-100px'>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {Array.isArray(allAirportList) && allAirportList.length > 0 && (
                                                                    allAirportList.map((airport: any, i: number) => (
                                                                        <tr className="align-middle h-60" key={airport.airportID}>
                                                                            <td className="text-center">{i + 1}</td>
                                                                            <td>{airport.name}</td>
                                                                            <td>{getNameOnly(airport.userFullName)}</td>
                                                                            <td>
                                                                                <div className="d-flex gap-3">
                                                                                {permissions?.menus.airport.write && <Link to={`/airport/${airport.airportID}`} className="react-icon-grey"><MdOutlineModeEdit /></Link>}
                                                                                {permissions?.menus.airport.delete &&<Link to="#" onClick={() => { deleteItem(airport.airportID, airport.fkCompanyID) }} className="react-icon-grey"><MdDeleteOutline /></Link>}
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    ))
                                                                )}
                                                            </tbody>
                                                        </table>
                                                        <TableLoader data={allAirportList} />
                                                        <Pagination total={total} page={page} setPage={setPage} pageLimit={pageLimit} />

                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer></Footer>
        </React.Fragment>
    )
}
