import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useWorkFlowApi } from '../../../../_common/hooks/api/WorkFlowApiHook';
import RightSlideModal from '../../../../components/RightSlideModal';
import { useUser } from '../../../../_common/hooks/authHook';
import DynamicFormView from '../../../../components/dynamicForm/dynamicFormView';
import FilePreview from '../../../../components/filePreview';
import { getNameOnly, mergeListData } from '../../../../_config/helpers';


export const RunwayOverviewTestPlan = () => {
    const { id } = useParams();
    const workFlowApi = useWorkFlowApi();
    const [rightPannel, setRightPannel] = useState<boolean>(false)
    const [criteria, setCriteria] = useState<any>(null)
    const user: any = useUser();

    const [rolesResponsibilitiesList, setRolesResponsibilitiesList] = useState<any>(null)
    const [testCriteriaList, setTestCriteriaList] = useState<any>(null)

    const getTestingRolesResponsibilitiesByUsecase = () => {
        workFlowApi.getTestingRolesResponsibilitiesByUsecase({ urlParams: { CompanyID: user.companyID, UseCaseID: id } }, (message: any, resp: any) => {
            setRolesResponsibilitiesList(resp.data?.userList?.map((tester: any) => {
                tester.label = getNameOnly(tester.userFullName)
                tester.value = tester.testingRolesResponsibilitiesID
                return tester;
            }))
        }, (message: any) => {
            setRolesResponsibilitiesList([]);
        })
    }

    const getTestCriteriaList = () => {
        workFlowApi.getTestCasesAcceptanceCriteriaByUsecase({ urlParams: { companyID: user.companyID, UseCaseID: id } }, (message: any, resp: any) => {
            setTestCriteriaList(mergeListData('testCasesAcceptanceCriteriaID','fileURL',resp.data?.testCriteriaList))
        }, (message: any) => {
            setTestCriteriaList([]);
        })
    }

    useEffect(() => {
        getTestingRolesResponsibilitiesByUsecase();
        getTestCriteriaList();
    }, [id])

    useEffect(() => {
        if (criteria) {
            setRightPannel(true)
        } else {
            setRightPannel(false)
        }
    }, [criteria])

    useEffect(() => {
        if (!rightPannel) {
            setCriteria(null)
        }
    }, [rightPannel])


    return (
        <React.Fragment>
            {
                ((rolesResponsibilitiesList && rolesResponsibilitiesList.length > 0) ||
                    (testCriteriaList && testCriteriaList.length > 0)) && (
                    <>
                        <div className="white-box mb-3">
                            <h6>Test Plan</h6><hr />

                            {
                                rolesResponsibilitiesList && rolesResponsibilitiesList.length > 0 && (
                                    <>
                                        <h5 className="sub_title">Testing Roles and Responsibilities</h5>
                                        <div className="table-responsive list-table">
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr className="align-middle">
                                                        <th scope="col" className="w-10 text-center">   # </th>
                                                        <th scope="col">Role</th>
                                                        <th scope="col" className="w-50">Name</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        rolesResponsibilitiesList.map((item: any, i: any) =>
                                                            <tr className="align-middle h-60" key={`rolesResponsibilitiesList ${i}`}>
                                                                <td className='text-center'>{i + 1}</td>
                                                                <td>{item?.roleName}</td>
                                                                <td>{getNameOnly(item?.userFullName)}</td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </>
                                )
                            }

                            {
                                testCriteriaList && testCriteriaList.length > 0 && (
                                    <>
                                        <h5 className="sub_title mt-3">Test Cases And Acceptance Criteria</h5>
                                        <div className="table-responsive list-table resp-table">
                                            <div className=" list-table resp-table" >
                                                <table className="table table-striped">
                                                    <thead>
                                                        <tr className="align-middle">
                                                            <th scope="col" className="w-10 text-center"> # </th>
                                                            <th scope="col" className="w-50">Test Case </th>
                                                            <th scope="col">File</th>
                                                            <th scope="col">Assign To</th>
                                                            <th scope="col">Created By</th>
                                                            <th scope="col"> </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            testCriteriaList.map((itemCriteria: any, i: any) => (
                                                                <tr className="align-middle h-60" key={`itemCriteria ${i}`}>
                                                                    <td className="text-center">
                                                                        {i + 1}
                                                                    </td>
                                                                    <td><h6 onClick={() => setCriteria(itemCriteria)} className='cursor-pointer'>{itemCriteria?.testCasesAcceptanceCriteria}</h6>
                                                                        <p>{itemCriteria?.description}</p>
                                                                    </td>
                                                                    <td  >
                                                                        <FilePreview url={itemCriteria?.fileURL} size={32} />
                                                                    </td>
                                                                    <td  >
                                                                        {itemCriteria?.assignUserName}
                                                                    </td>
                                                                    <td  >
                                                                        {itemCriteria?.addedByName}
                                                                    </td>
                                                                    <td>
                                                                        {/* {user.userID == itemCriteria.assignUserID ?
                                                    <Button size='sm' onClick={() => setCriteria(itemCriteria)}>Submit Test Cases</Button>
                                                    : null} */}

                                                                    </td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </>
                                )
                            }

                        </div>

                        <RightSlideModal visible={rightPannel} changeVisibility={setRightPannel} heading='Submit test case'>
                            <DynamicFormView editMode={true} answerID={criteria?.testCasesCriteriaAnswerID} _workflow='taxi-to-runway' useCaseID={id} _module='test-plan--tester-case' _form='test-plan--tester-case' onSubmitSuccess={() => { setRightPannel(false); getTestCriteriaList() }} />
                            <DynamicFormView editMode={true} answerID={criteria?.testCasesAnswerID != 0 ? criteria?.testCasesAnswerID : null} _workflow='in-the-air' useCaseID={id} _module='in-field-testing' _form='in-field-testing' onSubmitSuccess={() => { setCriteria(null); getTestCriteriaList() }} />
                            <br />
                        </RightSlideModal>
                    </>
                )
            }

        </React.Fragment>

    )
}
