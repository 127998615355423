import React, { useEffect, useState } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import TitleBar from "../../components/titleBar/TitleBar";
import { usecaseFilterData } from "../../_config/dataTypes";

import { PureComponent } from 'react';
import {
    BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
    ComposedChart,
    Line,
    Area,
} from 'recharts';
import { useUserApi } from "../../_common/hooks/api/UserApiHook";
import { useUser } from "../../_common/hooks/authHook";
import { DataFiltersPeriodDashboard } from "../../components/dataFilters/DataFiltersPeriodDashboard";


const PeriodComparisonDashboard = () => {
    const userApi = useUserApi()
    const user: any = useUser();

    const [filter, setFilter] = useState<usecaseFilterData>({})

    const [avgTotalDuration, setAvgTotalDuration] = useState<any>([])
    const [avgDurationBySection, setAvgDurationBySection] = useState<any>([])
    const [sectionPhaseTable, setSectionPhaseTable] = useState<any>([])
    const [sectionPhaseTableTotal, setSectionPhaseTableTotal] = useState<any>({})
    const [avgDurationPhase, setAvgDurationPhase] = useState<any>([])

    const getAvgTotalDuration = () => {
        userApi.dashboardAvgTotalDuration({ fkCompanyID: user.companyID, ...filter },
            (message: any, resp: any) => {
                const tempArr = resp.data;
                tempArr['Date Range 1'] = tempArr.period1
                tempArr['Date Range 2'] = tempArr.period2
                setAvgTotalDuration([tempArr]);
            }, (message: any) => {
                setAvgTotalDuration([])
            })
    }

    const getAvgDurationBySection = () => {
        userApi.dashboardAvgDurationBySection({ fkCompanyID: user.companyID, ...filter },
            (message: any, resp: any) => {
                const tempArr = resp.data.pcdAvgDurationBySectionList;
                tempArr.length > 0 && tempArr.map((tempItem: any) => {
                    tempItem['Date Range 1'] = tempItem.period1
                    tempItem['Date Range 2'] = tempItem.period2
                })

                setAvgDurationBySection(tempArr);
            }, (message: any) => {
                setAvgDurationBySection([])
            })
    }

    const getSectionPhaseTable = () => {
        userApi.dashboardSectionPhaseTable({ fkCompanyID: user.companyID, ...filter },
            (message: any, resp: any) => {
                const tempArr = resp.data.pcdSectionList;
                setSectionPhaseTable(tempArr);

                getCheckedFilter(tempArr[0])
                const tableTotalTemp = {
                    period1: 0,
                    period2: 0,
                    percentage: 0,
                }

                tempArr.length > 0 && tempArr.map((tempItem: any) => {
                    tableTotalTemp.period1 = tableTotalTemp.period1 + tempItem.period1
                    tableTotalTemp.period2 = tableTotalTemp.period2 + tempItem.period2
                    tableTotalTemp.percentage = tableTotalTemp.percentage + tempItem.percentage
                })
                setSectionPhaseTableTotal(tableTotalTemp)

            }, (message: any) => {
                setSectionPhaseTable([])
            })
    }


    useEffect(() => {
        getAvgTotalDuration()
        getAvgDurationBySection()
        getSectionPhaseTable()
    }, [filter])

    const getCheckedFilter = (data: any) => {
        const pcdPhaseList = data?.pcdPhaseList;
        let tempArr = [];
        tempArr = pcdPhaseList?.filter((tempItem: any) => {
            tempItem['Date Range 1'] = tempItem.period1
            tempItem['Date Range 2'] = tempItem.period2
            tempItem['phaseNamePercentage'] = tempItem.phaseName + ` (${tempItem.percentage}%)`
            return tempItem
        });

        setAvgDurationPhase(tempArr)
    }

    return (
        <React.Fragment>
            <Header></Header>
            <div className="main-container flex-grow-1">
                <div className="container">

                    <TitleBar title={'Date Range Comparison dashboard'} breadcrumbs={{
                        'Date Range Comparison dashboard': '#'
                    }} >

                    </TitleBar>

                    <DataFiltersPeriodDashboard setFilter={setFilter} dates={false} />


                    <div className=" executive-dashboard">
                        <div className="dashboard-top-part mb-5">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="dashboard-top-part-body flex-1">
                                        <h3>Avg Total Duration Comparison by Date Range</h3>
                                        <div style={{ height: '250px' }}>
                                            <ResponsiveContainer width="100%" height="100%">
                                                <BarChart
                                                    width={500}
                                                    height={300}
                                                    data={avgTotalDuration}
                                                    margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                                                >
                                                    <CartesianGrid strokeDasharray="3 3" />
                                                    <XAxis dataKey="title" />
                                                    <YAxis />
                                                    <Tooltip />
                                                    <Legend />
                                                    <Bar dataKey="Date Range 1" fill="#B32F84" activeBar={<Rectangle fill="#dd51ac" stroke="blue" />} />
                                                    <Bar dataKey="Date Range 2" fill="#2f3144" activeBar={<Rectangle fill="#5e6cc9" stroke="purple" />} />
                                                </BarChart>
                                            </ResponsiveContainer>

                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="dashboard-top-part-body flex-1">
                                        <h3>Avg Duration (days) by Section</h3>
                                        <div style={{ height: '250px' }}>

                                            <ResponsiveContainer width="100%" height="100%">
                                                <BarChart
                                                    width={500}
                                                    height={300}
                                                    data={avgDurationBySection}
                                                    margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                                                >
                                                    <CartesianGrid strokeDasharray="3 3" />
                                                    <XAxis dataKey="sectionName" />
                                                    <YAxis />
                                                    <Tooltip />
                                                    <Legend />
                                                    <Bar dataKey="Date Range 1" fill="#B32F84" activeBar={<Rectangle fill="#dd51ac" stroke="blue" />} />
                                                    <Bar dataKey="Date Range 2" fill="#2f3144" activeBar={<Rectangle fill="#5e6cc9" stroke="purple" />} />
                                                </BarChart>
                                            </ResponsiveContainer>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-4">
                                    <div className="dashboard-top-part-body flex-1 p-0 mt-3">
                                        <div className="table-responsive">
                                            <table className="table priod_table">
                                                <thead>
                                                    <tr>
                                                        <th>&nbsp;</th>
                                                        <th>Section</th>
                                                        <th>Date Range 1</th>
                                                        <th>Date Range 2</th>
                                                        <th>% Change</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        sectionPhaseTable && sectionPhaseTable.length > 0 &&
                                                        sectionPhaseTable.map((sectionTableItem: any, index: number) => (
                                                            <React.Fragment key={`sectionTableItem${index}`}>
                                                                <tr>
                                                                    <td>
                                                                        <input type="radio" name="sectionTableItem" defaultChecked={(index == 0) ? true : false}
                                                                            onClick={() => getCheckedFilter(sectionTableItem)} />
                                                                    </td>
                                                                    <td>{sectionTableItem?.sectionName}</td>
                                                                    <td>{sectionTableItem?.period1}</td>
                                                                    <td>{sectionTableItem?.period2}</td>
                                                                    <td>{sectionTableItem?.percentage}%</td>
                                                                </tr>
                                                                {
                                                                    sectionTableItem?.pcdPhaseList && sectionTableItem?.pcdPhaseList.length > 0 &&

                                                                    <tr >
                                                                        <td>  &nbsp; </td>
                                                                        <td>
                                                                            {
                                                                                sectionTableItem?.pcdPhaseList.map((phaseItem: any, i: number) => (
                                                                                    <div key={`phaseName${i}`}>{phaseItem?.phaseName}</div>
                                                                                ))
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                sectionTableItem?.pcdPhaseList.map((phaseItem: any, i: number) => (
                                                                                    <div key={`period1${i}`}>{phaseItem?.period1}</div>
                                                                                ))
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                sectionTableItem?.pcdPhaseList.map((phaseItem: any, i: number) => (
                                                                                    <div key={`period2${i}`}>{phaseItem?.period2}</div>
                                                                                ))
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                sectionTableItem?.pcdPhaseList.map((phaseItem: any, i: number) => (
                                                                                    <div key={`percentage${i}`}>{phaseItem?.percentage}%</div>
                                                                                ))
                                                                            }
                                                                        </td>
                                                                    </tr>

                                                                }

                                                            </React.Fragment>
                                                        ))
                                                    }


                                                    <tr>
                                                        <td> &nbsp;</td>
                                                        <td>Total</td>
                                                        <td>{sectionPhaseTableTotal?.period1}</td>
                                                        <td>{sectionPhaseTableTotal?.period2}</td>
                                                        <td>{sectionPhaseTableTotal?.percentage}%</td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8">

                                    <div className="dashboard-top-part-body flex-1 mt-3">
                                        <h3>Avg Duration (days) by Phase</h3>
                                        <div style={{ height: '450px' }}>
                                            <ResponsiveContainer width="100%" height="100%">
                                                <ComposedChart
                                                    layout="vertical"
                                                    width={500}
                                                    height={400}
                                                    data={avgDurationPhase}
                                                    margin={{ top: 20, right: 20, bottom: 20, left: 50 }}
                                                >
                                                    <CartesianGrid stroke="#f5f5f5" />
                                                    <XAxis type="number" />
                                                    <YAxis dataKey="phaseNamePercentage" type="category" scale="band" />
                                                    <Tooltip />
                                                    <Legend />
                                                    <Bar dataKey="Date Range 1" barSize={15} fill="#B32F84" />
                                                    <Bar dataKey="Date Range 2" barSize={15} fill="#2f3144" />
                                                </ComposedChart>
                                            </ResponsiveContainer>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>




                    </div>
                </div>
            </div>
            <Footer></Footer>
        </React.Fragment>
    );
}

export default PeriodComparisonDashboard;