import React, { useEffect, useState } from 'react'
import { Accordion, AccordionItem, Button, Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form';
import { Link, useLocation } from 'react-router-dom'
import Error from '../components/elements/formFieldError';
import toast from 'react-hot-toast';
import { useFormApi } from '../_common/hooks/api/FormApiHook';
import { useUser } from '../_common/hooks/authHook';

interface props {
    show: boolean,
    useCaseID: any,
    solutionID: any,
    onClose: (status:boolean)=>void,
    receiveNewData:(data:any)=>void
}

export default function ProductListModal({ show, onClose, receiveNewData, useCaseID, solutionID }: props) {
    const [visible,setVisible] = useState<boolean>(false);
    const handleClose = () => {setVisible(false);onClose(false)};

    const user:any = useUser();

    const { control, register, handleSubmit, reset, getValues, formState: { errors , isValid },  } = useForm()
    const formApi = useFormApi();

    const addProduct = (data:any) => {
        return new Promise((resolve, reject) => {
            formApi.addProduct({
               
                "fkCompanyID": user.companyID,
                "fkTenantID": user.tenantID,
                "fkUseCaseID": useCaseID,
                "fkSolutionID": solutionID,
                "productName": data.name,
                "productType": data.type,
                "productDescription": data.desc,
                "unit": data.definition,
                "unitCost": data.cost,
                "addedBy": user.userID,
                "isActive": true,
            },(msg:any,resp:any)=>{
                receiveNewData(resp.data)
                resolve(msg)
            },(msg:any)=>{})
            reject('Validation Error')
        })
    }

    const handleAddProduct = (data: any) => {
        if (isValid) {
            toast.promise(
                addProduct(data), {
                loading: 'Saving...',
                success: (msg: any) => {

                    reset();
                    handleClose();

                    return <b>{msg}</b>
                },
                error: (msg: any) => { return <b>{msg}</b> },
            }
            );
        } else {
            //form.current.submit()
            console.log('Not valid')
        }
    }
   
    useEffect(() => {
        setVisible(show)
    }, [show])



    return (
        <Modal show={visible} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Product</Modal.Title>
        </Modal.Header>
            <Modal.Body>
                <form className="form-area" id="addProductForm" onSubmit={handleSubmit(handleAddProduct)}>
                    <label>Product Name</label>
                    <input type="text" className="form-control mb-3" placeholder="Insert your Product Name here" {...register("name", { required: true })} />
                    <Error error={errors.name}/>
                    <label>Product Type</label>
                    <input type="text" className="form-control mb-3" placeholder="Hardware" {...register("type", { required: true })} />
                    <Error error={errors.type}/>
                    <label>Product Description</label>
                    <textarea className="form-control mb-3" rows={5} {...register("desc", { required: true })}></textarea>
                    <Error error={errors.desc}/>
                    <label>Unit Definition</label>
                    <input type="text" className="form-control mb-3" placeholder="Flat fee" {...register("definition", { required: true })} />
                    <Error error={errors.definition}/>
                    <label>Unit Cost ($)</label>
                    <input type="text" className="form-control" placeholder="Insert your unit cost here" {...register("cost", {
                        required: true, pattern: {
                            value: /\d/,
                            message: "only number allowed"
                        }
                    })} />
                    <Error error={errors.cost}/>
                </form>
            </Modal.Body>
            <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" type='submit' form='addProductForm'>
            Add Product
          </Button>
        </Modal.Footer>
      </Modal>
    )
}