import React, { HtmlHTMLAttributes } from 'react'
import { Link } from 'react-router-dom';
import { URLS } from '../_config';
import { usePhases } from '../_common/hooks/masterHook';
import { useUsecase } from '../_common/hooks/usecaseHook';

interface props extends HtmlHTMLAttributes<HTMLDivElement> {
    phase: string;
}
const PhaseGuard: React.FC<props> = ({ children, phase, ...args }) => {
    const usecase: any = useUsecase()
    const phases: any = usePhases();
    /* return (
        <React.Fragment>
            {(phases.indexOf(phase) != -1 && phases.indexOf(phase) < phases.indexOf(usecase.currentPhase) )?
            <React.Fragment>{children}</React.Fragment>
            :null }
        </React.Fragment>
    ) */

    return (
        <React.Fragment>{children}</React.Fragment>
    )
}

export default PhaseGuard;
