import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { showDate } from '../../../../_config/functions';
import { useWorkFlowApi } from '../../../../_common/hooks/api/WorkFlowApiHook';
import { useUser } from '../../../../_common/hooks/authHook';
import TableLoader from '../../../../components/tableLoader';
import { getNameOnly } from '../../../../_config/helpers';


export const RunwayOverviewProcurementPlan = () => {
    const { id } = useParams();
    const workFlowApi = useWorkFlowApi();
    const user: any = useUser();
    const [procurementPlanList, setProcurementPlanList] = useState<any>(null)

    const getAllProcurementPlan = () => {
        workFlowApi.getAllProcurementPlan({ urlParams: { companyID: user.companyID, UseCaseID: id } }, (message: any, resp: any) => {
            setProcurementPlanList(resp.data?.procurementPlanList.filter((planItem: any) => {
                return planItem?.procurementPlanID > 0
            }))
        }, (message: any) => {
            setProcurementPlanList([]);
        })
    }

    useEffect(() => {
        getAllProcurementPlan();
    }, [id])

    return (
        <React.Fragment>
            {
                procurementPlanList && procurementPlanList.length > 0 && (
                    <div className="white-box mb-3">
                        <h6>Procurement Plan</h6><hr />

                        <div className="row">
                            {
                                procurementPlanList.map((item: any, index: number) =>
                                    <div className="col-md-4 mb-3" key={Math.random()}>
                                        <div className="overview_box">
                                            <div className="d-flex ">
                                                <div className="circel">{index + 1}</div>
                                                <div className="p-0 m-0">
                                                    <p className="mb-2">
                                                        <strong>{item.procurementPlan}</strong>
                                                    </p>
                                                    <p className="mb-2">Uploaded by {getNameOnly(item.userFullName)} on {showDate(item.addedDate)}</p>
                                                    <p className="d-flex align-items-center mb-2">
                                                        <strong className="me-2">Download:</strong>
                                                        <a href={item.fileURL} download target='_blank'
                                                        ><i className="upload-icon"></i></a>
                                                    </p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                )
            }
        </React.Fragment>
    )
}
