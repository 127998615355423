/* 
    this timer component used for view timer object, also idle detection
*/

import React, { useEffect, useRef, useState } from "react";
import Icon from "../icon";
import Swal from "sweetalert2";
import useIdleTimeout from "../../_common/hooks/idleTimer";


interface props {
    startTime: Date | null
}

export default function Timer({ startTime }: props) {

    const [currentTime, setCurrentTime] = useState<any>(new Date())
    const [mount, setMount] = useState<boolean>(false)
    const requestRef: any = useRef();

    
    


    const updateTimer = (time: any) => {
        // The 'state' will always be the initial value here
        setCurrentTime(new Date())
        requestRef.current = requestAnimationFrame(updateTimer);
    }

    const getTime = () => {
        if (startTime) {
            let totalSec = Math.round((currentTime.getTime() - startTime?.getTime()) / 1000);
            let min = Math.floor(totalSec / 60)
            let sec = (totalSec % 60)
            let displayTime = `${(min < 10) ? '0' : ''}${min}:${(sec < 10) ? '0' : ''}${sec}`
            return displayTime;
        } else {
            return ''
        }

    }

    /* const inactivityTime = () => {
        let time: any;

        document.onmousemove = resetTimer;
        document.onmousedown = resetTimer;
        document.ontouchstart = resetTimer;
        document.onclick = resetTimer;
        document.onkeydown = resetTimer;
        document.addEventListener('scroll', resetTimer, true); // improved; see comments

    

        function resetTimer() {
            clearTimeout(time);
            if(mount){
                time = setTimeout(() => {
                    if (mount) {
                        Swal.fire({
                            title: 'Confirm Delete',
                            text: `Are you sure you want to delete this airport?`,
                            icon: 'warning',
                            showCancelButton: false,
                            showConfirmButton: false,
                            confirmButtonColor: '#B32F84',
                            cancelButtonColor: '#44546A',
                            confirmButtonText: `Yes, delete it!`
                        }).then((result) => { });
                    }

                }, 4000)
            }
            
        }
    }; */
    const handleIdle = () => {
        Swal.fire({
            title: 'IDLE',
            text: `Are you still working?`,
            icon: 'warning',
            showCancelButton: false,
            showConfirmButton: true,
            confirmButtonColor: '#B32F84',
            cancelButtonColor: '#44546A',
            confirmButtonText: `Yes, Working!`
        }).then((result) => {
            idleTimer.reset();
         });
    }

    const {idleTimer} = useIdleTimeout({ onIdle: handleIdle, idleTime: 120 })

    useEffect(() => {
        setMount(true)
        requestRef.current = requestAnimationFrame(updateTimer);
        return () => {
            cancelAnimationFrame(requestRef.current);
        }
    }, []);


    return <React.Fragment>
        {startTime && (
            <div className="d-flex align-items-center gap-1">
                <Icon name="clock" />
                <div style={{ fontSize: '18px', fontWeight: '600', minWidth: '50px', textAlign: 'right' }}> {getTime()}</div>
            </div>
        )}


    </React.Fragment>
}
