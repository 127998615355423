import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useWorkFlowApi } from '../../../../_common/hooks/api/WorkFlowApiHook';
import { useUser } from '../../../../_common/hooks/authHook';
import RightSlideModal from '../../../../components/RightSlideModal';
import { showDate } from '../../../../_config/functions';
import { Button } from 'react-bootstrap';
import AddScore from './addScore';
import { useUsecase } from '../../../../_common/hooks/usecaseHook';
import { useUserApi } from '../../../../_common/hooks/api/UserApiHook';
import TableLoader from '../../../../components/tableLoader';
import Icon from '../../../../components/icon';
import { getNameOnly } from '../../../../_config/helpers';

export const HangerOverviewRankingPrioritization = () => {
    const { id } = useParams();
    const workFlowApi = useWorkFlowApi();
    const userApi = useUserApi();
    const user: any = useUser();
    const usecase: any = useUsecase();
    const [scoringPrioritizationList, setScoringPrioritizationList] = useState<any>(null)
    const [scorer, setScorer] = useState<any>(null);

    const getScoringPrioritizationList = () => {
        return new Promise((resolve: any, reject: any) => {
            workFlowApi.getScoringPrioritizationByUsecase({ urlParams: { CompanyID: user.companyID, UseCaseID: id }, PageNo: 1, PageSize: 999 }, (message: any, resp: any) => {
                setScoringPrioritizationList(resp.data?.scorerList)
                resolve(resp.data?.scorerList)
            }, (message: any) => {
                setScoringPrioritizationList([]);
                resolve([])
            })
        })
    }

    const getUsecase = () => {
        return new Promise((resolve, reject) => {
            userApi.useCaseDetails({ urlParams: { companyID: user.companyID, id: id, userID: user.userID } }, (message: any, resp: any) => {
                resolve(true)
            }, (message: any) => { })
        })
    }


    const savePhaseChangeData = (data: any) => {
        return new Promise((resolve, reject) => {
            userApi.usecasePhaseChange(data, (message: any, resp: any) => {
                getUsecase().then(() => { resolve(message) }).catch((msg) => { reject(msg) })
            }, (message: any) => {
                //reject(message);
                //onSuccess();
            })
        })
    }

    const phaseChange = () => {
        let formParam = {
            "useCaseID": usecase.useCaseID,
            "fkCompanyID": user.companyID,
            "nextPhase": 'Hanger Decision',
            "updatedBy": user.userID,
        };
        savePhaseChangeData(formParam)
    }


    useEffect(() => {
        getScoringPrioritizationList();
    }, [id])

    const handleCloseClick = () => {
        setScorer(null);
    };

    return (
        <React.Fragment>
            {
                scoringPrioritizationList?.length > 0 && (
                    <div className="white-box mb-3">
                        <h6>Ranking and Prioritization</h6> <hr />

                        <div className="table-responsive list-table ">
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col" className='text-center'>#</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Role</th>
                                        <th scope="col">Date</th>
                                        <th scope="col" className="text-center">Scoring Complete</th>
                                        <th scope="col" className='text-center'>Score Value</th>
                                        <th scope="col" className='text-center'></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        scoringPrioritizationList?.map((empRow: any, index: any) => (
                                            <tr key={`scoringPrioritization${index}`}>
                                                <td className='text-center'>{index + 1}</td>
                                                <td>{ getNameOnly(empRow?.userFullName)  }</td>
                                                <td>{empRow?.roleName}</td>
                                                <td>{showDate(empRow?.addedDate)}</td>
                                                <td className="text-center">
                                                    <span className="badge badge-secondary">
                                                        {empRow?.isScoreComplete ? 'Yes' : 'No'}
                                                    </span>
                                                </td>
                                                <td className='text-center'>{(
                                                    empRow?.fkUserID == user.userID ||
                                                    usecase?.fkAitportManagerID == user.userID ||
                                                    usecase?.fkCoCaptainID == user.userID ||
                                                    usecase?.fkCaptainID == user.userID ||
                                                    usecase?.assignedInnovationManagerID == user.userID
                                                ) ? empRow?.score : '*'}</td>
                                                <td >
                                                    {
                                                        (empRow?.fkUserID == user.userID && usecase.fkStatusID != 42 && usecase.fkStatusID != 47) ?
                                                            <Button size="sm" className='d-flex gap-1 align-items-center' onClick={() => setScorer(empRow)}>Score <i className="arrow-right"></i></Button>
                                                            : null
                                                    }
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>

                        {/* <!-- right-bar2 --> */}
                        <RightSlideModal visible={scorer != null} changeVisibility={handleCloseClick}
                            heading='Submit Your Score Here' timer={true}>
                            <AddScore scorer={scorer} onSubmitSuccess={() => {
                                handleCloseClick();
                                getScoringPrioritizationList().then((scorer: any) => {
                                    getUsecase();
                                    if (scorer.length) {
                                        let completed = true;
                                        scorer.map((score: any) => {
                                            if (!score.isScoreComplete) { completed = false }
                                        })
                                        if (completed) {
                                            //phaseChange();
                                        }
                                    }
                                });
                            }}
                            />
                        </RightSlideModal>

                        {/* <!-- right-bar2 --> */}


                    </div>
                )
            }

        </React.Fragment>

    )
}
