
import React, { useEffect, useState } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import { useUserApi } from "../../_common/hooks/api/UserApiHook";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import toast from 'react-hot-toast';
import { useUser, useUserPermissions } from "../../_common/hooks/authHook";
import { URLS } from "../../_config";

import { MdOutlineModeEdit } from "react-icons/md";
import { MdDeleteOutline } from "react-icons/md";
import { replaceUrlParams } from "../../_config/helpers";
import TableLoader from "../../components/tableLoader";
import Pagination from "../../components/pagination/Pagination";
import TitleBar from "../../components/titleBar/TitleBar";


export const RunwayList = () => {
    const [dataList, setDataList] = useState<any>(null);
    const userApi = useUserApi();
    const userInfo: any = useUser();
    const permissions:any = useUserPermissions();

    const [page, setPage] = useState<number>(1)
    const [total, setTotal] = useState<number>(0)
    const [pageLimit, setPageLimit] = useState<number>(10)

    const getRunwayList = () => {
        userApi.runwayList({ urlParams: { PageIndex: page, PageSize: pageLimit } }, (message: any, resp: any) => {
            setTotal(resp.totalRecords)
            setDataList(resp.data.runway);
        }, (message: any, resp: any) => {
            setDataList([])
        })
    }

    
    useEffect(() => {
        getRunwayList()
    }, [page]);

    const submitData = (data: any) => {
        return new Promise((resolve, reject) => {
            userApi.runwayDelete(data, (message: any, resp: any) => {
                if (resp.success === true) {
                    resolve(resp.message);
                } else {
                    reject(resp.message);
                }
            }, (message: any, resp: any) => {
                // Error...
            })
        })
    }

    const deleteItem = (itemID: number) => {
        let data = {
            "runwayID": itemID,
            updatedBy: userInfo?.userID
        };

        Swal.fire({
            title: 'Confirm Delete',
            text: `Are you sure you want to delete this?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#B32F84',
            cancelButtonColor: '#44546A',
            confirmButtonText: `Yes, delete it!`
        }).then((result) => {
            if (result.isConfirmed) {
                toast.promise(
                    submitData(data), {
                    loading: 'Loading...',
                    success: (msg: any) => { getRunwayList(); return <b>{msg}</b> },
                    error: (msg: any) => { return <b>{msg}</b> },
                }
                );

            }
        });
    }

    const submitDataStatusChange = (data: any) => {
        return new Promise((resolve, reject) => {
            userApi.runwayChangeStatus(data, (message: any, resp: any) => {
                resolve(message);
            }, (message: any, resp: any) => {
                reject(message)
            })
        })
    }

    const statusChange = (status: boolean, itemID: number) => {
        let msg = status === false ? 'In-Active' : 'Active';
        let data = {
            "runwayID": itemID,
            "isActive": status,
            "updatedBy": userInfo?.userID,
            'isDeleted': false
        };

        Swal.fire({
            title: 'Confirm Status',
            text: `Are you sure you want to ${msg} this runway?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#B32F84',
            cancelButtonColor: '#44546A',
            confirmButtonText: `Yes, ${msg} it!`
        }).then((result) => {
            if (result.isConfirmed) {
                toast.promise(
                    submitDataStatusChange(data), {
                    loading: 'Updating...',
                    success: (msg: any) => { getRunwayList(); return <b>{msg}</b> },
                    error: (msg: any) => { return <b>{msg}</b> },
                });
            }
        });
    }

    return (
        <React.Fragment>
            <Header></Header>
            <div className="main-container flex-grow-1">
                <div className="container">
                    

                    <TitleBar title={'Taxi to Runway'} breadcrumbs={{
                        'Taxi to Runway ': '#'
                    }} >
                        {permissions?.menus.runway.write && <Link to={URLS.RUNWAY.CREATE} className="btn btn-info py-2 add-more-files-btn"> Add Runway</Link>}
                    </TitleBar>

                    <div className="row">
                        <div className="col-sm-12">
                            {/* <div className="d-flex justify-content-between align-items-center mb-3">
                                <h1 className="h5 mb-0 font-weight-600"></h1>
                                <Link to={URLS.RUNWAY.CREATE} className="btn btn-info py-2 add-more-files-btn"> Add Runway</Link>
                            </div> */}

                            <div className="white-box px-4 pt-3">
                                <div className="table-responsive list-table full-width">
                                    <table className="table table-striped">
                                        <thead>
                                            <tr className="align-middle">
                                                <th scope="col" className="text-center">#</th>
                                                <th scope="col">Runway</th>
                                                <th scope="col">Details  </th>
                                                <th scope="col" className="w-150px">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {dataList && dataList.length > 0 && (
                                                dataList.map((item: any, index: number) => (
                                                    <tr key={Math.random()} className="align-middle h-60">
                                                        <td className="text-center">{index + 1}</td>
                                                        <td >{item.title}</td>
                                                        <td >{item.description}</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-3">
                                                                {permissions?.menus.runway.write &&
                                                                    <Link to={replaceUrlParams(URLS.RUNWAY.EDIT, { 'id': item.runwayID })} className="react-icon-grey">
                                                                        <MdOutlineModeEdit />
                                                                    </Link>
                                                                }

                                                                {permissions?.menus.runway.delete &&
                                                                    <Link to="#" onClick={() => { deleteItem(item.runwayID) }} className="react-icon-grey"><MdDeleteOutline /></Link>
                                                                }

                                                                {permissions?.menus.runway.write &&
                                                                    <>
                                                                        {item.isActive === true ? (
                                                                            <Link to="#" onClick={() => { statusChange(false, item.runwayID) }}><i className="active-icon"></i></Link>
                                                                        ) : (
                                                                            <Link to="#" onClick={() => { statusChange(true, item.runwayID) }}><i className="in-active"></i></Link>
                                                                        )}
                                                                    </>
                                                                }

                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                            )}
                                        </tbody>
                                    </table>

                                    <TableLoader data={dataList} />
                                </div>

                                
                                <Pagination total={total} page={page} pageLimit={pageLimit} setPage={setPage} />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </React.Fragment>
    )
}
