import { useEffect, useState } from "react"
import Loader from "./loader"
import { useFormApi } from "../_common/hooks/api/FormApiHook"
import { useUser } from "../_common/hooks/authHook";

interface props {
    phase: string
    usecaseID: any
    update: boolean
    onUpdate: ()=>void
}
export default function PhaseStatus({ phase, usecaseID, update = false,  onUpdate }: props) {
    const formApi = useFormApi();
    const user:any = useUser();

    const [publish,setPublish] = useState<boolean | null>(null);

    const getPhaseStatus = ()=>{
        formApi.getPhaseStatus({
            urlParams:{
                companyID:user.companyID,
                usecaseID:usecaseID,
                phaseName:phase
            }
        },(message:any, resp:any)=>{
            console.log('Phase Status => ',resp);
            if(resp.data.phaseValue != 0){
                setPublish(true)
            } else {
                setPublish(false)
            }
        },(message:any)=>{
            setPublish(false);
        })
    }

    const setPhaseStatus = ()=>{
        formApi.setPhaseStatus({
            "useCaseID": usecaseID,
            "fkCompanyID": user.companyID,
            "phaseName": phase,
            "updatedBy": user.userID
        },(message:any, resp:any)=>{
            getPhaseStatus();
            if(onUpdate){
                onUpdate()
            }
        },(message:any)=>{
            setPublish(false);
        })
    }

    useEffect(()=>{
        getPhaseStatus();
    },[])
    useEffect(()=>{
        if(update){
            setPhaseStatus();
        }
    },[update])


    return (
        <>
        {publish?
            <p className="text-end" style={{color: '#7CDE6C'}}>(Phase Status: Published)</p>
            :
            (publish !== null)?<p className="text-end" style={{color: '#ED867B'}}>(Phase Status: Unpublished)</p>:<p>&nbsp;</p>
        }
        </>
    )
}