import React, { useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import Header from '../../../components/header/Header'
import Footer from '../../../components/footer/Footer'
import { URLS } from '../../../_config'
import RightSlideModal from '../../../components/RightSlideModal'
import DynamicForm from '../../../components/dynamicForm/dynamicForm'
import { Button } from 'react-bootstrap'
import { useUser } from '../../../_common/hooks/authHook'
import { useWorkFlowApi } from '../../../_common/hooks/api/WorkFlowApiHook'
import DynamicFormView from '../../../components/dynamicForm/dynamicFormView'
import FilePreview from '../../../components/filePreview'
import TableLoader from '../../../components/tableLoader'
import { useUsecase } from '../../../_common/hooks/usecaseHook'
import OverviewNextPervious from './OverviewNextPervious'
import PhaseGuard from '../../../components/phaseGuard'
import TitleBar from '../../../components/titleBar/TitleBar'
import { getNameOnly, mergeListData, replaceUrlParams } from '../../../_config/helpers'
import UsecasePermission from '../../../components/usecasePermission'
import UsecasePermissionFailed from '../../../components/usecasePermissionFailed'
import { useUserApi } from '../../../_common/hooks/api/UserApiHook'
import jsPDF from 'jspdf'


export const InFlightOverview = () => {
    const { id } = useParams();

    const [rightPannel, setRightPannel] = useState<boolean>(false);
    const [testCriteriaList, setTestCriteriaList] = useState<any>(null)
    const [criteria, setCriteria] = useState<any>(null)
    const user: any = useUser();
    const workFlowApi = useWorkFlowApi();
    const usecase = useUsecase();

    const [members, setMembers] = useState<any>(null);

    const overviewRef:any = useRef();
    const userApi:any = useUserApi();
    const getUsecase = () => {  /* get usecase details and store in redux */
        return new Promise((resolve, reject) => {
            userApi.useCaseDetails({ urlParams: {companyID:user.companyID, id: id, userID: user.userID } }, (message: any, resp: any) => {
                resolve(true)
            }, (message: any) => { })
        })
    }

    useEffect(() => {
        if (usecase) {
            if (usecase?.useCaseID != id) {
                getUsecase();
            } 
        } else {
            if (id && user?.userID) {
                getUsecase();
            }
        }
    }, [usecase])

    const downloadPDF = () => {
        overviewRef.current.style.width = '1260px'
        let doc = new jsPDF('p', 'px', 'a1');

        doc.html(overviewRef.current, {
            html2canvas: {
                width: 200
            },
            callback: function (doc) {
                doc.save(`${usecase.useCase}-inFlight-overview.pdf`);
                overviewRef.current.style.width = ''
            }
        });
    }


    const getTestCriteriaList = () => {
        workFlowApi.getTestCasesAcceptanceCriteriaByUsecase({ urlParams: { companyID: user.companyID, UseCaseID: id } }, (message: any, resp: any) => {
            setTestCriteriaList(mergeListData('testCasesAcceptanceCriteriaID','fileURL',resp.data?.testCriteriaList))
        }, (message: any) => {
            setTestCriteriaList([]);
        })
    }

    const getMembers = () => {
        workFlowApi.getMembers({ urlParams: { CompanyID: user.companyID, UseCaseID: id } }, (message: any, resp: any) => {
            setMembers(mergeListData('dayInTheLifeCandidateID','canidateReportFileURL',resp.data.candidateList));
        }, (message: any, resp: any) => {
            setMembers([])
        })
    }

    useEffect(() => {
        getMembers();
        getTestCriteriaList();
    }, [id])

    useEffect(() => {
        if (criteria) {
            setRightPannel(true)
        } else {
            setRightPannel(false)
        }
    }, [criteria])

    useEffect(() => {
        if (!rightPannel) {
            setCriteria(null)
        }
    }, [rightPannel])


    return (
        <React.Fragment>
            <Header></Header>

            <div className="main-container flex-grow-1">
                <div className="container">
                    <TitleBar title={`In Flight Overview | usecase : ${usecase?.useCase}`} breadcrumbs={{
                        'Use case list': URLS.USE_CASE_LIST,
                        'Use case Details': replaceUrlParams(URLS.USECASE_DETAILS, { id: id }),
                        'In Flight Overview': '#'
                    }} >
                        <Button className='print-hide' onClick={() => {
                            window.print();
                            //downloadPDF();
                        }}>Download As PDF</Button>
                    </TitleBar>


                    <div ref={overviewRef}>
                    <PhaseGuard phase='In-Field Testing'>
                        {
                            testCriteriaList && testCriteriaList.length > 0 && (
                                <div className="white-box mb-3">
                                    <h6 className="sub_title mt-3">Test Cases And Acceptance Criteria</h6>
                                    <div className="table-responsive list-table resp-table">
                                        <div className=" list-table resp-table" >
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr className="align-middle">
                                                        <th scope="col" className="w-10 text-center"> # </th>
                                                        <th scope="col" className="w-50">Test Case </th>
                                                        <th scope="col">File</th>
                                                        <th scope="col">Assign To</th>
                                                        <th scope="col">Created By</th>
                                                        <th scope="col"> </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        testCriteriaList.map((itemCriteria: any, i: any) => (
                                                            <tr className="align-middle h-60" key={`itemCriteria ${i}`}>
                                                                <td className="text-center">{i + 1}</td>
                                                                <td>
                                                                    <h6>{itemCriteria?.testCasesAcceptanceCriteria}</h6>
                                                                    <p>{itemCriteria?.description}</p>
                                                                </td>
                                                                <td>
                                                                    <FilePreview url={itemCriteria?.fileURL} size={32} />
                                                                    {/* <img src={itemCriteria?.fileURL} width="66" alt={itemCriteria?.addedByName} /> */}
                                                                </td>
                                                                <td>{itemCriteria?.assignUserName}</td>
                                                                <td>{itemCriteria?.addedByName}</td>
                                                                <td>
                                                                    
                                                                    {user.userID == itemCriteria.assignUserID ?
                                                                    (itemCriteria.fkStatusID != 33?<span className="badge bg-info">{itemCriteria?.status}</span>:<Button size='sm' onClick={() => setCriteria(itemCriteria)}>
                                                                    <UsecasePermission>Review Test Case</UsecasePermission>
                                                                    <UsecasePermissionFailed>Submited Test Cases</UsecasePermissionFailed>
                                                                </Button>)
                                                                        
                                                                        : (itemCriteria.fkStatusID != 33?<span className="badge bg-info">{itemCriteria?.status}</span>:null)}
                                                                    
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            )
                        }

                    </PhaseGuard>

                    <PhaseGuard phase='Day in the Life'>
                        {
                            members && members.length > 0 && (
                                <div className="white-box mb-3">
                                    <h6>Day In Life</h6><hr />

                                    <div className="table-responsive list-table">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr className="align-middle">
                                                    <th scope="col" className="text-left">Name</th>
                                                    <th scope="col">Role </th>
                                                    <th scope="col">Title</th>
                                                    <th scope="col" className="text-center">Upload Feedback</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    members.map((member: any) => (
                                                        <tr className="align-middle h-60" key={`member${member.dayInTheLifeCandidateID}`}>
                                                            <td>{getNameOnly(member.userFullName)}</td>
                                                            <td> <h6>{member.roleName}</h6> </td>
                                                            <td>{member.title}</td>
                                                            <td className="text-center">
                                                                <div className="d-flex align-items-center justify-content-center gap-2">
                                                                    <span className="badge badge-secondary">{member.submitStatus}</span>
                                                                    {member.canidateReportFileURL ? <FilePreview size={24} url={member.canidateReportFileURL} /> : null}
                                                                </div>
                                                            </td>
                                                            {/* <td >
                                                                <div className="d-flex align-items-center justify-content-center gap-2">
                                                                    <Button size='sm' variant='secondary' onClick={() => { setFeedbacker(member) }}>Add Feedback</Button>
                                                                    <Icon name='delete' onClick={() => { deleteItemHandler(member) }} />
                                                                </div>
                                                            </td> */}
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            )
                        }
                    </PhaseGuard>

                    <PhaseGuard phase='Field Acceptance'>
                        <div className="white-box mb-3">
                            <h6>Field Acceptance</h6><hr />

                            <DynamicFormView _workflow='in-the-air' useCaseID={id}
                                _module='field-assistance' _form='field-assistance'
                                editMode={true} section='In the Air' phase='Field Acceptance' />
                        </div>
                    </PhaseGuard>
                    </div>

                    <OverviewNextPervious page='In the Air' />

                </div>
            </div>

            <RightSlideModal visible={rightPannel} changeVisibility={setRightPannel} heading='Submit test case' timer={true}>
                <DynamicFormView editMode={true} answerID={criteria?.testCasesCriteriaAnswerID} _workflow='taxi-to-runway' useCaseID={id} _module='test-plan--tester-case' _form='test-plan--tester-case'  />
                <UsecasePermission>
                    <DynamicForm editMode={true} answerID={criteria?.testCasesAnswerID != 0 ? criteria?.testCasesAnswerID : null} metadata={{ TestCasesAcceptanceCriteriaID: criteria?.testCasesAcceptanceCriteriaID, TestingRolesResponsibilitiesID: criteria?.testingRolesResponsibilitiesID }} _workflow='in-the-air' useCaseID={id} _module='in-field-testing' _form='in-field-testing' onSubmitSuccess={() => { setCriteria(null); getTestCriteriaList() }} />
                </UsecasePermission>
                <UsecasePermissionFailed>
                    <DynamicFormView editMode={true} answerID={criteria?.testCasesAnswerID != 0 ? criteria?.testCasesAnswerID : null} _workflow='in-the-air' useCaseID={id} _module='in-field-testing' _form='in-field-testing'  />
                </UsecasePermissionFailed>
                
                <br />
                <br />
                <br />
            </RightSlideModal>


            <Footer></Footer>
        </React.Fragment>

    )
}
