import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { StateExtended } from '../interfaces/StateExtended'

export function useMasterWorkflows() {
  const masters = useSelector((state: StateExtended) => state.masters)
  return masters.workflows
}
export function usePhases() {
  const masters = useSelector((state: StateExtended) => state.masters)
  return masters.phases
}
export function useWorkflowUrls() {
  const masters = useSelector((state: StateExtended) => state.masters)
  return masters.urls
}
export function useSystemConfigs() {
  const masters = useSelector((state: StateExtended) => state.masters)
  return masters.systemConfigs
}
