import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import Header from '../../../components/header/Header'
import Footer from '../../../components/footer/Footer'
import { URLS } from '../../../_config'
import RightSlideModal from '../../../components/RightSlideModal'
import { HangerOverviewDiscovery } from './components/HangerOverviewDiscovery'
import { HangerOverviewChallengeStatement } from './components/HangerOverviewChallengeStatement'
import { HangerOverviewRankingPrioritization } from './components/HangerOverviewRankingPrioritization'
import DynamicFormView from '../../../components/dynamicForm/dynamicFormView'
import { HangerOverviewDecision } from './components/HangerOverviewDecision'
import { UseCaseStatusManageFooter } from '../UseCaseStatusManageFooter'
import OverviewNextPervious from './OverviewNextPervious'
import TitleBar from '../../../components/titleBar/TitleBar'
import { replaceUrlParams } from '../../../_config/helpers'
import PhaseGuard from '../../../components/phaseGuard'
import { Button } from 'react-bootstrap'
import DownloadAsPDF from '../../../components/downloadAsPDF'
import jsPDF from 'jspdf'
import { useUsecase } from '../../../_common/hooks/usecaseHook'
import { useUserApi } from '../../../_common/hooks/api/UserApiHook'
import { useUser } from '../../../_common/hooks/authHook'

export const HangerOverview = () => {
    const { id } = useParams();
    const [rightPannel, setRightPannel] = useState<boolean>(false);
    const [downloadAsPDF, setDownloadAsPDF] = useState<boolean>(false);
    
    const [discoveryData, setDiscoveryData] = useState<any>(null);
    const [challengeData, setChallengeData] = useState<any>(null);
    const overviewRef:any = useRef();

    const user:any = useUser();
    const usecase:any = useUsecase();
    const userApi:any = useUserApi();
    const getUsecase = () => {  /* get usecase details and store in redux */
        return new Promise((resolve, reject) => {
            userApi.useCaseDetails({ urlParams: {companyID:user.companyID, id: id, userID: user.userID } }, (message: any, resp: any) => {
                resolve(true)
            }, (message: any) => { })
        })
    }

    useEffect(() => {
        if (usecase) {
            if (usecase?.useCaseID != id) {
                getUsecase();
            } 
        } else {
            if (id && user?.userID) {
                getUsecase();
            }
        }
    }, [usecase])


    const downloadPDF = () => {
        overviewRef.current.style.width = '1260px'
        let doc = new jsPDF('p', 'px', 'a1');

        doc.html(overviewRef.current, {
            callback: function (doc) {
                doc.save(`${usecase.useCase}-hanger-overview.pdf`);
                overviewRef.current.style.width = ''
            }
        });
    }


    useEffect(()=>{
        if(downloadAsPDF){
            setDownloadAsPDF(false)
        }
    },[downloadAsPDF])


    return (
        <React.Fragment>
            <Header></Header>

            <div className="main-container flex-grow-1">
                <div className="container">

                    <TitleBar title={`Hanger Overview | usecase : ${usecase?.useCase}`} breadcrumbs={{
                        'Use case list': URLS.USE_CASE_LIST,
                        'Use case Details': replaceUrlParams(URLS.USECASE_DETAILS, { id: id }),
                        'Hanger Overview': '#'
                    }} >
                        <Button className='print-hide' onClick={() => {
                            window.print();
                            //downloadPDF();
                        }}>Download As PDF</Button>
                    </TitleBar>
                    <div ref={overviewRef}>
                        <PhaseGuard phase='Discovery'>
                            <HangerOverviewDiscovery getData={setDiscoveryData} />
                        </PhaseGuard>

                        <PhaseGuard phase='Challenge Statement'>
                            <HangerOverviewChallengeStatement getData={setChallengeData} />
                        </PhaseGuard>

                        <PhaseGuard phase='Solution Framing'>
                            <DynamicFormView _workflow='hangar' useCaseID={id}
                                _module='create-solution-provider' section='Hanger'
                                editMode={true} phase='Solution Framing' _form='solution-provider' />
                        </PhaseGuard>

                        <PhaseGuard phase='Ranking and Prioritization'>
                            <HangerOverviewRankingPrioritization />
                        </PhaseGuard>
                        
                        <PhaseGuard phase='Hanger Decision'>
                            <>
                                {/* <HangerOverviewDecision /> */}
                                <DynamicFormView _workflow='hangar' useCaseID={id}
                                    _module='decision' section='Hanger'
                                    editMode={true} _form='decision' phase='Hanger Decision' />
                            </>
                        </PhaseGuard>

                    </div>


                    
                    <PhaseGuard phase='Hanger Decision'>
                        <OverviewNextPervious page='Hanger' />
                    </PhaseGuard>


                </div>
            </div>

            <RightSlideModal visible={rightPannel} changeVisibility={setRightPannel} heading='Use case'>
                {/* <DynamicForm _workflow='taxi-to-runway' _module='test-plan--tester-case' _form='test-plan--tester-case' onSubmitSuccess={() => { setCollapse(false) }} /> */}
            </RightSlideModal>

            <UseCaseStatusManageFooter />
            <Footer></Footer>
            <DownloadAsPDF data={{discovery:discoveryData,challenge:challengeData}} print={downloadAsPDF}/>
        </React.Fragment>

    )
}
