import React from 'react'
import { showDate } from '../../_config/functions';
import { getNameOnly } from '../../_config/helpers';

export const UseCaseTimeLine = (data: any) => {
   // console.log('props', data.props);

    return (
        <React.Fragment>
            <section className="py-3">
                <ul className="timeline-with-icons">
                    {data.props.reviewList && data.props.reviewList.length > 0 ? (
                        data.props.reviewList.map((review: any, index: any) => (
                            <li className="timeline-item" key={index}>
                                <span className="timeline-icon">
                                    <span className='timeline-icon-inner'></span>
                                </span>
                                <h5 className="">{review.statusTitle}</h5>
                                <p className="text-muted mb-2"><em>{showDate(review.addedDate, 'DD MMM, YYYY, hh:mm A')}</em></p>
                                
                                <p className="text-cstm-md mb-0">{review.review}</p>
                                {review.userFullName?<p className="text-muted mb-2"><em> by {getNameOnly(review.userFullName)}</em></p>:null}
                            </li>
                        ))
                    ) : (
                        <p></p>
                    )}
                </ul>
            </section>
        </React.Fragment>

    )
}
