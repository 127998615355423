import React, { useEffect, useState } from "react"
import { IoDocumentTextOutline } from "react-icons/io5";
import { BsFileEarmarkPpt, BsFileEarmarkZip } from "react-icons/bs";
import { FaRegFile } from "react-icons/fa";
import { FaRegFilePdf } from "react-icons/fa6";
import { Dropdown } from "react-bootstrap";
interface props {
    url: any,
    size?:number
}
export default function FilePreview({ url , size = 64}: props) {
    const [fileType,setFileType] = useState<any>(null)


    const getTypeFromUrl = (url:any)=>{
        if(url) {
            let ext =  url.split(/[#?]/)[0].split('.').pop().trim();
            let type = 'file';
            switch (ext.toLowerCase()) {
                case 'jpg':
                case 'jpeg':
                case 'png':
                case 'svg':
                case 'webp':
                case 'gif':
                case 'avif':
                    type = 'image'; break;
                case 'pdf':
                    type = 'pdf'; break;
                case 'zip':
                case 'x-zip-compressed':
                    type = 'zip'; break;
                case 'doc':
                case 'docx':
                    type = 'doc'; break;
                case 'ppt':
                case 'pptx':
                    type = 'ppt'; break;
                default: type = 'file'; break;
            }
            return type
        } else {
           return null
        }
    }
    


    const getFile = (url: any) => {

        let fileType:any = getTypeFromUrl(url)
        return (
            <a href={url} download target='_blank' className="react-icon-grey" key={url}>
                {fileType == 'image' &&
                    <img src={url} style={{ width: '60px', height: '60px', objectFit: 'contain' }} />
                }


                {fileType == 'pdf' &&
                    <div className="text-center">
                        <FaRegFilePdf style={{ fontSize: size + 'px' }} />
                    </div>
                }

                {fileType == 'zip' &&
                    <div className="text-center">
                        <BsFileEarmarkZip style={{ fontSize: size + 'px' }} />
                    </div>
                }
                {fileType == 'doc' &&
                    <div className="text-center">
                        <IoDocumentTextOutline style={{ fontSize: size + 'px' }} />
                    </div>
                }

                {fileType == 'ppt' &&
                    <div className="text-center">
                        <BsFileEarmarkPpt style={{ fontSize: size + 'px' }} />
                    </div>

                }

                {fileType == 'file' &&
                    <div className="text-center">
                        <FaRegFile style={{ fontSize: size + 'px' }} />
                    </div>

                }
            </a>
        )
    }


    return (
        <React.Fragment>
            {url && (
                <React.Fragment>
                    {Array.isArray(url) ?
                        <>{url.map((u: any) => (getFile(u)))}</> : getFile(url)}
                </React.Fragment>
            )}
        </React.Fragment>
    )
}



