import { Outlet, Navigate, useParams, useLocation } from 'react-router-dom'
import { useAuthStatus, useUser } from '../_common/hooks/authHook'
import { useUsecase } from '../_common/hooks/usecaseHook';
import { useEffect, useState } from 'react';
import { useUserApi } from '../_common/hooks/api/UserApiHook';
import { replaceUrlParams } from './helpers';
import { URLS } from '.';
import { usePhases, useWorkflowUrls } from '../_common/hooks/masterHook';
import toast from 'react-hot-toast';

interface IncominfProps { }

const UsecaseRoutes = ({ }: IncominfProps) => {
  const { id } = useParams();
  const isAuth = useAuthStatus()
  const usecase: any = useUsecase()
  const user: any = useUser()
  const location: any = useLocation()
  const userApi = useUserApi();
  const phases: any = usePhases();
  const workflowUrls: any = useWorkflowUrls();

  const [urls, setUrls] = useState<any>(null);
  const [allow, setAllow] = useState<boolean | null>(null);


  const checkRouteGuard = ()=>{
    if(usecase.useCaseID != id){
      getUsecase();
    } else {

      let currentPhaseIndex = phases.indexOf(usecase.currentPhase)
      if(currentPhaseIndex < 2){
        currentPhaseIndex = 2
      }
      if(currentPhaseIndex == 5 || currentPhaseIndex == 6){
        currentPhaseIndex = 7
      }

      Object.keys(urls).map((phase: any) => {
        if (urls[phase] == location.pathname) {
          if (phases.indexOf(phase) <= currentPhaseIndex) {
            setAllow(true);
          } else {
            setAllow(false);
            toast((t) => (
              <div>
                <p>Access to this phase is restricted until the preceding phases are successfully completed.</p>
              </div>
            ));
          }
        }
      })
      //setAllow(true);
    }
    
  }

  const getUsecase = ()=>{
    userApi.useCaseDetails({ urlParams: {companyID:user.companyID, id: id, userID: user.userID } }, (message: any, resp: any) => {
    }, (message: any) => { })
  }

  useEffect(() => {
    if (workflowUrls && id) {
      let _urls: any = {};
      Object.keys(workflowUrls).map((phase: any) => {
        _urls[phase] = replaceUrlParams(workflowUrls[phase], { id: id })
      })
      setUrls(_urls)
    }
  }, [workflowUrls, id])

  useEffect(() => {
    if (usecase == null && id && isAuth && user) {
      getUsecase();
    }
  }, [usecase, id, user, isAuth]);

  useEffect(() => {
    if (urls && usecase) {
      checkRouteGuard();
    }
  }, [urls, usecase])


  useEffect(()=>{
    if(urls && usecase){
      checkRouteGuard();
    }
  },[location])


  return (urls && usecase && allow != null)  ? (
    (allow?<Outlet />:<Navigate to={urls[usecase.currentPhase]} />)
  ) : <div></div>
}

export default UsecaseRoutes
