import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom'
import Error from '../components/elements/formFieldError';
import toast from 'react-hot-toast';
import { useUser } from '../_common/hooks/authHook';
import { useWorkFlowApi } from '../_common/hooks/api/WorkFlowApiHook';
import { useUserApi } from '../_common/hooks/api/UserApiHook';
import { useUsecase } from '../_common/hooks/usecaseHook';

interface props {
    show: boolean,
    onClose: (status: boolean) => void
}

export default function DisapproveUsecaseModal({ show, onClose }: props) {
    const [visible, setVisible] = useState<boolean>(false);
    const handleClose = () => { setVisible(false); onClose(false) };
    const user: any = useUser();
    const { control, register, handleSubmit, formState: { errors }, } = useForm();
    const userApi = useUserApi();
    const { id } = useParams();


    const getUsecase = () => {
        return new Promise((resolve, reject) => {
            userApi.useCaseDetails({ urlParams: { companyID:user.companyID,id: id, userID: user.userID } }, (message: any, resp: any) => {
                resolve(true)
            }, (message: any) => { })
        })
    }

    const submitDisapprovedStatusData = (data: any) => {
        let formParam = {
            "fkRoleID": user?.roleID,
            "fkCompanyID": user?.companyID,
            "fkUseCaseID": id,
            "fkUserID": user.userID,
            "isApproved": false,
            "reason": data.diapprovedReason,
        };

        return new Promise((resolve, reject) => {
            userApi.postUsecaseApproved(formParam, (message: any, resp: any) => {
                resolve(message);
            }, (message: any, resp: any) => {
                reject(message);
            })
        })
    }

    const onSubmitDisapproved = (data: any) => {
        toast.promise(
            submitDisapprovedStatusData(data), {
            loading: 'Disapproving...',
            success: (msg: any) => {
                getUsecase();
                handleClose();
                return <b>{msg}</b>
            },
            error: (msg: any) => { return <b>{msg}</b> },
        });
    }

    useEffect(() => {
        setVisible(show)
    }, [show])


    return (
        <Modal show={visible} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Disapprove</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(onSubmitDisapproved)} id="disapproveUsecase">
                    <div className="mb-3">
                        <label htmlFor="reject-message" className="col-form-label">Reason Note:</label>
                        <textarea
                            className="form-control"
                            id="reject-message"
                            {...register('diapprovedReason', { required: 'Reason note is required' })}
                        ></textarea>
                        <Error error={errors.diapprovedReason} />
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>Cancel</Button>
                <Button variant="primary" type='submit' form='disapproveUsecase'>Send Note</Button>
            </Modal.Footer>
        </Modal>
    )
}