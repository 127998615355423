import React, { useEffect, useRef, useState } from "react";
import { Container, Dropdown, NavDropdown, Navbar } from "react-bootstrap";
import Nav from 'react-bootstrap/Nav';
import { Link, useLocation, useNavigate } from "react-router-dom";
import './header.scss'
import { useUserApi } from "../../_common/hooks/api/UserApiHook";
import { useUser, useUserPermissions } from "../../_common/hooks/authHook";
import { URLS } from "../../_config";

import { SlPeople } from "react-icons/sl";
import { MdOutlineSettings } from "react-icons/md";
import { TbReport } from "react-icons/tb";
import { TbBulb } from "react-icons/tb";
import { GrDocumentText } from "react-icons/gr";
import { GiAirplaneDeparture } from "react-icons/gi";
import { MdOutlineDashboardCustomize } from "react-icons/md";
import { MdLocalAirport } from "react-icons/md";
import { FaUserShield } from "react-icons/fa";
import Icon from "../icon";

import { useAuth } from "@clerk/clerk-react"
import NotificationModal from "../../modals/notification.modal";
import { showDate } from "../../_config/functions";
import Search from "../search/Search";
interface props {
   
}
const Header = ({}:props) => {

    const navigate = useNavigate();
    const userApi = useUserApi();
    const user: any = useUser();
    const permissions: any = useUserPermissions();
    const ClerkAuth = useAuth();
    const [notificationCount,setNotificationCount] = useState<number>(0);
    const [notifications,setNotifications] = useState<any[]>([]);
    const [notification,setNotification] = useState<boolean>(false);
    const [notificationData,setNotificationData] = useState<any>(null);

    const location:any = useLocation();

    const notiRef:any = useRef();

    const logout = () => {
        if (ClerkAuth.isSignedIn) {
            ClerkAuth.signOut().then(() => {
                userApi.logout({}, (message: any, resp: any) => {
                    navigate(URLS.LOGIN);
                }, (message: any) => {
                    // Error...
                })
            })

        } else {
            userApi.logout({}, (message: any, resp: any) => {
                navigate(URLS.LOGIN);
            }, (message: any) => {
                // Error...
            })
        }

    }


    const handleScroll = (e: any) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    }

    const getNotifications = ()=>{
        userApi.getNotifications({urlParams:{
            userID:user.userID,
            pageNo:1,
            pageSize:10
        }},(message:any, resp:any)=>{
            setNotificationCount(resp.totalRecords)
            setNotifications(resp.data.userNotification)
        },(message:any)=>{
            setNotifications([])
            setNotificationCount(0)
        })
        
    }

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (notiRef.current && !notiRef.current.contains(event.target)) {
               setNotification(false)
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        getNotifications();
    }, [location]);

    const getHeaderRightElements = () => {
        return (
            <>
                <Search />
                <div className="notification-container">
                    <div className="notification-btn" onClick={()=>{setNotification(!notification)}}>
                        <Icon name="bell" />
                        {notificationCount != 0 && <div className="count">{notificationCount}</div>}
                        
                    </div>
                    {notification ?
                        <div className={`notifications ${notification ? 'show' : ''}`} ref={notiRef} onScroll={handleScroll} >

                            {notifications.length ? <>
                                {notifications.map((noti: any, i: any) => (
                                    <div className="notification" onClick={()=>{setNotificationData(noti)}}>
                                        <p className="mb-1 subject">{noti.subject}</p>
                                        <p className="mb-0 text-grey" style={{fontSize:'9px'}}>{showDate(noti.notificationDate,'DD MMM, YYYY')}</p>
                                    </div>
                                ))}
                            
                            </>: <p className="text-center m-0"><b>There is no notification for you</b></p> }
                        </div>
                        : null}
                    
                </div>

                <div className="dropdown">
                    <div className="d-flex gap-2 align-items-center user-link ">
                        <div className="top-avatar">
                            <img src={user?.avatar} alt="" />
                        </div>
                        <NavDropdown title={user?.firstName} id="basic-nav-dropdown" className="">
                            <NavDropdown.Item as={Link} to={URLS.PROFILE_VIEW}>Profile</NavDropdown.Item>
                            <NavDropdown.Item className="text-purple" onClick={() => logout()} >Logout</NavDropdown.Item>
                        </NavDropdown>
                    </div>
                </div>

                <NotificationModal show={notificationData != null} onClose={(st:any)=>{setNotificationData(null); getNotifications();}} data={notificationData}  />
            </>
        )
    }

    return (
        <div className="position-sticky top-0 left-0 right-0 sticky-nav print-hide">
            <nav className="navbar bg-dark bg-dark-cutom navbar-expand-lg" data-bs-theme="dark">
                <div className="container">
                    <Link className="navbar-brand" to="#">
                        <img src="https://ik.imagekit.io/innovationforce/InnovationForceSAAS/Logo/logo-white.svg" alt="" />
                    </Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="d-flex gap-4 for-mob align-items-center">
                        {getHeaderRightElements()}
                    </div>
                    
                    
                    <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
                        <div className="navbar-nav me-auto mb-2 mb-lg-0 gap-4">
                        </div>

                        <div className="d-flex align-items-center gap-4">
                            {getHeaderRightElements()}
                        </div>
                    </div>
                </div>
            </nav>

            <div className="sub-nav-menu">
                <Navbar expand="lg" className="bg-dark bg-dark-cutom ">
                    <Container>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="navbar-nav me-auto mb-2 mb-lg-0 gap-4">
                                {permissions?.menus.dashboard.read &&
                                    <div className="react-dashboard-nav">
                                        <MdOutlineDashboardCustomize />
                                        <NavDropdown title="Dashboard" id="basic-nav-dropdown" className="sub-header-dropdown">
                                            <div className="top-dropdown">
                                                <NavDropdown.Item as={Link} to={URLS.DASHBOARD_EXECUTIVE} ><MdOutlineDashboardCustomize /> Executive Dashboard</NavDropdown.Item>
                                                <NavDropdown.Item as={Link} to={URLS.DASHBOARD_PERIOD_COMPARISON} ><MdOutlineDashboardCustomize /> Date Range Comparison Dashboard</NavDropdown.Item>
                                            </div>
                                        </NavDropdown>
                                    </div>
                                }

                                {permissions?.menus.airport.read && <Nav.Link as={Link} to={URLS.AIRPORT.LIST} className="nav-link d-flex align-items-center gap-2"><MdLocalAirport /> Airport</Nav.Link>}
                                {permissions?.menus.runway.read && <Nav.Link as={Link} to={URLS.RUNWAY.LIST} className="nav-link d-flex align-items-center gap-2"><GiAirplaneDeparture style={{ fontSize: '21px' }} /> Runway</Nav.Link>}
                                {permissions?.menus.usecase.read && <Nav.Link as={Link} to={URLS.USE_CASE_LIST} className="nav-link d-flex align-items-center gap-2"><GrDocumentText /> Use Case</Nav.Link>}
                                {permissions?.menus.employee.read && <Nav.Link as={Link} to={URLS.EMPLOYEE_LIST} className="nav-link d-flex align-items-center gap-2"><SlPeople /> Employee</Nav.Link>}
                                {permissions?.menus.solutionProviders.read && <Nav.Link as={Link} to={URLS.SOLUTION_PROVIDER.LIST} className="nav-link d-flex align-items-center gap-2"><TbBulb style={{ fontSize: '22px' }} /> Solution Provider</Nav.Link>}
                                {permissions?.menus.reports.read && <Nav.Link as={Link} to="#" className="nav-link d-flex align-items-center gap-2"><TbReport /> Reports</Nav.Link>}

                                {permissions?.menus.settings.read &&
                                    <div className="react-dashboard-nav">
                                        <MdOutlineSettings />
                                        <NavDropdown title="Settings" id="basic-nav-dropdown" className="sub-header-dropdown">
                                            <div className="top-dropdown">
                                                <NavDropdown.Item as={Link} to={URLS.ROLE.LIST} ><FaUserShield /> Role</NavDropdown.Item>
                                                <NavDropdown.Item as={Link} to={URLS.NOTIFICATION.LIST} ><Icon name="notification" size={18} /> Email & Notification</NavDropdown.Item>
                                                <NavDropdown.Item as={Link} to={URLS.MANAGE_FORMS} ><Icon name="form" size={18} /> Manage Forms</NavDropdown.Item>
                                                <NavDropdown.Item as={Link} to={URLS.SCORE_QUESTION.LIST}><Icon name="score-card" size={18} /> Manage Score Questions</NavDropdown.Item>
                                                {/* <NavDropdown.Item as={Link} to={URLS.TEST} ><Icon name="form" size={18} /> TEST</NavDropdown.Item> */}
                                            </div>
                                        </NavDropdown>
                                    </div>

                                }
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </div>
        </div>
    );
}

export default Header;