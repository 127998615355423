import React, { useEffect, useState } from 'react'
import Layout from '../../../components/workflow/Layout'
import { PageMenu } from '../../../components/workflow/PageMenu'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { URLS } from '../../../_config'
import { replaceUrlParams } from '../../../_config/helpers'
import { useUserApi } from '../../../_common/hooks/api/UserApiHook'
import { useWorkFlowApi } from '../../../_common/hooks/api/WorkFlowApiHook'
import toast from 'react-hot-toast'
import { useUser } from '../../../_common/hooks/authHook'
import { GoToNextPhaseButton } from '../../../components/workflow/elements/GoToNextPhaseButton'
import { useUsecase } from '../../../_common/hooks/usecaseHook'
import TitleBar from '../../../components/titleBar/TitleBar'
import Timer from '../../../components/timer/Timer'
import GetStartDateEndDate from '../../../components/getStartDateEndDate'
import DynamicForm from '../../../components/dynamicForm/dynamicForm'
import DynamicFormView from '../../../components/dynamicForm/dynamicFormView'


export const Decision = () => { 
    const { id } = useParams();
    const user:any = useUser();
    const usecase:any = useUsecase();
    const userApi = useUserApi();
    const workflowApi = useWorkFlowApi();
    const [decisionData, setDecisionData] = useState<any>(null);
    const [decision, setDecision] = useState<any>(null)
    const navigate = useNavigate();
    const [addStartTime, setAddStartTime] = useState<Date>(new Date())

    const [dateSubmited, setDateSubmited] = useState<boolean>(false)
    const [triggerSDED, setTriggerSDED] = useState<boolean>(false);
    const [SDEDPromise, setSDEDPromise] = useState<any>(null);

    const getDecisionButton = (_decision:any)=>{
        switch(_decision.statusTitle){
            case 'Approved - Funded': return (
                <>
                    <div className={`hangerdesicionbox ${_decision.isChecked?'active':''}` } style={decision?{pointerEvents:'none'}:{}}>
                        <div className="hangerdesicionbox_circel">
                            <i className="icon-asset material-symbols-outlined">thumb_up</i>
                        </div>
                    </div>
                    <h5>{_decision.statusTitle}</h5>
                </>
            )
            case 'Approved - Awaiting Funding': return (
                <>
                    <div className={`hangerdesicionbox ${_decision.isChecked?'active':''}` } style={decision?{pointerEvents:'none'}:{}}>
                        <div className="hangerdesicionbox_circel">
                            <i className="icon-asset material-symbols-outlined">thumb_up</i>
                        </div>
                    </div>
                    <h5>{_decision.statusTitle}</h5>
                </>
            )
            case 'Decline': return (
                <>
                    <div className={`hangerdesicionbox ${_decision.isChecked?'active':''}` } style={decision?{pointerEvents:'none'}:{}}>
                        <div className="hangerdesicionbox_circel">
                            <i className="icon-asset material-symbols-outlined">thumb_down</i>
                        </div>
                    </div>
                    <h5>{_decision.statusTitle}</h5>
                </>
            )
            default : return null
        }
    }
    
    const getDecisionByUsecase = () => {
        userApi.getDecisionByUsecase({ urlParams: { UseCaseID: id } }, (message: any, resp: any) => {
            setDecisionData(resp.data.decisionlist)
            resp.data.decisionlist.map((deci:any)=>{
                if(deci.isChecked){
                    setDecision(deci)
                }
            })
        }, (message: any) => {
            setDecisionData([]);
        })
    }

    const getUsecase = () => {
        return new Promise((resolve,reject)=>{
            userApi.useCaseDetails({ urlParams: {companyID:user.companyID, id: id, userID: user.userID } }, (message: any, resp: any) => {
                resolve(true)
            }, (message: any) => {  resolve(true)})
        })
        
    }

    const phaseChange = () => {
        userApi.usecasePhaseChange({
            "useCaseID": usecase.useCaseID,
            "fkCompanyID": user.companyID,
            "nextPhase": 'Project Plan',
            "updatedBy": user.userID,
        },(message:any, resp:any)=>{
            
            getUsecase().then(()=>{
                navigate(replaceUrlParams(URLS.WORKFLOW.RUNWAY.PROJECT_PLAN, { id: id }))
            })
        },(message:any, resp:any)=>{
            getUsecase()
        })
    }

    const submitHangerDecision = (data:any) => {
        return new Promise((resolve,reject)=>{
            workflowApi.submitHangerDecision({ 
                "fkUseCaseID": id,
                "fkCompanyID": user.companyID,
                "fkStatusID": data.statusIntValue,
                "timeInSec": Math.round(((new Date()).getTime() - addStartTime.getTime()) / 1000),
                "addedBy": user.userID
              }, (message: any, resp: any) => {
                resolve(message);
                getDecisionByUsecase();
                if(data.statusTitle != 'Decline'){
                    phaseChange();
                }
            }, (message: any) => {
               reject(message)
            })
            
        })
    }

    const submitHangerDecisionHandler = (data:any) => {
        checkSDED().then(()=>{
            if(decision){

            } else {
                toast.promise(submitHangerDecision(data), {
                    loading: 'Saving...',
                    success: (msg: any) => {
                        //navigate(replaceUrlParams(URLS.USECASE_DETAILS, { id: id }))
                        setAddStartTime(new Date())
                        return <b>{msg}</b>
                    },
                    error: (msg: any) => { return <b>{msg}</b> },
                });
                
                
            }
        })
        
        
    }

    useEffect(() => {
        getDecisionByUsecase();
    }, [id])


    const checkSDED = ()=>{
        let p:any = new Promise((resolve,reject)=>{
            if(dateSubmited){
                resolve(true)
            } else {
                setSDEDPromise(()=>{ return resolve})
                setTriggerSDED(true);
            }
        })
        return p;
    }

    useEffect(() => {
        if(dateSubmited){
            if(SDEDPromise){
                SDEDPromise();
            }
        }
    }, [dateSubmited])

    useEffect(() => {
        if(triggerSDED){
            setTriggerSDED(false)
        }
    }, [triggerSDED])

  
    return (
        <Layout titleBar={
            <TitleBar title={`Decisions | usecase : ${usecase?.useCase}`} breadcrumbs={{
                'Use case list': URLS.USE_CASE_LIST,
                'Use case Details': replaceUrlParams(URLS.USECASE_DETAILS, { id: id }),
                'Decisions': '#'
            }} >
                {user.roleKey == 'INNOVATION_MANAGER_ROLE' && user.roleKey == 'ADMIN' &&
                    <Timer startTime={new Date()} />
                }
            </TitleBar>
        }>
            
            <PageMenu pageTab="Decision" />
            <div className="white-box">
               {/*  {decision?
                <DynamicFormView _workflow='hangar' useCaseID={id}
                _module='decision' section='Hanger'
                editMode={true} phase='Solution Framing' _form='decision' />
                :
                
                <DynamicForm sded={true} _workflow='hangar' useCaseID={id} _module='decision' _form='decision'
                    onSubmitSuccess={(edit) => {
                        phaseChange();
                    }} _startTime={addStartTime} />
                } */}

                {usecase.fkSectionID > 1 ?

                    <DynamicFormView _workflow='hangar' useCaseID={id}
                        _module='decision' section='Hanger'
                        editMode={true} _form='decision' phase='Hanger Decision' />

                    :

                    <>
                        {user.roleKey == 'INNOVATION_MANAGER_ROLE' || user.roleKey == 'ADMIN'?
                            <DynamicForm
                                _workflow='hangar'
                                _module='decision'
                                _form='decision'
                                useCaseID={id}
                                section='Hanger'
                                editMode={true}
                                sded={true}
                                phase='Hanger Decision'
                                onSubmitSuccess={(edit) => {
                                    phaseChange();
                                }}
                                _startTime={addStartTime}
                            />
                            :
                            <div className='col-12 d-flex justify-content-center gap-3'>
                                <h5>Waiting for Decision</h5>
                            </div>
                        }

                    </>



            }

                

                

                {/* <DynamicForm
                    _workflow='hangar'
                    _module='create-challenge'
                    _form='challenge'
                    useCaseID={id}
                    section='Hanger'
                    editMode={true}
                    sded={true}
                    phase='Challenge Statement'
                    onSubmitSuccess={(edit: boolean) => {

                    }} /> */}
                
                {/* <div className="form-area mb-5">
                    <div className="d-flex justify-content-between align-items-center">
                        <h6 className="mb-0">Decision</h6>
                    </div>
                    <hr />
                    <div className="row">
                        <div className="col-md-8">
                          
                            <GetStartDateEndDate  submited={setDateSubmited} submitTrigger={triggerSDED} dataFor={{
                                useCaseID: id,
                                fkCompanyID: user.companyID,
                                phaseName: 'Hanger Decision',
                                startDate: '',
                                completeDate: '',
                                updatedBy: user.userID
                            }} />
                        </div>
                    </div>


                    {user.roleKey == 'INNOVATION_MANAGER_ROLE' ?
                        <div className="hangerdesicion_content">
                            <ul>
                                {decisionData?.map((decision: any, i: any) => (
                                    <li key={`decision-btn-${i}`} onClick={() => { submitHangerDecisionHandler(decision) }}>{getDecisionButton(decision)}</li>
                                ))}
                            </ul>
                        </div>
                        :
                        <div className='col-12 d-flex justify-content-center gap-3'>
                            <h5>Waiting for Decision</h5>
                        </div>
                    }
                </div> */}

                {/* <hr />
                <div className='col-12 d-flex justify-content-end gap-3'>
                    {decision ?
                        <GoToNextPhaseButton usecaseID={id} nextPhase='Project Plan' onSuccess={() => {
                            navigate(replaceUrlParams(URLS.WORKFLOW.RUNWAY.PROJECT_PLAN, { id: id }))
                        }} />
                        : null}
                </div> */}
            </div>

        </Layout>


    )
}
