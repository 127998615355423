import React, { useEffect, useState } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import TitleBar from "../../components/titleBar/TitleBar";
import PrgressCircle from "./components/PrgressCircle";
import { DataFilters } from "../../components/dataFilters/DataFilters";
import { usecaseFilterData } from "../../_config/dataTypes";
import { useUserApi } from "../../_common/hooks/api/UserApiHook";
import Loader from "../../components/loader";
import { useUser } from "../../_common/hooks/authHook";
import { secondsToDhms } from "../../_config/helpers";
import UsecaseFilteredModal from "../../modals/usecaseFiltered.modal";

const DashboardExecutive = () => {
    const userApi = useUserApi()
    const user: any = useUser();

    const [filter, setFilter] = useState<usecaseFilterData>({})
  
    const [summeryCount, setSummeryCount] = useState<any>(null)
 
    const [avgDurationList, setAvgDurationList] = useState<any>(null)
    const avgDurationListImg = ['Vector 9.png', 'Vector 14.png', 'Vector 13.png', 'Vector 15.png']
 
    const [phasePlan, setPhasePlan] = useState<any>(null)
    const phasePlanImg = ['hang-bulb.svg', 'hang-flight-run.svg', 'hang-flight-running.svg', 'hang-flight-land.svg']
    const phasePlanClases = ['col-lg-4 col-md-6 col-12', 'col-lg-3 col-md-6 col-12', 'col-lg-3 col-md-6 col-12', 'col-lg-2 col-md-6 col-12']
    const phasePlanCountBtnClases = ['number-avatar', 'number-avatar', 'number-avatar number-avatar-purple', 'number-avatar number-avatar-red']
 
    const [phaseAvg, setPhaseAvg] = useState<any>(null)
    const [modal, setModal] = useState<boolean>(false)
    const [modalFilterBy, setModalFilterBy] = useState<any>(null)

    const getSummeryCount = () => {
        userApi.dashboardSummeryCount({ fkCompanyID: user.companyID, ...filter },
            (message: any, resp: any) => {
                setSummeryCount(resp.data.summaryCountList);
            }, (message: any) => {
                setSummeryCount([])
            })
    }
    const getAvgDurationList = () => {
        userApi.dashboardAvgDurationList({ fkCompanyID: user.companyID, ...filter },
            (message: any, resp: any) => {
                setAvgDurationList(resp.data.avgDurationList);
            }, (message: any) => {
                setAvgDurationList([])
            })
    }
    const getPhasePlan = () => {
        userApi.dashboardPhasePlan({ fkCompanyID: user.companyID, ...filter }, (message: any, resp: any) => {
            setPhasePlan(resp.data.phasePlanSectionList);
        }, (message: any) => {
            setPhasePlan([])
        })
    }
    const getPhaseAvg = () => {
        userApi.dashboardPhaseAvg({ fkCompanyID: user.companyID, ...filter }, (message: any, resp: any) => {
            setPhaseAvg(resp.data.phaseAvgSectionList);
        }, (message: any) => {
            setPhaseAvg([])
        })
    }

    useEffect(() => {
        getSummeryCount()
        getAvgDurationList()
        getPhasePlan()
        getPhaseAvg()
    }, [filter])

    useEffect(()=>{
        if(modalFilterBy){
            setModal(true);
        } else {
            setModal(false)
        }

    },[modalFilterBy])


    return (
        <React.Fragment>
            <Header></Header>
            <div className="main-container flex-grow-1">
                <div className="container">

                    <TitleBar title={'Executive dashboard'} breadcrumbs={{
                        'Executive dashboard': '#'
                    }} >
                    </TitleBar>

                    <DataFilters setFilter={setFilter} />


                    <div className=" executive-dashboard">
                        <div className="dashboard-top-part">
                            <div className="row">
                                <div className="dashboard-top-part kpidashboard_top mb-5">
                                    <div className="d-flex flex-wrap gap-3">
                                        {
                                            summeryCount && summeryCount.length > 0 &&
                                            summeryCount.map((summeryCountItem: any, i: number) => {
                                                if (summeryCountItem.title == 'average-idea-submission') {
                                                    return (
                                                        <div className="dashboard-top-part-body average-idea-submission flex-1" key={`summeryCountItem_${i}`}>
                                                            <p>Running average of how long the idea submission form is taking</p>
                                                            <div className="dashboard-topgraph">
                                                                <div className="dashboard-topgraphleft">
                                                                    <div className="d-flex align-items-center">
                                                                        <div className="dashboard-topPrice">{secondsToDhms(summeryCountItem?.value)}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                } else {
                                                    return (
                                                        <div className="dashboard-top-part-body flex-1" key={`summeryCountItem_${i}`}>
                                                            <h3>{summeryCountItem?.title}</h3>
                                                            <div className="dashboard-topgraph">
                                                                <div className="dashboard-topgraphleft">
                                                                    <div className="d-flex align-items-center">
                                                                        <div className="dashboard-topPrice">{summeryCountItem?.value}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            })
                                        }
         
                                    </div>
                                    {summeryCount == null && <Loader />}
                                </div>
                            </div>


                            <div className="row">
                                <div className="dashboard-top-part kpidashboard_top mb-5">
                                    <div className="d-flex flex-wrap gap-3">
                                        {
                                            avgDurationList && avgDurationList.length > 0 &&
                                            avgDurationList.map((avgDurationItem: any, index: number) => (
                                                <div className={`dashboard-top-part-body flex-1`} key={`avgDurationItem_${index}`}>
                                                    <h3>{avgDurationItem?.title}</h3>
                                                    <div className="dashboard-topgraph">
                                                        <div className="dashboard-topgraphleft">
                                                            <div className="d-flex align-items-end gap-2">
                                                                <div className="dashboard-topPrice">{avgDurationItem?.avgValue}</div>
                                                                <h2 className="dashboard-topPrice m-0">{avgDurationItem?.avgValue >1?'Days':'Day'}</h2>
                                                            </div>
                                                            {/* <p>Goal : {avgDurationItem?.goal}(+{avgDurationItem?.percentage}%)</p> */}
                                                        </div>
                                                        {/* <div className="dashboard-topgraphright">
                                                            <img src={`/images/${avgDurationListImg[index]}`} />
                                                        </div> */}
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>

                            {
                                phasePlan && phasePlan.length > 0 &&
                                <div className="row graph-section gap-2">
                                    {
                                        phasePlan.map((phasePlanItem: any, index: number) => (
                                            <div className={`${(index == 0?'':'flex-1 mb-2')}`} key={`phasePlanItem_${index}`}>
                                            {/* <div className={`${phasePlanClases[index]}`} key={`phasePlanItem_${index}`}> */}
                                                <div className="dashboard-top-part-body">
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <h2 className="h5 mb-0 font-weight-600">{phasePlanItem?.sectionName}</h2>
                                                        <img src={`../images/${phasePlanImg[index]}`} alt="#" />
                                                    </div>
                                                    <div className="d-flex gap-1 justify-content-center">
                                                        {
                                                            phasePlanItem?.phasePlanPhaseList.length > 0 &&
                                                            phasePlanItem?.phasePlanPhaseList?.map((phaseItem: any, j: number) => (

                                                                <PrgressCircle key={`phaseItem_${j}`}
                                                                    deg={phaseItem.count / phasePlanItem?.totalCount * 180}
                                                                    phaseName={phaseItem.phaseName}
                                                                    count={phaseItem.count}
                                                                    totalCount={phasePlanItem?.totalCount}
                                                                    color1={'color1'}
                                                                    onClick={()=>{
                                                                        setModalFilterBy(phaseItem.phaseName)
                                                                        console.log(phaseItem)
                                                                    }}
                                                                    />
                                                            ))
                                                        }
                                                    </div>

                                                    <div className="dashboard-bottom-part-body mt-5">
                                                        <span># of Planes on the {phasePlanItem?.sectionName} </span>
                                                        <div className={phasePlanCountBtnClases[index]}>{phasePlanItem?.totalCount}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }

                                </div>

                            }

                        </div>

                        <div className="flight-section">
                            <ul>
                                <li>
                                    <img src="../images/hanger.svg" alt="#" />
                                    <span>Hanger</span>
                                </li>
                                <li>
                                    <img src="../images/runway.svg" alt="#" />
                                    <span>Taxi to Runway</span>
                                </li>
                                <li>
                                    <img src="../images/in-flight.svg" alt="#" />
                                    <span>In-flight</span>
                                </li>
                                <li>
                                    <img src="../images/landed.svg" alt="#" />
                                    <span>landed</span>
                                </li>
                            </ul>
                        </div>

                        <div className="flight-take-information">
                            {
                                phaseAvg && phaseAvg.length > 0 && (
                                    <div className="row">
                                        {
                                            phaseAvg.map((phaseAvgItem: any, index: any) => (
                                                <div className={`col-lg-3 col-md-6 col-12 p-0 flight-take-black-col${(index == 0 || index == 3) ? '-two' : ''}`} key={`phaseAvgItem_${index}`}>
                                                    <div className="flight-take-information-body">
                                                        <h2>{phaseAvgItem.sectionName}</h2>
                                                        <ul>
                                                            {
                                                                phaseAvgItem.phaseAvgPhaseList && phaseAvgItem.phaseAvgPhaseList.length && phaseAvgItem.phaseAvgPhaseList.map((PhaseAvgCountItem: any, i: number) => (
                                                                    <li>Avg days in {PhaseAvgCountItem.phaseName}
                                                                        <div className="number-avatar" key={`PhaseAvgCountItem_${i}`}>{PhaseAvgCountItem.avgCount}</div>
                                                                    </li>
                                                                ))
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                )
                            }

                        </div>

                    </div>
                </div>
            </div>
            <Footer></Footer>
            <UsecaseFilteredModal show={modal} onClose={()=>{setModalFilterBy(null)}} phase={modalFilterBy} />
        </React.Fragment>
    );
}

export default DashboardExecutive;