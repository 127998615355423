import { call, put } from 'redux-saga/effects'
import { ACTIONS, API_URL, STORAGE, UpdateWithVariables } from '../../_config'
import { CallApi, responseHandler } from '../api/callApi'
import { ApiActionType } from '../../_common/interfaces/ActionExtended'


const NO_IMAGE = '/images/no-image.png';




export function* login(action: ApiActionType): any {
  try {
    const data = action.payload
    const resp: any = yield call(CallApi.POST, API_URL.USER.LOGIN, data) // login user
    let respChecker = responseHandler(resp)
    if (respChecker.status) {
      localStorage.setItem(STORAGE, JSON.stringify(resp.data.data));

      let _resp: any = yield call(CallApi.GET, API_URL.USER.GET_MEMBER_DETAILS, {}, true) // get logged-in  member details
      if (_resp.status >= 200 && _resp.status < 300 && _resp.data) {

        let API2 = UpdateWithVariables(API_URL.USER.CONFIG_BY_COMPANY_ID, { companyID: _resp.data.data.companyID }) 
        const __resp: any = yield call(CallApi.GET, API2, {}, true)// get system configs.. 
        if (__resp.status >= 200 && __resp.status < 300 && __resp.data) {

          _resp.data.data.roleKey = ''
          let config = __resp.data.data.systemConfigs.find((config: any) => config.value === _resp.data.data.roleName);
          if (config) {
            let configKey = config.key;
            _resp.data.data.roleKey = configKey
          }
          yield put({
            type: ACTIONS.MASTER.SYSTEM_CONFIGS,
            payload: {
              systemConfigs: __resp.data.data.systemConfigs

            },
          })

          let rolePermissionAPI = UpdateWithVariables(API_URL.USER.MENU_PERMISSION.GET_LIST, { CompanyID: _resp.data.data.companyID,  FKRoleID: _resp.data.data.roleID }) 
          let permissionResponse: any = yield call(CallApi.GET, rolePermissionAPI, {}, true);

          if (permissionResponse.status >= 200 && permissionResponse.status < 300 && permissionResponse.data) {

            let _permissions = permissionResponse.data.data.permissionList;
            let permissions:any = {
              menus : {
                dashboard:{read:false,write:false,delete:false},
                airport:{read:false,write:false,delete:false},
                runway:{read:false,write:false,delete:false},
                usecase:{read:false,write:false,delete:false},
                employee:{read:false,write:false,delete:false},
                solutionProviders:{read:false,write:false,delete:false},
                reports:{read:false,write:false,delete:false},
                settings:{read:false,write:false,delete:false},
                roll:{read:false,write:false,delete:false},
              },
              actions:{
                usecase:false
              }
            }

            let menuKey:any =  {
              'DSB':'dashboard',
              'ARP':'airport',
              'RUN':'runway',
              'USC':'usecase',
              'EMP':'employee',
              'SPV':'solutionProviders',
              'REP':'reports',
              'SET':'settings',
              'ROL':'roll',
              'CreateUsecase':'usecase',
            }
            _permissions.map((menu: any) => {
              switch (menu.type) {
                case 1:
                  permissions.menus[menuKey[menu.code]].read = menu.read;
                  permissions.menus[menuKey[menu.code]].write = menu.write;
                  permissions.menus[menuKey[menu.code]].delete = menu.delete;
                  break;
                case 2:
                  permissions.actions[menuKey[menu.code]] = menu.read;
                  break;
                default:
              }
            })

            yield put({
              type: ACTIONS.USER.LOGIN,
              payload: {
                user: _resp.data.data,
                token: resp.data.data.accessToken,
                refreshtoken: resp.data.data.refreshToken,
                permissions: permissions
              },
            })
            action && action.callbackSuccess && action.callbackSuccess(resp.data)
          } else {
            action && action.callbackError && action.callbackError(resp.data)
          }
          
          
        } else {
          action && action.callbackError && action.callbackError(resp.data)
        }
      } else {
        action && action.callbackError && action.callbackError(resp.data)
      }


      //action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
    } else {
      action && action.callbackError && action.callbackError(respChecker.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}

export function* loginSSO(action: ApiActionType): any {
  try {
    const data = action.payload
    const resp: any = yield call(CallApi.POST, API_URL.USER.LOGIN_SSO, data) // login user
    let respChecker = responseHandler(resp)
    if (respChecker.status) {
      localStorage.setItem(STORAGE, JSON.stringify(resp.data.data));

      let _resp: any = yield call(CallApi.GET, API_URL.USER.GET_MEMBER_DETAILS, {}, true) // get logged-in  member details
      if (_resp.status >= 200 && _resp.status < 300 && _resp.data) {

        let API2 = UpdateWithVariables(API_URL.USER.CONFIG_BY_COMPANY_ID, { companyID: _resp.data.data.companyID }) 
        const __resp: any = yield call(CallApi.GET, API2, {}, true)// get system configs.. 
        if (__resp.status >= 200 && __resp.status < 300 && __resp.data) {

          _resp.data.data.roleKey = ''
          let config = __resp.data.data.systemConfigs.find((config: any) => config.value === _resp.data.data.roleName);
          if (config) {
            let configKey = config.key;
            _resp.data.data.roleKey = configKey
          }
          yield put({
            type: ACTIONS.MASTER.SYSTEM_CONFIGS,
            payload: {
              systemConfigs: __resp.data.data.systemConfigs

            },
          })

          let rolePermissionAPI = UpdateWithVariables(API_URL.USER.MENU_PERMISSION.GET_LIST, { CompanyID: _resp.data.data.companyID,  FKRoleID: _resp.data.data.roleID }) 
          let permissionResponse: any = yield call(CallApi.GET, rolePermissionAPI, {}, true);

          if (permissionResponse.status >= 200 && permissionResponse.status < 300 && permissionResponse.data) {

            let _permissions = permissionResponse.data.data.permissionList;
            let permissions:any = {
              menus : {
                dashboard:{read:false,write:false,delete:false},
                airport:{read:false,write:false,delete:false},
                runway:{read:false,write:false,delete:false},
                usecase:{read:false,write:false,delete:false},
                employee:{read:false,write:false,delete:false},
                solutionProviders:{read:false,write:false,delete:false},
                reports:{read:false,write:false,delete:false},
                settings:{read:false,write:false,delete:false},
                roll:{read:false,write:false,delete:false},
              },
              actions:{
                usecase:false
              }
            }

            let menuKey:any =  {
              'DSB':'dashboard',
              'ARP':'airport',
              'RUN':'runway',
              'USC':'usecase',
              'EMP':'employee',
              'SPV':'solutionProviders',
              'REP':'reports',
              'SET':'settings',
              'ROL':'roll',
              'CreateUsecase':'usecase',
            }
            _permissions.map((menu: any) => {
              console.log('.................',menu.code,menuKey[menu.code])
              switch (menu.type) {
                case 1:
                  permissions.menus[menuKey[menu.code]].read = menu.read;
                  permissions.menus[menuKey[menu.code]].write = menu.write;
                  permissions.menus[menuKey[menu.code]].delete = menu.delete;
                  break;
                case 2:
                  permissions.actions[menuKey[menu.code]] = menu.read;
                  break;
                default:
              }
            })

            yield put({
              type: ACTIONS.USER.LOGIN,
              payload: {
                user: _resp.data.data,
                token: resp.data.data.accessToken,
                refreshtoken: resp.data.data.refreshToken,
                permissions: permissions
              },
            })
            action && action.callbackSuccess && action.callbackSuccess(resp.data)
          } else {
            action && action.callbackError && action.callbackError(resp.data)
          }
          
          
        } else {
          action && action.callbackError && action.callbackError(resp.data)
        }
      } else {
        action && action.callbackError && action.callbackError(resp.data)
      }


      //action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
    } else {
      action && action.callbackError && action.callbackError(respChecker.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}
 

export function* loginWithClerk(action: ApiActionType): any {
  try {
    const data = action.payload
    const resp: any = yield call(CallApi.POST, API_URL.USER.LOGIN_WITH_CLERK, data) // login user with clerk
    let respChecker = responseHandler(resp)
    if (respChecker.status) {
      localStorage.setItem(STORAGE, JSON.stringify(resp.data.data));

      let _resp: any = yield call(CallApi.GET, API_URL.USER.GET_MEMBER_DETAILS, {}, true) // get logged-in  member details
      if (_resp.status >= 200 && _resp.status < 300 && _resp.data) {

        let API2 = UpdateWithVariables(API_URL.USER.CONFIG_BY_COMPANY_ID, { companyID: _resp.data.data.companyID }) 
        const __resp: any = yield call(CallApi.GET, API2, {}, true)// get system configs.. 
        if (__resp.status >= 200 && __resp.status < 300 && __resp.data) {

          _resp.data.data.roleKey = ''
          let config = __resp.data.data.systemConfigs.find((config: any) => config.value === _resp.data.data.roleName);
          if (config) {
            let configKey = config.key;
            _resp.data.data.roleKey = configKey
          }
          yield put({
            type: ACTIONS.MASTER.SYSTEM_CONFIGS,
            payload: {
              systemConfigs: __resp.data.data.systemConfigs

            },
          })

          let rolePermissionAPI = UpdateWithVariables(API_URL.USER.MENU_PERMISSION.GET_LIST, { CompanyID: _resp.data.data.companyID,  FKRoleID: _resp.data.data.roleID }) 
          let permissionResponse: any = yield call(CallApi.GET, rolePermissionAPI, {}, true);

          if (permissionResponse.status >= 200 && permissionResponse.status < 300 && permissionResponse.data) {

            let _permissions = permissionResponse.data.data.permissionList;
            let permissions:any = {
              menus : {
                dashboard:{read:false,write:false,delete:false},
                airport:{read:false,write:false,delete:false},
                runway:{read:false,write:false,delete:false},
                usecase:{read:false,write:false,delete:false},
                employee:{read:false,write:false,delete:false},
                solutionProviders:{read:false,write:false,delete:false},
                reports:{read:false,write:false,delete:false},
                settings:{read:false,write:false,delete:false},
                roll:{read:false,write:false,delete:false},
              },
              actions:{
                usecase:false
              }
            }

            let menuKey:any =  {
              'DSB':'dashboard',
              'ARP':'airport',
              'RUN':'runway',
              'USC':'usecase',
              'EMP':'employee',
              'SPV':'solutionProviders',
              'REP':'reports',
              'SET':'settings',
              'ROL':'roll',
              'CreateUsecase':'usecase',
            }
            _permissions.map((menu: any) => {
              switch (menu.type) {
                case 1:
                  permissions.menus[menuKey[menu.code]].read = menu.read;
                  permissions.menus[menuKey[menu.code]].write = menu.write;
                  permissions.menus[menuKey[menu.code]].delete = menu.delete;
                  break;
                case 2:
                  permissions.actions[menuKey[menu.code]] = menu.read;
                  break;
                default:
              }
            })

            yield put({
              type: ACTIONS.USER.LOGIN,
              payload: {
                user: _resp.data.data,
                token: resp.data.data.accessToken,
                refreshtoken: resp.data.data.refreshToken,
                permissions: permissions
              },
            })
            action && action.callbackSuccess && action.callbackSuccess(resp.data)
          } else {
            action && action.callbackError && action.callbackError(resp.data)
          }
          
          
        } else {
          action && action.callbackError && action.callbackError(resp.data)
        }
      } else {
        action && action.callbackError && action.callbackError(resp.data)
      }


      //action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
    } else {
      action && action.callbackError && action.callbackError(respChecker.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}


export function* logout(action: ApiActionType): any {
  try {
    const data = action.payload
    //const resp = yield call(CallApi.GET, API_URL.USER.LOGOUT, data, true)
    // if (resp.status >= 200 && resp.status < 300 && resp.data) {
    yield put({
      type: ACTIONS.RESET_REDUCER,
    })
    let clerkJWT = localStorage.getItem('clerk-db-jwt')
    localStorage.clear();
    if(clerkJWT){localStorage.setItem('clerk-db-jwt',clerkJWT)}
    yield put({
      type: ACTIONS.USER.LOGOUT,
    })
    action && action.callbackSuccess && action.callbackSuccess({})
    // } else {
    //   action && action.callbackError && action.callbackError(resp.data)
    // }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}

export function* getMemberDetails(action: ApiActionType): any {
  try {
    const data = action.payload
    const resp: any = yield call(CallApi.GET, API_URL.USER.GET_MEMBER_DETAILS, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      yield put({
        type: ACTIONS.USER.ME,
        payload: {
          user: resp.data.data,
        },
      })
      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}


export function* forgotPassword(action: ApiActionType): any {
  try {
    const data = action.payload
    const resp: any = yield call(CallApi.POST, API_URL.USER.FORGOT_PASSWORD, data, true)

    let respChecker = responseHandler(resp)
    if (respChecker.status) {
      action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
    } else {
      action && action.callbackError && action.callbackError(respChecker.data)
    }


  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}

export function* resetPassword(action: ApiActionType): any {
  try {
    const data = action.payload
    const resp: any = yield call(CallApi.POST, API_URL.USER.RESET_PASSWORD, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
  }
}




export function* employeeList(action: ApiActionType): any {
  try {
    const data = action.payload
    const resp: any = yield call(CallApi.GET, API_URL.USER.EMPLOYEE_LIST, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {

      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}
export function* deleteEmployee(action: ApiActionType): any {
  try {
    const data = action.payload
    const resp: any = yield call(CallApi.DELETE, API_URL.USER.DELETE, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {

      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}



export function* getFormConfig(action: ApiActionType): any {
  const data = action.payload
  let API = UpdateWithVariables(API_URL.USER.GET_FORM_CONFIG, data.urlParams)
  if (data.urlParams) {
    delete data.urlParams
  }
  try {
    const resp: any = yield call(CallApi.GET, API, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
  }
}
export function* submitDynamicFrom(action: ApiActionType): any {
  const data = action.payload
  if (data.api) {
    let API = API_URL.USER.SUBMIT_DYNAMIC_FORM + data.api
    delete data.api
    try {
      const resp: any = yield call(CallApi.POST, API, data, true)
      let respChecker = responseHandler(resp)
      if (respChecker.status) {
        action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
      } else {
        action && action.callbackError && action.callbackError(respChecker.data)
      }
      /*  if (resp.status >= 200 && resp.status < 300 && resp.data) {
         action && action.callbackSuccess && action.callbackSuccess(resp.data)
       } else {
         action && action.callbackError && action.callbackError(resp.data)
       } */
    } catch (e: any) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
    }
  } else {
    action && action.callbackError && action.callbackError('Server Error')
    console.error('api is not found on dynamic from submit data')
  }
}

export function* memberActivation(action: ApiActionType): any {
  try {
    const data = action.payload
    const resp: any = yield call(CallApi.POST, API_URL.USER.EMPLOYEE_ACTIVE_INACTIVE, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
  }
}


export function* addRole(action: ApiActionType): any {
  try {
    const data = action.payload
    const resp: any = yield call(CallApi.POST, API_URL.USER.ROLE_ADD, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
  }
}

export function* editRole(action: ApiActionType): any {

  try {
    const data = action.payload

    const resp: any = yield call(CallApi.PUT, API_URL.USER.ROLE_EDIT, data, true)
    let respChecker = responseHandler(resp)
    if (respChecker.status) {
      action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
    } else {
      action && action.callbackError && action.callbackError(respChecker.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}

export function* detailsRole(action: ApiActionType): any {
  try {
    const data = action.payload
    const resp: any = yield call(CallApi.GET, API_URL.USER.ROLE_DETAILS, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {

      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}

export function* listRole(action: ApiActionType): any {
  try {
    const data = action.payload
    const resp: any = yield call(CallApi.GET, API_URL.USER.ROLE_LIST, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {

      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}

export function* deleteRole(action: ApiActionType): any {
  try {
    const data = action.payload
    const resp: any = yield call(CallApi.DELETE, API_URL.USER.ROLE_DELETE, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {

      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}

export function* useCaseList(action: ApiActionType): any {
  try {
    const data = action.payload
    const resp: any = yield call(CallApi.POST, API_URL.USER.USE_CASE_LIST, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      resp.data.data.usecaseList.map((usecase:any)=>{
        if(!usecase.imgURL){
          usecase.imgURL = NO_IMAGE;
        }
        return usecase
      })
      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}

export function* useCaseDetails(action: ApiActionType): any {
  try {
    const data = action.payload
    let API = UpdateWithVariables(API_URL.USER.USE_CASE_DETAILS, data.urlParams)
    if (data.urlParams) {
      delete data.urlParams
    }
    const resp: any = yield call(CallApi.GET, API, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {

      let details:any = resp.data.data.usecaseList[0];
      if(!details.imgURL){
        details.imgURL = NO_IMAGE
      }
      yield put({
        type: ACTIONS.USECASE.USECASE,
        payload: {
          usecase: details
        }
      })

      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}

export function* deleteUsecase(action: ApiActionType): any {
  try {
    const data = action.payload
    let API = UpdateWithVariables(API_URL.USER.USE_CASE_DELETE, data.urlParams)
    if (data.urlParams) {
      delete data.urlParams
    }
    const resp: any = yield call(CallApi.DELETE, API, data, true);
    let respChecker = responseHandler(resp)
    if (respChecker.status) {
      action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
    } else {
      action && action.callbackError && action.callbackError(respChecker.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}
export function* changeAirportRunwayCaptains(action: ApiActionType): any {
  try {
    const data = action.payload
    let API = UpdateWithVariables(API_URL.USER.CHANGE_AIRPORT_RUNWAY_CAPTAINS, data.urlParams)
    if (data.urlParams) {
      delete data.urlParams
    }
    const resp: any = yield call(CallApi.POST, API, data, true);
    let respChecker = responseHandler(resp)
    if (respChecker.status) {
      action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
    } else {
      action && action.callbackError && action.callbackError(respChecker.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}

export function* airportList(action: ApiActionType): any {
  try {
    const data = action.payload
    const resp: any = yield call(CallApi.GET, API_URL.USER.AIRPORT_LIST, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {

      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}

export function* airportAdd(action: ApiActionType): any {
  try {
    const data = action.payload
    const resp: any = yield call(CallApi.POST, API_URL.USER.AIRPORT_ADD, data, true)
    let respChecker = responseHandler(resp)
    if (respChecker.status) {
      action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
    } else {
      action && action.callbackError && action.callbackError(respChecker.data)
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
  }
}

export function* airportEdit(action: ApiActionType): any {
  try {
    const data = action.payload
    const resp: any = yield call(CallApi.PUT, API_URL.USER.AIRPORT_EDIT, data, true)
    let respChecker = responseHandler(resp)
    if (respChecker.status) {
      action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
    } else {
      action && action.callbackError && action.callbackError(respChecker.data)
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
  }
}

export function* airportDetails(action: ApiActionType): any {
  try {
    const data = action.payload
    const resp: any = yield call(CallApi.GET, API_URL.USER.AIRPORT_DETAILS, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {

      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}

export function* airportDelete(action: ApiActionType): any {
  try {
    const data = action.payload
    const resp: any = yield call(CallApi.DELETE, API_URL.USER.AIRPORT_DELETE, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {

      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}

export function* getUsersByRoleName(action: ApiActionType): any {
  try {
    const data = action.payload
    const resp: any = yield call(CallApi.GET, API_URL.USER.BY_ROLE_NAME, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {

      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}

export function* getConfigByKey(action: ApiActionType): any {
  try {
    const data = action.payload
    let API = UpdateWithVariables(API_URL.USER.CONFIG_BY_ROLE_NAME, data.urlParams)
    if (data.urlParams) {
      delete data.urlParams
    }
    const resp: any = yield call(CallApi.GET, API, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {

      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}

export function* getConfigByCompany(action: ApiActionType): any {
  try {
    const data = action.payload
    let API = UpdateWithVariables(API_URL.USER.CONFIG_BY_COMPANY_ID, data.urlParams)
    if (data.urlParams) {
      delete data.urlParams
    }
    const resp: any = yield call(CallApi.GET, API, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {

      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}

export function* getWorkFlowStatusLookUpsAll(action: ApiActionType): any {
  try {
    const data = action.payload
    let API = UpdateWithVariables(API_URL.USER.WORKFLOW_STATUS_LOOKUPS_ALL, data.urlParams)
    if (data.urlParams) {
      delete data.urlParams
    }
    const resp: any = yield call(CallApi.GET, API, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {

      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}

export function* getRunwayByAirport(action: ApiActionType): any {
  try {
    const data = action.payload
    let API = UpdateWithVariables(API_URL.USER.GET_RUNWAY_BY_AIRPORT, data.urlParams)
    if (data.urlParams) {
      delete data.urlParams
    }
    const resp: any = yield call(CallApi.GET, API, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {

      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }

  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}

export function* postUsecaseReviewData(action: ApiActionType): any {
  try {
    const data = action.payload
    const resp: any = yield call(CallApi.POST, API_URL.USER.POST_USECASE_REVIEW_DATA, data, true)
    let respChecker = responseHandler(resp)
    if (respChecker.status) {
      action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
    } else {
      action && action.callbackError && action.callbackError(respChecker.data)
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
  }
}

export function* postUsecaseApproved(action: ApiActionType): any {
  try {
    const data = action.payload
    const resp: any = yield call(CallApi.PUT, API_URL.USER.POST_USECASE_APPROVED, data, true)
    let respChecker = responseHandler(resp)
    if (respChecker.status) {
      action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
    } else {
      action && action.callbackError && action.callbackError(respChecker.data)
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
  }
}

export function* postUsecaseDispproved(action: ApiActionType): any {
  try {
    const data = action.payload
    const resp: any = yield call(CallApi.POST, API_URL.USER.POST_USECASE_DISAPPROVED, data, true)
    let respChecker = responseHandler(resp)
    if (respChecker.status) {
      action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
    } else {
      action && action.callbackError && action.callbackError(respChecker.data)
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
  }
}

export function* setCaptainCoCaptain(action: ApiActionType): any {
  try {
    const data = action.payload
    const resp: any = yield call(CallApi.POST, API_URL.USER.SET_CAPTAIN_CO_CAPTAIN, data, true)
    let respChecker = responseHandler(resp)
    if (respChecker.status) {
      action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
    } else {
      action && action.callbackError && action.callbackError(respChecker.data)
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
  }
}

export function* getUsecaseReviewList(action: ApiActionType): any {

  try {
    const data = action.payload
    let API = UpdateWithVariables(API_URL.USER.GET_USECASE_REVIEW_LIST, data.urlParams)
    if (data.urlParams) {
      delete data.urlParams
    }
    const resp: any = yield call(CallApi.GET, API, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {

      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }

  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}





export function* addEmployee(action: ApiActionType): any {
  try {
    const data = action.payload
    const resp: any = yield call(CallApi.POST, API_URL.USER.ADD_EMPLOYEE, data, true)
    let respChecker = responseHandler(resp)
    if (respChecker.status) {
      action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
    } else {
      action && action.callbackError && action.callbackError(respChecker.data)
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
  }
}
export function* sendInvitation(action: ApiActionType): any {
  try {
    const data = action.payload
    const resp: any = yield call(CallApi.POST, API_URL.USER.SEND_INVITATION, data, true)
    let respChecker = responseHandler(resp)
    if (respChecker.status) {
      action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
    } else {
      action && action.callbackError && action.callbackError(respChecker.data)
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
  }
}


export function* userDetails(action: ApiActionType): any {
  try {
    const data = action.payload
    let API = UpdateWithVariables(API_URL.USER.USER_DETAILS, data.urlParams)
    if (data.urlParams) {
      delete data.urlParams
    }
    const resp: any = yield call(CallApi.GET, API, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {

      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}
export function* userUpdate(action: ApiActionType): any {
  try {
    const data = action.payload
    const resp: any = yield call(CallApi.PUT, API_URL.USER.USER_UPDATE, data, true)
    let respChecker = responseHandler(resp)
    if (respChecker.status) {
      action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
    } else {
      action && action.callbackError && action.callbackError(respChecker.data)
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
  }
}
export function* userChangeStatus(action: ApiActionType): any {
  try {
    const data = action.payload
    const resp: any = yield call(CallApi.PUT, API_URL.USER.USER_CHANGE_STATUS, data, true)
    let respChecker = responseHandler(resp)
    if (respChecker.status) {
      action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
    } else {
      action && action.callbackError && action.callbackError(respChecker.data)
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
  }
}
export function* userRoleMap(action: ApiActionType): any {
  try {
    const data = action.payload
    const resp: any = yield call(CallApi.POST, API_URL.USER.USER_ROLE_MAP, data, true)
    let respChecker = responseHandler(resp)
    if (respChecker.status) {
      action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
    } else {
      action && action.callbackError && action.callbackError(respChecker.data)
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
  }
}



export function* getDiscovery(action: ApiActionType): any {
  try {
    const data = action.payload
    let API = UpdateWithVariables(API_URL.USER.DISCOVERY_GET_SINGLE, data.urlParams)
    if (data.urlParams) {
      delete data.urlParams
    }
    const resp: any = yield call(CallApi.GET, API, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {

      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}
export function* getAllDiscovery(action: ApiActionType): any {
  try {
    const data = action.payload
    let API = UpdateWithVariables(API_URL.USER.DISCOVERY_GET_ALL, data.urlParams)
    if (data.urlParams) {
      delete data.urlParams
    }
    const resp: any = yield call(CallApi.GET, API, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {

      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}
export function* deleteDiscoveryFile(action: ApiActionType): any {
  try {
    const data = action.payload
    let API = UpdateWithVariables(API_URL.USER.DISCOVERY_DELETE, data.urlParams)
    if (data.urlParams) {
      delete data.urlParams
    }
    const resp: any = yield call(CallApi.DELETE, API, data, true)
    let respChecker = responseHandler(resp)
    if (respChecker.status) {
      action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
    } else {
      action && action.callbackError && action.callbackError(respChecker.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}
export function* getChallengeStatementByUsecase(action: ApiActionType): any {
  try {
    const data = action.payload
    let API = UpdateWithVariables(API_URL.USER.USECASE_CHALLENGE_STATEMENT, data.urlParams)
    if (data.urlParams) {
      delete data.urlParams
    }
    const resp: any = yield call(CallApi.GET, API, data, true)
    let respChecker = responseHandler(resp)
    if (respChecker.status) {
      action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
    } else {
      action && action.callbackError && action.callbackError(respChecker.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}

export function* usecasePhaseChange(action: ApiActionType): any {
  try {
    const data = action.payload
    const resp: any = yield call(CallApi.PUT, API_URL.USER.USECASE_PHASE_CHANGE, data, true)
    let respChecker = responseHandler(resp)
    if (respChecker.status) {
      action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
    } else {
      action && action.callbackError && action.callbackError(respChecker.data)
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
  }
}

export function* decisionGet(action: ApiActionType): any {
  try {
    const data = action.payload
    let API = UpdateWithVariables(API_URL.USER.DECISION_GET, data.urlParams)
    if (data.urlParams) {
      delete data.urlParams
    }
    const resp: any = yield call(CallApi.GET, API, data, true)
    let respChecker = responseHandler(resp)
    if (respChecker.status) {
      action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
    } else {
      action && action.callbackError && action.callbackError(respChecker.data)
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
  }
}
export function* decisionSave(action: ApiActionType): any {
  try {
    const data = action.payload
    const resp: any = yield call(CallApi.POST, API_URL.USER.DECISION_SAVE, data, true)
    let respChecker = responseHandler(resp)
    if (respChecker.status) {
      action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
    } else {
      action && action.callbackError && action.callbackError(respChecker.data)
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
  }
}



export function* companyList(action: ApiActionType): any {
  try {
    const data = action.payload
    let API = UpdateWithVariables(API_URL.COMPANY.LIST, data.urlParams)
    if (data.urlParams) {
      delete data.urlParams
    }
    const resp: any = yield call(CallApi.GET, API, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {

      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}



//================= START:: Tenant Section =================//

export function* getTenantsAll(action: ApiActionType): any {
  try {
    const data = action.payload
    let API = UpdateWithVariables(API_URL.TENANT.LIST, data.urlParams)
    if (data.urlParams) {
      delete data.urlParams
    }
    const resp: any = yield call(CallApi.GET, API, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {

      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}

//================== START:: Runway Section ===================//
export function* runwayList(action: ApiActionType): any {
  try {
    const data = action.payload
    let API = UpdateWithVariables(API_URL.RUNWAY.LIST, data.urlParams)
    if (data.urlParams) {
      delete data.urlParams
    }
    const resp: any = yield call(CallApi.GET, API, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {

      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}
export function* runwayDetails(action: ApiActionType): any {
  try {
    const data = action.payload
    let API = UpdateWithVariables(API_URL.RUNWAY.DETAILS, data.urlParams)
    if (data.urlParams) {
      delete data.urlParams
    }
    const resp: any = yield call(CallApi.GET, API, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {

      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}
export function* runwaySave(action: ApiActionType): any {
  try {
    const data = action.payload

    const resp: any = yield call(CallApi.POST, API_URL.RUNWAY.SAVE, data, true)
    let respChecker = responseHandler(resp)
    if (respChecker.status) {
      action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
    } else {
      action && action.callbackError && action.callbackError(respChecker.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}
export function* runwayUpdate(action: ApiActionType): any {
  try {
    const data = action.payload

    const resp: any = yield call(CallApi.PUT, API_URL.RUNWAY.UPDATE, data, true)
    let respChecker = responseHandler(resp)
    if (respChecker.status) {
      action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
    } else {
      action && action.callbackError && action.callbackError(respChecker.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}
export function* runwayDelete(action: ApiActionType): any {
  try {
    const data = action.payload

    const resp: any = yield call(CallApi.DELETE, API_URL.RUNWAY.DELETE, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {

      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}
export function* runwayChangeStatus(action: ApiActionType): any {
  try {
    const data = action.payload
    const resp: any = yield call(CallApi.PUT, API_URL.RUNWAY.CHANGE_STATUS, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}
//================== END:: Runway Section ===================//


//================== START:: Runway Section ===================//
export function* solutionProviderList(action: ApiActionType): any {
  try {
    const data = action.payload
    let API = UpdateWithVariables(API_URL.SOLUTION_PROVIDER.LIST, data.urlParams)
    if (data.urlParams) {
      delete data.urlParams
    }
    const resp: any = yield call(CallApi.GET, API, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {

      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}
export function* solutionProviderDetails(action: ApiActionType): any {
  try {
    const data = action.payload
    let API = UpdateWithVariables(API_URL.SOLUTION_PROVIDER.DETAILS, data.urlParams)
    if (data.urlParams) {
      delete data.urlParams
    }
    const resp: any = yield call(CallApi.GET, API, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {

      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}
export function* solutionProviderSave(action: ApiActionType): any {
  try {
    const data = action.payload

    const resp: any = yield call(CallApi.POST, API_URL.SOLUTION_PROVIDER.SAVE, data, true)
    let respChecker = responseHandler(resp)
    if (respChecker.status) {
      action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
    } else {
      action && action.callbackError && action.callbackError(respChecker.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}
export function* solutionProviderUpdate(action: ApiActionType): any {
  try {
    const data = action.payload

    const resp: any = yield call(CallApi.PUT, API_URL.SOLUTION_PROVIDER.UPDATE, data, true)
    let respChecker = responseHandler(resp)
    if (respChecker.status) {
      action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
    } else {
      action && action.callbackError && action.callbackError(respChecker.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}
export function* solutionProviderDelete(action: ApiActionType): any {
  try {
    const data = action.payload

    const resp: any = yield call(CallApi.DELETE, API_URL.SOLUTION_PROVIDER.DELETE, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {

      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}
export function* solutionProviderChangeStatus(action: ApiActionType): any {
  try {
    const data = action.payload
    const resp: any = yield call(CallApi.PUT, API_URL.SOLUTION_PROVIDER.CHANGE_STATUS, data, true)
    let respChecker = responseHandler(resp)
    if (respChecker.status) {
      action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
    } else {
      action && action.callbackError && action.callbackError(respChecker.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}
export function* usecaseLike(action: ApiActionType): any {
  try {
    const data = action.payload
    const resp: any = yield call(CallApi.POST, API_URL.USER.USECASE_LIKE, data, true)
    let respChecker = responseHandler(resp)
    if (respChecker.status) {
      yield put({
        type: ACTIONS.USECASE.USECASE,
        payload: {
          usecase: respChecker.data.data
        }
      })
      action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
    } else {
      action && action.callbackError && action.callbackError(respChecker.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}
//================== END:: Runway Section ===================//

export function* getUseCaseCaptains(action: ApiActionType): any {
  try {
    const data = action.payload

    const resp: any = yield call(CallApi.POST, API_URL.USER.USECASE_CAPTAINS, data, true)
    let respChecker = responseHandler(resp)
    if (respChecker.status) {
      action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
    } else {
      action && action.callbackError && action.callbackError(respChecker.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}


export function* assignInnovationManagerToUsecase(action: ApiActionType): any {
  try {
    const data = action.payload
    const resp: any = yield call(CallApi.POST, API_URL.USER.ASSIGN_INNOVATION_MANAGER_TO_USECASE, data, true)
    let respChecker = responseHandler(resp)
    if (respChecker.status) {
      action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
    } else {
      action && action.callbackError && action.callbackError(respChecker.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}


//================= START:: Role Permission =========// 
export function* menuPermissionGetByCompanyID(action: ApiActionType): any {
  try {
    const data = action.payload
    let API = UpdateWithVariables(API_URL.USER.MENU_PERMISSION.GET_LIST, data.urlParams)
    if (data.urlParams) {
      delete data.urlParams
    }
    const resp: any = yield call(CallApi.GET, API, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {

      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}


export function* menuPermissionSave(action: ApiActionType): any {
  try {
    const data = action.payload

    const resp: any = yield call(CallApi.POST, API_URL.USER.MENU_PERMISSION.SAVE, data, true)
    let respChecker = responseHandler(resp)
    if (respChecker.status) {
      action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
    } else {
      action && action.callbackError && action.callbackError(respChecker.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}


export function* executiveDashboardSummeryCoun(action: ApiActionType): any {
  try {
    const data = action.payload

    const resp: any = yield call(CallApi.POST, API_URL.USER.DASHBOARD.SUMMERY_COUNT, data, true)
    let respChecker = responseHandler(resp)
    if (respChecker.status) {
      action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
    } else {
      action && action.callbackError && action.callbackError(respChecker.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}
export function* executiveDashboardAvgDuration(action: ApiActionType): any {
  try {
    const data = action.payload

    const resp: any = yield call(CallApi.POST, API_URL.USER.DASHBOARD.AVG_DURATION_LIST, data, true)
    let respChecker = responseHandler(resp)
    if (respChecker.status) {
      action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
    } else {
      action && action.callbackError && action.callbackError(respChecker.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}
export function* executiveDashboardPhasePlan(action: ApiActionType): any {
  try {
    const data = action.payload

    const resp: any = yield call(CallApi.POST, API_URL.USER.DASHBOARD.PHASE_PLAN, data, true)
    let respChecker = responseHandler(resp)
    if (respChecker.status) {
      action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
    } else {
      action && action.callbackError && action.callbackError(respChecker.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}
export function* executiveDashboardPhaseAvg(action: ApiActionType): any {
  try {
    const data = action.payload

    const resp: any = yield call(CallApi.POST, API_URL.USER.DASHBOARD.PHASE_AVG, data, true)
    let respChecker = responseHandler(resp)
    if (respChecker.status) {
      action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
    } else {
      action && action.callbackError && action.callbackError(respChecker.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}



export function* periodComparisionDashboardAvgTotalDuration(action: ApiActionType): any {
  try {
    const data = action.payload

    const resp: any = yield call(CallApi.POST, API_URL.USER.DASHBOARD.AVG_TOTAL_DURATION, data, true)
    let respChecker = responseHandler(resp)
    if (respChecker.status) {
      action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
    } else {
      action && action.callbackError && action.callbackError(respChecker.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}
export function* periodComparisionDashboardAvgDurationBySection(action: ApiActionType): any {
  try {
    const data = action.payload

    const resp: any = yield call(CallApi.POST, API_URL.USER.DASHBOARD.AVG_DURATION_BY_SECTION, data, true)
    let respChecker = responseHandler(resp)
    if (respChecker.status) {
      action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
    } else {
      action && action.callbackError && action.callbackError(respChecker.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}
export function* periodComparisionDashboardSectionPhaseTable(action: ApiActionType): any {
  try {
    const data = action.payload

    const resp: any = yield call(CallApi.POST, API_URL.USER.DASHBOARD.SECTION_PHASE_TABLE, data, true)
    let respChecker = responseHandler(resp)
    if (respChecker.status) {
      action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
    } else {
      action && action.callbackError && action.callbackError(respChecker.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}
export function* periodComparisionDashboardAvgDurationPhase(action: ApiActionType): any {
  try {
    const data = action.payload

    const resp: any = yield call(CallApi.POST, API_URL.USER.DASHBOARD.AVG_DURATION_PHASE, data, true)
    let respChecker = responseHandler(resp)
    if (respChecker.status) {
      action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
    } else {
      action && action.callbackError && action.callbackError(respChecker.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}

export function* getNotifications(action: ApiActionType): any {
  try {
    const data = action.payload;
    let API = UpdateWithVariables(API_URL.USER.GET_NITIFICATIONS, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
    const resp: any = yield call(CallApi.GET, API, data, true)
    let respChecker = responseHandler(resp)
    if (respChecker.status) {
      action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
    } else {
      action && action.callbackError && action.callbackError(respChecker.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}

export function* getNotificationDetails(action: ApiActionType): any {
  try {
    const data = action.payload
    let API = UpdateWithVariables(API_URL.USER.GET_NITIFICATION_DETAILS, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
    const resp: any = yield call(CallApi.GET, API, data, true)
    let respChecker = responseHandler(resp)
    if (respChecker.status) {
      action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
    } else {
      action && action.callbackError && action.callbackError(respChecker.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}


export function* notificationList(action: ApiActionType): any {
  const data = action.payload
  let API = UpdateWithVariables(API_URL.NOTIFICATION.LIST, data.urlParams)
  if (data.urlParams) {
    delete data.urlParams
  }
  try {
    const resp: any = yield call(CallApi.GET, API, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
  }
}
export function* notificationDetails(action: ApiActionType): any {
  const data = action.payload
  let API = UpdateWithVariables(API_URL.NOTIFICATION.DETAILS, data.urlParams)
  if (data.urlParams) {
    delete data.urlParams
  }
  try {
    const resp: any = yield call(CallApi.GET, API, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
  }
}
export function* notificationUpdate(action: ApiActionType): any {
  try {
    const data = action.payload

    const resp: any = yield call(CallApi.PUT, API_URL.NOTIFICATION.UPDATE, data, true)
    let respChecker = responseHandler(resp)
    if (respChecker.status) {
      action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
    } else {
      action && action.callbackError && action.callbackError(respChecker.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}