import { combineReducers } from 'redux'
import loaderReducer from './common/loaderReducer'
import userReducer from './userReducer'
import formReducer from './formReducer'
import masterReducer from './masterReducer'
import usecaseReducer from './usecaseReducer'


import { ACTIONS } from '../../_config'

const appReducer = combineReducers({
  loader: loaderReducer,
  user: userReducer,
  form: formReducer,
  masters: masterReducer,
  usecase: usecaseReducer,
})

const rootReducer = (state: any, action: any) => {
  if (action.type === ACTIONS.RESET_REDUCER) {
    state = undefined;
    localStorage.removeItem('persist:root');
  }
  
  return appReducer(state, action)
}

export default rootReducer