import { SAGA_ACTIONS } from '../../../_config'
import { useApiCall } from '../common/appApiCallHook'



export function useFormApi() {
  const callApi = useApiCall()

  const call = (action:any,data: any, onSuccess: Function, onError: Function) => {
    callApi(action, data, onSuccess, onError)
  }

  const getSolutionID = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.FORM.GET_SOLUTION_ID, data, onSuccess, onError)
  }

  const addProduct = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.FORM.ADD_PRODUCT, data, onSuccess, onError)
  }

  const getProductsBySolution = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.FORM.GET_PRODUCTS_BY_SOLUTION, data, onSuccess, onError)
  }
  const productDelete = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.FORM.PRODUCT.DELETE, data, onSuccess, onError)
  }

  const getDynamicFormData = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.FORM.GET_DYNAMIC_FORM_DATA, data, onSuccess, onError)
  }
  const uploadFile = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.FORM.UPLOAD_FILE, data, onSuccess, onError)
  }

  const upload3rdPartyUrl = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.FORM.UPLOAD_3RD_PARTY_URL, data, onSuccess, onError)
  }




  const getAllScoreQuestions = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SCORE_QUESTION.LIST, data, onSuccess, onError)
  }
  const scoreQuestionSave = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SCORE_QUESTION.SAVE, data, onSuccess, onError)
  }
  const scoreQuestionUpdate = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SCORE_QUESTION.UPDATE, data, onSuccess, onError)
  } 
  const getScoreQuestions = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SCORE_QUESTION.DETAILS, data, onSuccess, onError)
  } 
  const scoreQuestionStatusChange = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SCORE_QUESTION.CHANGE_STATUS, data, onSuccess, onError)
  } 
  const scoreQuestionDelete = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SCORE_QUESTION.DELETE, data, onSuccess, onError)
  } 

  const getPhaseStatus = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.FORM.GET_PHASE_STATUS, data, onSuccess, onError)
  }  

  const setPhaseStatus = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.FORM.SET_PHASE_STATUS, data, onSuccess, onError)
  }  

  return {
    call,
    getSolutionID,
    addProduct,
    getProductsBySolution,
    productDelete,
    getDynamicFormData,
    uploadFile,
    upload3rdPartyUrl,
    getAllScoreQuestions,
    scoreQuestionSave,
    scoreQuestionUpdate,
    getScoreQuestions,
    scoreQuestionStatusChange,
    scoreQuestionDelete,
    getPhaseStatus,
    setPhaseStatus
  }
}
