import React, { useEffect, useState } from 'react'
import { Accordion, AccordionItem, Button, Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Error from '../components/elements/formFieldError';
import toast from 'react-hot-toast';
import { useFormApi } from '../_common/hooks/api/FormApiHook';
import { useUser } from '../_common/hooks/authHook';
import { useUserApi } from '../_common/hooks/api/UserApiHook';
import Icon from '../components/icon';
import { replaceUrlParams } from '../_config/helpers';
import { URLS } from '../_config';
import { showDate } from '../_config/functions';
import TableLoader from '../components/tableLoader';
import Pagination from '../components/pagination/Pagination';

interface props {
    show: boolean,
    onClose: (status: boolean) => void
    phase: any
}

export default function UsecaseFilteredModal({ show, onClose, phase }: props) {
    const handleClose = () => { onClose(false) };

    const location = useLocation();
    const navigate = useNavigate()
    const user: any = useUser();

    const formApi = useFormApi();
    const userApi = useUserApi();

    const [useCaseList, setUseCaseList] = useState<any>(null);
    const [page, setPage] = useState<number>(1)
    const [total, setTotal] = useState<number>(0)
    const [pageLimit, setPageLimit] = useState<number>(10)
    const [rankSort, setRankSort] = useState<number>(0)
    
    const [filter, setFilter] = useState<any>(null)

    const getSortIcon = (sort: number) => {
        switch (sort) {
            case 0: return <Icon name='unsorted' size={18} />;
            case 1: return <Icon name='sort-up' size={18} />;
            case 2: return <Icon name='sort-down' size={18} />;
            default: return null
        }
    }

    const getUseCaseList = () => {
        setUseCaseList(null);
        userApi.useCaseList({
            fkCompanyID: user.companyID,
            pageNo: page,
            rankSorting: rankSort,
            pageSize: pageLimit, ...filter
        }, (message: any, resp: any) => {
            setTotal(resp.totalRecords)
            setUseCaseList(resp.data.usecaseList.filter((usecase: any) => {
                if (usecase.fkStatusID == 18 && usecase.addedBy != user.userID) {
                    return false
                } else {
                    return true;
                }
            }));
        }, (message: any, resp: any) => {
            setUseCaseList([])
        })

    }

    useEffect(()=>{
        if(filter){
            getUseCaseList();
        }
    },[filter])

    useEffect(() => {
        if (useCaseList !== null) {
            getUseCaseList()
        }
    }, [page]);
    useEffect(()=>{
        if(phase){
            switch (phase) {
                case 'New':
                    setFilter({ sectionIDs: "0", phaseIDs: "0" })
                    break;
                case 'Discovery':
                    setFilter({ sectionIDs: "1", phaseIDs: "1" })
                    break;
                case 'Challenge Statement':
                    setFilter({ sectionIDs: "1", phaseIDs: "2" })
                    break;
                case 'Solution Framing':
                    setFilter({ sectionIDs: "1", phaseIDs: "3" })
                    break;
                case 'Ranking and Prioritization':
                    setFilter({ sectionIDs: "1", phaseIDs: "4" })
                    break;
                case 'Hanger Decision':
                    setFilter({ sectionIDs: "1", phaseIDs: "5" })
                    break;
                    case 'Project Plan':
                    setFilter({ sectionIDs: "2", phaseIDs: "1" })
                    break;
                    case 'Procurement Plan':
                    setFilter({ sectionIDs: "2", phaseIDs: "2" })
                    break;
                    case 'Test Plan':
                    setFilter({ sectionIDs: "2", phaseIDs: "3" })
                    break;
                    case 'In-Field Testing':
                    setFilter({ sectionIDs: "3", phaseIDs: "1" })
                    break;
                    case 'Day in the Life':
                    setFilter({ sectionIDs: "3", phaseIDs: "2" })
                    break;
                    case 'Field Acceptance':
                    setFilter({ sectionIDs: "3", phaseIDs: "3" })
                    break;
                    case 'Go/No Go':
                    setFilter({ sectionIDs: "4", phaseIDs: "1" })
                    break;

                default:
                    setUseCaseList([])
                    break;
            }

        }
    },[phase])


    return (
        <Modal show={show} onHide={handleClose} size='xl'>
            <Modal.Header closeButton>
                <Modal.Title>{phase}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="table-responsive list-table full-width">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col" className="w-5 text-center">Use Case ID</th>
                                <th scope="col"></th>
                                <th scope="col">Use Case Name</th>
                                <th scope="col">Airport</th>
                                <th scope="col">Captain</th>
                                <th scope="col">Section</th>
                                <th scope="col">Phase</th>
                                {/* <th scope="col" className="d-flex cursor-pointer" onClick={() => {
                                    let rs: number = rankSort + 1;
                                    if (rs > 2) { rs = 0 }
                                    setRankSort(rs)
                                }}>Rank {getSortIcon(rankSort)}</th> */}
                                <th scope="col">Rank</th>
                                <th scope="col">Likes</th>
                                <th scope="col">Strategic Value</th>
                                <th scope="col">Submission Date</th>
                                <th scope="col">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {useCaseList && useCaseList.length > 0 && (
                                useCaseList.map((usecase: any, i: any) => (
                                    <tr key={`usecase-${usecase.useCaseID}-${i}`}>
                                        <th className="text-center vr-middle">{usecase.useCaseID}</th>
                                        <td>
                                            <div className="table-pic">
                                                <Link to={replaceUrlParams(URLS.USECASE_DETAILS, { id: usecase.useCaseID })}>
                                                    <img src={usecase.imgURL} alt="" className="table-pic-img" />
                                                </Link>
                                            </div>
                                        </td>
                                        <td className="use-case-name">
                                            <h6>
                                                <Link to={replaceUrlParams(URLS.USECASE_DETAILS, { id: usecase.useCaseID })} className="text-dark">
                                                    {usecase.useCase}
                                                </Link>
                                            </h6>
                                            <p> {usecase.addedByName}  </p>
                                        </td>
                                        <td>{usecase.airPort ? usecase.airPort : '--'}</td>
                                        <td>{usecase.captain ? usecase.captain : '--'}</td>
                                        <td>{usecase.section ? usecase.section : '--'}</td>
                                        <td>{usecase.currentPhase ? usecase.currentPhase : '--'}</td>
                                        <td className="text-center">{usecase.rank ? usecase.rank : '--'}</td>
                                        <td className="text-center">{usecase.likes}</td>
                                        <td>{usecase.strategicValue ? usecase.strategicValue : '--'}</td>
                                        <td>{showDate(usecase.addedDate)}</td>
                                        <td className="text-center">
                                            <span className={`tags tags-${usecase.status.toLowerCase()}`}>
                                                {usecase?.fkStatusID == 10 ? (
                                                    'Under Review'
                                                ) : (
                                                    usecase.status
                                                )}
                                            </span>
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>

                    <TableLoader data={useCaseList} />
                    {useCaseList ? <Pagination total={total} page={page} setPage={setPage} pageLimit={pageLimit} /> : null}

                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}