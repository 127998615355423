export default function Loader() {
    return (
        <div className="d-flex justify-content-center" >
            <svg xmlns="http://www.w3.org/2000/svg" width="100px" height="20px" viewBox="0 0 100 20" preserveAspectRatio="xMidYMid">
                <g transform="translate(20 10)">
                    <circle cx="0" cy="0" r="6" fill="#b32f84">
                        <animateTransform attributeName="transform" type="scale" begin="-0.4411764705882353s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1.1764705882352942s" repeatCount="indefinite"></animateTransform>
                    </circle>
                </g><g transform="translate(40 10)">
                    <circle cx="0" cy="0" r="6" fill="#eb61ba">
                        <animateTransform attributeName="transform" type="scale" begin="-0.29411764705882354s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1.1764705882352942s" repeatCount="indefinite"></animateTransform>
                    </circle>
                </g><g transform="translate(60 10)">
                    <circle cx="0" cy="0" r="6" fill="#dc8dc0">
                        <animateTransform attributeName="transform" type="scale" begin="-0.14705882352941177s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1.1764705882352942s" repeatCount="indefinite"></animateTransform>
                    </circle>
                </g><g transform="translate(80 10)">
                    <circle cx="0" cy="0" r="6" fill="#efc6e1">
                        <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1.1764705882352942s" repeatCount="indefinite"></animateTransform>
                    </circle>
                </g>
            </svg>
        </div>
    )
}