import React, { useEffect, useRef, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import Loader from '../components/loader';
import { useUserApi } from '../_common/hooks/api/UserApiHook';
import parse from 'html-react-parser';

interface props {
    show: boolean,
    onClose: (status: boolean) => void,
    data:any
}

export default function NotificationModal({ show, onClose, data}: props) {
    const [notification, setNotification] = useState<any>(null);
    const handleClose = () => { onClose(false) };

    const userApi:any = useUserApi();

    const getNotificationDetails = ()=>{
        userApi.getNotificationDetails({urlParams:{userNotificationID:data.userNotificationID}},(message:any, resp:any)=>{
            console.log('Noti details =>',resp.data)
            setNotification(resp.data)
        },(message:any)=>{
            setNotification(null)
        })
    }

 
    useEffect(()=>{
        if(show){
            getNotificationDetails()
        } else {
            setNotification(null)
        }
    },[show])

    return (
        <Modal show={show} onHide={handleClose} size='xl'>
            <Modal.Header closeButton>
                <Modal.Title>{notification?.subject}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {notification != null ?
                    <div className='d-flex justify-content-between align-items-center'>
                        <div>
                            {parse(notification?.messageBody.replace('max-width:600px;','').replace('margin:60px auto;',''))}
                        </div>
                    </div>
                    : <Loader />
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}> Close </Button>
            </Modal.Footer>
        </Modal>
    )
}