import React, { useEffect, useRef, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom'
import toast from 'react-hot-toast';
import { useUser } from '../_common/hooks/authHook';
import { useWorkFlowApi } from '../_common/hooks/api/WorkFlowApiHook';
import { useUserApi } from '../_common/hooks/api/UserApiHook';
import { getSelectedValue, isImageUrl } from '../_config/helpers';
import Icon from '../components/icon';
import { useMasterWorkflows } from '../_common/hooks/masterHook';
import { useMasterApi } from '../_common/hooks/api/MasterApiHook';
import { useFormApi } from '../_common/hooks/api/FormApiHook';
import { ACTIONS, SAGA_ACTIONS, URLS } from '../_config';
import { useDispatch } from 'react-redux';
import { useControlTypes, useWorkflows } from '../_common/hooks/formHook';
import { showDate } from '../_config/functions';
import { useAppLoader } from '../_common/hooks/common/appLoaderHook';
import readXlsxFile from 'read-excel-file'
import XLSX from 'sheetjs-style'
import * as FileSaver from 'file-saver'
interface props {
    show: boolean,
    onClose: (status: boolean) => void,
}

export default function BulkUsecaseUploadModal({ show, onClose }: props) {
    const [visible, setVisible] = useState<boolean>(false);
    const handleClose = () => { setVisible(false); onClose(false) };

    const user: any = useUser();

    const { control, register, handleSubmit, reset, getValues, formState: { errors, isValid }, } = useForm()
    const workflowSteps = useMasterWorkflows();
    const masterApi = useMasterApi();
    const userApi = useUserApi();
    const formApi = useFormApi();
    const workflows = useWorkflows();
    const controlTypes: any = useControlTypes();

    const [modulies, setModulies] = useState<any[]>([])
    const [forms, setForms] = useState<any[]>([])
    const [correctRows, setCorrectRows] = useState<any>(null);
    const [config, setConfig] = useState<any>(null)
    const dispatch: any = useDispatch()

    const navigate = useNavigate()

    const tbodyRef:any = useRef();
    const tableHeadRowRef:any = useRef();

    const { showLoader, hideLoader } = useAppLoader()


    const getControlTypes = () => {
        formApi.call(SAGA_ACTIONS.FORM.GET_CONTROL_TYPES, { urlParams: { PageIndex: 0, PageSize: 999 } }, (message: any, resp: any) => {

        }, (message: any, resp: any) => {

        })
    }

    const getSections = () => {
        masterApi.getSections({ urlParams: { companyID: user.companyID } }, (msg: any, resp: any) => {
            let workflowSteps: any = [];
            let sections: any = resp.data.workFlowSection
            masterApi.getPhases({ urlParams: { companyID: user.companyID } }, (msg: any, resp: any) => {
                let phases: any = resp.data.workFlowPhase
                sections.map((section: any) => {
                    let sec: any = {
                        sectionDetails: section,
                        phases: []
                    };
                    phases.map((phase: any) => {
                        if (phase.fkSectionValue == section.sectionID) {
                            sec.phases.push(phase)
                        }
                    })
                    workflowSteps.push(sec)
                })
                dispatch({
                    type: ACTIONS.MASTER.WORKFLOWS, payload: {
                        workflows: workflowSteps
                    }
                })
            }, (msg: any) => { })
        }, (msg: any) => { })
    }

    const getWorkFlows = () => {
        formApi.call(SAGA_ACTIONS.FORM.GET_WORKFLOWS, { urlParams: { companyID: user.companyID } }, (message: any, resp: any) => { }, (message: any) => { })
    }

    const getModules = (workFlowID: any) => {
        formApi.call(SAGA_ACTIONS.FORM.GET_MODULES, { urlParams: { WorkFowID: workFlowID, companyID: user.companyID } }, (message: any, resp: any) => {
            setModulies(resp.data.moduleIs.map((mod: any) => {
                mod.value = mod.moduleID;
                mod.label = mod.pageTitle;
                if (mod.pageTitle == 'create-use-case') {//_module
                    getForms(mod.moduleID)
                }
                return mod
            }))
        }, (message: any, resp: any) => {

        })
    }

    const getForms = (moduleID: any) => {
        formApi.call(SAGA_ACTIONS.FORM.GET_FORMS, { urlParams: { ModuleID: moduleID, companyID: user.companyID } }, (message: any, resp: any) => {
            getFormConfig(resp.data.formID)
            setForms([{ ...resp.data, value: resp.data.formID, label: resp.data.title }])
        }, (message: any, resp: any) => {

        })
    }

    const getFormConfig = (formID: any) => {
        userApi.getFormConfig({ urlParams: { id: formID } }, (message: any, resp: any) => {
            setConfig(resp.data);
        }, (message: any) => {

        })
    }

    const upload3rdPartyUrl = (url:any) =>{
        return new Promise((resolve: any, reject: any) => {
            formApi.upload3rdPartyUrl({ fileURL: url }, (message: any, resp: any) => {
                resolve(resp.data.fileURL)
            }, (message: any) => {
                reject(message)
            })
        })

    };

    const AddErrorInfo = (msg:string)=>{
        return `<div class="info">
                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"></path></svg>
                <div class="tip">
                    <p>${msg}</p>
                </div>
            </div>
        `       
    }

    const addImageUrlAsImage = (cell:any, url:any, required:any)=>{

        if (isImageUrl(url)) {
            let img = document.createElement('img');
            img.src = url;
            cell.appendChild(img)               
            return true;
        } else {
            if(required){
                cell.classList.add('cell-error');
                cell.innerHTML = url + AddErrorInfo('Not an image URL.<br/>For images, we require an absolute image URL with the extension.<br/>Example: https://example.com/images/image.jpg.<br/>Accepted formats include jpg, jpeg, png, webp, gif, and svg');
                return false
            } else{
                if(url != ''){
                    cell.classList.add('cell-warn');
                    cell.innerHTML = url + AddErrorInfo('Not an image URL.<br/>For images, we require an absolute image URL with the extension.<br/>Example: https://example.com/images/image.jpg.<br/>Accepted formats include jpg, jpeg, png, webp, gif, and svg');
                }
                return true;
            }
            
        }

        
    }

    const CheckCheckboxOptions = (cell:any, data:any, options:any)=>{
        let _options = data.split('|');
        let checked = true
        _options.map((o:any)=>{
            if(options.indexOf(o) == -1){checked = false}
        })
        if(checked){
            cell.innerHTML = data;
            return true;
        } else {
            cell.classList.add('cell-error');
            cell.innerHTML = data + AddErrorInfo('Options Should be <b>Demonstration,Prototype and Pilot,</b> separated by | sign');
            return false
        }

    }


    const CheckRadioOptions = (cell:any, data:any, options:any)=>{
        if(options.indexOf(data) >=0){
            cell.innerHTML = data;
            return true
        } else {
            cell.classList.add('cell-error');
            cell.innerHTML = data + AddErrorInfo('Options Should be <b>'+options.join('</b> or <b>')+'</b>');
            return false
        }
    }

    const validateCellData = (index:number,data:any,newRow:any)=>{
        if(data == null){data = ''}
        data = data.toString();
        let field:any = config.controls[index];
        let cellStatus = true;
        if(field){
            let newCell = newRow.insertCell(); // add td
            if (data.trim() == "" && field.required) {
                newCell.classList.add('cell-error');
                newCell.innerHTML = data + AddErrorInfo('Data Required');
                cellStatus = false;
            } else {
                switch (field.inputType) {
                    case 'FileField':
                        let status = addImageUrlAsImage(newCell, data, field.required);
                        if(!status){cellStatus = false;}
                        break;
                    case 'CheckBox':
                        let chStatus = CheckCheckboxOptions(newCell, data, Object.values(field.options))
                        if(!chStatus){cellStatus = false;}
                        break;
                    case 'Radio':
                        let rStatus = CheckRadioOptions(newCell, data,Object.values(field.options))
                        if(!rStatus){cellStatus = false;}
                        break;
                    default:
                        newCell.innerHTML = data;
                        break;
                }
            }
            
        }

        return cellStatus;
        
    }


    const readCSVFile = (file: any) => {
        // FileReader Object
        var reader = new FileReader();

        // Read file as string 
        reader.readAsText(file);

        // Load event
        reader.onload = (event:any) => {
            // Read file data
            let csvdata = event.target.result;
            // Split by line break to gets rows Array
            let rowData = csvdata.split('\n');
            tableHeadRowRef.current.innerHTML = ""; 
            tbodyRef.current.innerHTML = ""; 
            let checkedRow:any = []
            for (let row = 0; row < rowData.length; row++) {
                let rowColData = rowData[row].split(',');
                
                if (row == 0) {
                    for (let col = 0; col < rowColData.length; col++) {
                        let th = document.createElement('th');
                        tableHeadRowRef.current.appendChild(th);
                        th.innerHTML = rowColData[col];
                    }
                } else {
                    let rowStatus = true;
                    let newRow = tbodyRef.current.insertRow();
                    for (let col = 0; col < rowColData.length; col++) {
                        let cellStatus = validateCellData(col,rowColData[col],newRow);
                        if(!cellStatus) {rowStatus = false}
                    }
                    //console.log('RowStatus =>', rowStatus)
                    if(rowStatus){
                        checkedRow.push(rowColData)
                    }
                }

            }
            setCorrectRows(checkedRow)
        };


    }

    const onSelectFile = (e:any)=>{
        if(e.target.files.length > 0 ){
            //readCSVFile(e.target.files[0])
            readXlsxFile(e.target.files[0]).then((rows:any) => {
                console.log('rows =>',rows)
                
                tableHeadRowRef.current.innerHTML = "";
                tbodyRef.current.innerHTML = "";
                let checkedRow: any = []
                for (let row = 0; row < rows.length; row++) {
                    let rowColData = rows[row]

                    if (row == 0) {
                        for (let col = 0; col < rowColData.length; col++) {
                            let th = document.createElement('th');
                            tableHeadRowRef.current.appendChild(th);
                            th.innerHTML = rowColData[col];
                        }
                    } else {
                        let rowStatus = true;
                        let newRow = tbodyRef.current.insertRow();
                        for (let col = 0; col < rowColData.length; col++) {
                            let cellStatus = validateCellData(col, rowColData[col], newRow);
                            if (!cellStatus) { rowStatus = false }
                        }
                        //console.log('RowStatus =>', rowStatus)
                        if (rowStatus) {
                            checkedRow.push(rowColData)
                        }
                    }

                }
                setCorrectRows(checkedRow)






              })
        }
    }


    const uploadRow = (index:any) => {
        return new Promise((resolve:any,reject:any)=>{
            let row:any = correctRows[index]
            let formData: any = {
                api:"api/v1/DynamicForm/Submit",
                "formId": 1,
                "fkCompanyID": user.companyID,
                "fkTenantID": user.tenantID,
                "metadata": [],
                "status": 1,
                "userID": user?.userID,
                "timeSpendInSec": 10,
                "answers": []
            }

            let images:any = {}

            config.controls.map(async (field: any, i:any) => {
                switch ((getSelectedValue(field.fkControlTypeID, 'controlTypeID', controlTypes) as any)?.controlName) {
                    case 'FileField':

                        let data: any = {
                            "fkFormControlID": field.fkFormControlID,
                            "inputType": "File",
                            "fkControlTypeID": field.fkControlTypeID,
                            "values": [],
                        }
                        formData.answers.push(data);
                        if (isImageUrl(row[i])) {
                            images[`img-${i}`] = false;
                            upload3rdPartyUrl(row[i]).then((url: any) => {
                                data.values = [{ text: '', values: url }]
                                images[`img-${i}`] = true;
                            })
                        }
                        
                        break;
                    case 'TextBox':
                        formData.answers.push({
                            "fkFormControlID": field.fkFormControlID,
                            "inputType": "TextBox",
                            "fkControlTypeID": field.fkControlTypeID,
                            "values": [{ text: '', values: row[i] }]
                        })
                        break;
                    case 'TextArea':
                        formData.answers.push({
                            "fkFormControlID": field.fkFormControlID,
                            "inputType": "TextArea",
                            "fkControlTypeID": field.fkControlTypeID,
                            "values": [{ text: '', values: row[i] }]
                        })
                        break;
                    case 'CheckBox':
                        let val: any = []
                        Object.keys(field.options).map((valueKey: any, k: any) => {
                            let checkedOptions = row[i].split('|');
                            checkedOptions.map((v:any)=>{
                                if(v == field.options[valueKey]){
                                    val.push({ text: '', values: valueKey })
                                }
                            })    
                        })
    
                        formData.answers.push({
                            "fkFormControlID": field.fkFormControlID,
                            "inputType": "CheckBox",
                            "fkControlTypeID": field.fkControlTypeID,
                            "values": val
                        })
                        break;
                    case 'Radio':
                        let rval:any = '';
                        Object.keys(field.options).map((valueKey: any, k: any) => {
                            if(row[i] == field.options[valueKey]){
                                rval = valueKey
                            }   
                        })
                        formData.answers.push({
                            "fkFormControlID": field.fkFormControlID,
                            "inputType": "Radio",
                            "fkControlTypeID": field.fkControlTypeID,
                            "values": [{ text: '', values: rval }]
                        })
    
                        break;
                    case 'Select':
                    case 'Dropdown':
                        let sval:any = '';
                        Object.keys(field.options).map((valueKey: any, k: any) => {
                            if(row[i] == field.options[valueKey]){
                                sval = valueKey
                            }   
                        })
                        formData.answers.push({
                            "fkFormControlID": field.fkFormControlID,
                            "inputType": "Select",
                            "fkControlTypeID": field.fkControlTypeID,
                            "values": [{ text: '', values: sval }]
                        })
    
                        break;
                    case 'DatePicker':
                        formData.answers.push({
                            "fkFormControlID": field.fkFormControlID,
                            "inputType": "DatePicker",
                            "fkControlTypeID": field.fkControlTypeID,
                            "values": [{ text: '', values: showDate(row[i], 'YYYY-MM-DD') }]
                        })
    
                        break;
    
                    default:
                        return null;
                }
            })

            let checking: any = setInterval(() => {
                let passed: boolean = true;
                Object.values(images).map((loaded: any) => {
                    if (!loaded) { passed = false }
                })
                if (passed) {
                    clearInterval(checking);
                    userApi.submitDynamicForm(formData, (message: any, resp: any) => {
                        index++;
                        if (index < correctRows.length) {
                            uploadRow(index)
                        } else {
                            hideLoader();
                            toast.success('imported successfully')
                            onClose(false);
                            navigate(URLS.USE_CASE_LIST + '?reload=true')
                        }
                    }, (message: any) => {
                        index++;
                        if (index < correctRows.length) {
                            uploadRow(index)
                        } else {
                            hideLoader();
                            toast.success('imported successfully')
                            onClose(false);
                            navigate(URLS.USE_CASE_LIST + '?reload=true')
                        }
                    })
                }
            }, 400)  

        })

    }

    const uploadRows = ()=>{
        let submited:any = {};
        showLoader();
        uploadRow(0)


      /*   let finalized = () => {
            let allComplete = true;
            let error = 0;
            Object.values(submited).map((status: any) => {
                if (submited === null) {
                    allComplete = false;
                }
                if (submited === false) {
                    error++
                }
            })
            if(allComplete){
                if(error === 0){
                    // good
                    hideLoader();
                    toast.success('imported successfully')
                    onClose(false);
                    navigate(URLS.USE_CASE_LIST+'?reload=true')
                } else {
                    // bad--
                    hideLoader();
                    toast.error( (Object.values(submited).length - error) +' out of '+Object.values(submited).length+' was imported successfully')
                    onClose(false)
                    navigate(URLS.USE_CASE_LIST+'?reload=true')
                }
            }
        }

        console.log('correctRows =>', correctRows)

        correctRows?.map((row:any, rowIndex:any)=>{
            console.log('Upload row ->', rowIndex)
            submited[`usecase-${rowIndex}`] = null;

            
            let formData: any = {
                api:"api/v1/DynamicForm/Submit",
                "formId": 1,
                "fkCompanyID": user.companyID,
                "fkTenantID": user.tenantID,
                "metadata": [],
                "status": 1,
                "userID": user?.userID,
                "timeSpendInSec": 10,
                "answers": []
            }

            let images:any = {}

            config.controls.map(async (field: any, i:any) => {
                switch ((getSelectedValue(field.fkControlTypeID, 'controlTypeID', controlTypes) as any)?.controlName) {
                    case 'FileField':

                        let data: any = {
                            "fkFormControlID": field.fkFormControlID,
                            "inputType": "File",
                            "fkControlTypeID": field.fkControlTypeID,
                            "values": [],
                        }
                        formData.answers.push(data);
                        if (isImageUrl(row[i])) {
                            images[`img-${i}`] = false;
                            upload3rdPartyUrl(row[i]).then((url: any) => {
                                data.values = [{ text: '', values: url }]
                                images[`img-${i}`] = true;
                            })
                        }
                        
                        break;
                    case 'TextBox':
                        formData.answers.push({
                            "fkFormControlID": field.fkFormControlID,
                            "inputType": "TextBox",
                            "fkControlTypeID": field.fkControlTypeID,
                            "values": [{ text: '', values: row[i] }]
                        })
                        break;
                    case 'TextArea':
                        formData.answers.push({
                            "fkFormControlID": field.fkFormControlID,
                            "inputType": "TextArea",
                            "fkControlTypeID": field.fkControlTypeID,
                            "values": [{ text: '', values: row[i] }]
                        })
                        break;
                    case 'CheckBox':
                        let val: any = []
                        Object.keys(field.options).map((valueKey: any, k: any) => {
                            let checkedOptions = row[i].split('|');
                            checkedOptions.map((v:any)=>{
                                if(v == field.options[valueKey]){
                                    val.push({ text: '', values: valueKey })
                                }
                            })    
                        })
    
                        formData.answers.push({
                            "fkFormControlID": field.fkFormControlID,
                            "inputType": "CheckBox",
                            "fkControlTypeID": field.fkControlTypeID,
                            "values": val
                        })
                        break;
                    case 'Radio':
                        let rval:any = '';
                        Object.keys(field.options).map((valueKey: any, k: any) => {
                            if(row[i] == field.options[valueKey]){
                                rval = valueKey
                            }   
                        })
                        formData.answers.push({
                            "fkFormControlID": field.fkFormControlID,
                            "inputType": "Radio",
                            "fkControlTypeID": field.fkControlTypeID,
                            "values": [{ text: '', values: rval }]
                        })
    
                        break;
                    case 'Select':
                    case 'Dropdown':
                        let sval:any = '';
                        Object.keys(field.options).map((valueKey: any, k: any) => {
                            if(row[i] == field.options[valueKey]){
                                sval = valueKey
                            }   
                        })
                        formData.answers.push({
                            "fkFormControlID": field.fkFormControlID,
                            "inputType": "Select",
                            "fkControlTypeID": field.fkControlTypeID,
                            "values": [{ text: '', values: sval }]
                        })
    
                        break;
                    case 'DatePicker':
                        formData.answers.push({
                            "fkFormControlID": field.fkFormControlID,
                            "inputType": "DatePicker",
                            "fkControlTypeID": field.fkControlTypeID,
                            "values": [{ text: '', values: showDate(row[i], 'YYYY-MM-DD') }]
                        })
    
                        break;
    
                    default:
                        return null;
                }
            })

            let checking:any =  setInterval(()=>{
                let passed:boolean = true;
                Object.values(images).map((loaded:any)=>{
                    if(!loaded){passed = false}
                })
                if(passed){
                    clearInterval(checking);
                    console.log('Form Data =>',formData);
                    userApi.submitDynamicForm(formData, (message: any, resp: any) => {
                        submited[`usecase-${rowIndex}`] = true;
                        finalized();
                    }, (message: any) => {
                        submited[`usecase-${rowIndex}`] = false;
                        finalized();
                    })
                }
            },400)  


            
        })
         */
      





    }

    useEffect(() => {
        if (workflowSteps == null && user) {
            getSections();
        }
    }, [workflowSteps, user])

    useEffect(() => {
        if (workflows == null && user) {
            getWorkFlows();
        }
    }, [user, workflows])

    useEffect(() => {
        if (workflows && controlTypes && show && config == null) {
            workflows.map((flow: any) => {
                if (flow.title == 'use-case') {
                    getModules(flow.workFlowID)
                }
            })
        }
    }, [workflows, controlTypes, show, config])

    useEffect(() => {
        if (!controlTypes) {
            getControlTypes()
        }
    }, [controlTypes])

    useEffect(()=>{
        setVisible(show)
        setCorrectRows(null);
    },[show])

    //  sample csv maker ----- 

    const downloadSampleCSV = (data:any) => { 
        /* console.log('CSV=>',data)
        let blob = new Blob([data], { type: 'text/csv' }); 
        let url = window.URL.createObjectURL(blob) 
        let a = document.createElement('a') 
        a.setAttribute('href', url) 
        a.setAttribute('download', 'import_usecases.csv');
        a.click() ; */

        /* let keys = data.split(',')
        let _data:any = {}
        keys.map((str:string)=>{
            _data[str] = ''
        }) */
        fnExcelReport(data)
    } 
      
    const csvmaker = (data:any) => { 
        let csvRows = []; 
        const headers = Object.keys(data); 
        //csvRows.push(headers.join(',')); 
        const values = Object.values(data).join(','); 
        csvRows.push(values) 
        return csvRows.join('\n') 
    } 
      
    const getSampleUsecaseCSV = async ()=> { 
        const data:any = { } 
        config.controls.map((field:any,i:any)=>{
            data[field.caption] = ''
        })
        downloadSampleCSV([data]); 
    } 




    function fnExcelReport(data:any) {
        let fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let fileExt = '.xlsx';

        let ws:any = XLSX.utils.json_to_sheet(data)
        let wb:any = {Sheets:{'data':ws}, SheetNames:['data']}
        let excelBuffer = XLSX.write(wb,{bookType:'xlsx',type:'array'})
        let _data:any = new Blob([excelBuffer],{type:fileType})

        FileSaver.saveAs(_data,'sample-usecase-data'+fileExt)


        /* let tab_text = '<html xmlns:x="urn:schemas-microsoft-com:office:excel">';
        tab_text = tab_text + '<head><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet>';
        //tab_text = tab_text + '<x:Name>Error Messages</x:Name>';
        tab_text = tab_text + '<x:WorksheetOptions><x:Panes></x:Panes></x:WorksheetOptions></x:ExcelWorksheet>';
        tab_text = tab_text + '</x:ExcelWorksheets></x:ExcelWorkbook></xml></head><body>';
        tab_text = tab_text + "<table border='1px'><thead><tr>";
        for (let i: any = 0; i < data.length; i++) {
            tab_text += `<td><b>${data[i]}</b></td>`
        }
        tab_text += `</tr></thead><tbody></tbody>`;
        tab_text = tab_text + '</table></body></html>';

        let data_type = 'data:application/vnd.ms-excel';

        let a = document.createElement('a') 
        a.setAttribute('href', data_type + ', ' + encodeURIComponent(tab_text)) 
        a.setAttribute('download', 'Test file.xlsx');
        a.click()  */
    }


   
    




    return (
        <Modal show={visible} onHide={handleClose} size='xl' scrollable={true}>
            <Modal.Header closeButton>
                <Modal.Title>Bulk Upload</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='d-flex justify-content-between align-items-center'>
                    <div>
                        <label>Choose a .xlsx file</label>
                        <input type='file' onChange={onSelectFile} accept=".xlsx" className='form-control' style={{height:'32px'}} />
                    </div>
                    <div>
                        <Button onClick={getSampleUsecaseCSV}>Download Sample XLSX</Button>
                    </div>
                </div>
                
                

                <hr />
                <div className="table-responsive list-table">
                    <table className="table table-striped csv-table">
                        <thead>
                            <tr ref={tableHeadRowRef}></tr>
                        </thead>
                        <tbody ref={tbodyRef}></tbody>
                    </table>
                </div>
                {(correctRows != null) && <h6>Got {correctRows?.length} row{correctRows?.length?'s':''} can be imported</h6>}

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}> Close </Button>
                <Button variant="primary" type='submit' onClick={uploadRows} disabled={(correctRows == null || correctRows?.length == 0)?true:false} >Upload </Button>
            </Modal.Footer>
        </Modal>
    )
}