import React, { useState } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import TitleBar from "../../components/titleBar/TitleBar";
import PrgressCircle from "./components/PrgressCircle";
import { DataFilters } from "../../components/dataFilters/DataFilters";
import { usecaseFilterData } from "../../_config/dataTypes";

const Dashboard2 = () => {
    const [filter, setFilter] = useState<usecaseFilterData>({})

    return (
        <React.Fragment>
            <Header></Header>
            <div className="main-container flex-grow-1">
                <div className="container">

                    <TitleBar title={'Executive summary dashboard'} breadcrumbs={{
                        'Executive summary dashboard': '#'
                    }} >

                    </TitleBar>

                    <DataFilters setFilter={setFilter} />


                    <div className=" executive-dashboard">
                        <div className="dashboard-top-part kpidashboard_top mb-5">
                            <div className="d-flex flex-wrap gap-3">
                                <div className="dashboard-top-part-body flex-1">
                                    <h3>Avg. Hanger duration</h3>
                                    <div className="dashboard-topgraph">
                                        <div className="dashboard-topgraphleft">
                                            <div className="d-flex align-items-center">
                                                <div className="dashboard-topPrice">0.00</div>
                                                <div className="dashboard-topOk">
                                                    <img src="/images/Done.png" />
                                                </div>
                                            </div>
                                            <p>Goal : 90(+100%)</p>
                                        </div>
                                        <div className="dashboard-topgraphright">
                                            <img src="/images/Vector 9.png" />
                                        </div>
                                    </div>
                                </div>
                                <div className="dashboard-top-part-body flex-1">
                                    <h3>Avg. runway duration</h3>
                                    <div className="dashboard-topgraph">
                                        <div className="dashboard-topgraphleft">
                                            <div className="d-flex align-items-center">
                                                <div className="dashboard-topPrice">0.00</div>
                                                <div className="dashboard-topOk">
                                                    <img src="/images/Done.png" />
                                                </div>
                                            </div>
                                            <p>Goal : 150(+100%)</p>
                                        </div>
                                        <div className="dashboard-topgraphright">
                                            <img src="/images/Vector 14.png" />
                                        </div>
                                    </div>
                                </div>
                                <div className="dashboard-top-part-body flex-1">
                                    <h3>Avg. in-the-air duration</h3>
                                    <div className="dashboard-topgraph">
                                        <div className="dashboard-topgraphleft">
                                            <div className="d-flex align-items-center">
                                                <div className="dashboard-topPrice">7.00</div>
                                                <div className="dashboard-topOk">
                                                    <img src="/images/Done.png" />
                                                </div>
                                            </div>
                                            <p>Goal : 90(+92.22%)</p>
                                        </div>
                                        <div className="dashboard-topgraphright">
                                            <img src="/images/Vector 13.png" />
                                        </div>
                                    </div>
                                </div>
                                <div className="dashboard-top-part-body flex-1">
                                    <h3>Avg. landed durations</h3>
                                    <div className="dashboard-topgraph">
                                        <div className="dashboard-topgraphleft">
                                            <div className="d-flex align-items-center">
                                                <div className="dashboard-topPrice">320.00</div>
                                                <div className="dashboard-topOk">
                                                    <img src="/images/Done.png" />
                                                </div>
                                            </div>
                                            <p>Goal : 395(+18.99%)</p>
                                        </div>
                                        <div className="dashboard-topgraphright">
                                            <img src="/images/Vector 15.png" />
                                        </div>
                                    </div>
                                </div>
                                <div className="dashboard-top-part-body flex-1">
                                    <h3>total use cases</h3>
                                    <div className="dashboard-topgraph">
                                        <div className="dashboard-topgraphleft">
                                            <div className="d-flex align-items-center">
                                                <div className="dashboard-topPrice">47</div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="dashboard-top-part kpidashboard_top mb-5">
                            <div className="d-flex flex-wrap gap-3">
                                <div className="dashboard-top-part-body flex-1">
                                    <h3>Avg. duration by date</h3>
                                    <div className="dashboard-topgraph">
                                        <img src="/images/graph_01.jpg" />
                                    </div>
                                </div>
                                <div className="dashboard-top-part-body flex-1">
                                    <h3>use cases by date and section</h3>
                                    <div className="dashboard-topgraph">
                                        <img src="/images/graph_02.jpg" />
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="dashboard-top-part kpidashboard_top mb-5">
                            <div className="d-flex flex-wrap gap-3">
                                <div className="dashboard-top-part-body flex-1">
                                    <h3>Avg. duration by phase</h3>
                                    <div className="dashboard-topgraph">
                                        <img src="/images/graph_03.jpg" />
                                    </div>
                                </div>
                                <div className="dashboard-top-part-body flex-1">
                                    <h3>Avg. duration by section</h3>
                                    <div className="dashboard-topgraph">
                                        <img src="/images/graph_04.jpg" />
                                    </div>
                                </div>

                            </div>
                        </div>



                    </div>
                </div>
            </div>
            <Footer></Footer>
        </React.Fragment>
    );
}

export default Dashboard2;