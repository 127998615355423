import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useUsecase } from '../../_common/hooks/usecaseHook';
import { useUser } from '../../_common/hooks/authHook';
import { useUserApi } from '../../_common/hooks/api/UserApiHook';
import { Button } from 'react-bootstrap';
import { useWorkFlowApi } from '../../_common/hooks/api/WorkFlowApiHook';
import { replaceUrlParams } from '../../_config/helpers';
import { URLS } from '../../_config';
import Swal from 'sweetalert2';
import toast from 'react-hot-toast';
import RejectScoringPhaseModal from '../../modals/rejectScoringPhase.modal';

interface props {


}
export const UseCaseStatusManageFooter = ({ }: props) => {
    const { id } = useParams();
    const usecase: any = useUsecase();
    const user: any = useUser();
    const userApi: any = useUserApi();
    const workflowApi: any = useWorkFlowApi();
    const [rejectModal, setRejectModal] = useState<boolean>(false);
    const navigate = useNavigate()

    const getUsecase = () => {
        return new Promise((resolve, reject) => {
            userApi.useCaseDetails({ urlParams: {companyID:user.companyID, id: id, userID: user.userID } }, (message: any, resp: any) => {
                resolve(true)
            }, (message: any) => { })
        })
    }

    const moveToNextPhase = () => {
        return new Promise((resolve, reject) => {
            let formParam = {
                "useCaseID": id,
                "fkCompanyID": user.companyID,
                "nextPhase": 'Ranking and Prioritization',
                "updatedBy": user.userID,
            };

            userApi.usecasePhaseChange(formParam, (message: any, resp: any) => {
                resolve(message);
            }, (message: any, resp: any) => {
                reject(message);
            })
        })
    }

    

    const accepting = () => {
        return new Promise((resolve, reject) => {
            workflowApi.approvePreScoring({
                "fkUseCaseID": usecase.useCaseID,
                "approvalStatus": 1,
                "fkCompanyID": user.companyID,
                "updatedBy": user.userID,
                "reason": ""
            }, (message: any, resp: any) => {
                moveToNextPhase().then(() => {
                    getUsecase().then(() => {
                        resolve(message)
                        navigate(replaceUrlParams(URLS.WORKFLOW.HANGER.SCORE, { id: id }))
                    }).catch(()=>{resolve('please Re-login ')})
                }).catch(()=>{resolve('please Re-login ')})
            }, (message: any) => { reject(message)})
        })
    }
    const accept = () => {
        Swal.fire({
            title: `Confirm alert!`,
            text: `Are you sure you want to approve this use case for scoring?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#B32F84',
            cancelButtonColor: '#44546A',
            confirmButtonText: `Yes, Approve!`
        }).then((result) => {
            if (result.isConfirmed) {
                toast.promise(
                    accepting(), {
                    loading: 'Approving...',
                    success: (msg: any) => {
                        return <b>{msg}</b>
                    },
                    error: (msg: any) => { return <b>{msg}</b> },
                });
            }
        });
    }

    const declining = () => {
        return new Promise((resolve, reject) => {
            workflowApi.approvePreScoring({
                "fkUseCaseID": usecase.useCaseID,
                "approvalStatus": 0,
                "fkCompanyID": user.companyID,
                "reason": "No reason"
            }, (message: any, resp: any) => {
                getUsecase().then(() => { resolve(message) }).catch(() => { resolve('please Re-login ') })
            }, (message: any, resp: any) => { reject(message) })
        })
    }
  


//|| user.userID == usecase.fkAitportManagerID
    return (
        <React.Fragment>
            {usecase ?
                <React.Fragment>
                    {usecase.status == 'Pre-scoring Review' ?
                        <React.Fragment>
                            {//user.userID === usecase.assignedInnovationManagerID  ?
                            user.roleKey == 'INNOVATION_MANAGER_ROLE' || user.roleKey == 'ADMIN'?
                                <div className='footer-sticky-btn' style={{ bottom: '0' }}>
                                    <p>Please review the solution framing of this use case and take the necessary decision.</p>
                                    <Button size='lg' onClick={accept}>Accept</Button>
                                    <Button size='lg' variant='secondary' onClick={()=>{setRejectModal(true)}}>Decline</Button>
                                </div>
                                :
                                <div className='footer-sticky-btn' style={{ bottom: '0' }}>
                                    <p>This use case is locked for Pre-Scoring review.</p>
                                </div>
                            }
                        </React.Fragment>
                        : null}
                </React.Fragment>
                : null}


            <RejectScoringPhaseModal show={rejectModal} onClose={setRejectModal} />
        </React.Fragment>
    )
}
