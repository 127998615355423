import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { showDate } from '../../../../_config/functions';
import { useWorkFlowApi } from '../../../../_common/hooks/api/WorkFlowApiHook'; 
import { useUser } from '../../../../_common/hooks/authHook';
import { getNameOnly } from '../../../../_config/helpers';

export const RunwayOverviewProjectPlan = () => {
    const { id } = useParams();
    const workFlowApi = useWorkFlowApi();
    const user: any = useUser();
    const [projectPlanList, setProjectPlanList] = useState<any>(null)

    const getAllProjectPlan = () => {
        workFlowApi.getAllProjectPlan({ urlParams: { companyID: user.companyID, UseCaseID: id } }, (message: any, resp: any) => {
            setProjectPlanList(resp.data?.projectPlan.filter((planItem: any) => {
                return planItem?.projectPlanID > 0
            }))
        }, (message: any) => {
            setProjectPlanList([]);
        })
    }

    useEffect(() => {
        getAllProjectPlan();
    }, [id])

    return (
        <React.Fragment>
            {
                projectPlanList && projectPlanList.length > 0 && (
                    <div className="white-box mb-3">
                        <h6>Project Plan</h6> <hr />

                        <div className="row">
                            {
                                projectPlanList.map((item: any, index: number) =>
                                    <div className="col-md-4 mb-3" key={Math.random()}>
                                        <div className="overview_box">
                                            <div className="d-flex ">
                                                <div className="circel">{index + 1}</div>
                                                <div className="p-0 m-0">
                                                    <p className="mb-2"><strong>{item.projectPlan}</strong></p>
                                                    <p className="mb-2">Uploaded by {getNameOnly(item.userFullName)} on {showDate(item.addedDate)}</p>
                                                    <p className="d-flex align-items-center mb-2">
                                                        <strong className="me-2">Download:</strong>
                                                        <a href={item.fileURL} download target='_blank'><i className="upload-icon"></i></a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                )
            }

        </React.Fragment>

    )
}
